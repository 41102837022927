<template>
    <div class="scenario-field-header with-elements bg-secondary text-white">
        Partner Distribution
        <div class="float-right">
            <modal v-model="modal">
                <template v-if="canEdit" v-slot:trigger="triggerProps">
                    <button @click="triggerProps.click" type="button" class="btn slim-button btn-primary">
                        <span class="fa fa-plus"></span> Add
                    </button>
                </template>
                <template v-slot:header>Select a Partner to Add</template>
                <template v-slot:default>
                    <Loading :active="loading" :is-full-page="false"></Loading>
                    <div class="mx-auto">
                        <select class="form-control" v-model="selected">
                            <option :disabled="partners.find((i)=>i.id === item.id)" v-for="(item,index) in availablePartners" v-bind:key="index" :value="item.id">{{item.long_name}}</option>
                        </select>
                        <div v-if="partner_error" class="alert alert-danger mt-3">{{partner_error}}</div>
                        <div class="row partner-modal">
                            <div class="col-6 mt-2">Make Scenario Featured?</div>
                            <div class="col-6">
                                <select class="form-control" v-model="featured">
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </select>
                            </div>
                        </div>
                        <div class="row partner-modal">
                            <div class="col-6 mt-2">Job Number</div>
                            <div class="col-6">
                                <input v-model="job_number" maxlength="255" class="job_number_edit" />
                            </div>
                            <div v-if="job_number_error" class='col-12'>
                                <div class="alert alert-danger mt-3">{{job_number_error}}</div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:footer>
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-default mx-2" @click="modal = !modal">{{partners.length === availablePartners.length ? 'Done' : 'Cancel'}}</button>
                        <button v-if="partners.length !== availablePartners.length" :disabled="loading" @click="add" class="btn btn-primary">Add</button>
                    </div>
                </template>
            </modal>
        </div>
    </div>

    <div class="card-body">
        <div class="scenario-field-title text-center" v-if="partners.length === 0">
            No Partners Selected
        </div>
        <div class="row" v-else>
            <div class="col-5"><strong>Partner</strong></div>
            <div class="col-7"><strong>Featured</strong></div>
        </div>
        <div class="scenario-field-title" v-for="(item, index) in scenarioPartners" v-bind:key="item.partner_id">
            <scenario-partner-distribution-field :can-edit="canEdit" :item="item" :scenario="scenario" v-on:updateFeatured="scenarioPartnerUpdate" v-on:deletePartner="scenarioDeletePartner(index)"/>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from "vue";
    import {IPartner} from "@/interfaces/partner";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    import {store} from "@/store";
    import Loading from "vue3-loading-overlay";
    import Modal from "@/components/modal.vue";
    import Partner from "@/models/partner";
    import Scenario from "../../models/scenario";
    import ScenarioPartnerDistributionField from "@/components/scenarios/scenario-partner-distribution-field.vue";
    export default defineComponent({
        name: "scenario-partner-distribution",
        components: {Modal, Loading, ScenarioPartnerDistributionField},
        props: {
            scenario:{type:Scenario,required:true},
            canEdit: {type: Boolean,default:false}
        },
        setup(props) {
            const modal = ref(false);
            const loading = ref(false);
            const availablePartners = ref<Array<IPartner>>([]);
            const scenarioPartnerId = ref();
            const selected = ref(0);
            const featured = ref(0);
            const partners = ref(props.scenario.data?.partners);
            const scenarioPartners = ref(props.scenario.data?.scenario_partners);
            const job_number = ref("");
            const has_add_errors = ref<Boolean>(false);
            const partner_error = ref<any>(false);
            const job_number_error = ref<any>(false);
            watch(modal, async (n) =>{
                if(availablePartners.value.length === 0 && n){
                    loading.value = true;
                    await Partner.list().then((res)=>{
                        availablePartners.value = res.data;
                    })
                    loading.value = false;
                }
            });
            const add = async ()=>{
                if(selected.value == 0){
                    partner_error.value = 'You must select a partner';
                    has_add_errors.value = true;
                }else{
                    partner_error.value = false;
                }
                if(job_number.value.replace(/\s/g, "") ==  ""){
                    job_number_error.value = 'You must provide a job number';
                    has_add_errors.value = true;
                }else{
                    job_number_error.value = false;
                }
                if(!has_add_errors.value){
                    loading.value = true;
                    await Scenario.addPartner(props.scenario.data?.id,selected.value,{featured:featured.value,job_number:job_number.value.toUpperCase()}).then((res)=>{
                        let newItem = availablePartners.value?.find((i)=>i.id === selected.value);
                        if(newItem) {
                            partners.value?.push(newItem)
                            // eslint-disable-next-line
                            props.scenario.data?.scenario_partners?.push(res.data[0]);
                        }
                        store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Partner added!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    });
                    modal.value = false;
                    loading.value = false;
                }
                has_add_errors.value = false;
            }

            const scenarioDeletePartner = async (index:any)=> {
                // eslint-disable-next-line
                props.scenario.data?.scenario_partners?.splice(index,1);
            }

            const scenarioPartnerUpdate = async (featured:any)=> {
                await Scenario.updateScenatioPartner(featured.scenarioPartnerId,{featured:featured.featured,job_number:featured.job_number}).then(()=>{
                    var ind = scenarioPartners.value?.findIndex((element:any) => element.id == featured.scenarioPartnerId);
                    if(typeof scenarioPartners.value != undefined){
                        //@ts-ignore
                        scenarioPartners.value[ind].featured = featured.featured;
                        //@ts-ignore
                        scenarioPartners.value[ind].job_number = featured.job_number;
                    }
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Featured Updated!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                });
            }

            return {
                add,
                availablePartners,
                featured,
                has_add_errors,
                job_number,
                job_number_error,
                modal,
                partners,
                partner_error,
                scenarioDeletePartner,
                scenarioPartnerId,
                scenarioPartnerUpdate,
                selected,
                scenarioPartners,
                loading,
            }
        }
    });
</script>

<style scoped>
    .job_number_edit{
        text-transform: uppercase;
    }
</style>
