<template>
    <div class="scenario-field-header with-elements bg-secondary text-white">
        {{observer ? 'Observer Assignment' : 'SP Assignment'}}
        <div class="float-right">
            <modal v-model="modal">
                <template v-if="canEdit" v-slot:trigger="triggerProps">
                    <button @click="triggerProps.click" type="button" class="btn slim-button btn-primary">
                        <span class="fa fa-plus"></span> Add
                    </button>
                </template>
                <template v-slot:header>Select a User to Assign</template>
                <template v-slot:default>
                    <Loading :active="loading" :is-full-page="false"></Loading>
                    <div class="mx-auto">
                        <select class="form-control" v-model="selected">
                            <option selected disabled hidden>-- Select A User --</option>
                            <option :disabled="selectedUsers.find((i)=>i.id === item.id)" v-for="(item,index) in availableUsers" v-bind:key="index" :value="item.id">{{item.name}}</option>
                        </select>
                    </div>
                </template>
                <template v-slot:footer>
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-default mx-2" @click="modal = !modal">{{selectedUsers.length === availableUsers.length ? 'Done' : 'Cancel'}}</button>
                        <button v-if="selectedUsers.length !== availableUsers.length" :disabled="loading" @click="add" class="btn btn-primary">Add</button>
                    </div>
                </template>
            </modal>
        </div>
    </div>

    <div class="card-body">
        <ul class="list-group list-group-flush">
            <li v-for="(item, index) in selectedUsers" v-bind:key="index" class="list-group-item">
                <div class="media align-items-center">
                    <div class="media-body px-2">
                        <span class="text-body d-block">{{ item.name }}</span>
                        <span class="d-block"><small>{{ item.email }}</small></span>
                    </div>
                    <a v-if="canEdit" href="javascript:void(0)" @click="remove(item.id)" class="d-block text-light text-large font-weight-light">×</a>
                </div>
            </li>
            <li v-if="selectedUsers.length === 0" class="list-group-item text-center">
                No User Selected
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref, watch} from "vue";
    import Scenario from "@/models/scenario";
    import {IUser} from "@/interfaces/user";
    import Loading from "vue3-loading-overlay";
    import Modal from "@/components/modal.vue";
    import {store} from "@/store";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    import {UserRole} from "@/enums/user-role";
    export default defineComponent({
        name: "scenario-user-assignment",
        components: {Modal, Loading},
        props: {
            scenario:{type:Scenario,required:true},
            canEdit: {type: Boolean,default:false},
            observer:{type:Boolean,default: false},
        },
        setup(props){
            const modal = ref(false);
            const loading = ref(false);
            const availableUsers = ref<Array<IUser>>([]);
            const selected = ref();
            const selectedUsers = ref(props.scenario.data?.users.filter((i:any)=>{
                if(props.observer){
                    return i.pivot.role_id === UserRole.OBSERVER;
                }else{
                    return i.pivot.role_id === UserRole.SP;
                }
            }));
            watch(modal, async (n) =>{
                if(availableUsers.value.length === 0 && n){
                    loading.value = true;
                    if(props.observer){
                        await Scenario.getObserverOptions(props.scenario.data?.id).then((res)=>{
                            availableUsers.value = res.data;
                        })
                    }else{
                        await Scenario.getSpOptions(props.scenario.data?.id).then((res)=>{
                            availableUsers.value = res.data;
                        })
                    }
                    loading.value = false;
                }
            });
            const add = async ()=>{
                loading.value = true;
                if(selected.value !== undefined){
                    await Scenario.addUser(props.scenario.data?.id,selected.value,props.observer ? UserRole.OBSERVER : UserRole.SP).then(()=>{
                        let newItem = availableUsers.value?.find((i)=>i.id === selected.value);
                        if(newItem) selectedUsers.value?.push(newItem);
                        store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'User added!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                        selected.value = undefined;
                        modal.value = false;
                    });
                }else{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error',content:'Please select a user to attach.',icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                }
                loading.value = false;
            }
            const remove = async (partnerId:number) =>{
                await Scenario.removeUser(props.scenario.data?.id,partnerId).then(()=>{
                    selectedUsers.value?.splice(selectedUsers.value?.findIndex((i)=>i.id === partnerId),1);
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'User Removed!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                });
            }
            return{
                add,
                availableUsers,
                modal,
                remove,
                selected,
                selectedUsers,
                loading,
            }
        }
    });
</script>

<style scoped>

</style>
