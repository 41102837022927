
import {api} from "@/store";
import {IProfession} from "@/interfaces/profession";

export class Profession{
    constructor(public data?:IProfession) {}

    static async getProfessions():Promise<any> {
        return await api.get('api/professions');
    }

    static async getProfession(id: Number | undefined): Promise<any>{
        return await api.get('api/professions/'+id);
    }
}