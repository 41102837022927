<template>
    <div class="card shadow-sm mb-4">
        <div class="card-header text-white bg-secondary font-weight-bold">Scheduled Interviews</div>
        <div class="card-content p-2">
            <div v-if="!loading">
                <span v-if="Object.keys(props.scheduled_interviews).length">
                    <div v-for="interview in props.scheduled_interviews" v-bind:key="interview.id" class="col-12" @click="router.push({name:'recruitment.pending',params:{user_id:interview.applicant_user_id}})">
                        <div class="row visit-card">
                            <div class="col-10">
                                <img :src="interview.applicant.image" class="rounded-circle float-left mr-2" width="75" />
                                <div class="border-right time-slot float-left pt-3 mr-2">
                                    <span class="text-secondary font-weight-bold">{{ moment(interview.local_time).format('MM/DD/YY') }}</span>
                                    <hr class="my-1">
                                    <span class="text-primary font-weight-bold">{{ moment(interview.local_time).format('h:mm') }}<sup>{{ moment(interview.local_time).format('a') }}</sup></span>
                                </div>
                                <div class="mobile-visit-card">
                                    <div class="font-weight-bold text-secondary lh-normal mb-1">{{interview.applicant.name}}</div>
                                    <div class="overflow-hidden">{{interview.applicant.email}}</div>
                                </div>
                            </div>
                            <div class="col-2 d-flex flex-column justify-content-center">
                                <h3 class="text-center"><i class='fas fa-arrow-circle-right text-primary'></i></h3>
                            </div>
                        </div>
                        <hr class="w-75" />
                    </div>
                    <lazy-pagination v-if="props.scheduled_interviews_total > 10" :items="props.scheduled_interviews" :total="props.scheduled_interviews_total" :page_start="page_start" :page_end="page_end" v-on:next-page="nextPage" v-on:previous-page="previousPage" :label="'Interviews'" />
                </span>
                <p v-else class="text-center pt-3">No Scheduled Interviews</p>
            </div>
            <div class="loading-card-area" v-else>
                <loading :active="loading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent,ref} from "vue";
    import Loading from 'vue3-loading-overlay';
    import moment from 'moment';
    import LazyPagination from '@/components/dashboard/lazy-pagination.vue';
    import router from "@/router";

    export default defineComponent({
        emits: ['change-page'],
        components: {
            Loading,
            LazyPagination
        },
        props: {
            scheduled_interviews: {type:Object, required:true},
            scheduled_interviews_total: {type:Number, required:true},
            loading: {type:Boolean, required:true}
        },
        setup(props, {emit}) {
            const page_start = ref(0);
            const page_end = ref(10);
            const nextPage = async() => {
                page_start.value = page_start.value + 10;
                page_end.value = page_end.value + 10;
                if(page_end.value > props.scheduled_interviews_total){
                    page_end.value = props.scheduled_interviews_total;
                }
                emit('change-page',{type:'scheduled_interviews','page_start':page_start.value});
            };
            const previousPage = async() => {
                page_start.value = page_start.value - 10;
                page_end.value = page_start.value + 10;
                emit('change-page',{type:'scheduled_interviews','page_start':page_start.value});
            };
            return {
                moment,
                nextPage,
                page_end,
                page_start,
                previousPage,
                props,
                router
            };
        }
    });
</script>
