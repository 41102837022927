<template>
    <div v-if="!loading && !has_errors">
        <div class="p-4 scenario-share">
            <button class="btn btn-primary mb-5" @click="printScenario">Print <i class="fas fa-print"></i></button>
            <h3>{{scenario.patient_name}}</h3>
            <p>{{scenario.title}}</p>
            <span v-for="field in formatted_field_data" :key="field.id">
                <h4>{{field.title}}</h4>
                <div v-for="data in field.fields" :key="data.id">
                    <p v-if="field.fields.length > 1" class="mb-1"><strong>{{data.title}}</strong></p>
                    <p v-html="data.value"></p>
                </div>
            </span>
            <h4>Visit Objective</h4>
            <p v-html="scenario.objective"></p>
            <h4>Target Audience</h4>
            <p v-html="scenario.target_audience"></p>
        </div>
    </div>
    <div v-else-if="!loading && has_errors">
        <div class="px-4">
            <h4>Error Loading Scenario</h4>
            <p>{{error_message}}</p>
        </div>
    </div>
    <Loading :active="loading" :is-full-page="true"></Loading>
</template>

<script lang="ts">
    import { defineComponent, ref } from "vue";
    import Loading from 'vue3-loading-overlay';
    import {IScenarioFieldGroup} from "@/interfaces/scenario-field-group";
    import router from "@/router";
    import Scenario from "@/models/scenario";
    export default defineComponent({
        components: {
            Loading
        },
        mounted(){
            this.getScenarioForPrint();
        },
        setup(){
            const error_message = ref('');
            const fields = ref<Array<IScenarioFieldGroup>>([]);
            const formatted_field_data = ref<Array<any>>([]);
            const has_errors = ref<Boolean>(false);
            const scenario = ref<any>(null);
            const loading = ref<Boolean>(true);
            const scenario_id = ref<number | undefined>(router.currentRoute.value.params.id ? parseInt(router.currentRoute.value.params.id as string) : undefined);

            const getScenarioForPrint = async() =>{
                loading.value = true;
                await Scenario.getForPrint(scenario_id.value).then((res:any)=>{
                    scenario.value = res.data.scenario;
                    fields.value = res.data.fields;
                    has_errors.value = false;
                    mapFields();
                },(err)=>{
                    error_message.value = err.response.data.message;
                    scenario.value = null;
                    has_errors.value = true;
                });
                loading.value = false;
            }

            const mapFields = () => {
                fields.value.forEach(function(group){
                    let hasData = false;
                    let formatted_fields:any = [];
                    group.fields.forEach(function(field){
                        let found_field = scenario.value?.fields?.find(function(elm:any){ return elm.scenario_field_id === field.id});
                        if(found_field){
                            hasData = true;
                            if(field.label == 'Learner Scenario') field.label = 'Scenario';
                            formatted_fields.push({
                                'title':field.label,
                                'value':found_field.value
                            });
                        }
                    });
                    if(hasData){
                        let array:any = [];
                        if(group.fields.length > 1){
                            array['title'] = group.label;
                        }else{
                            array['title'] = group.fields[0].label;
                        }
                        array['fields'] = formatted_fields;
                        formatted_field_data.value.push(array);
                    }
                });
            }

            const printScenario = async() => {
                window.print();
            }

            return {
                error_message,
                has_errors,
                formatted_field_data,
                getScenarioForPrint,
                loading,
                printScenario,
                scenario
            }
        }
    })
</script>

<style scoped lang="scss">
    .scenario-share {
        background: #fff;
        width: 100%;
        position: absolute;
    }
</style>
