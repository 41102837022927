<template>
    <div class="card shadow-sm mb-4">
        <div class="card-header text-white bg-secondary font-weight-bold">Session Counter</div>
        <div class="card-content">
            <div class="row m-auto" v-if="!loading">
                <div class="col-md-12 px-5 py-2">
                    <div v-if="partners.length" class="py-4">
                        <div v-for="partner in partners.slice(page_start,page_end)" v-bind:key="partner.id" class="row py-2">
                            <div :class="is_client_admin ? 'col-12' : 'col-11'">
                                <p>{{partner.long_name}} - {{partner.usage}}% ({{partner.used_seats}}/{{partner.seats}})</p>
                                <div class="w-100 session-bar">
                                    <div :class="partner.usage >= 80 ? 'bg-danger session-bar' : 'bg-primary session-bar'" :style="'width:' + partner.usage + '%'"></div>
                                </div>
                            </div>
                            <div v-if="!is_client_admin && partner.usage >= 80" class="col-1 d-flex flex-column justify-content-center">
                                <button class="btn btn-sm btn-primary" @click="clientList(partner)"><i class="fas fa-envelope"></i></button>
                            </div>
                        </div>
                        <pagination v-if="partners.length > 5" :items="partners" :page_start="page_start" :page_end="page_end" v-on:next-page="nextPage" v-on:previous-page="previousPage" label="Co-Brands" />
                    </div>
                    <div v-else>
                        <div class="row py-4">
                            <div class="col-12">
                                <p>No partners with session data available.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="loading-area" v-else>
                <loading :active="loading" :is-full-page="false"></loading>
            </div>
            <modal v-model="client_list_modal">
                <template v-slot:header>{{client_list_title}}</template>
                <p>Client Administrators</p>
                <div v-if="client_list.length">
                    <div v-for="client in client_list" v-bind:key="client.id">
                        {{client.first_name}} {{client.last_name}} ({{client.email}})
                    </div>
                    <div class="mt-4">
                        <button class="float-right btn btn-sm btn-primary" @click="copyEmails(copy_list)">Copy Email Addresses</button>
                    </div>
                </div>
                <div v-else>
                    <p>No client administrators associated to {{client_list_title}}.</p>
                </div>
            </modal>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent,ref} from "vue";
    import Loading from 'vue3-loading-overlay';
    import Modal from "@/components/modal.vue";
    import Pagination from "@/components/dashboard/pagination.vue";
    import Partner from '@/models/partner';

    export default defineComponent({
        components: {
            Loading,
            Modal,
            Pagination
        },
        props: {
            is_client_admin: {type:Boolean,default:false},
        },
        mounted() {
            this.getPartnerInfo();
        },
        setup(props) {
            const loading = ref<boolean>(true);
            const partners = ref({});
            const client_list = ref({});
            const client_list_modal = ref<boolean>(false);
            const client_list_title = ref<String>("");
            const copy_list = ref<String>("");
            const page_start = ref(0);
            const page_end = ref(5);

            const clientList = async(partner:any) => {
                client_list_title.value = partner.long_name;
                client_list.value = partner.client_admins;
                copy_list.value = "";
                for (const [key, value] of Object.entries(client_list.value)) {
                    key;
                    //@ts-ignore
                    copy_list.value += value.email + ";";
                }
                client_list_modal.value = true;
            }

            const copyEmails = async(user_email:any) => {
                var emails = document.createElement('textarea');
                emails.value = user_email;
                emails.style.position = 'absolute';
                emails.style.left = '-9999px';
                document.body.appendChild(emails);
                emails.select();
                document.execCommand('copy');
                document.body.removeChild(emails);
            }

            const getPartnerInfo = async() => {
                var post_data:any = {
                    'is_client_admin': props.is_client_admin
                };
                await Partner.getSessionCounterData(post_data).then((res)=>{
                    partners.value = res.data.partners
                },(err)=>{
                    console.log(err);
                });
                loading.value = false;
            }

            const nextPage = async() => {
                page_start.value = page_start.value + 5;
                page_end.value = page_end.value + 5;
                if(page_end.value > Object.keys(partners.value).length){
                    page_end.value = Object.keys(partners.value).length;
                }
            };

            const previousPage = async() => {
                page_start.value = page_start.value - 5;
                page_end.value = page_start.value + 5;
            };

            return {
                clientList,
                client_list,
                client_list_modal,
                client_list_title,
                copyEmails,
                copy_list,
                getPartnerInfo,
                loading,
                nextPage,
                page_end,
                page_start,
                partners,
                previousPage
            };
        }
    });
</script>
<style scoped lang="scss">
    .loading-area{
        height: 500px;
    }
    .session-bar{
        height: 10px;
        background: #F0F0F1;
    }
</style>
