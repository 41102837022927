<template>
    <slot name="trigger" :click="open"></slot>
    <modal v-model="modal" allow-close>
        <template v-slot:header>Edit Article</template>
        <div class="form-group">
            <label>Title</label>
            <input type="text" name="social-title" :class="{'is-invalid':!validation.title}" class="form-control" v-model="title" required maxlength="250">
            <input type="hidden" name="article_id" v-model="id">
            <small v-if="!validation.title" class="invalid-feedback">A title is required</small>
        </div>
        <div class="form-group">
            <label>Description</label>
            <textarea :class="{'is-invalid':!validation.description}" class="form-control" v-model="description" maxlength="400" required rows="3"></textarea>
            <small v-if="!validation.description" class="invalid-feedback">A description is required</small>
        </div>
        <div class="form-group">
            <label>Article Text</label>
            <textarea :class="{'is-invalid':!validation.article_text}" class="form-control" v-model="article_text" required rows="8"></textarea>
            <small v-if="!validation.article_text" class="invalid-feedback">The main body of the article is required</small>
        </div>
        <div class="row">
            <div class="form-group col-md-5">
                <label>Author</label> &nbsp;
                <select v-model="selected_author">
                    <option :value="null"> -- Select An Author --</option>
                    <option v-for="author in authorsObj" v-bind:key="author.author_id" :value="author.author_id">{{author.full_name}}</option>
                </select>
            </div>
            <div class="form-group col-md-7">
                <label>Publish On</label> &nbsp;
                <datepicker :class="{'is-invalid':!validation.published_on}" class="form-control" :lower-limit="dateRange" v-model="published_on"></datepicker>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6">
                <label>Current Web Image (Small)</label><br/>
                <img :src="image_path" width="150" alt="Current Image" title="Currnet Social Media Image"><br/>
                <label>Upload a New Image</label> <small><i>Max Size 5MB</i></small><br/>
                <input type="file" ref="imageFile" class="form-control" @change="onAttachment" accept="image/jpeg, image/png">
            </div>
            <div class="form-group col-md-6">
                <label>Current Socail OG Image (Large)</label><br/>
                <img :src="image_path_large" width="200" alt="Current OG Image" title="Currnet OG Tag Image"><br/>
                <label>Upload a New Image</label> <small><i>Max Size 5MB</i></small><br/>
                <input type="file" ref="imageFileLarge" class="form-control" @change="onAttachmentSocial" accept="image/jpeg, image/png">
            </div>
        </div>
        <template v-slot:footer>
            <div class="d-flex justify-content-end">
                <button @click="modal = !modal" class="btn btn-default mx-2">Cancel</button>
                <button @click="update" class="btn btn-primary">Save</button>
            </div>
        </template>
    </modal>
</template>

<script lang="ts">
    import {defineComponent, reactive, ref} from "vue";
    import Datepicker from 'vue3-datepicker';
    import Modal from "@/components/modal.vue";
    import moment from 'moment';
    import {IArticle} from '@/interfaces/article';
    import {store} from "@/store";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationIcon,NotificationColor} from "@/interfaces/notification";
    import {Article} from "@/models/article";

    export default defineComponent({
        name: "EditArticle",
        components: {
            Datepicker,
            Modal
        },
        props:{
            articleObj:{type:Object, required:true},
            authorsObj:{type:Object, required:true},
        },
        emits:['updateArticleData'],
        computed:{
            dateRange(){
                let today = new Date();
                let priorDate = new Date(new Date().setDate(today.getDate() - 30));
                return priorDate;
            }
        },
        setup(props, {emit}){
            let file = ref<File>();
            let fileLarge = ref<File>();
            const modal = ref(false);
            const open = ()=>modal.value = !modal.value;
            const id = ref(props.articleObj.id);
            const title = ref(props.articleObj.title);
            const description = ref(props.articleObj.description);
            const article_text = ref(props.articleObj.article_text);
            const published_on = ref(new Date(props.articleObj.published_on));
            const selected_author = ref(props.articleObj.author_id);
            const image_path = ref(props.articleObj.image_path);
            const image_path_large = ref(props.articleObj.image_path_large);
            const validation = reactive({
                'title':true,
                'description':true,
                'article_text':true,
                'published_on':true,
            })
            const onAttachment = async(event:any) => {
                file.value = event.target.files[0];
            }
            const onAttachmentSocial = async(event:any) => {
                fileLarge.value = event.target.files[0];
            }
            const resetForm = (newData:IArticle)=>{
                title.value = newData.title;
                description.value = newData.description;
                article_text.value = newData.article_text;
                published_on.value = new Date(newData.published_on);
                image_path.value = newData.image_path;
                image_path_large.value = newData.image_path_large;
            }
            const resetValidation = ()=>{
                validation.title = true;
                validation.description = true;
                validation.article_text = true;
                validation.published_on = true;
            }

            const update = () =>{
                //reset validation
                resetValidation();

                //check validation
                if(title.value == '') validation.title = false;
                if(description.value == '') validation.description = false;
                if(article_text.value == '') validation.article_text = false;
                if(published_on.value == null) validation.published_on = false;

                if(validation.title && validation.description && validation.article_text && validation.published_on){
                    //update
                    let formData = new FormData();
                    formData.append("id", id.value);
                    formData.append("title", title.value);
                    formData.append("description", description.value);
                    formData.append("article_text", article_text.value);
                    formData.append("author_id", selected_author.value);
                    formData.append("published_on", moment(published_on.value).format('YYYY-MM-DD HH:mm:ss'));
                    formData.append("file", file.value!);
                    formData.append("file_large", fileLarge.value!);

                    Article.updateArticle(formData).then((res)=>{
                        let article:IArticle = res.data;
                        emit('updateArticleData',article);
                        modal.value = false;
                        resetForm(article);
                        store.commit(MutationTypes.addSystemNotification,{title:'Success',content:'The article has been updated',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                    });
                }
            }

            return{
                Article,
                article_text,
                description,
                id,
                image_path,
                image_path_large,
                modal,
                moment,
                onAttachment,
                onAttachmentSocial,
                open,
                published_on,
                selected_author,
                update,
                title,
                validation,
            }
        }
    });
</script>

<style scoped>

</style>
