import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_scenario_field = _resolveComponent("scenario-field")!

  return (_openBlock(), _createBlock(_component_scenario_field, {
    "disable-label": _ctx.disableLabel,
    type: _ctx.field.type,
    field: _ctx.field.label,
    required: _ctx.field.required,
    items: _ctx.field.items,
    "tool-tip": _ctx.field.tool_tip,
    "can-edit": _ctx.canEdit,
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    onSave: _ctx.fieldUpdate
  }, null, 8, ["disable-label", "type", "field", "required", "items", "tool-tip", "can-edit", "modelValue", "onSave"]))
}