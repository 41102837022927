import {ActionContext, ActionTree} from "vuex";
import {Mutations, MutationTypes} from './mutations';
import {MutationTypes as ActionMutation} from '../ux/mutations';
import {State} from "./index";
import {api, RootState} from '@/store';
import {Credentials} from "@/interfaces/credentials";
import Notification from "@/models/notification";
import router from "@/router";
import {NotificationColor, NotificationIcon} from '@/interfaces/notification';
import {UserRole} from '@/enums/user-role';


export enum ActionTypes {
    login = "AUTH_LOGIN",
    user = "AUTH_USER",
    check = "AUTH_CHECK",
    logout = "AUTH_LOGOUT",
    hasPermission = 'AUTH_HAS_PERMISSION'
}

// !!! AUGUMENTED ACTION CONTEXT !!!
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.login](
    { commit }: AugmentedActionContext,
    payload: any
  ): void;
}

// [C.3] declara a ação setX
// [ActionTypes.setX]({ commit }, payload) {
//   commit(MutationTypes.X, payload);
// },
export const actions: ActionTree<State, RootState> & Actions = {
    async [ActionTypes.login]({ commit,dispatch }, payload:Credentials) {
        commit(MutationTypes.setAuthenticating, true);
        await api.get('sanctum/csrf-cookie');
        api.post('api/login',payload).then(
          async (res:any)=>{
              await dispatch(ActionTypes.user);
              commit(MutationTypes.setAuthenticating, false);
              commit(MutationTypes.setAuthenticated, true);
              commit(MutationTypes.setError, false);
              commit(MutationTypes.setPartner, res.data.partner);
              router.push({name:'dashboard'});
          }
        ).catch((err)=>{
            if(err.response){
                commit(MutationTypes.setErrorMessage, new Notification({title:'Authentication Failed',content:err.response.data.message, icon: NotificationIcon.ERROR, color:NotificationColor.RED}));
                commit(MutationTypes.setError, true);
                commit(MutationTypes.setAuthenticating, false);
                commit(MutationTypes.setAuthenticated, false);
            }
            console.warn(err);
        });

    },
    async [ActionTypes.user]({ commit }) {
        await api.get('api/auth/user',{}).then(
        (res:any)=>{
            if(res.data.partner === null){
                window.location = process.env.VUE_APP_API_URL;
            }
            commit(MutationTypes.setUser,res.data.user);
            commit(MutationTypes.setPermissions,res.data.permissions);
            commit(MutationTypes.setPartners,res.data.partners);
            commit(MutationTypes.setPartner,res.data.partner);
            commit(ActionMutation.setNotifications,res.data.notifications);
            commit(MutationTypes.setRegistationFields,res.data.registation_fields);
            commit(MutationTypes.setPasswordApproved,res.data.password_approved);
            const dashboard_roles:any = [];
            function hasHeadTrainer(ur:any){
                return (ur.role_id === UserRole.HEADTRAINER && ur.is_approved);
            }
            const has_head_trainer = res.data.user.user_roles.filter(hasHeadTrainer).length > 0;
            res.data.user.user_roles.forEach((user_role:any) => {
                if (this.getters.partner) {
                    if(user_role.partner_id === this.getters.partner.id && user_role.is_approved){
                        if(
                            !(user_role.role_id === UserRole.ADMIN && this.getters.user.props.super_admin) &&
                            !(user_role.role_id === UserRole.TRAINER && has_head_trainer) &&
                            !(user_role.role_id === UserRole.FINANCIALADMIN) &&
                            !(user_role.role_id === UserRole.EXCLUDEFROMREPORTS) &&
                            !(user_role.role_id === UserRole.REPORTMANAGER) &&
                            !(user_role.role_id === UserRole.PARTNER)
                        ){
                            dashboard_roles.push(user_role);
                        }
                    }
                }
            });
            //if super admin add admin role
            commit(MutationTypes.setDashboardRoles,dashboard_roles);
            if(res.data.user.super_admin){
                commit(MutationTypes.setDashboardView,UserRole.ADMIN);
            }else if(dashboard_roles.length){
                commit(MutationTypes.setDashboardView,dashboard_roles[0].role_id);
            }


            // echo.private('App.User.'+res.data.user.id).listen('.notification',(e:any)=>{
            //     const notification = {
            //         title: e.notification.title,
            //         content: e.notification.content,
            //         color: NotificationColor[e.notification.color],
            //         icon: NotificationIcon[e.notification.type],
            //         linkToNotificationCenter:true,
            //         showDismiss: false
            //     };
            //     commit(ActionMutation.addNotification,notification);
            // });
        }
        ).catch((err)=>{
            if(err.response){
                commit(MutationTypes.setError, true);
                commit(MutationTypes.setErrorMessage, new Notification({title:'Access Denied',content:'Failed to retrieve authenticated user', icon: NotificationIcon.WARNING, color:NotificationColor.YELLOW}));
            }
            console.warn(err);
        });
    },
    async [ActionTypes.check]({commit,dispatch}){
      await api.get('api/auth/check',{}).then(
        async (res:any)=>{
              //get authenticated user
              await dispatch(ActionTypes.user);
              commit(MutationTypes.setAuthenticated, true);
          }
        ).catch((err)=>{
            if(err.response){
                commit(MutationTypes.setError, true);
                commit(MutationTypes.setErrorMessage, new Notification({title:'Authentication Failed',content:err.response.data.message, icon: NotificationIcon.ERROR, color:NotificationColor.RED}));
                commit(MutationTypes.setAuthenticating, false);
                commit(MutationTypes.setAuthenticated, false);
            }
            console.warn(err);
        });
    },
    async [ActionTypes.logout]({commit}){
      await api.get('api/logout',{}).then(()=>{
          commit(MutationTypes.setError,false);
          commit(MutationTypes.setAuthenticating, false);
          commit(MutationTypes.setAuthenticated, false);
          delete api.defaults.headers['selected-partner'];
          localStorage.removeItem('selected-partner');
          commit(MutationTypes.setPartner, undefined);
          commit(MutationTypes.setRegistationFields,[]);
          commit(MutationTypes.setDashboardRoles,[]);
          commit(MutationTypes.setDashboardView,UserRole.ZERO);
          commit(MutationTypes.setUser,undefined);
          commit(MutationTypes.setPasswordApproved,false);
          router.push({name:'login'});
      });
    },
    [ActionTypes.hasPermission]({commit},payload:String) {
        return Object.values(this.state.auth.permissions).indexOf(payload) > -1;
    }
};
