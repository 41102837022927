export enum ScorecardDisplayType{
    ZERO,
    NONE,
    FEEDBACK,
    FEEDBACK_INDIVIDUAL,
    FEEDBACK_MERGED,
    FEEDBACK_INDIVIDUAL_MERGED,
    INDIVIDUAL,
    MERGED,
    INDIVIDUAL_MERGED
}
