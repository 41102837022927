
export const toKebabCase = (str:string) :string | null =>
    str &&
    // @ts-ignore
    str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toLowerCase())
        .join('-');
export const toSnakeCase = (str:string) :string | null =>
    str &&
    // @ts-ignore
    str.replace(/\W+/g, " ")
        .split(/ |\B(?=[A-Z])/)
        .map(x => x.toLowerCase())
        .join('_');
export const toTitleCase = (str:string) :string | null => {
    const frags = str.split('_');
    let i;
    for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}

