import {IQuestion} from "@/interfaces/question";
import {QuestionType} from "@/enums/question-type";

export default class Question{
    constructor(data: IQuestion) {
    }

    static parseType(type:QuestionType){
        switch (type) {
            case QuestionType.TRUEFALSE:
                return 'True/False';
            case QuestionType.LIKERT:
                return 'Likert 5'
            case QuestionType.LONGFILL:
                return 'Long Fill';
            case QuestionType.MULTIPLECHOICE:
                return 'Multiple Choice';
        }
    }
}