import {api} from '@/store';
import {IScenario} from "@/interfaces/scenario";
import {FormData} from "@/interfaces/form-config";
import {IComment} from "@/interfaces/comment";

export default class Scenario{
    constructor(public data:IScenario) {}

    /** SCENARIO **/
    static async addPartner(id:Number | undefined, partner_id:Number | undefined,form:FormData): Promise<any>{
        return await api.post('api/scenario/'+id+'/add-partner/'+partner_id,form);
    }
    static async addTrainer(id:Number | undefined, user_id:Number):Promise<any>{
        return await api.post('api/scenario/'+id+'/add-trainer',{user_id:user_id});
    }
    static async addUser(id:Number | undefined, user_id:Number | undefined, role_id:Number | undefined): Promise<any>{
        return await api.post('api/scenario/'+id+'/add-user',{user_id:user_id,role_id:role_id});
    }
    static async clone(form:FormData):Promise<any>{
        return await api.post('api/scenario/clone',form);
    }
    static async comment(id:Number | undefined,content:string, field?:string):Promise<any>{
        return await api.post('api/scenario/'+id+'/comment',{content:content,field:field})
    }
    static async create():Promise<any>{
        return await api.post('api/scenario');
    }
    static async deleteScenario(id:number | undefined):Promise<any>{
        return await api.get('api/scenario/'+id+'/delete');
    }
    static async get(id:number | undefined):Promise<any>{
        return await api.get('api/scenario/'+id);
    }
    static async getFields(id:Number | undefined):Promise<any>{
        return await api.get('api/scenario/'+id+'/fields');
    }
    static async getFieldsByType(id:Number | undefined, type:Number | undefined):Promise<any>{
        return await api.get('api/scenario/'+id+'/fields-by-type/'+type  );
    }
    static async getForShare(id:number | undefined):Promise<any>{
        return await api.get('api/scenario/'+id+'/share');
    }
    static async getForOverview(id:number | undefined):Promise<any>{
        return await api.get('api/scenario/'+id + '/overview');
    }
    static async getForPrint(id:number | undefined):Promise<any>{
        return await api.get('api/scenario/' + id + '/print');
    }
    static async getObserverOptions(id:number|undefined):Promise<any>{
        return  await api.get('api/scenario/'+id+'/observer-options');
    }
    static async getSpOptions(id:number|undefined):Promise<any>{
        return  await api.get('api/scenario/'+id+'/sp-options');
    }
    static async getTagOptions(id:number|undefined):Promise<any>{
        return  await api.get('api/scenario/'+id+'/tags');
    }
    static async getTrainerOptions(id:number|undefined):Promise<any>{
        return  await api.get('api/scenario/'+id+'/trainer-options');
    }
    static async getVerbalFeedback(id:number|undefined):Promise<any>{
        return await api.get('api/scenario/'+id+'/verbal-feedback');
    }
    static async hasVerbalFeedback(id:number|undefined):Promise<any>{
        return await api.get('api/scenario/'+id+'/has-verbal-feedback');
    }
    static async removePartner(id:Number | undefined, partner_id:Number | undefined): Promise<any>{
        return await api.post('api/scenario/'+id+'/remove-partner/'+partner_id);
    }
    static async removeUser(id:Number | undefined, user_id:Number | undefined): Promise<any>{
        return await api.post('api/scenario/'+id+'/remove-user/'+user_id);
    }
    static async take(id:Number | undefined):Promise<any>{
        return await api.post('api/scenario/'+id+'/take');
    }
    static async update(form:FormData,id:number | undefined):Promise<any>{
        return await api.put('api/scenario/'+id,form);
    }
    static async updateScenatioPartner(scenarioPartnerId:number,form:any):Promise<any>{
        return await api.put('api/scenario/'+scenarioPartnerId+'/udpate',form);
    }
    static async updateField(id:Number | undefined, field_id:number,form:any):Promise<any>{
        return await api.post('api/scenario/'+id+'/fields/'+field_id,form);
    }

    /** SCENARIOS **/
    static async list():Promise<any>{
        return api.get('api/scenario');
    }
    static async getAuthoringData():Promise<any>{
        return  await api.get('api/scenarios/authoring');
    }
    static async getReviewerData():Promise<any>{
        return  await api.get('api/scenarios/reviewing');
    }
    static async myScenarios():Promise<any>{
        return api.get('api/scenarios/my-scenarios');
    }
    static async neeedReview():Promise<any>{
        return api.get('api/scenarios/need-review');
    }
    static async inReview():Promise<any>{
        return api.get('api/scenarios/in-review');
    }
    static async approved():Promise<any>{
        return api.get('api/scenarios/approved');
    }
    static async archived():Promise<any>{
        return api.get('api/scenarios/archived');
    }


    addComment(comment:IComment){
        this.data?.comments.push(comment);
    }
    getCommentsByField(field:String) :Array<IComment>{
        return this.data?.comments.filter((i:IComment)=>i.field === field) ?? [];
    }
    setField(field:String,value:any){
        // @ts-ignore
        this.data[field] = value;
    }
    getField(field:String){
        // @ts-ignore
        return this.data[field];
    }
    async saveField(field:String,value:any){
        return await api.put('api/scenario/'+this.data?.id+'/update-field/'+field,{value:value});
    }
    getStatus(){
        return this.data?.status;
    }
    static async getScorecardDisplaySettings(scenario_id:Number|undefined):Promise<any>{
        return api.get('api/scenario/' + scenario_id + '/scorecard-display');
    }

    static async saveScorecardDisplaySettings(scenario_id:Number|undefined,form:any):Promise<any>{
        return await api.post('api/scenario/' + scenario_id + '/scorecard-display/save',form);
    }

    static async saveFeeSettings(scenario_id:Number|undefined,form:any):Promise<any>{
        return await api.post('api/scenario/' + scenario_id + '/scenario-fee/save',form);
    }
}
