<template>
    <div>
        <h1>Social Media Posts</h1>
    </div>
    <div class="social-media-posts" v-if="!loading">
        <div id="social-media" v-if="posts.length" class="card py-2 px-2">
            <table>
                <tr>
                    <th class="header-row py-2 px-2">Title</th>
                    <th class="header-row py-2">Date</th>
                    <th class="header-row py-2"></th>
                </tr>
                <tr v-for="(post, index) in posts" v-bind:key="index">
                    
                    <td class="py-1 px-2">{{post.title}}</td>
                    <td class="py-1">{{moment(post.published_on).format('YYYY-MM-DD')}}</td>
                    <td>
                        <edit-social-post :post-obj="post" @updatePostData="updateSocialPost" :key='post.id'>
                            <template v-slot:trigger="triggerProps">
                                <button @click="triggerProps.click" class="ml-4 btn btn-primary btn-sm">Edit Post</button>
                            </template>
                        </edit-social-post>&nbsp;&nbsp;
                        <delete-social-post :post-obj="post" @deletePostData="deleteSocialPost(index)" :key='post.id'>
                            <template v-slot:trigger="triggerProps">
                                <button @click="triggerProps.click" class="ml-4 btn btn-primary btn-sm">Delete</button>
                            </template>
                        </delete-social-post>
                    </td>
                </tr>
            </table>
        </div>
        <div v-else id="social-media" class="card py-2 px-2">
            <table>
                <tr>
                    <th class="header-row py-2 px-2">Title</th>
                    <th class="header-row py-2">Date</th>
                    <th class="header-row py-2"></th>
                </tr>
                <tr>
                    <td class="text-center" colspan="3">There are no social media posts at this time.</td>
                </tr>
            </table>
        </div>
        <create-social-post @update="newSocialPost">
            <template v-slot:trigger="triggerProps">
                <button @click="triggerProps.click" class="ml-2 my-4 btn btn-primary">Create New Social Media Post</button>
            </template>
        </create-social-post>
    </div>
    <br/>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { api } from "@/store";
    import { defineComponent, ref } from "vue";
    import { store } from '@/store';
    import Loading from 'vue3-loading-overlay';
    import moment from 'moment';
    import CreateSocialPost from '@/components/create-social-post.vue';
    import DeleteSocialPost from '@/components/delete-social-post.vue';
    import EditSocialPost from '@/components/edit-social-post.vue';

    export default defineComponent({
        components: {
            CreateSocialPost,
            DeleteSocialPost,
            EditSocialPost,
            Loading,
        },
        name: "SocialMedia",
        mounted(){
            this.getSocialPosts();
        },
        setup() {
            const edit_post_modal = ref<Boolean>(false);
            const loading = ref(false);
            const posts = ref<any>({});
            const showEditPostModal = () => edit_post_modal.value = !edit_post_modal.value;

            const deleteSocialPost = (index:number) =>{
                if (index !== undefined && index !== null) {
                    posts.value.splice(index, 1);
                }
            }

            const getSocialPosts = async () => {
                loading.value = true;
                await api.get('sanctum/csrf-cookie');

                api.get('api/social',{}).then((res)=>{
                    var formatted:any = [];

                    res.data.forEach(function(elm:any){

                        formatted.push({
                            'id': elm.id,
                            'title': elm.title,
                            'description': elm.description,
                            'image_path': elm.image_path,
                            'published_on': elm.published_on,
                        });

                    });
                    posts.value = formatted;
                    loading.value = false;

                },(err)=>{
                    console.log(err);
                    loading.value = false;
                });
            }

            const newSocialPost = (newSocialPost:any) =>{
                posts.value.push(newSocialPost);
            }

            const updateSocialPost = async(updateSocialPost:any)=>{
                loading.value = true;
                if (updateSocialPost !== undefined) {
                    let index = posts.value.findIndex(
                        (function(elm:any){
                            return elm.id === updateSocialPost.id})
                        );
                    if (index !== -1) {
                        posts.value[index] = updateSocialPost;
                    }
                }
                loading.value = false;
            }

            return {
                user: store.state.auth.user,
                deleteSocialPost,
                getSocialPosts,
                loading,
                moment,
                newSocialPost,
                posts,
                showEditPostModal,
                updateSocialPost,
            };
        },
    });
</script>

<style lang="scss">
    
</style>
