<template>
    <div>
        <div class="row card text-white rounded-0 top-header">
            <div class="col-12 text-center">
                <h2 class="my-3 font-weight-bold">Participant Feedback</h2>
            </div>
        </div>
        <div class="row card rounded-0">
            <div class="col-12 card-header">
                <div class="row">
                    <div class="col-sm-12 col-md-8">
                        <img :src="visit.scenario.patient_image.location" class="rounded-circle float-left mr-4" width="100" />
                        <h4 class="pb-0 mb-0">{{visit.scenario.patient_name}}</h4>
                        <p class="mb-2">{{visit.scenario.title}}</p>
                        <p class="text-primary">Visit was completed on {{moment(visit.local_time).format('MM/DD/YY') }} at {{ moment(visit.local_time).format('h:mma') }}</p>
                    </div>
                    <div class="col-sm-12 col-md-4 text-right">
                        <button class="btn btn-lg btn-secondary-alt w-75" @click="returnToVisit">Return to Visit</button>
                    </div>
                </div>
            </div>
            <div class="col-12" v-if="view == 'form'">
                <div class="row">
                    <div class="col-12 px-5 py-4 text-center font-weight-bold" v-html="visit.scenario.participant_feedback.instructions"></div>
                    <hr class="w-75" />
                    <div class="col-12" v-for="(question_set,key) in questions" :key="key">
                        <h3 v-if="question_set.name !== null" v-html="question_set.name" class="col-12 text-center"></h3>
                        <div v-if="question_set.instructions !== null" v-html="question_set.instructions" class="col-12 px-5 pt-1 pb-4 text-center font-weight-bold"></div>
                        <div class="card-columns px-3">
                            <div v-for="(question,k) in question_set" :key="k">
                                <div v-if="k !== 'instructions' && k !== 'name'" class="card mb-4 shadow-sm w-100">
                                    <div class="card-header bg-secondary text-white font-weight-bold">Question {{parseInt(k) + 1}}</div>
                                    <div class="card-body">
                                        <span v-html="question.question"></span>
                                        <hr>
                                        <div v-if="question.question_type_id == QuestionType.MULTIPLECHOICE">
                                            <div v-for="answer in question.answers" :key="answer.id">
                                                <label class="radio-custom"><span>{{answer.answer}}</span><input type="radio" :id="answer.id" :name="question.id" :value="answer.id" @click="clearError('error_'+question.id)"><span class="checkmark"></span></label>
                                            </div>
                                        </div>
                                        <div v-if="question.question_type_id == QuestionType.TRUEFALSE">
                                            <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
                                                <label v-for="answer in question.answers" :key="answer.id" :class="'p-3 btn btn-light btn_ques_' + question.id" :for="answer.id"><input type="radio" :id="answer.id" :name="question.id" :value="answer.id" @click="buttonUpdate">{{answer.answer}}</label>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                        <div v-if="question.question_type_id == QuestionType.LONGFILL">
                                            <textarea class="w-100" :name="question.id" @input="clearError('error_'+question.id)"/>
                                        </div>
                                        <div v-if="question.question_type_id == QuestionType.LIKERT">
                                            <div class="row">
                                                <div class="col-6">
                                                    <small>
                                                        <span v-for="(answer,index) in question.answers" :key="answer.id">
                                                            {{index + 1}} = {{answer.answer}}<span v-if="index !== 4">, </span>
                                                        </span>
                                                    </small>
                                                </div>
                                                <div class="col-6">
                                                    <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                                                        <label v-for="(answer,index) in question.answers" :key="answer.id" :class="'p-3 btn btn-light btn_ques_' + question.id" :for="'ans_' + answer.id">
                                                            <input type="radio" :id="'ans_' + answer.id" :name="question.id" :value="answer.id" @click="buttonUpdate">{{index + 1}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <error-alert :elm_id="'error_'+question.id" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="w-75" />
                    </div>
                    <div class="col-12 text-center">
                        <button class="btn btn-lg btn-primary my-4 px-5 font-weight-bold" @click="submitParticipantFeedback">SUBMIT FEEDBACK</button>
                    </div>
                </div>
            </div>
            <div class="col-12" v-if="view == 'observe'">
                <div class="row">
                    <div class="col-12 mt-4">
                        <completed-form :form="visit.scenario.participant_feedback" :visit_form="visit.participant_feedback" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { defineComponent, reactive, ref } from "vue";
    import { store } from '../store/index';
    import {FormConfig} from "@/interfaces/form-config";
    import Form from "@/utilities/form";
    import Loading from 'vue3-loading-overlay';
    import {QuestionType} from "@/enums/question-type";
    import moment from 'moment';
    import {NotificationIcon, NotificationColor} from "@/interfaces/notification";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import ParticipantFeedback from "@/models/participant-feedback";
    import ErrorAlert from "@/components/error-alert.vue";
    import CompletedForm from "@/components/completed-form.vue";

    export default defineComponent({
        emits: ['participant-feedback-completed','return-to-visit'],
        components: { ErrorAlert, CompletedForm, Loading },
        props: {
            visit:{type:Object,required:true},
            view:{type:String,required:true}
        },
        mounted(){
            this.formatQuestions();
        },
        setup(props, {emit}) {
            const loading = ref(false);
            const loadErrorMessage = ref('');
            const questions = ref<Array<any>>([]);
            const submitForm:FormConfig = reactive({
                visit_id: {
                    value:props.visit.id,
                    valid:true,
                    validationMessage:''
                },
                responses: {
                    value:[],
                    valid:false,
                    validationMessage:''
                }
            });
            const submitFormHelper = new Form(submitForm);

            const buttonUpdate = async(event: { target: HTMLInputElement; }) => {
                let classes = event?.target.parentElement?.classList;
                let target_class = null;
                let err_class = null;
                classes?.forEach((element) => {
                    if(element.startsWith('btn_ques')){
                        target_class = element;
                        err_class = element.replace('btn_ques','error');
                    }
                });
                if(target_class !== null){
                    if(err_class !== null){
                        clearError(err_class);
                    }
                    let btn_group: Element[] = Array.from(document.getElementsByClassName(target_class));
                    for(let btn of btn_group){
                        btn.classList.remove('active');
                    }
                    event?.target.parentElement?.classList.add('active');
                }
            }

            const clearError = (err_class:string) => {
                let ques_err = document.getElementById(err_class);
                if(ques_err !== null){
                    ques_err.innerHTML = '';
                    ques_err.classList.add('d-none');
                }
            }

            const formatQuestions = async() => {
                let index = 0;
                let q_index = 0;
                props.visit.scenario.participant_feedback.questions.forEach((elm:any) => {
                    if(elm.question !== null){
                        if(typeof(questions.value[index]) == 'undefined'){
                            questions.value[index] = {};
                        }
                        questions.value[index][q_index] = elm.question;
                        q_index++;
                    }else if(elm.rubric !== null){
                        if(typeof(questions.value[index]) !== 'undefined'){
                            index++;
                        }
                        questions.value[index] = {};
                        q_index = 0;
                        questions.value[index]['name'] = elm.rubric.name;
                        questions.value[index]['instructions'] = elm.rubric.learner_instructions;
                        elm.rubric.questions.forEach((rubq:any) => {
                            questions.value[index][q_index] = rubq;
                            q_index++;
                        });
                        q_index = 0;
                        index++;
                    }
                });
            }

            const returnToVisit = () => {
                emit('return-to-visit');
            }

            const submitParticipantFeedback = async() => {
                loading.value = true;
                let responses:Array<any> = [];
                let response = '';
                let has_errors = false;
                var errors: string[] = [];
                questions.value.forEach((ques_set:any) => {
                    Object.keys(ques_set).forEach((key:any) => {
                        if(key !== 'instructions' && key !== 'name'){
                            let ques = ques_set[key];
                            response = '';
                            if([QuestionType.MULTIPLECHOICE, QuestionType.TRUEFALSE, QuestionType.LIKERT].includes(ques.question_type_id)){
                                let answers = document.getElementsByName(ques.id);
                                answers.forEach((ans:any) => {
                                    if(ans.checked){
                                        response = ans.value;
                                    }
                                });
                            }else if(ques.question_type_id == QuestionType.LONGFILL){
                                response = (document.getElementsByName(ques.id)[0] as HTMLInputElement).value;
                            }else{
                                has_errors = true;
                                errors[ques.id] = 'Invalid question type';
                            }
                            if(response == ''){
                                errors[ques.id] = 'Response is required';
                                has_errors = true;
                            }else{
                                responses[ques.id] = {
                                    question_id: ques.id,
                                    response: response
                                }
                            }
                        }
                    });
                });
                if(has_errors){
                    for(var key in errors){
                        let error_msg = document.getElementById('error_' + key);
                        if(error_msg !== null){
                            error_msg.innerHTML = errors[key];
                            error_msg.classList.remove('d-none');
                        }
                    }
                    store.commit(MutationTypes.addSystemNotification, {title:'All fields must be completed before submitting feedback form.',content:'',icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    loading.value = false;
                }else{
                    submitForm.responses.value = responses;
                    submitForm.responses.valid = true;
                    await ParticipantFeedback.submitVisitParticipantFeedback(props.visit.id,submitFormHelper.mapValues()).then(()=>{
                        emit('participant-feedback-completed');
                        loading.value = false;
                        store.commit(MutationTypes.addSystemNotification, {title:'Participant Feedback Submitted.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                    },(err)=>{
                        loading.value = false;
                        store.commit(MutationTypes.addSystemNotification, {title:'Error submitting feedback.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                        console.log(err);
                    });
                }
            }

            return {
                buttonUpdate,
                clearError,
                ErrorAlert,
                formatQuestions,
                loadErrorMessage,
                loading,
                moment,
                QuestionType,
                questions,
                returnToVisit,
                submitParticipantFeedback,
                user: store.state.auth.user,
            };
        },
    });
</script>
<style scoped lang="scss">
    .card-columns {
        column-count: 2;
        @media (max-width: 720px){
            column-count: 1;
        }
        .card{
            display: inline-block;
            textarea{
                height: 90px;
                resize: none;
                border-color: #6E6E70;
                &:focus {
                    outline: none !important;
                    border: 2px solid #6BBAA3;
                }
            }
        }
    }
    .top-header{
        background-color: #6E6E70;
    }
    .btn-group{
        height: 3.6rem;
        border: 1px solid #6E6E70;
        border-radius: .5rem;
        .btn{
            color: #6E6E70;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &:hover{
                background-color: #6BBAA3 !important;
                color: #fff !important;
            }
        }
        .active{
            background-color: #6BBAA3 !important;
            color: #fff !important;
        }
    }
    .radio-custom {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 6px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default radio button */
    .radio-custom input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 1.2rem;
        width: 1.2rem;
        background-color: rgba(24, 28, 33, 0.06);
        border-radius: 50%;
        border: 1px solid #6E6E70;
        margin-top: 3px;
    }

    /* On mouse-over, add a grey background color */
    .radio-custom:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .radio-custom input:checked ~ .checkmark {
        background-color: rgba(24, 28, 33, 0.06);
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .radio-custom input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .radio-custom .checkmark:after {
        top: 1px;
        left: 1px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #6BBAA3;
    }
</style>
