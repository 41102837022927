<template>
  <slot name="trigger" :click="open"></slot>
  <modal v-model="modal" allow-close>
      <template v-slot:header><span class="text-white">Create Playlist</span> </template>
      <div>
          <Loading :active="loading" :is-full-page="false"></Loading>
          <div class="form-group" v-if="!loading">
              <div class="form-group">
                  <label>Name<span class="text-danger">*</span></label>
                  <input type="text" :class="{'is-invalid':invalid}" required v-model="name" class="form-control">
                  <small v-if="invalid" class="invalid-feedback">A Name is Required</small>
              </div>
              <div class="form-group">
                  <label>Desription</label>
                  <textarea v-model="description" required cols="3" class="form-control"></textarea>
              </div>
          </div>
      </div>

      <template v-slot:footer>
          <div class="d-flex justify-content-end">
              <button @click="modal = !modal" class="btn btn-default mx-2">Cancel</button>
              <button @click="save" :disabled="name === ''" class="btn btn-primary">Save</button>
          </div>
      </template>
  </modal>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import Loading from "vue3-loading-overlay";
import Modal from "@/components/modal.vue";
import Playlist from "@/models/playlist";
export default defineComponent({
  name: "create-playlist",
  components: {Modal,Loading},
  emits:['create'],
  setup(_,{emit}){
    const modal = ref(false);
    const loading = ref(false);
    const open = ()=>modal.value = !modal.value;
    const name = ref('');
    const description = ref('');
    const invalid = ref(false);
    const save = () =>{
        if(name.value != ''){
          invalid.value = false;
          Playlist.create({name:name.value,description:description.value}).then((res)=>{
            emit('create',res.data);
            modal.value = false;
          })
        }else{
          invalid.value = true;
        }
    }
    return{
      description,
      invalid,
      loading,
      Loading,
      name,
      modal,
      open,
      save,
    }
  }
});
</script>

<style scoped>

</style>