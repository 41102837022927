<template>
    <!-- Layout footer -->
    <nav class="layout-footer footer">
        <div class="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x py-2">
            <div class="d-sm-block d-none">
                Copyright © {{ date }} <span class="footer-text font-weight-bolder">HealthCourse Inc.</span>
            </div>
            <div class="d-xs-block d-sm-none d-md-none d-lg-none dxl-none text-center w-100">
                <div v-if="store.getters.partner && store.getters.partner.is_sim && store.getters.partner.slug != 'simclusive'">
                    <img src="/img/partners/SIMclusive_Logo.png" alt="Simclusive Logo" title="Simclusive Logo" height="45" @click="router.push({name:'dashboard'});">
                </div>
                Copyright © {{ date }} <span class="footer-text font-weight-bolder">HealthCourse Inc.</span>
            </div>
            <div class="d-none d-md-block">
                <a href="#/contactus" class="footer-link ml-4">Contact Us / Help</a>
                <a href="#/terms-of-service" class="footer-link ml-4">Terms of Service</a>
                <a href="#/privacy-policy" class="footer-link ml-4">Privacy Policy</a>
                <a href="https://healthcourse.com/cookie-policy" class="footer-link ml-4" target="_blank">Cookie Policy</a>
                <!-- CookiePro Cookies Settings button start -->
                    <button id="ot-sdk-btn" class="ot-sdk-show-settings cookie-link btn btn-link">Cookie Settings</button>
                <!-- CookiePro Cookies Settings button end -->
            </div>
        </div>
    </nav>
    <!-- / Layout footer -->
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import {store} from '@/store';

    export default defineComponent( {
        name: "Footer",
        setup(){
            return{
                date: new Date().getFullYear(),
                store
            }
        }
    });
</script>

<style scoped>

</style>
