<template>

    <template v-if="listGroupItem">
        <li class="list-group-item">
            <div class="d-flex justify-content-between align-items-center">
                <button @click="edit = true" class="btn btn-link text-primary btn-sm" v-if="canEdit && !edit"><i class="fa fa-edit"></i> </button>
                <div class="text-muted">{{ label }}</div>
            </div>

            <div v-if="type === 'editor' && !edit" v-html="value" class="text-muted"></div>
            <div v-else-if="type === 'tags' && !edit" class="text-muted">
                <span v-for="(item,index) in value" v-bind:key="index" class="tag px-2 mx-1 badge badge-primary">{{item.name.en}}</span>
            </div>
            <div v-else-if="type ==='money' && !edit" class="text-muted text-right"><strong>${{value}}</strong></div>
            <div v-else-if="!edit && field === 'enable_giftcard'" class="text-muted text-right"><strong>{{value === 1 ? 'Yes' : 'No'}}</strong></div>
            <div v-else-if="!edit" class="text-muted text-right">{{ (field === "giftcard_campaign_id" || field === "institution_id") ? getItemLabel(value) : value}}</div>

            <div class="input-group" v-if="edit && type === 'text'">
                <input type="text" v-model="value" class="form-control">
            </div>
            <div class="input-group" v-if="edit && type === 'money'">
                <div class="input-group-prepend">
                    <div class="input-group-text">$</div>
                </div>
                <input type="number" min="1" step="any" v-model="value" class="form-control">
            </div>
            <div class="input-group" v-if="edit && type === 'number'">
                <input type="number" min="1" step="any" v-model="value" class="form-control">
            </div>
            <div v-if="edit && type === 'editor'">
                <text-editor :options="simpleEditorOptions" v-model="value"></text-editor>
            </div>
            <div v-if="edit && type === 'select'">
                <select v-model="value" class="form-control">
                    <option v-for="(item,index) in items" v-bind:key="index" :value="item.value" :selected="value === item.value">{{item.label}}</option>
                </select>
            </div>
            <div v-if="edit && type === 'tags'">
                <Multiselect
                    mode="tags"
                    v-model="value"
                    :options="items"
                    :searchable="true"
                    :createTag="true"
                ></Multiselect>
            </div>
            <div v-if="edit" class="d-flex justify-content-end mt-2">
                <button :disabled="value===''" class="btn btn-primary" @click="save(value)">Save</button>
                <button class="btn btn-default mx-2" @click="cancel">Cancel</button>
            </div>
        </li>

        <Loading :active="loading" :is-full-page="false"></Loading>
    </template>

    <template v-else>
        <div class="scenario-field-alternating">
        <div class="scenario-field-data">
            <div class="scenario-field-title">
                <scenario-select-image v-model="value" v-on:selected="save" v-if="canEdit && !edit && type === 'image-select'">
                    <template v-slot:trigger="triggerProps">
                        <button @click="triggerProps.click" class="btn btn-link text-primary btn-sm"><i class="fa fa-edit"></i> </button>
                    </template>
                </scenario-select-image>
                <button @click="edit = true" class="btn btn-link text-primary btn-sm" v-if="canEdit && !edit && type !== 'image-select'"><i class="fa fa-edit"></i> </button>
                <label v-if="!disableLabel" :class="{'text-danger':!value && required}">{{ label }}
                    <span v-if="!value && required" :class="{'text-danger':!value}">*</span>
                    <span class="tooltip-container">
                        <i v-if="toolTip" class="fas fa-info-circle"></i>
                        <span class="tooltiptext tooltip-right">{{toolTip}}</span>
                    </span>
                </label>
            </div>
            <div class="scenario-field-values" v-bind:class="[canEdit ? '' : 'no-editing']">
                <div v-if="type === 'image-select'" class="text-muted"><user-image size="ui-w-80" :source="value" /></div>
                <div v-else-if="type === 'editor' && !edit" v-html="value" class="text-muted"></div>
                <div v-else-if="!edit && (type === 'select' || type === 'radio')" class="text-muted">{{getItemLabel(value)}}</div>
                <div v-else-if="!edit" class="text-muted" v-html="value"></div>

                <div class="input-group" v-if="edit && type === 'text'">
                    <input type="text" v-model="value" class="form-control">
                    <span>
                        <button class="btn btn-primary" type="button" @click="save(value)">Save</button>
                        <button class="btn btn-secondary" @click="cancel" type="button">Cancel</button>
                    </span>
                </div>
                <div v-if="edit && type === 'editor'">
                    <text-editor v-model="value"></text-editor>
                    <div class="d-flex justify-content-end mt-2">
                        <button class="btn btn-primary" @click="save(value)">Save</button>
                        <button class="btn btn-default mx-2" @click="cancel">Cancel</button>
                    </div>
                </div>
                <div v-if="edit && type === 'select'" class="input-group">
                    <select v-model="value" class="form-control">
                        <option v-for="(item,index) in items" v-bind:key="index" :value="item.value" :selected="value === item.value">{{item.label}}</option>
                    </select>
                    <span>
                        <button class="btn btn-primary" type="button" @click="save(value)">Save</button>
                        <button class="btn btn-secondary" @click="cancelSelect" type="button">Cancel</button>
                    </span>
                </div>
                <div v-if="edit && type === 'radio'" class="d-flex justify-content-start align-items-center">
                    <label v-for="(item,index) in items" v-bind:key="index"  class="form-check form-check-inline p-o m-0 mx-2">
                        <input type="radio" class="form-check-input" v-model="value" :value="item.value" :checked="item.value === value"  /> <span class="form-check-label">{{ item.label }}</span>
                    </label>
                    <span class="btn-group btn-group-sm">
                        <button class="btn btn-primary" type="button" @click="save(value)">Save</button>
                        <button class="btn btn-secondary" @click="cancel" type="button">Cancel</button>
                    </span>
                </div>
                <Loading :active="loading" :is-full-page="false"></Loading>
            </div>
        </div>
        </div>
    </template>

</template>


<script lang="ts">
    import {computed, defineComponent, ref} from "vue";
    import TextEditor from "@/components/text-editor.vue";
    import Loading from "vue3-loading-overlay";
    import Multiselect from "@vueform/multiselect";
    import UserImage from "@/components/user-image.vue";
    import ScenarioSelectImage from "@/components/scenarios/scenario-select-image.vue";
    import {toTitleCase} from "@/utilities/string-helpers";
    import {ISelectOption} from "@/interfaces/select-option";
    export default defineComponent({
        name: "scenario-field",
        components: {ScenarioSelectImage, UserImage, TextEditor, Loading, Multiselect},
        props:{
            modelValue: [String,Boolean,Array,Number],
            field:{type:String,required:true},
            label_override:{type:String,required:false, default:''},
            type:{type:String,required:true},
            canEdit: {type:Boolean, required:true},
            listGroupItem: {type:Boolean, default:false},
            required: {type:Boolean, default:false},
            toolTip: {type:String,required:false},
            items: {type:Array},
            disableLabel: {type:Boolean, default:false}
        },
        emits:['update:modelValue','save'],
        setup(props,{emit}){
            const value = computed({
                get: () => props.modelValue,
                set: (value) => emit('update:modelValue', value)
            })
            const userDataBeforeEdit = value.value; //Used in cancel to revert value
            const loading = ref(false);
            const label = computed(()=> props.label_override ? props.label_override : toTitleCase(props.field));
            const simpleEditorOptions = {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                    ]
                },
                // more options
            }
            const edit = ref(false);

            const cancel = ()=>{
                edit.value = false;
                value.value = userDataBeforeEdit;
            }

            const cancelSelect = ()=>{
                edit.value = false;
            }

            const getItemLabel = (value:string)=>{
                //@ts-ignore
                let item:ISelectOption | undefined = props.items?.find((i:ISelectOption)=>i.value === value);
                return item ? item.label : '';
            }

            const save = async (value:any)=>{
                edit.value = false;
                loading.value = true;
                emit('save',{field:props.field,value:value});
                loading.value = false;
            }
            return{
                cancel,
                cancelSelect,
                label,
                loading,
                edit,
                getItemLabel,
                save,
                simpleEditorOptions,
                value
            }
        }
    });
</script>

<style lang="scss">
    @import "@vueform/multiselect/themes/default";
</style>
