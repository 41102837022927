import {FormConfig, FormData} from "@/interfaces/form-config";

export default class Form{
    constructor(public config:FormConfig) {}

    public mapValues(): FormData {
        const data:FormData = {};
        for (const [key, value] of Object.entries(this.config)) {
            data[key] = value.value;
        }
        return data;
    }

     public validation(err:any){
        if(err.response.data.errors){
            for (const [key, value] of Object.entries(err.response.data.errors)) {
                const msg = value as Array<String>;
                this.config[key].valid = false;
                this.config[key].validationMessage = msg[0];
            }
        }
    }
}
