<template>
    <scenario-field :disable-label="disableLabel"  :type="field.type" :field="field.label" :required="field.required" :items="field.items" :tool-tip="field.tool_tip" :can-edit="canEdit" v-model="value" v-on:save="fieldUpdate" />
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, ref} from "vue";
    import {IScenarioFields} from "@/interfaces/scenario-fields";
    import ScenarioField from "@/components/scenarios/scenario-field.vue";
    import {toSnakeCase} from "@/utilities/string-helpers";
    import Scenario from "@/models/scenario";
    import {IScenarioFieldData} from "@/interfaces/scenario-field-data";

    export default defineComponent({
        components: {ScenarioField},
        props:{
            field:{type:Object as PropType<IScenarioFields>,required:true},
            canEdit:{type:Boolean,default:false},
            scenario:{type:Scenario,required:true},
            disableLabel:{type:Boolean, default:false}
        },
        name: "scenario-dynamic-field",
        setup(props){
            const loading = ref(false);
            const value = ref();

            onMounted(()=>{
                value.value = props.scenario.data.fields.find((i:IScenarioFieldData) => i.scenario_field_id === props.field.id)?.value;
            })

            const fieldUpdate = async ()=>{
                loading.value = true;
                await Scenario.updateField(props.scenario.data.id,props.field.id,{value:value.value}).then((res)=>{
                    value.value = res.data.value;
                })
                loading.value = false;
            }

            return{
                toSnakeCase,
                fieldUpdate,
                value
            }
        }
    });
</script>

<style scoped>

</style>