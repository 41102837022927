<template>
    <datepicker class="float-left date-margin" :lower-limit="startDate" :upper-limit="endDate" placeholder="Select Date..." v-model="dateSelect" @update:modelValue="emitDate()"></datepicker>
    <select v-model="timeSelect" @change="emitDate()">
        <option v-for="time in timeOptions" v-bind:key="time.value" :value="time.value">{{time.text}}</option>
    </select>
    <select v-model="meridiemSelect" @change="emitDate()">
        <option v-for="mer in ['AM','PM']" v-bind:key="mer" :value="mer">{{mer}}</option>
    </select>
</template>

<script>
    import { defineComponent, ref } from "vue";
    import Datepicker from 'vue3-datepicker';
    import moment from 'moment';
    export default defineComponent({
        components: {Datepicker},
        emits: ['update-date'],
        props:{
            startDate: Date,
            field: String,
            endDate: {required:false, default: null}
        },
        computed:{
            timeOptions(){
                let timeOptions = [];
                for(let h = 0; h < 12; h++){
                    if(h == 0) h = 12;
                    timeOptions.push({'value':h+':00', 'text': h+':00'});
                    timeOptions.push({'value':h+':30', 'text': h+':30'});
                    if(h==12) h = 0;
                }
                return timeOptions;
            }
        },
        methods: {
            emitDate(){
                if(
                    typeof(this.dateSelect) !== 'undefined' &&
                    typeof(this.timeSelect) !== 'undefined' &&
                    typeof(this.meridiemSelect) !== 'undefined'
                ){
                    let date = moment(this.dateSelect);
                    let times = this.timeSelect.split(':');
                    if(this.meridiemSelect == 'AM' && times[0] == '12') times[0] = '00';
                    if(this.meridiemSelect == 'PM' && times[0] != '12') times[0] = parseInt(times[0]) + 12
                    date.hour(times[0]);
                    date.minutes(times[1]);
                    date = date.format('YYYY-MM-DD HH:mm:00');
                    this.$emit('update-date',{date:date,field:this.field});
                }
            }
        },
        setup(){
            const dateSelect = ref();
            const timeSelect = ref('12:00');
            const meridiemSelect = ref('PM');
            return {
                dateSelect,
                timeSelect,
                meridiemSelect
            }
        }
    });
</script>

<style scoped>
    :deep(.v3dp__popout){
        z-index: 2000;
    }
    select {
        margin-top: 6px;
    }
</style>
