<template>
    <div class="authentication-wrapper authentication-2 px-4">
        <div class="authentication-inner py-5">

            <!-- Logo -->
            <div class="d-flex justify-content-center mb-3">
                <img :src="imageURL" :alt="partner +' Logo'" :title="partner +' Logo'" height="75" class="cursor-pointer partner-logo" @click="frontEndPartner">
            </div>
            <!-- / Logo -->

            <!-- Form -->
            <form class="card" name="loginform" @submit.prevent="login">
                <div class="p-4">
                    <h2 class="text-center text-muted mb-4">Login</h2>

                    <hr class="mt-0 mb-4">
                    <notification v-if="error" :notification="errorMessage" :index="0" :fix_alert="false" />
                    <div class="form-group">
                        <label class="form-label">Email</label>
                        <input id="email" name="email" type="email" :disabled="authenticating" v-model="email" autocomplete="email" required class="form-control">
                    </div>
                    <div class="form-group">
                        <label class="form-label d-flex justify-content-between align-items-end">
                            <div>Password</div>
                            <router-link to="/forgot-password" :disabled="authenticating" class="d-block">
                                Forgot your password?
                            </router-link>
                        </label>
                        <input id="password" name="password" type="password" :disabled="authenticating" v-model="password" autocomplete="current-password" required class="form-control">
                    </div>
                    <div class="d-flex justify-content-end align-items-center m-0">
                        <button type="submit" name="loginformsubmit" :disabled="authenticating"  class="btn btn-primary">Sign In</button>
                    </div>
                </div>
            </form>
            <!-- / Form -->
            <!-- Logo -->
            <div v-if="partner !== 'sp' && partner !== 'simclusive'" class="mt-3 text-center">
                Don't have an account?<br>
                <a :href="basicRegistration">Register today!</a>
            </div>
            <div v-else-if="partner == 'sp'" class="mt-3 text-center">
                <p><a :href="authorRegistration">Want to Become a Scenario Author?</a></p>
                <a :href="spRegistration">Want to Become a SP?</a>
            </div>
            <div v-else-if="partner == 'simclusive'" class="mt-3 text-center">
                <p><a :href="simAuthorRegistration">Want to Become a Scenario Author?</a></p>
                <a :href="requestQuote">Request a Quote?</a>
            </div>
            <!-- / Logo -->

        </div>
    </div>
    <loading :active="authenticating" :is-full-page="true"></loading>
</template>

<script lang="ts">
import { ActionTypes } from "@/store/modules/auth";
import { computed, defineComponent, ref } from "vue";
import { MutationTypes as MT } from '@/store/modules/auth/mutations';
import { store} from '@/store';
import { useRoute } from 'vue-router';
import Loading from 'vue3-loading-overlay';
import Notification from '../components/notification.vue';
import Partner from "@/models/partner";
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default defineComponent({
    components:{Loading, Notification},
    mounted() {
        this.getPartnerData();
    },
    setup() {
        const route = useRoute();
        const email = ref("");
        const is_sim = ref<Boolean>(false);
        const loading = ref(false);
        const password = ref("");

        let partner = ref(route.params.partner_slug);
        let imageURL = ref('/img/partners/sp-logo.svg');


        if (!partner.value) {
            partner.value = 'sp';
        }

        const authorRegistration = ref<String>(process.env.VUE_APP_API_PROTOCAL + partner.value + '.' + process.env.VUE_APP_API_BASE + 'application');
        const basicRegistration = ref<String>(process.env.VUE_APP_API_PROTOCAL + partner.value + '.' + process.env.VUE_APP_API_BASE + 'registration');
        const spRegistration = ref<String>(process.env.VUE_APP_API_PROTOCAL + partner.value + '.' + process.env.VUE_APP_API_BASE + 'recruitment');
        const simAuthorRegistration = ref<String>(process.env.VUE_APP_API_PROTOCAL + 'register.' + process.env.VUE_APP_SIM_URL + 'application');
        const requestQuote = ref<String>(process.env.VUE_APP_API_PROTOCAL + 'register.' + process.env.VUE_APP_SIM_URL + 'client');

        const getPartnerData = () => {
            loading.value = true;

            var form = {
                partner_slug: partner.value
            };
            Partner.single(form).then((res)=>{
                if (res.data.slug == 'error') {
                    window.location = process.env.VUE_APP_API_URL;
                } else {
                    store.commit(MT.setPartner,partner);
                    partner.value = res.data.slug;
                    is_sim.value = res.data.is_sim;
                    if(res.data.image !== null){
                        if(res.data.image.startsWith('http://') || res.data.image.startsWith('https://')){
                            imageURL.value = res.data.image;
                        }else{
                            imageURL.value = '/img/partners/'+ res.data.image;
                        }
                    }else if(is_sim.value){
                        imageURL.value = '/img/partners/SIMclusive_Logo.png';
                    }
                }
                var base = is_sim.value ? process.env.VUE_APP_SIM_URL : process.env.VUE_APP_API_BASE;
                authorRegistration.value = process.env.VUE_APP_API_PROTOCAL + partner.value + '.' + base +'application';
                basicRegistration.value = process.env.VUE_APP_API_PROTOCAL + partner.value + '.' + base +'registration';
                spRegistration.value = process.env.VUE_APP_API_PROTOCAL + partner.value + '.' + base +'recruitment';
            },(err)=>{
                console.log(err);
            });

            loading.value = false;
        };

        const login = () => store.dispatch(ActionTypes.login,{email:email.value, password:password.value, slug: partner.value});

        const frontEndPartner = () => {
            var redirect = process.env.VUE_APP_API_PROTOCAL + partner.value + '.';
            redirect += is_sim.value ? process.env.VUE_APP_SIM_URL : process.env.VUE_APP_API_BASE;
            window.location.href = redirect;
        }

        return {
            authorRegistration,
            basicRegistration,
            email,
            frontEndPartner,
            getPartnerData,
            imageURL,
            loading,
            login,
            password,
            partner,
            requestQuote,
            spRegistration,
            simAuthorRegistration,
            authenticating: computed(() => store.state.auth.authenticating),
            error:computed(()=>store.state.auth.error),
            errorMessage: computed(()=>store.state.auth.errorMessage),
        };
    },
});
</script>
<style lang="scss">
    @import "src/assets/styles/pages/authentication";
</style>
