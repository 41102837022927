import {ISocial} from "@/interfaces/social-media-post";
import {api} from "@/store";

export class SocialMediaPost{
    constructor(public data?:ISocial) {}

    static async deleteSocialMediaPost(formData:any):Promise<any>{
        return await api.post('api/social/delete',formData);
    }

    static async saveSocialMediaPost(formData:any):Promise<any>{
        return await api.post('api/social/save',formData);
    }

    static async updateSocialMediaPost(formData:any):Promise<any>{
        return await api.post('api/social/update',formData);
    }

}