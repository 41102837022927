<template>
    <div class="card shadow-sm mb-4">
        <div class="card-header text-white bg-secondary font-weight-bold">Pending Interviews</div>
        <div class="card-content p-2">
            <div v-if="!loading">
                <span v-if="Object.keys(props.pending_interviews).length">
                    <div v-for="pending in props.pending_interviews" v-bind:key="pending.id" class="col-12" @click="router.push({name:'recruitment.pending',params:{user_id:pending.user_id}})">
                        <div class="row visit-card">
                            <div class="col-10">
                                <img :src="pending.user.image" class="rounded-circle float-left mr-2" width="75" />
                                <div class="font-weight-bold text-secondary">{{pending.user.name}}</div>
                                <div class="overflow-hidden">
                                    <span>{{pending.user.email}}</span>
                                    <br>
                                    <span v-for="(date, index) in pending.dates" v-bind:key="date.id" class="visit-dates">
                                        <span class="text-secondary">{{ moment(date.local_time).format('MM/DD/YY') }}</span>&nbsp;<span class="text-primary">{{ moment(date.local_time).format('h:mma') }}</span><span class="font-italic" v-if="index==0"> or </span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-2 d-flex flex-column justify-content-center">
                                <h3 class="text-center"><i class='far fa-calendar-alt text-primary'></i></h3>
                            </div>
                        </div>
                        <hr class="w-75" />
                    </div>
                    <lazy-pagination v-if="props.pending_interviews_total > 10" :items="props.pending_interviews" :total="props.pending_interviews_total" :page_start="page_start" :page_end="page_end" v-on:next-page="nextPage" v-on:previous-page="previousPage" :label="'Interviews'" />
                </span>
                <p v-else class="text-center pt-3">No Pending Interviews</p>
            </div>
            <div class="loading-card-area" v-else>
                <loading :active="loading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent,ref} from "vue";
    import Loading from 'vue3-loading-overlay';
    import moment from 'moment';
    import LazyPagination from '@/components/dashboard/lazy-pagination.vue';
    import router from "@/router";

    export default defineComponent({
        emits: ['change-page'],
        components: {
            Loading,
            LazyPagination
        },
        props: {
            pending_interviews: {type:Object, required:true},
            pending_interviews_total: {type:Number, required:true},
            loading: {type:Boolean, required:true}
        },
        setup(props, {emit}) {
            const page_start = ref(0);
            const page_end = ref(10);
            const nextPage = async() => {
                page_start.value = page_start.value + 10;
                page_end.value = page_end.value + 10;
                if(page_end.value > props.pending_interviews_total){
                    page_end.value = props.pending_interviews_total;
                }
                emit('change-page',{type:'pending_interviews','page_start':page_start.value});
            };
            const previousPage = async() => {
                page_start.value = page_start.value - 10;
                page_end.value = page_start.value + 10;
                emit('change-page',{type:'pending_interviews','page_start':page_start.value});
            };
            return {
                moment,
                nextPage,
                page_end,
                page_start,
                previousPage,
                props,
                router
            };
        }
    });
</script>
