<template>
    <div>
        <h1>Articles</h1>
    </div>
    <div id="articles-list" v-if="!loading">
        <div v-if="articles.length" class="card py-2 px-2">
            <table>
                <tr>
                    <th class="header-row py-2 px-2" style="width:40%;">Title</th>
                    <th class="header-row py-2">Date</th>
                    <th class="header-row py-2 px-2">Author</th>
                    <th class="header-row py-2"></th>
                </tr>
                <tr v-for="(article, index) in articles" v-bind:key="index">
                    
                    <td class="py-1 px-2">{{article.title}}</td>
                    <td class="py-1 nowrap">{{moment(article.published_on).format('YYYY-MM-DD')}}</td>
                    <td class="py-1 px-2 nowrap">{{article.full_name}}</td>
                    <td>
                        <edit-article :article-obj="article" :authors-obj="authors" @updateArticleData="updateArticle" :key='article.id'>
                            <template v-slot:trigger="triggerProps">
                                <button @click="triggerProps.click" class="ml-4 btn btn-primary btn-sm">Edit Article</button>
                            </template>
                        </edit-article>&nbsp;&nbsp;
                        <delete-article :article-obj="article" @deleteArticleData="deleteArticle(index)" :key='article.id'>
                            <template v-slot:trigger="triggerProps">
                                <button @click="triggerProps.click" class="ml-4 btn btn-primary btn-sm">Delete</button>
                            </template>
                        </delete-article>
                    </td>
                </tr>
            </table>
        </div>
        <div v-else class="card py-2 px-2">
            <table>
                <tr>
                    <th class="header-row py-2 px-2">Title</th>
                    <th class="header-row py-2">Date</th>
                    <th class="header-row py-2">Author</th>
                    <th class="header-row py-2"></th>
                </tr>
                <tr>
                    <td class="text-center" colspan="4">There are no articles at this time.</td>
                </tr>
            </table>
        </div>
        <create-article @update="newArticle" :authors-obj="authors">
            <template v-slot:trigger="triggerProps">
                <button @click="triggerProps.click" class="ml-2 my-4 btn btn-primary">Create New Article</button>
            </template>
        </create-article>
        <create-author @update="newAuthor">
            <template v-slot:trigger="triggerProps">
                <button @click="triggerProps.click" class="ml-2 my-4 btn btn-primary">Create New Author</button>
            </template>
        </create-author>
    </div>
    <br/>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { api } from "@/store";
    import { defineComponent, ref } from "vue";
    import { store } from '@/store';
    import Loading from 'vue3-loading-overlay';
    import moment from 'moment';
    import CreateArticle from '@/components/create-article.vue';
    import CreateAuthor from '@/components/create-author.vue';
    import DeleteArticle from '@/components/delete-article.vue';
    import EditArticle from '@/components/edit-article.vue';

    export default defineComponent({
        components: {
            CreateArticle,
            CreateAuthor,
            DeleteArticle,
            EditArticle,
            Loading,
        },
        name: "Articles",
        mounted(){
            this.getArticles();
        },
        setup() {
            const edit_article_modal = ref<Boolean>(false);
            const loading = ref(false);
            const articles = ref<any>({});
            const authors = ref<any>({});
            const showEditArticleModal = () => edit_article_modal.value = !edit_article_modal.value;

            const deleteArticle = (index:number) =>{
                if (index !== undefined && index !== null) {
                    articles.value.splice(index, 1);
                }
            }

            const getArticles = async () => {
                loading.value = true;
                await api.get('sanctum/csrf-cookie');

                api.get('api/article',{}).then((res)=>{
                    var formatted:any = [];
                    var formattedAuthor:any = [];
                    var full_name:any = '';
                    var author_id:any = '';
                    var biosketch:any = '';
                    res.data.articles.forEach(function(elm:any){
                        if (elm.author != null) {
                            full_name = elm.author.full_name;
                            author_id = elm.author_id;
                            biosketch = elm.author.biosketch;
                        } else {
                            full_name = null;
                            author_id = null;
                            biosketch = null;
                        }
                        formatted.push({
                            'id': elm.id,
                            'title': elm.title,
                            'full_name': full_name,
                            'author_id': author_id,
                            'biosketch': biosketch,
                            'description': elm.description,
                            'article_text': elm.article_text,
                            'image_path': elm.image_path,
                            'image_path_large': elm.image_path_large,
                            'published_on': elm.published_on,
                        });
                    });
                    res.data.authors.forEach(function(elm:any){
                        formattedAuthor.push({
                            'author_id': elm.id,
                            'full_name': elm.full_name,
                            'biosketch': elm.biosketch
                        });
                    });
                    articles.value = formatted;
                    authors.value = formattedAuthor;
                    loading.value = false;

                },(err)=>{
                    console.log(err);
                    loading.value = false;
                });
            }

            const newArticle = (newArticle:any) =>{
                articles.value.push(newArticle);
            }

            const newAuthor = (newAuthor:any) =>{
                authors.value.push({
                    'author_id': newAuthor.id,
                    'full_name': newAuthor.full_name,
                    'biosketch': newAuthor.biosketch
                });
            }

            const updateArticle = async(updateArticle:any)=>{
                loading.value = true;
                if (updateArticle !== undefined) {
                    let index = articles.value.findIndex(
                        (function(elm:any){
                            return elm.id === updateArticle.id})
                        );
                    let author_index = authors.value.findIndex(
                        (function(elm2:any){
                            return elm2.author_id == updateArticle.author_id})
                        );
                    if (index !== -1) {
                        articles.value[index] = updateArticle;
                        if (author_index !== -1) {
                            articles.value[index].full_name = authors.value[author_index].full_name;
                        }
                    }
                }
                loading.value = false;
            }

            return {
                user: store.state.auth.user,
                articles,
                authors,
                deleteArticle,
                getArticles,
                loading,
                moment,
                newArticle,
                newAuthor,
                showEditArticleModal,
                updateArticle,
            };
        },
    });
</script>

<style lang="scss">
    
</style>
