<template>
	<div class="scenario-field-header with-elements bg-secondary text-white">
		Scenario Fee Settings
	</div>
	<div class="card-body">
		<label>Scenario Fee: $</label>
		<input type="number" max="255" v-model="scenario_fee" v-on:input="setProcessingFee" />
		<p>Processing Fee: $<span>{{processing_fee == 0 ? 0.00 : processing_fee.toFixed(2)}}</span></p>
		<p>Participant will be required to pay ${{scenario_fee == 0 ? 0.00 : (scenario_fee + processing_fee).toFixed(2)}} after fees.</p>
		<button class="btn btn-primary btn-md my-3 float-right" @click="saveScenarioFeeSettings">Save</button>
	</div>
</template>
<script lang="ts">
	import {defineComponent, ref} from "vue";
	import Scenario from "@/models/scenario";
	import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
	import {MutationTypes} from "@/store/modules/ux/mutations";
	import { store } from '../../store/index';
	export default defineComponent({
		name: "scenario-fee-settings",
		props: {
			scenario:{type:Scenario,required:true},
			canEdit:{type: Boolean,default:false},
		},
		mounted(){
			this.setProcessingFee();
		},
		setup(props){
			const scenario_fee = ref(props.scenario.data.fee);
			const processing_fee = ref(0);

			const saveScenarioFeeSettings = async() => {
				let post_data = {
					scenario_fee: scenario_fee.value
				}
				await Scenario.saveFeeSettings(props.scenario.data.id,post_data).then(() => {
					store.commit(MutationTypes.addSystemNotification, {title:'Scenario fee updated.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
				},(err) => {
					console.log(err);
					store.commit(MutationTypes.addSystemNotification, {title:'Error updating scenario fee.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
				});
			}

			const setProcessingFee = async() => {
				if(scenario_fee.value > 0){
					let fee_str = ((scenario_fee.value + 0.30)/0.971) - scenario_fee.value;
					processing_fee.value = parseFloat((fee_str).toFixed(2));
				}else{
					processing_fee.value = 0;
				}
			}

			return {
				saveScenarioFeeSettings,
				scenario_fee,
				setProcessingFee,
				processing_fee,
			}
		}
	});
</script>
<style scoped>
	
</style>