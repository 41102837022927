<template>
    <div class="row">
        <div class="card w-100">
            <div class="mx-3 pt-4 text-center">
                <img class="rounded-circle mb-3" width="100" :src="patient_image"/>
                <h3>{{scenario.patient_name}}</h3>
                <p class="w-50 m-auto patient-title">{{scenario.title}}</p>
                <hr class="hr-dark">
            </div>
            <div class="mx-5">
                <span v-for="field in formatted_field_data" :key="field.id">
                    <h4>{{field.title}}</h4>
                    <div v-for="data in field.fields" :key="data.id">
                        <p v-if="field.fields.length > 1" class="mb-1"><strong>{{data.title}}</strong></p>
                        <p v-html="data.value"></p>
                    </div>
                </span>
                <h4>Visit Objective</h4>
                <p v-html="scenario.objective"></p>
                <h4>Target Audience</h4>
                <p v-html="scenario.target_audience"></p>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { defineComponent, ref } from "vue";
    export default defineComponent({
        props: {
            formatted_field_data: Array,
            scenario: Object,
            is_learner: {type: Boolean, default: false},
            sp: {type:Object, required: false}
        },
        mounted(){
            this.setImage();
        },
        setup(props){
            const patient_image = ref(props.scenario?.patient_image.location);
            const setImage = () => {
                if(props.is_learner && typeof(props.sp) !== 'undefined'){
                    if(props.sp?.image){
                        patient_image.value = props.sp.image;
                    }
                }
            }
            return {
                patient_image,
                setImage
            }
        }
    });
</script>
<style lang="scss" scoped>
    .h2 {
        color: #6cbaa2;
    }
    .scen-title {
        color: #112c55;
    }
    hr.hr-dark{
        border-top: 1px solid #112c55;
        width: 95%;
    }
</style>
