<template>
    <div class="authentication-wrapper authentication-2 px-4">
        <div class="authentication-inner py-5">

            <!-- Logo -->
            <div class="d-flex justify-content-center mb-3">
                <img :src="imageURL" :alt="partner +' Logo'" :title="partner +' Logo'" height="75" class="cursor-pointer partner-logo" @click="frontEndPartner">
            </div>
            <!-- / Logo -->

            <!-- Form -->
            <form class="card" v-if="showForm" @submit.prevent="submit">
                <div class="p-4">
                    <h2 class="text-center text-muted mb-4">Reset Your Password</h2>

                    <hr class="mt-0 mb-4">

                    <p>Enter your email address and we will send you a link to reset your password.</p>

                    <div class="form-group">
                        <input id="email" name="email" type="email" v-model="form.email.value" autocomplete="email" :disabled="loading" required :class="form.email.valid ? '' : 'border-danger'"  class="form-control " placeholder="Enter your email address">
                        <p class="mt-2 text-sm text-danger" v-if="!form.email.valid">
                            {{form.email.validationMessage}}
                        </p>
                    </div>

                    <button type="submit" class="btn btn-primary btn-block">Send password reset email</button>
                </div>
            </form>
            <!-- / Form -->

            <div >
                <h4 class="text-center" v-if="!showForm">{{message}}</h4>
                <div class="d-flex justify-content-center" >
                    <router-link class="mt-2 font-medium text-primary" to="/login/">Back to Login</router-link>
                </div>
            </div>
        </div>
    </div>
<loading :active="loading"></loading>

</template>
<script lang="ts">
import {api} from "@/store";
import {defineComponent, reactive, ref} from "vue";
import {FormConfig} from "@/interfaces/form-config";
import {MutationTypes as MT} from '@/store/modules/auth/mutations';
import {store} from '@/store';
import {useRoute} from 'vue-router';
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import Form from "@/utilities/form";
import Partner from "@/models/partner";


export default defineComponent({
    components:{Loading},
    mounted() {
        this.getPartnerData();
    },
    setup() {
        const route = useRoute();
        const showForm = ref(true);
        const loading = ref(false);
        const message= ref("");

        const form:FormConfig = reactive({
            email: {
                value:'',
                valid:true,
                validationMessage:''
            }
        });
        const formHelper = new Form(form);

        let partner = ref(route.params.partner_slug);
        let imageURL = ref('/img/partners/sp-logo.svg');

        const getPartnerData = () => {
            loading.value = true;

            var form = {
                partner_slug: partner.value
            };
            Partner.single(form).then((res)=>{
                if (res.data.slug == 'error') {
                    partner.value = 'sp';
                } else {
                    store.commit(MT.setPartner,partner);
                    partner.value = res.data.slug;
                    imageURL.value = '/img/partners/'+ res.data.image;
                }
            },(err)=>{
                console.log(err);
            });

            loading.value = false;
        };

        const submit = async () => {
            loading.value= true;
            await api.get('sanctum/csrf-cookie');
            await api.post('api/forgot-password',formHelper.mapValues()).then((res)=>{
                message.value = res.data.message;
                showForm.value = false;
                loading.value = false;
            },(err)=>{
                formHelper.validation(err);
            });
            loading.value= false;
        }

        const frontEndPartner = () => {
            //@ts-ignore
            window.location = process.env.VUE_APP_API_PROTOCAL + partner.value + '.' + process.env.VUE_APP_API_BASE;
        }

        return {
            form,
            frontEndPartner,
            getPartnerData,
            imageURL,
            loading,
            partner,
            message,
            showForm,
            submit
        };
    },
});
</script>
<style lang="scss">
    @import "src/assets/styles/pages/authentication";
</style>
