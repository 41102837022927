<template>
	<div v-if="!loading && !err_msg && quote">
		<div class="row">
			<div class="col-12 card">
				<div class="row mx-2 my-5">
					<div class="col-sm-12 col-md-8">
						<div class="row">
							<div class="col-12 card">
								<div v-if="is_approved">
									<div class="row quote-approved">
										<div class="col-12 text-center p-2">
											<strong>Quote sent to applicant on {{moment(pending_partner.approved_at).format('MM/DD/YYYY')}}</strong>
										</div>
									</div>
								</div>
								<div v-if="is_rejected">
									<div class="row quote-rejected">
										<div class="col-12 text-center p-2">
											<strong>Applicant rejected on {{moment(pending_partner.rejected_at).format('MM/DD/YYYY')}}</strong>
										</div>
									</div>
								</div>
								<div class="text-center mt-3">
									<p class="h2">{{quote.user.name}}</p>
									<p class="applicant-contact mb-0 w-50 m-auto">{{quote.user.email}}</p>
									<p class="applicant-contact mb-0 w-50 m-auto">{{quote.user.mobile_number}}</p>
									<p class="applicant-contact mb-5 w-50 m-auto">{{quote.user.job_title}}</p>
									<hr class="hr-dark">
								</div>
								<div class="mx-5">
									<div class="row">
										<div class="col-sm-12 col-md-4">
											<h4>{{quote.user.company_name}}</h4>
											<p class="mb-0">{{quote.user.address_1}}</p>
											<p class="mb-0" v-if="quote.user.address_2 !== null">{{quote.user.address_2}}</p>
											<p class="mb-0">{{quote.user.city}}<span v-if="quote.user.state !== null">, {{quote.user.state.short_name}}</span> {{quote.user.zip}}</p>
										</div>
										<div class="col-sm-12 col-md-4">
											<h4>Target Start Date</h4>
											<p>{{moment(start_date).format('MM/DD/YYYY')}}</p>
										</div>
										<div class="col-sm-12 col-md-4">
											<h4>Submitted on</h4>
											<p>{{moment(quote.created_at).format('MM/DD/YYYY')}}</p>
										</div>
									</div>
									<hr class="hr-dark">
									<div class="row mb-4">
										<div class="col-12 mb-3">
											<h4>Requested Scenario<span v-if="selected_scenarios.length">s</span></h4>
										</div>
										<div v-for="option in selected_scenarios" v-bind:key="option.id" class="col-sm-12 col-md-6">
											<div class="mr-4 float-left">
												<img class="rounded-circle" width="100" :src="option.scenario.patient_image.location" />
											</div>
											<h4 class="scenario-name">{{option.scenario.patient_name}}</h4>
											<div>{{option.scenario.title}}</div>
											<small>Requested Number of Sessions: {{option.scenario_count}}</small>
											<p v-if="option.scenario_count_invoice > 0"><small>
												<span v-if="is_approved">Invoiced</span>
												<span v-else>Proposed</span>
												Number of Sessions: {{option.scenario_count_invoice}}</small></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="quote-builder" class="col-sm-12 col-md-4">
						<div class="row">
							<div class="col-12">
								<p class="mb-0"><strong>Quote Builder</strong></p>
								<p><small>Automatically generate and send quote</small></p>
							</div>
						</div>
						<div class="row mb-5">
							<div class="col-12">
								<label for="session_rate" class="mb-0">Session Rate</label>
								<div class="text-small">Amount charged to partner per session. Amount should reflect all associated fees per session.</div>
								<currency-input v-model="session_rate" :maxlength="3" :size="8" v-on:change="calculateTotal" :disabled="is_approved || is_rejected"/>
							</div>
						</div>
						<div class="row mb-5">
							<div class="col-12">
								<label for="number_of_sessions" class="mb-0">Number of Sessions</label>
								<p><small>Co-brand will be limited to this number of sessions.</small></p>
								<div class="session-numbers" v-for="option in scenarios" v-bind:key="option.id">
									<span v-if="option.scenario_count_invoice > 0">
										<input type="number" v-model="option.scenario_count_invoice" maxlength="3" min="0" required v-on:change="calculateTotalSessions" :disabled="is_approved || is_rejected"/>
									</span>
									<span v-else>
										<input type="number" v-model="option.scenario_count" maxlength="3" min="0" required v-on:change="calculateTotalSessions" :disabled="is_approved || is_rejected"/>
									</span> {{option.patient_name}}
								</div>

								<div class="session-numbers">
									<input type="number" min="1" name="number_of_sessions" v-model="quote_number_of_sessions" :disabled="true"/> Total
								</div>
							</div>
						</div>
						<div class="row mb-4">
							<div class="col-12">
								<label for="payment_amount" class="mb-0">Program Cost</label>
								<div class="text-small">This is the total amount that partner will be required to pay in order to execute contract. Calculated based on session rate times number of sessions plus the processing fee.</div>
								<table>
									<tr>
										<td align="right">${{ calculateSessions() }}&nbsp;</td>
										<td>Session Rate</td>
									</tr>
									<tr>
										<td align="right">${{ calculateProcessingFee() }}&nbsp;</td>
										<td>Proccessing Fee</td>
									</tr>
									<tr>
										<td align="right"><strong>${{ (processingFee + subTotal).toFixed(2) }}&nbsp;</strong></td>
										<td><strong>Total</strong></td>
									</tr>
								</table>
							</div>
						</div>
						<div class="row mb-5">
							<div class="col-12">
								<label for="agreement_duration" class="mb-0">Number of Months</label>
								<p><small>The term of the agreement in months.</small></p>
								<input type="number" min="1" max="60" name="agreement_duration" v-model="agreement_duration" :disabled="is_approved || is_rejected"/>
							</div>
						</div>
						<div class="row mb-5">
							<div class="col-12">
								<p class="mb-0"><label for="brand_title">Brand Title</label></p>
								<input type="text" maxlength="255" size=30 name="brand_title" v-model="brand_title" :disabled="is_approved || is_rejected"/>
							</div>
						</div>
						<div class="row mb-5">
							<div class="col-12">
								<label for="brand_subdomain" class="mb-0">Brand Subdomain</label>
								<div class="text-small">First subdomain of URL for co-brand.<br>
								E.G., partner.simclusive.com, co-brand.simclusive.com, etc.</div>
								<input type="text" maxlength="50" size=30 name="brand_subdomain" v-model="brand_subdomain" :disabled="is_approved || is_rejected"/>
							</div>
						</div>
						<div v-if="!is_approved && !is_rejected">
							<div class="row">
								<div class="col-12 mb-4">
									<button class="btn btn-md btn-primary w-50" @click="saveQuote">Save Quote</button><br>
									<small>Allows for updating of quote before finalizing</small>
								</div>
							</div>
							<div class="row">
								<div class="col-12 mb-4">
									<button class="btn btn-md btn-primary w-50" @click="approvePartner">Approve Request</button><br>
									<small>Partner will be notified by email to sign and submit payment</small>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<button class="btn btn-md btn-danger w-50" @click="rejectPartner">Reject Request</button>
									<p><small>Applicant will be notified by email of rejection decision</small></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="!loading && err_msg">
		<div class="alert alert-danger">{{err_msg}}</div>
	</div>
	<loading :active="loading" :is-full-page="true"></loading>
</template>
<script lang="ts">
	import {defineComponent, ref} from "vue";
	import Loading from "vue3-loading-overlay";
	import moment from "moment";
	import { MutationTypes } from "@/store/modules/ux/mutations";
	import { NotificationIcon, NotificationColor } from "@/interfaces/notification";
	import Request from '@/models/request';
	import { store } from '../../store/index';
	import CurrencyInput from '@/components/currency-input.vue';
	import { useRoute } from 'vue-router';

	export default defineComponent({
		components: {
			CurrencyInput,
			Loading
		},
		name:'quote',
		mounted(){
			this.getQuote();
		},
		setup(){
			const agreement_duration = ref<number>(0);
			const brand_title = ref<String>("");
			const brand_subdomain = ref<String>("");
			const err_msg = ref(false);
			const loading = ref<Boolean>(false);
			const payment_amount = ref<number>(0);
			const pending_partner = ref(null);
			const processingFee = ref<number>(0);
			const quote = ref(null);
			const route = useRoute();
			const scenarios = ref([]);
			const selected_scenarios = ref<Array<Boolean>>([]);
			const session_rate = ref<number>(0);
			const start_date = ref(null);
			const subTotal = ref<number>(0);
			const quote_number_of_sessions = ref<number>(0);
			const is_approved = ref<Boolean>(false);
			const is_rejected = ref<Boolean>(false);

			const approvePartner = async() => {
				loading.value = true;
				var post_data = buildQuotePostData();
				await Request.approvePartner(post_data).then((res) => {
					is_approved.value = true;
					pending_partner.value = res.data.quote;
					store.commit(MutationTypes.addSystemNotification, {title:'Partner approval email sent.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
				},(err)=>{
					var err_str = handleError(err);
					store.commit(MutationTypes.addSystemNotification, {title:'Error approving partner.',content:err_str,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
				});
				loading.value = false;
			}

			const buildQuotePostData = () => {
				var post_data = {
					request_id: +route.params.request_id,
					//@ts-ignore
					user_id: quote.value.user_id,
					payment_amount: payment_amount.value,
					processing_fee: processingFee.value,
					total_amount: (processingFee.value + subTotal.value).toFixed(2),
					number_of_sessions: quote_number_of_sessions.value,
					agreement_duration: agreement_duration.value,
					scenarios: scenarios.value,
					session_rate: session_rate.value,
					subdomain: brand_subdomain.value,
					target_start_date: start_date.value,
					title: brand_title.value,
				}
				if(pending_partner.value !== null){
					//@ts-ignore
					post_data['id'] = pending_partner.value.id;
				}
				return post_data;
			}

			const calculateTotal = async() => {
				payment_amount.value = session_rate.value * quote_number_of_sessions.value;
			}

			const calculateTotalSessions = async() => {
				quote_number_of_sessions.value = 0;
				scenarios.value.forEach((scenario:any) =>{

					if (scenario.scenario_count_invoice > 0) {
						// The OR statement prevent leading zeros in total when value is Null
						quote_number_of_sessions.value += (scenario.scenario_count_invoice || 0);
					} else {
						quote_number_of_sessions.value += (scenario.scenario_count || 0);
					}
					
				});
				calculateTotal();
			}

			const getQuote = async() => {
				loading.value = true;
				var post_data = {
					request_id: +route.params.request_id
				};
				await Request.getQuote(post_data).then((res) => {
					quote.value = res.data.quote;
					selected_scenarios.value = res.data.selected_scenarios;
					scenarios.value = res.data.scenarios;
					pending_partner.value = res.data.pending_partner;
					if(res.data.settings !== null){
						start_date.value = res.data.settings.start_date;
					}
					calculateTotalSessions();

					if(pending_partner.value !== null){
						//@ts-ignore
						brand_title.value = pending_partner.value.title;
						//@ts-ignore
						brand_subdomain.value = pending_partner.value.slug;
						//@ts-ignore
						payment_amount.value = pending_partner.value.payment_amount;
						//@ts-ignore
						is_approved.value = pending_partner.value.is_approved;
						//@ts-ignore
						is_rejected.value = pending_partner.value.is_rejected;
						//@ts-ignore
						session_rate.value = pending_partner.value.session_rate;
						//@ts-ignore
						agreement_duration.value = pending_partner.value.agreement_duration;
					}
				},(err) => {
					console.log(err);
					err_msg.value = err.response.data.message;
				})
				loading.value = false;
			}

			const rejectPartner = async() => {
				loading.value = true;
				var post_data = buildQuotePostData();
				await Request.rejectPartner(post_data).then((res) => {
					is_rejected.value = true;
					pending_partner.value = res.data.quote;
					store.commit(MutationTypes.addSystemNotification, {title:'Partner rejected.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
				},(err) => {
					var err_str = handleError(err);
					store.commit(MutationTypes.addSystemNotification, {title:'Error rejecting partner.',content:err_str,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
				});

				loading.value = false;
			}

			const saveQuote = async() => {
				loading.value = true;
				var post_data = buildQuotePostData();
				await Request.saveQuote(post_data).then((res) => {
					pending_partner.value = res.data.pending_partner;
					store.commit(MutationTypes.addSystemNotification, {title:'Quote saved.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
					setTimeout(function(){
						location.reload();
					}, 3000);
				},(err) =>{
					var err_str = handleError(err);
					store.commit(MutationTypes.addSystemNotification, {title:'Error saving quote.',content:err_str,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
				})
				loading.value = false;
			}

			const handleError = (err:any) =>{
				console.log(err);
				var err_str = '<ul>';
				for(const error in err.response.data.errors){
					err_str += '<li>' + err.response.data.errors[error] + '</li>';
				}
				err_str += '</ul>';
				return err_str;
			}

			function calculateProcessingFee() {
				if (payment_amount.value > 0) {
					processingFee.value = ((payment_amount.value + 0.30) / 0.971) - payment_amount.value;
					return (processingFee.value).toFixed(2);
				} else {
					return 0;
				}
			}
			
			function calculateSessions() {
				if (payment_amount.value > 0) {
					subTotal.value = payment_amount.value;
					return (subTotal.value).toFixed(2);
				} else {
					return 0;
				}
				
			}

			return {
				agreement_duration,
				approvePartner,
				brand_title,
				brand_subdomain,
				calculateProcessingFee,
				calculateSessions,
				calculateTotal,
				calculateTotalSessions,
				err_msg,
				getQuote,
				is_approved,
				is_rejected,
				loading,
				moment,
				payment_amount,
				pending_partner,
				processingFee,
				quote,
				quote_number_of_sessions,
				rejectPartner,
				saveQuote,
				scenarios,
				selected_scenarios,
				session_rate,
				start_date,
				subTotal
			}
		}
	});
</script>
<style scoped lang="scss">
	.scenario-select-box {
		height: 100px;
	}
</style>