<template>
	<div>
		<h1>Manage Quote Requests</h1>
		<p>View and respond to quote requests</p>
	</div>
	<div v-if="!loading">
		<tabs :options="{ useUrlFragment: false, defaultTabHash: 'to-do' }" nav-class="nav nav-pills m-3" nav-item-class="nav-item col-md-3 col-sm-12 text-center" nav-item-link-class="nav-link btn-dashboard-sub-nav" nav-item-link-active-class="active">
			<tab prefix="<h2 class='mt-2'>" suffix="</h2>" name="To Do" id="quotes-to-do">
				<hr class="w-75 mx-auto" />
				<quote-list :quotes="todo" type="todo" />
			</tab>
			<tab prefix="<h2 class='mt-2'>" suffix="</h2>" name="Pending" id="quotes-pending">
				<hr class="w-75 mx-auto" />
				<quote-list :quotes="pending" type="pending" />
			</tab>
			<tab prefix="<h2 class='mt-2'>" suffix="</h2>" name="Completed" id="quotes-completed">
				<hr class="w-75 mx-auto" />
				<quote-list :quotes="completed" type="completed" />
			</tab>
			<tab prefix="<h2 class='mt-2'>" suffix="</h2>" name="Rejected" id="quotes-rejected">
				<hr class="w-75 mx-auto" />
				<quote-list :quotes="rejected" type="rejected" />
			</tab>
        </tabs>
	</div>
	<loading :active="loading" :is-full-page="true"></loading>
</template>
<script lang="ts">
	import {defineComponent, ref} from "vue";
	import {Tabs, Tab} from 'vue3-tabs-component';
	import Loading from "vue3-loading-overlay";
	import Request from "@/models/request";
	import QuoteList from "../../components/quote-list.vue";

	export default defineComponent({
		components: {
			Loading,
			QuoteList,
			Tab,
			Tabs
		},
		mounted(){
			this.getQuotes();
		},
		setup(){
			const loading = ref<Boolean>(false);
			const todo = ref([]);
			const pending = ref([]);
			const completed = ref([]);
			const rejected = ref([]);

			const getQuotes = async() => {
				loading.value = true;
				var post_data = {
					type: 'all'
				};
				await Request.getQuotes(post_data).then((res)=>{
					todo.value = res.data.todo;
					pending.value = res.data.pending;
					completed.value = res.data.completed;
					rejected.value = res.data.rejected;
				},(err)=>{
					console.log(err);
				})
				loading.value = false;
			}

			return {
				completed,
				getQuotes,
				loading,
				pending,
				rejected,
				todo,
			}
		}
	});
</script>
<style lang="scss" scoped>

</style>