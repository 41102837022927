<template>
    <div class="card shadow-sm mb-4">
        <div class="card-header text-white bg-secondary font-weight-bold">Featured Scenarios</div>
        <div class="card-content p-4">
            <div v-if="!visit_loading">
                <span v-if="Object.keys(props.featured_scenarios).length">
                    <div v-for="scenario in props.featured_scenarios" v-bind:key="scenario.id" class="col-12" @click="router.push({name:'scenario.overview',params:{id:scenario.id}})">
                        <div class="row visit-card">
                            <div class="col-10">
                                <img :src="scenario.patient_image.location" class="rounded-circle float-left mr-2" width="75" />
                                <div class="font-weight-bold text-secondary">{{scenario.patient_name}}</div>
                                <div class="overflow-hidden">{{scenario.title}}</div>
                            </div>
                            <div class="col-2 d-flex flex-column justify-content-center">
                                <h3 class="text-center"><i class='fas fa-arrow-circle-right text-primary'></i></h3>
                            </div>
                        </div>
                        <hr class="w-75" />
                    </div>
                </span>
                <p  v-else class="text-center pt-3">No Featured Scenarios</p>
            </div>
            <div class="loading-card-area" v-else>
                <loading :active="visit_loading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import Loading from 'vue3-loading-overlay';
    import router from "@/router";

    export default defineComponent({
        components: {
            Loading
        },
        props: {
            featured_scenarios: {type: Object, required: true},
            visit_loading: {type:Boolean, required:true}
        },
        setup(props) {
            return {
                router,
                props
            };
        }
    });
</script>
