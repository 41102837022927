<template>
    <div v-if="!loading && typeof(pendingUser) !== 'undefined'">
        <div class="row" id="pending-recruit">
            <div class="col-12 card">
                <div class="row mx-2 my-5">
                    <div class="col-sm-12 col-md-8">
                        <div class="row">
                            <div class="col-12 card">
                                <div class="text-center mt-5">
                                    <img class="rounded-circle" width="100" :src="pendingUser.user.image"/>
                                    <p class="h2">{{pendingUser.user.name}}</p>
                                    <p class="applicant-contact mb-0 w-50 m-auto">{{pendingUser.user.email}}</p>
                                    <p class="applicant-contact mb-5 w-50 m-auto">{{pendingUser.user.mobile_number}}</p>
                                    <hr class="hr-dark">
                                </div>
                                <div class="mx-5">
                                    <p><small>Submitted {{ moment(pendingUser.created_at).format('MM/DD/YY') }}</small></p>
                                    <h4>Experience</h4>
                                    <p>{{pendingUser.user.experience}}</p>
                                    <h4>Availabilities</h4>
                                    <p v-if="pendingUser.user.availabilities.length == 0">No Availability</p>
                                    <ul v-else>
                                        <li v-for="availability in pendingUser.user.availabilities" :key="availability.id">
                                            <small>{{availability.day}} {{moment(availability.start_time, ["HH:mm:ss"]).format("hh:mm A")}} - {{moment(availability.end_time, ["HH:mm:ss"]).format("hh:mm A")}}</small>
                                        </li>
                                    </ul>
                                    <h4>Roles Applied</h4>
                                    <span v-for="role in roles" v-bind:key="role.id">
                                        <div v-if="this.hasAppliedForRole(role.id, false)"><span v-if="role.id == UserRole.AUTHOR">SP-ED </span>{{role.name}}</div>
                                        <div v-if="role.id == UserRole.AUTHOR && this.hasAppliedForRole(role.id, true)"> Simclusive Author</div>
                                    </span>
                                    <h4>Time Zone</h4>
                                    <p v-if="pendingUser.user.timezone">{{pendingUser.user.timezone.title}}</p>
                                    <p v-else>Not Set</p>
                                    <br>
                                    <div v-if="pendingUser.user.resume !== null">
                                        <a :href="pendingUser.user.resume" download>Download Resume</a>
                                    </div>
                                    <br>
                                    <div v-if="pendingUser.user.headshot !== null">
                                        <p>Headshot (right click and select "Save Image As" to download)</p>
                                        <a :href="pendingUser.user.headshot" :download="pendingUser.user.name" target="_blank">
                                            <img class="w-100" :src="pendingUser.user.headshot" />
                                        </a>
                                    </div>
                                    <br>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <div v-if="pendingUser.interview !== null">
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <p><strong>Interview Scheduled</strong></p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>An interview has been scheduled by {{pendingUser.interview.trainer.name}} for {{ moment(pendingUser.interview.local_time).format('MM/DD/YY h:mm a')}}.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <join-links :joinevent="pendingUser.interview" :label="'Interview'" :user="user"></join-links>
                                </div>
                            </div>
                            <div class="row" v-if="!pendingUser.interview.is_complete">
                                <div class="col-md-12">
                                    <button type="button" class="btn btn-secondary btn-md w-100 mb-2" @click="showCancelInterviewModal">Cancel Interview</button>
                                </div>
                            </div>
                            <modal v-model="cancelInterviewModal">
                                <template v-slot:header>Cancel Interview</template>
                                <p>Are you sure you want to cancel this interview?</p>
                                <button type="button" class="btn btn-primary btn-xs float-right" @click="cancelInterview">Cancel Interview</button>
                            </modal>
                        </div>
                        <div v-else-if="pendingUser.interview_requests.length !== 0">
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <p><strong>Interview Requested</strong></p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>An interview has been requested by {{pendingUser.interview_requests[0].trainer.name}} for the following dates:</p>
                                    <ul>
                                        <li v-for="date in pendingUser.interview_requests[0].dates" :key="date.id">{{ moment(date.local_time).format('MM/DD/YY h:mm a') }}</li>
                                    </ul>
                                    <p><small>To reschedule please cancel the request</small></p>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-12">
                                    <button type="button" class="btn btn-secondary btn-md w-100 mb-2" @click="showCancelRequestModal">Cancel Request</button>
                                </div>
                            </div>
                            <modal v-model="cancelRequestModal">
                                <template v-slot:header>Cancel Interview Request</template>
                                <p>Are you sure you want to cancel this request?</p>
                                <button type="button" class="btn btn-primary btn-xs float-right" @click="cancelRequest">Cancel Interview Request</button>
                            </modal>
                        </div>
                        <div v-else>
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <p><strong>Schedule an Interview</strong> <small>Please select two dates</small></p>
                                </div>
                            </div>
                            <schedule-form field="interview_date_one" alt-field="interview_date_two" :start-date="new Date()" v-on:update-date="setDate"></schedule-form>
                            <div class="row mb-2">
                                <div class="col-md-12">
                                    <button type="button" class="btn btn-secondary btn-md w-100 my-2" @click="scheduleInterview">Send Scheduling Request to Applicant</button>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <p><strong>Role Settings</strong> <small>To approve, please select roles and assign pay rates.</small></p>
                            </div>
                        </div>
                        <div v-for="role in roles" v-bind:key="role.id" class="row">
                            <div class="col-md-12">
                                <label><input type="checkbox" :value="true" v-model="selected_roles[role.id]"> <span v-if="role.id == UserRole.AUTHOR">SP-ED </span>{{role.name}} <strong v-if="this.hasAppliedForRole(role.id, false)" class="text-primary">Applied</strong></label>
                                <br>
                                <label><small>Rate:</small> <currency-input v-model="role_rates[role.id]"/></label>
                            </div>
                            <div v-if="role.id == UserRole.AUTHOR" class="col-md-12">
                                <label><input type="checkbox" :value="true" v-model="selected_roles['simclusive_author']"> Simclusive Author <strong v-if="this.hasAppliedForRole(role.id, true)" class="text-primary">Applied</strong></label>
                                <br>
                                <label><small>Rate:</small> <currency-input v-model="role_rates['simclusive_author']"/></label>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-12">
                                <button class="btn btn-secondary btn-md w-100 my-2" @click="showApproveApplicationModal">Approve Applicant</button>
                                <button class="btn btn-secondary btn-md w-100" @click="showRejectApplicationModal">Reject Applicant</button>
                            </div>
                        </div>
                        <modal v-model="approveApplicationModal">
                            <template v-slot:header>Approve Application</template>
                            <p>Are you sure you want to approve this application?</p>
                            <p>Approving for the following roles:</p>
                            <ul>
                                <li v-if="selected_roles[UserRole.SP]">Standardized Patient - Rate: ${{role_rates[UserRole.SP]}}</li>
                                <li v-if="selected_roles[UserRole.OBSERVER]">Observer - Rate: ${{role_rates[UserRole.OBSERVER]}}</li>
                                <li v-if="selected_roles[UserRole.TRAINER]">Trainer - Rate: ${{role_rates[UserRole.TRAINER]}}</li>
                                <li v-if="selected_roles[UserRole.AUTHOR]">SP-ED Author - Rate: ${{role_rates[UserRole.AUTHOR]}}</li>
                                <li v-if="selected_roles['simclusive_author']">Simclusive Author - Rate: ${{role_rates['simclusive_author']}}</li>
                                <li v-if="selected_roles[UserRole.REVIEWER]">Reviewer - Rate: ${{role_rates[UserRole.REVIEWER]}}</li>
                            </ul>
                            <button type="button" class="btn btn-primary btn-xs float-right" @click="approveApplication">Approve Application</button>
                        </modal>
                        <modal v-model="rejectApplicationModal">
                            <template v-slot:header>Reject Application</template>
                            <p>Are you sure you want to reject this application?</p>
                            <textarea class="w-100" placeholder="Please provide a note as to why this application was rejected." v-model="rejectForm.rejection_note.value" @change="validateRejectNote"/>
                            <p class="small">This information will be used for internal tracking purposes and is not shared with applicant.</p>
                            <button type="button" class="btn btn-primary btn-xs float-right" @click="rejectApplication">Reject Application</button>
                        </modal>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!loading && typeof(pendingUser) === 'undefined'">
        <p>Error Loading User</p>
        <p>{{ loadErrorMessage }}</p>
    </div>
    <br>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { api, store } from "@/store";
    import { defineComponent, reactive, ref } from "vue";
    import Request from "@/models/request";
    import Interview from "@/models/interview";
    import {RequestType} from "@/enums/request-type";
    import {FormConfig} from "@/interfaces/form-config";
    import {UserRole} from "@/enums/user-role";
    import Form from "@/utilities/form";
    import Loading from 'vue3-loading-overlay';
    import moment from 'moment';
    import JoinLinks from '@/components/join-links.vue';
    import CurrencyInput from '@/components/currency-input.vue';
    import {NotificationIcon,NotificationColor} from "@/interfaces/notification";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import { useRoute } from 'vue-router';
    import router from "@/router";
    import Modal from "@/components/modal.vue";
    import ScheduleForm from '@/components/schedule-form.vue';

    export default defineComponent({
        components: {Loading, Modal, JoinLinks, CurrencyInput, ScheduleForm},
        mounted(){
            this.getPendingUser();
        },
        methods: {
            hasAppliedForRole(roleId:Number,isSim:Boolean):Boolean {
                return this.pendingUser.user.user_roles.some(function(user_role:any){
                    var check = false;
                    if(roleId == UserRole.AUTHOR){
                        var partner_check = isSim ? 28 : 1;
                        check = user_role.role_id === roleId && user_role.partner_id === partner_check;
                    }else{
                        check = user_role.role_id == roleId;
                    }
                    return check;
                },roleId);
            },
            setDate(date:any):void {
                this.scheduleForm[date.field].value = date.date;
                this.scheduleForm[date.field].valid = true;
                this.scheduleForm[date.field].validationMessage = '';
            }
        },
        setup() {
            const loading = ref(false);
            const pendingUser = ref();
            const roles = ref(null);
            const loadErrorMessage = ref('');
            const route = useRoute();
            const cancelRequestModal = ref(false);
            const cancelInterviewModal = ref(false);
            const approveApplicationModal = ref(false);
            const rejectApplicationModal = ref(false);
            const showCancelRequestModal = () => cancelRequestModal.value = !cancelRequestModal.value;
            const showCancelInterviewModal = () => cancelInterviewModal.value = !cancelInterviewModal.value;
            const showApproveApplicationModal = () => approveApplicationModal.value = !approveApplicationModal.value;
            const showRejectApplicationModal = () => rejectApplicationModal.value = !rejectApplicationModal.value;
            const selected_roles = ref<any>({});
            selected_roles.value[UserRole.SP] = false;
            selected_roles.value[UserRole.OBSERVER] = false;
            selected_roles.value[UserRole.TRAINER] = false;
            selected_roles.value[UserRole.AUTHOR] = false;
            selected_roles.value[UserRole.REVIEWER] = false;
            selected_roles.value['simclusive_author'] = false;
            const role_rates = ref<any>({});
            role_rates.value[UserRole.SP] = 0;
            role_rates.value[UserRole.OBSERVER] = 0;
            role_rates.value[UserRole.TRAINER] = 0;
            role_rates.value[UserRole.AUTHOR] = 0;
            role_rates.value[UserRole.REVIEWER] = 0;
            role_rates.value['simclusive_author'] = 0;
            const scheduleForm:FormConfig = reactive({
                interview_date_one:{
                    value:'',
                    valid:true,
                    validationMessage:''
                },
                interview_date_two:{
                    value:'',
                    valid:true,
                    validationMessage:''
                },
                request_type: {
                    value: RequestType.INTERVIEW,
                    valid:true,
                    validationMessage:'',
                },
                applicant_user_id: {
                    value: route.params.user_id,
                    valid: true,
                    validationMessage:''
                },
                parent_request_id: {
                    value: null,
                    valid: false,
                    validationMessage:''
                }
            });
            const scheduleFormHelper = new Form(scheduleForm);
            const cancelRequestForm:FormConfig = reactive({
                request_type: {
                    value: RequestType.INTERVIEW,
                    valid:true,
                    validationMessage:''
                },
                request_id: {
                    value:'',
                    valid:false,
                    validationMessage:''
                }
            });
            const cancelRequestFormHelper = new Form(cancelRequestForm);
            const cancelInterviewForm:FormConfig = reactive({
                interview_id: {
                    value:'',
                    valid:false,
                    validationMessage:''
                }
            });
            const cancelInterviewFormHelper = new Form(cancelInterviewForm);
            const approveForm:FormConfig = reactive({
                request_id: {
                    value:'',
                    valid:false,
                    validationMessage:''
                },
                role_rates: {
                    value:role_rates.value,
                    valid:true,
                    validationMessage:''
                },
                selected_roles: {
                    value:selected_roles.value,
                    valid:true,
                    validationMessage:''
                }
            });
            const approveFormHelper = new Form(approveForm);
            const rejectForm:FormConfig = reactive({
                request_id: {
                    value:'',
                    valid:false,
                    validationMessage:''
                },
                request_type: {
                    value: RequestType.APPLICATION,
                    valid:true,
                    validationMessage:'',
                },
                rejection_note: {
                    value:'',
                    valid:false,
                    validationMessage:'Please provide rejection note'
                }
            });
            const rejectFormHelper = new Form(rejectForm);

            const getPendingUser = async () => {
                loading.value = true;
                await api.get('sanctum/csrf-cookie');
                api.post('api/recruitment/user/' + route.params.user_id,{}).then((res)=>{
                    pendingUser.value = res.data.pending_user;
                    roles.value = res.data.roles;
                    scheduleForm.parent_request_id.value = res.data.pending_user.id;
                    scheduleForm.parent_request_id.valid = true;
                    if(res.data.pending_user.interview_requests.length > 0){
                        cancelRequestForm.request_id.value = res.data.pending_user.interview_requests[0].id;
                        cancelRequestForm.request_id.valid = true;
                    }
                    if(res.data.pending_user.interview !== null){
                        cancelInterviewForm.interview_id.value = res.data.pending_user.interview.id;
                        cancelInterviewForm.interview_id.valid = true;
                    }
                    approveForm.request_id.value = rejectForm.request_id.value = res.data.pending_user.id;
                    loading.value = false;
                },(err)=>{
                    router.push({ path: '/management/user/' + route.params.user_id });
                    console.log(err);
                    loading.value = false;
                });
            }

            const scheduleInterview = async() => {
                loading.value = true;
                await Request.create(scheduleFormHelper.mapValues()).then((res)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Interview request has been sent!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    pendingUser.value.interview_requests = res.data.interview_requests;
                    cancelRequestForm.request_id.value = res.data.interview_requests[0].id;
                    cancelRequestForm.request_id.valid = true;
                },(err:any)=>{
                    scheduleFormHelper.validation(err);
                    let err_body = '';
                    if(typeof(err.response.data.errors) == 'undefined'){
                        err_body = err.response.data.message;
                    }else{
                        err_body = '<ul>';
                        for (const [key, value] of Object.entries(err.response.data.errors)){
                            err_body += '<li>' + key + ': ' + value + '</li>';
                        }
                        err_body += '</ul>'
                    }
                    store.commit(MutationTypes.addSystemNotification, {title:err.response.data.message,content:err_body,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const cancelRequest = async() => {
                loading.value = true;
                await Request.cancel(cancelRequestFormHelper.mapValues()).then(()=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Interview request has been cancelled!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    pendingUser.value.interview_requests = [];
                    cancelRequestModal.value = false;
                },(err:any)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error cancelling interview request.',content:err.response.data.message,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const cancelInterview = async() => {
                loading.value = true;
                await Interview.cancel(cancelInterviewFormHelper.mapValues()).then(()=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Interview has been cancelled!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    pendingUser.value.interview = null;
                    cancelInterviewModal.value = false;
                },(err:any)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error cancelling interview',content:err.response.data.message,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const approveApplication = async() => {
                loading.value = true;
                console.log(approveFormHelper.mapValues());
                await Request.approve(approveFormHelper.mapValues()).then(()=>{
                    router.push({name:'dashboard'});
                    store.commit(MutationTypes.addSystemNotification,{title:'Success',content:'Application has been approved',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err:any)=>{
                    store.commit(MutationTypes.addSystemNotification,{title:'Error approving application',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const rejectApplication = async() => {
                loading.value = true;
                if(!rejectForm.rejection_note.valid){
                    store.commit(MutationTypes.addSystemNotification,{title:'Error rejecting application',content:'Please provide a rejection note for record keeping purposes.',icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                }else{
                    await Request.reject(rejectFormHelper.mapValues()).then(()=>{
                        router.push({name:'dashboard'});
                        store.commit(MutationTypes.addSystemNotification,{title:'Success',content:'Application has been rejected',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                    },(err:any)=>{
                        store.commit(MutationTypes.addSystemNotification,{title:'Error rejecting application',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    });
                }
                loading.value = false;
            }

            const validateRejectNote = async() => {
                rejectForm.rejection_note.valid = rejectForm.rejection_note.value != '';
            }

            return {
                user: store.state.auth.user,
                getPendingUser,
                pendingUser,
                loading,
                moment,
                route,
                roles,
                role_rates,
                selected_roles,
                scheduleForm,
                scheduleFormHelper,
                scheduleInterview,
                cancelRequestForm,
                cancelRequestFormHelper,
                cancelRequest,
                cancelRequestModal,
                showCancelRequestModal,
                cancelInterviewModal,
                showCancelInterviewModal,
                approveApplicationModal,
                showApproveApplicationModal,
                rejectApplicationModal,
                showRejectApplicationModal,
                cancelInterviewForm,
                cancelInterviewFormHelper,
                cancelInterview,
                loadErrorMessage,
                approveApplication,
                approveForm,
                approveFormHelper,
                rejectApplication,
                rejectForm,
                rejectFormHelper,
                UserRole,
                validateRejectNote
            };
        },
    });
</script>
<style lang="scss">
    .h2 {
        color: #6cbaa2;
    }
    .h5,
    .applicant-contact {
        color: #112c55;
    }
    hr.hr-dark{
        border-top: 1px solid #112c55;
        width: 95%;
    }
</style>
