<template>
    <div v-if="typeof(store.getters.user) !== 'undefined' && typeof(store.getters.user.props) !== 'undefined'" class="row user-subhead">
        <div class="col-md-6 col-sm-12">
            
        </div>
        <div class="col-md-6 col-sm-12">
            <div v-if="route.name !== 'dashboard' && route.name !== 'passcode' && route.name !== 'PartnerAgreement'">
                <button class="btn btn-primary px-5 font-weight-bold" @click="returnToDashboard">Return to Dashboard</button>
            </div>
            <div v-else-if="(store.getters.dashboard_roles.length + store.getters.user.props.super_admin) > 1" class="float-right">
                <button v-for="user_role in store.getters.dashboard_roles" v-bind:key="user_role.id" class="btn btn-lg btn-dashboard-nav ml-2" :class="store.getters.dashboard_view === user_role.role_id ? 'active' : ''" @click="switchDashboard(user_role.role.id)">{{user_role.role.name}}</button>
                <button v-if="store.getters.user.props.super_admin" class="btn btn-lg btn-dashboard-nav ml-2" :class="store.getters.dashboard_view == UserRole.ADMIN ? 'active' : ''" @click="switchDashboard(UserRole.ADMIN)">Admin</button>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import {defineComponent, ref} from "vue";
    import moment from "moment";
    import router from "@/router";
    import {store} from "@/store";
    import { useRoute } from 'vue-router';
    import {MutationTypes} from "@/store/modules/auth/mutations";
    import {UserRole} from "@/enums/user-role";

    export default defineComponent({
        setup() {
            const today = ref(moment().format('dddd, LL'))
            const route = useRoute();
            const returnToDashboard = () => {
                router.push({name:'dashboard'});
            }
            const switchDashboard = async(role:string) => {
                let btn_group: Element[] = Array.from(document.getElementsByClassName('btn-dashboard-nav'));
                for(let btn of btn_group){
                    btn.classList.remove('active');
                }
                let selected = (event?.target as Element);
                selected.classList.add('active');
                store.commit(MutationTypes.setDashboardView, role);
            }
            return {
                returnToDashboard,
                route,
                store,
                switchDashboard,
                today,
                UserRole
            };
        }
    });
</script>
<style lang="scss" scoped>

</style>
