<template>
    <div class="authentication-wrapper authentication-2 px-4">
        <div class="authentication-inner py-5">

            <!-- Form -->
            <div class="d-flex justify-content-center align-items-center mb-5">
                <div>
                    <img :src="imageURL" :alt="partner +' Logo'" :title="partner +' Logo'" height="75">
                </div>
            </div>
            <form class="card" @submit.prevent="submit" v-show="showForm">
                <div class="p-4 p-sm-5">

                    <h2 class="text-center text-muted mb-4">Change Password</h2>

                    <hr class="mt-0 mb-4">

                    <div class="form-group">
                        <label for="email" class="form-label">
                            Email address
                        </label>
                        <input id="email" name="email" type="email" v-model="form.email.value" autocomplete="email" :disabled="loading" required :class="form.email.valid ? '' : 'border-danger'"  class="form-control " placeholder="Enter your email address">
                        <p class="mt-2 text-sm text-danger" v-if="!form.email.valid">
                            {{form.email.validationMessage}}
                        </p>
                    </div>
                    <div class="form-group">
                        <label for="password" class="form-label">
                            New Password
                        </label>
                        <input id="password" name="new_password" type="password" v-model="form.password.value" :disabled="loading" required :class="form.email.valid ? '' : 'border-danger'"  class="form-control ">
                        <p class="mt-2 text-sm text-danger" v-if="!form.password.valid">
                            {{form.password.validationMessage}}
                        </p>
                    </div>
                    <div class="form-group">
                        <label for="password_confirmation" class="form-label">
                            Confirm New Password
                        </label>
                        <input id="password_confirmation" name="re_password" type="password" v-model="form.password_confrimation.value" :disabled="loading" required :class="form.password_confrimation.valid ? '' : 'border-danger'"  class="form-control">
                        <p class="mt-2 text-sm text-danger" v-if="!form.password_confrimation.valid">
                            {{form.password_confirmation.validationMessage}}
                        </p>
                    </div>

                    <button type="submit" class="btn btn-primary btn-block">Change Password</button>

                </div>
            </form>
            <!-- / Form -->
            <div >
                <h4 class="text-center" v-if="!showForm">{{message}}</h4>
                <div class="d-flex justify-content-center" >
                    <router-link v-if="showForm" class="mt-2 font-medium text-primary" to="/login">Back to Login</router-link>
                    <router-link v-if="!showForm" class="mt-2 font-medium text-primary" to="/dashboard">Continue to dashboard >></router-link>
                </div>
            </div>
        </div>
        <loading :active="loading" :is-full-page="true"></loading>
    </div>
</template>
<script lang="ts">
import { api } from "@/store";
import { defineComponent, reactive, ref} from "vue";
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import { useRoute } from 'vue-router';
import Partner from "@/models/partner";
import { MutationTypes as MT } from '@/store/modules/auth/mutations';
import { store} from '@/store';
export default defineComponent({
    components: {Loading},
    mounted() {
        this.getPartnerData();
    },
    setup(){
        const route = useRoute();
        const form:any = reactive({
            email: {
                value:'',
                valid:true,
                validationMessage:''
            },
            password:{
                value:'',
                valid:true,
                validationMessage:''
            },
            password_confrimation: {
                value:'',
                valid:true,
                validationMessage:''
            }
        });

        const showForm = ref(true);
        const loading = ref(false);
        const message= ref("");

        let partner = ref(route.params.partner_slug);
        let imageURL = ref('/img/partners/sp-logo.svg');


        if (!partner.value) {
            partner.value = 'sp';
        }

        const getPartnerData = () => {
            loading.value = true;

            var form = {
                partner_slug: partner.value
            };
            Partner.single(form).then((res)=>{
                if (res.data.slug == 'error') {
                    partner.value = 'sp';
                } else {
                    store.commit(MT.setPartner,partner);
                    partner.value = res.data.slug;
                    imageURL.value = '/img/partners/'+ res.data.image;
                }
            },(err)=>{
                console.log(err);
            });

            loading.value = false;
        };

        const submit = async ()=>{
            await api.get('sanctum/csrf-cookie');
            api.post('api/reset-password',{email:form.email.value,password: form.password.value, password_confirmation: form.password_confrimation.value, token: route.params.token}).then((res)=>{
                message.value = res.data.message;
                showForm.value = false;
                loading.value = false;
            },(err)=>{
                if(err.response.data.errors){
                    for (const [key, value] of Object.entries(err.response.data.errors)) {
                        const msg = value as Array<String>;
                        form[key].valid = false;
                        form[key].validationMessage = msg[0];
                    }
                }
                loading.value = false;
            });
        }
        return {
            form,
            getPartnerData,
            imageURL,
            partner,
            showForm,
            loading,
            message,
            submit
        }
    }
})
</script>
<style lang="scss">
    @import "src/assets/styles/pages/authentication";
</style>
