import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "layout-wrapper layout-2" }
const _hoisted_2 = { class: "layout-inner" }
const _hoisted_3 = { class: "layout-container" }
const _hoisted_4 = { class: "layout-content condensed-section" }
const _hoisted_5 = { class: "router-transitions container-fluid" }
const _hoisted_6 = { class: "top-right mr-2 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Header = _resolveComponent("Header")!
  const _component_user_subheader = _resolveComponent("user-subheader")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Sidebar),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Header),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_user_subheader),
            _renderSlot(_ctx.$slots, "default")
          ]),
          _createVNode(_component_Footer)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("span", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.getters.systemNotifications, (notification, index) => {
          return (_openBlock(), _createBlock(_component_Notification, {
            key: index,
            index: index,
            notification: notification
          }, null, 8, ["index", "notification"]))
        }), 128))
      ])
    ])
  ]))
}