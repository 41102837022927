<template>
    <tabs :options="{ useUrlFragment: false }" nav-class="nav nav-pills m-3" nav-item-class="nav-item col-md-4 col-sm-12 text-center" nav-item-link-class="nav-link btn-dashboard-sub-nav" nav-item-link-active-class="active">
        <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='far fa-clock text-primary'></i></h4>" name="Pending">
            <hr class="w-75 mx-auto" />
            <div v-if="[1,28].includes(current_partner)" class="row mx-auto my-3">
                <div class="col-sm-12 col-md-12">
                    <dashboard-filters :report_data="report_data" v-on:dashboard-change="dashboardChange" :filter_partner_id="filter_partner_id" :filter_scenario_id="filter_scenario_id" />
                </div>
            </div>
            <div v-if="current_partner == 28" class="row mx-auto my-3">
                <div class="col-sm-12 col-md-12">
                    <session-counter :is_client_admin="true" />
                </div>
            </div>
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <pending-visits :pending_visits="pending_visits" :pending_visits_total="pending_visits_total" :visit_loading="pending_visits_loading" :is_admin="true" :reset_pagination="reset_pagination" v-on:change-page="changePage" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <visit-report :is_global="true" :report_data="report_data" :is_client_admin="true"/>
                    <participant-list :report_data="pending_participants" status="Pending" />
                </div>
            </div>
        </tab>
        <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='far fa-calendar-alt text-primary'></i></h4>" name="Scheduled">
            <hr class="w-75 mx-auto" />
            <div v-if="[1,28].includes(current_partner)" class="row mx-auto my-3">
                <div class="col-sm-12 col-md-12">
                    <dashboard-filters :report_data="report_data" v-on:dashboard-change="dashboardChange" :filter_partner_id="filter_partner_id" :filter_scenario_id="filter_scenario_id" />
                </div>
            </div>
            <div v-if="current_partner == 28" class="row mx-auto my-3">
                <div class="col-sm-12 col-md-12">
                    <session-counter :is_client_admin="true" />
                </div>
            </div>
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <scheduled-visits :scheduled_visits="scheduled_visits" :scheduled_visits_total="scheduled_visits_total" :visit_loading="scheduled_visits_loading" :is_admin="true" :is_client_admin="true" :reset_pagination="reset_pagination" v-on:change-page="changePage" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <visit-report :is_global="true" :report_data="report_data" :is_client_admin="true" />
                    <participant-list :report_data="scheduled_participants" status="Scheduled" />
                </div>
            </div>
        </tab>
        <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-check text-primary'></i></h4>" name="Completed">
            <hr class="w-75 mx-auto" />
            <div v-if="[1,28].includes(current_partner)" class="row mx-auto my-3">
                <div class="col-sm-12 col-md-12">
                    <dashboard-filters :report_data="report_data" v-on:dashboard-change="dashboardChange" :filter_partner_id="filter_partner_id" :filter_scenario_id="filter_scenario_id" />
                </div>
            </div>
            <div v-if="current_partner == 28" class="row mx-auto my-3">
                <div class="col-sm-12 col-md-12">
                    <session-counter :is_client_admin="true" />
                </div>
            </div>
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <completed-visits :completed_visits="completed_visits" :completed_visits_total="completed_visits_total" :visit_loading="completed_visits_loading" :is_admin="true" :is_client_admin="true" :reset_pagination="reset_pagination" v-on:change-page="changePage" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <visit-report :is_global="true" :report_data="report_data" :is_client_admin="true" />
                    <participant-list :report_data="completed_participants" status="Completed" />
                </div>
            </div>
        </tab>
    </tabs>
</template>

<script lang="ts">
    import {defineComponent,ref} from "vue";
    import { store } from '../../store/index';
    import {Tabs, Tab} from 'vue3-tabs-component';
    import CompletedVisits from '@/components/dashboard/completed-visits.vue';
    import DashboardFilters from '@/components/dashboard/dashboard-filters.vue';
    import moment from 'moment';
    import ParticipantList from '@/components/dashboard/participant-list.vue';
    import PendingVisits from '@/components/dashboard/pending-visits.vue';
    import router from "@/router";
    import ScheduledVisits from '@/components/dashboard/scheduled-visits.vue';
    import SessionCounter from '@/components/dashboard/session-counter.vue';
    import Visit from '@/models/visit';
    import VisitReport from '@/components/dashboard/visit-report.vue';


    export default defineComponent({
        components: {
            CompletedVisits,
            DashboardFilters,
            ParticipantList,
            PendingVisits,
            ScheduledVisits,
            SessionCounter,
            Tab,
            Tabs,
            VisitReport
        },
        mounted() {
            this.getVisitData(0,'all');
            this.getVisitReportData(null,null);
            this.getParticipantList(null,null);
        },
        setup() {
            const pending_visits = ref([]);
            const pending_visits_total = ref(0);
            const pending_visits_loading = ref<Boolean>(true);
            const scheduled_visits = ref([]);
            const scheduled_visits_total = ref(0);
            const scheduled_visits_loading = ref<Boolean>(true);
            const completed_visits = ref([]);
            const completed_visits_total = ref(0);
            const completed_visits_loading = ref<Boolean>(true);
            const current_partner = ref(store.getters.partner?.id);
            const filter_partner_id = ref(null);
            const filter_scenario_id = ref(null);
            const report_data = ref({});
            const completed_participants = ref({});
            const pending_participants = ref({});
            const scheduled_participants = ref({});
            const reset_pagination = ref<Boolean>(false);
            const getParticipantList = async(partner_id:any, scenario_id:any) => {
                var post_data = {
                    partner_id: partner_id,
                    scenario_id: scenario_id,
                    status: 'all'
                }
                Visit.getParticipantList(post_data).then((res)=>{
                    completed_participants.value = {
                        list: res.data.list.completed,
                        scenarios: res.data.scenarios,
                        partners: res.data.partners
                    };
                    pending_participants.value = {
                        list: res.data.list.pending,
                        scenarios: res.data.scenarios,
                        partners: res.data.partners
                    };
                    scheduled_participants.value = {
                        list: res.data.list.scheduled,
                        scenarios: res.data.scenarios,
                        partners: res.data.partners
                    };
                },(err)=>{
                    console.log(err);
                });
            }
            const getVisitData = async(page_start:Number,type:String) => {
                if(type == 'all' || type == 'requests'){
                    pending_visits_loading.value = true;
                }
                if(type == 'all' || type == 'scheduled'){
                    scheduled_visits_loading.value = true;
                }
                if(type == 'all' || type == 'completed'){
                    completed_visits_loading.value = true;
                }
                var post_data = {
                    user_id: store.getters.user.props.id,
                    role_id: store.getters.dashboard_view,
                    page_start: page_start,
                    type: type,
                    partner_id: filter_partner_id.value,
                    scenario_id: filter_scenario_id.value
                };
                Visit.getUserVisits(post_data).then((res)=>{
                    if(type == 'all' || type == 'requests'){
                        pending_visits.value = res.data.visit_requests;
                        pending_visits_total.value = res.data.visit_requests_total;
                        pending_visits_loading.value = false;
                    }
                    if(type == 'all' || type == 'scheduled'){
                        scheduled_visits.value = res.data.scheduled_visits;
                        scheduled_visits_total.value = res.data.scheduled_visits_total;
                        scheduled_visits_loading.value = false;
                    }
                    if(type == 'all' || type == 'completed'){
                        completed_visits.value = res.data.completed_visits;
                        completed_visits_total.value = res.data.completed_visits_total;
                        completed_visits_loading.value = false;
                    }
                },(err)=>{
                    console.log(err);
                });
            };
            const getVisitReportData = async(partner_id:any, scenario_id:any) => {
                var post_data:any = {
                    partner_id: partner_id,
                    scenario_id: scenario_id,
                    is_client_admin: true
                };
                Visit.getReportData(post_data).then((res)=>{
                    report_data.value = {
                        pending_count: res.data.pending_count,
                        scheduled_count: res.data.scheduled_count,
                        completed_count: res.data.completed_count,
                        partners: res.data.partners,
                        scenarios: res.data.scenarios
                    };
                },(err)=>{
                    console.log(err);
                })
            };

            const dashboardChange = async(res:any) => {
                reset_pagination.value = true;
                pending_visits_loading.value = true;
                scheduled_visits_loading.value = true;
                completed_visits_loading.value = true;
                pending_visits.value = res.data.visit_requests;
                pending_visits_total.value = res.data.visit_requests_total;
                pending_visits_loading.value = false;
                scheduled_visits.value = res.data.scheduled_visits;
                scheduled_visits_total.value = res.data.scheduled_visits_total;
                scheduled_visits_loading.value = false;
                completed_visits.value = res.data.completed_visits;
                completed_visits_total.value = res.data.completed_visits_total;
                completed_visits_loading.value = false;
                filter_partner_id.value = res.data.partner_id;
                filter_scenario_id.value = res.data.scenario_id;
                getVisitReportData(res.data.partner_id, res.data.scenario_id);
                getParticipantList(res.data.partner_id, res.data.scenario_id);
            }

            const changePage = (vars:any) => {
                getVisitData(vars.page_start,vars.type);
                reset_pagination.value = false;
           }
            return {
                changePage,
                current_partner,
                dashboardChange,
                filter_partner_id,
                filter_scenario_id,
                pending_visits,
                pending_visits_total,
                pending_visits_loading,
                scheduled_visits,
                scheduled_visits_total,
                scheduled_visits_loading,
                completed_visits,
                completed_visits_total,
                completed_visits_loading,
                pending_participants,
                scheduled_participants,
                completed_participants,
                getParticipantList,
                getVisitData,
                getVisitReportData,
                moment,
                report_data,
                router,
                reset_pagination
            };
        }
    });
</script>
