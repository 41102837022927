<template>
    <div class="d-flex justify-content-end mb-3">
      <button v-if="author" class="btn btn-primary" @click="create" :disabled="loading"><i class="fa fa-plus-circle"></i> <span class="d-none d-md-inline">Create New Scenario</span></button>
    </div>

    <div id="scenario-dashboard" class="card">
        <div class="card-body">
            <div v-if="learner">
                <div v-if="scenarioList.length > 0" class="row mx-auto my-3">
                    <div class="col-12 scenario-list-header">Available Scenarios</div>
                    <ScenarioListItem v-for="(item,index) in scenarioList.slice(page_start,page_end)" v-bind:key="index" :scenario="item" :is-learner="learner"/>
                    <pagination class="col-12" v-if="Object.keys(scenarioList).length > 10" :items="scenarioList" :page_start="page_start" :page_end="page_end" v-on:next-page="nextPage" v-on:previous-page="previousPage" :label="'Scenarios'" />
                </div>
            </div>

            <tabs v-else :options="{ useUrlFragment: false }" nav-class="nav nav-pills" nav-item-class="nav-item col-md-4 col-sm-6 col-xs-12 text-center mb-4" nav-item-link-class="nav-link btn-dashboard-sub-nav" nav-item-link-active-class="active">

                <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-clipboard-list text-primary'></i></h4>" name="Published Scenarios">
                    <hr class="w-75 mx-auto" />
                    <div v-if="scenarioList.length > 0" class="row mx-auto my-3">
                        <div class="col-12 scenario-list-header">Published Scenarios</div>
                        <ScenarioListItem v-for="(item,index) in scenarioList.slice(page_start,page_end)" v-bind:key="index" :scenario="item" />
                        <pagination class="col-12" v-if="Object.keys(scenarioList).length > 10" :items="scenarioList" :page_start="page_start" :page_end="page_end" v-on:next-page="nextPage" v-on:previous-page="previousPage" :label="'Scenarios'" />
                    </div>
                </tab>

                <tab v-if="!trainer" prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-user text-primary'></i></h4>" name="My Scenarios">
                    <hr class="w-75 mx-auto" />
                    <div class="col-12 scenario-list-header">My Scenarios</div>
                    <div v-if="myScenarios.length > 0" class="row mx-auto my-3">
                        <ScenarioListItem v-for="(item,index) in myScenarios" v-bind:key="index" :scenario="item" />
                    </div>
                    <div class="row mx-auto my-3">
                        <div class="col-12">
                            <div class="dashbaord-create-container">
                                <p v-if="myScenarios.length === 0">You do not have any scenarios</p>
                                <button class="btn btn-primary" @click="create" :disabled="loading"><i class="fa fa-plus-circle"></i> <span class="d-none d-md-inline">CREATE A NEW SCENARIO</span></button>
                            </div>
                        </div>
                    </div>
                </tab>

                <tab v-if="!trainer" prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-search text-primary'></i></h4>" name="Needs Review">
                    <hr class="w-75 mx-auto" />
                    <div class="col-12 scenario-list-header">Scenarios Needing Review</div>
                    <div v-if="needsReview.length > 0" class="row mx-auto my-3">
                        <ScenarioListItem v-for="(item,index) in needsReview" v-bind:key="index" :scenario="item" />
                    </div>
                    <div v-else class="row mx-auto my-3">
                        <div class="col-12">
                            <div class="dashbaord-create-container">
                                <p>There are No Scenarios Needing Review</p>
                            </div>
                        </div>
                    </div>
                </tab>

                <tab v-if="!trainer" prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-eye text-primary'></i></h4>" name="In Review">
                    <hr class="w-75 mx-auto" />
                    <div class="col-12 scenario-list-header">Scenarios In Review</div>
                    <div v-if="inReview.length > 0" class="row mx-auto my-3">
                        <ScenarioListItem v-for="(item,index) in inReview" v-bind:key="index" :scenario="item" />
                    </div>
                    <div v-else class="row mx-auto my-3">
                        <div class="col-12">
                            <div class="dashbaord-create-container">
                                <p>There are No Scenarios In Review</p>
                            </div>
                        </div>
                    </div>
                </tab>

                <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-check-circle text-primary'></i></h4>" name="Approved">
                    <hr class="w-75 mx-auto" />
                    <div class="col-12 scenario-list-header">Approved Scenarios</div>
                    <div v-if="approved.length > 0" class="row mx-auto my-3">
                        <ScenarioListItem v-for="(item,index) in approved" v-bind:key="index" :scenario="item" />
                    </div>
                    <div v-else class="row mx-auto my-3">
                        <div class="col-12">
                            <div class="dashbaord-create-container">
                                <p>There are No Approved Scenarios</p>
                            </div>
                        </div>
                    </div>
                </tab>

                <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-archive text-primary'></i></h4>" name="Archived">
                    <hr class="w-75 mx-auto" />
                    <div class="col-12 scenario-list-header">Archived Scenarios</div>
                    <div v-if="archived.length > 0" class="row mx-auto my-3">
                        <ScenarioListItem v-for="(item,index) in archived" v-bind:key="index" :scenario="item" />
                    </div>
                    <div v-else class="row mx-auto my-3">
                        <div class="col-12">
                            <div class="dashbaord-create-container">
                                <p>There are No Archived Scenarios</p>
                            </div>
                        </div>
                    </div>
                </tab>
            </tabs>
        </div>
    </div>
  <Loading :active="loading" :is-full-page="true"></Loading>
</template>
<script lang="ts">
    import {computed, defineComponent, onMounted, ref} from "vue";
    import {IScenario} from "@/interfaces/scenario";
    import {store} from "@/store";
    import {Tabs, Tab} from 'vue3-tabs-component';
    import Loading from "vue3-loading-overlay";
    import Pagination from '@/components/dashboard/pagination.vue';
    import Partner from "@/models/partner";
    import router from "@/router";
    import Scenario from "@/models/scenario";
    import ScenarioListItem from "@/components/scenarios/scenario-list-item.vue";
    //import Tabs from "@/components/tabs.vue";

    export default defineComponent({
        name: "scenarios",
        components: {
            Loading,
            Pagination,
            Tab,
            Tabs,
            ScenarioListItem
        },
        setup(){
            const approved = ref<Array<IScenario>>([]);
            const archived = ref<Array<IScenario>>([]);
            const inReview = ref<Array<IScenario>>([]);
            const myScenarios = ref<Array<IScenario>>([]);
            const needsReview = ref<Array<IScenario>>([]);
            const scenarioList = ref<Array<IScenario>>([]);
            const tabs = ref<Array<string>>([]);

            const admin = ref(store.getters.hasPermission('scenario.admin'));
            const author = computed(()=>!admin.value && store.getters.hasPermission('scenario.create'));
            const headtrainer = computed(()=>!admin.value && store.getters.hasPermission('scenario.create'));
            const reviewer = computed(()=>!admin.value && store.getters.hasPermission('scenario.review'));
            const trainer = computed(()=>!admin.value && store.getters.hasPermission('training_session.schedule'));

            const learner = computed(()=>!admin.value && !author.value && !headtrainer.value && !reviewer.value && !trainer.value);
            const loading = ref(false);

            onMounted(async ()=>{
                loading.value = true;
                if(store.getters.partner && ![1,28].includes(store.getters.partner.id)){
                    await Partner.scenarios(store.getters.partner.id).then((res:any)=>{scenarioList.value = res.data;});
                }else{
                    await Scenario.list().then((res:any)=>scenarioList.value = res.data);
                }

                if (admin.value) {
                    await Scenario.approved().then((res:any)=>approved.value = res.data);
                    await Scenario.archived().then((res:any)=>archived.value = res.data);
                    await Scenario.myScenarios().then((res:any)=>myScenarios.value = res.data);
                    await Scenario.neeedReview().then((res:any)=>needsReview.value = res.data);
                    await Scenario.inReview().then((res:any)=>inReview.value = res.data);
                } else if (trainer.value) {
                    await Scenario.approved().then((res:any)=>approved.value = res.data);
                    await Scenario.archived().then((res:any)=>archived.value = res.data);
                }
                loading.value = false;
            });

            const page_start = ref(0);
            const page_end = ref(10);
            const nextPage = async() => {
                page_start.value = page_start.value + 10;
                page_end.value = page_end.value + 10;
                if(page_end.value > Object.keys(scenarioList.value).length){
                    page_end.value = Object.keys(scenarioList.value).length;
                }
            };
            const previousPage = async() => {
                page_start.value = page_start.value - 10;
                page_end.value = page_start.value + 10;
            };


          const create = async ()=>{
            loading.value = true;
            Scenario.create().then((res)=>{
              router.push({path:'/scenarios/'+res.data.id});
            });

            loading.value = false;
          }
            return {
                admin,
                approved,
                archived,
                author,
                create,
                inReview,
                learner,
                loading,
                myScenarios,
                needsReview,
                nextPage,
                page_end,
                page_start,
                previousPage,
                reviewer,
                scenarioList,
                tabs,
                trainer,
            }
        }
    });
</script>
<style lang="scss">
    @import "src/assets/styles/pages/clients.scss";
</style>
