<template>
    <div>
        <h1>Welcome Messages</h1>
        <p><small>Set welcome messages for each partner's landing page.</small></p>
    </div>
    <div v-if="!loading && !has_error && partners" class="overflow-auto">
        <div class="card py-2 px-2">
            <table class="table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th class="header-row py-2 px-2">Partner Name</th>
                        <th class="header-row py-2">Partner Slug</th>
                        <th class="header-row py-2">Welcome Message</th>
                        <th class="header-row py-2">Welcome Header</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(partner, i) in partners" v-bind:key="partner.id">
                        <td>{{partner.short_name}}</td>
                        <td>{{partner.slug}}</td>
                        <td>
                            <div v-html="partner.welcome_message"></div>
                            <br />
                            <span><button class="btn btn-primary" @click="editMessage(i)">Edit Welcome Message</button></span>
                        </td>
                        <td v-if="partner.is_sim">
                            <div v-html="partner.welcome_header"></div>
                            <br />
                            <span><button class="btn btn-primary" @click="editHeader(i)">Edit Welcome Header</button></span>
                        </td>
                        <td v-else>-</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-else-if="!loading && has_error">
        <h3>Error</h3>
        <p v-html="error_msg"></p>
    </div>
    <div v-else-if="!loading && !has_error && !partners">
        <p>No partners in system</p>
    </div>
    <br/>
    <modal v-model="display_edit_form">
        <template v-slot:header>Update Welcome Message for {{edit_partner_name}}</template>
        <template v-slot:default>
            <Loading :active="loading" :is-full-page="false"></Loading>
            <text-editor v-model="welcome_message"/>
        </template>
        <template v-slot:footer>
            <div class="d-flex justify-content-end">
                <button class="btn btn-primary" type="button" @click="saveWelcomeMessage()">Save</button>
                <button class="btn btn-secondary" @click="display_edit_form = false" type="button">Cancel</button>
            </div>
        </template>
    </modal>
    <modal v-model="display_edit_header_form">
        <template v-slot:header>Update Welcome Header for {{edit_partner.short_name}}</template>
        <template v-slot:default>
            <loading :active="loading" :is-full-page="false"></loading>
            <input type="text" maxlength="255" class="w-100" v-model="welcome_header"/>
        </template>
        <template v-slot:footer>
            <div class="d-flex justify-content-end">
                <button class="btn btn-primary" type="button" @click="saveWelcomeHeader()">Save</button>
                <button class="btn btn-secondary" @click="display_edit_header_form = false" type="button">Cancel</button>
            </div>
        </template>
    </modal>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">

    import {defineComponent, ref} from "vue";
    import Loading from 'vue3-loading-overlay';
    import Modal from "@/components/modal.vue";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    import {store} from "@/store";
    import Partner from "@/models/partner";
    import TextEditor from "@/components/text-editor.vue";

    export default defineComponent({
        components: {Loading, Modal, TextEditor},
        mounted(){
            this.getPartners();
        },

        setup() {
            const display_edit_form = ref<Boolean>(false);
            const display_edit_header_form = ref<Boolean>(false);
            const edit_partner_id = ref<number>(0);
            const edit_partner_index = ref<number>(0);
            const edit_partner_name = ref<String>('');
            const edit_partner = ref<any>(null);
            const error_msg = ref<String>('');
            const has_error = ref<Boolean>(false);
            const loading = ref<Boolean>(false);
            const partners = ref<Array<any>>([]);
            const welcome_header = ref(null);
            const welcome_message = ref(null);

            const getPartners = async() => {
                loading.value = true;
                Partner.getPartners().then((res)=>{
                    has_error.value = false;
                    error_msg.value = '';
                    partners.value = res.data;
                },(err)=>{
                    console.log(err);
                    has_error.value = true;
                    error_msg.value = err;
                });
                loading.value = false;
            }

            const editHeader = (partner_id:number) => {
                welcome_header.value = partners.value[partner_id].welcome_header;
                edit_partner_id.value = partners.value[partner_id].id;
                edit_partner_index.value = partner_id;
                edit_partner.value = partners.value[partner_id];
                display_edit_header_form.value = true;
            }

            const editMessage = (partner_id:number) => {
                welcome_message.value = partners.value[partner_id].welcome_message;
                edit_partner_id.value = partners.value[partner_id].id;
                edit_partner_index.value = partner_id;
                edit_partner_name.value = partners.value[partner_id].short_name;
                display_edit_form.value = true;
            }

            const saveWelcomeHeader = async() => {
                loading.value = true;
                var post_data = {
                    partner_id: edit_partner_id.value,
                    welcome_header: welcome_header.value
                };
                await Partner.updateWelcomeHeader(edit_partner_id.value, post_data).then(()=>{
                    partners.value[edit_partner_index.value].welcome_header = welcome_header.value;
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Partner welcome header updated!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    display_edit_header_form.value = false;
                    loading.value = false;
                },(err)=>{
                    console.log(err);
                    store.commit(MutationTypes.addSystemNotification, {title:'Error',content:'Error updating partner welcome header.',icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                    loading.value = false;
                });
            }

            const saveWelcomeMessage = async() => {
                loading.value = true;
                var post_data = {
                    partner_id: edit_partner_id.value,
                    welcome_message: welcome_message.value
                };
                await Partner.updateWelcomeMessage(edit_partner_id.value, post_data).then(()=>{
                    partners.value[edit_partner_index.value].welcome_message = welcome_message.value;
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Partner welcome message updated!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    display_edit_form.value = false;
                    loading.value = false;
                },(err)=>{
                    console.log(err);
                    console.log(err.res.data);
                    store.commit(MutationTypes.addSystemNotification, {title:'Error',content:'Error updating partner welcome message.',icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                    loading.value = false;
                });
            }

            return {
                display_edit_form,
                display_edit_header_form,
                editHeader,
                editMessage,
                edit_partner,
                edit_partner_id,
                edit_partner_name,
                error_msg,
                getPartners,
                has_error,
                loading,
                partners,
                saveWelcomeHeader,
                saveWelcomeMessage,
                welcome_header,
                welcome_message,
            };
        },
    });
</script>

<style lang="scss">

</style>
