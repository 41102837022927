<template>
    <div class="row">
        <div class="card w-100">
            <div class="mx-3 pt-4 text-center">
                <div class="rounded-circle icon-circle mx-auto mb-3"><i class="fas fa-chalkboard-teacher mx-auto"></i></div>
                <h3 v-if="training_session.training_session_type_id == TrainingSessionType.SCENARIO">Scenario Training</h3>
                <h3 v-else-if="training_session.training_session_type_id == TrainingSessionType.ONBOARDING">On-Boarding</h3>
                <h3 v-else-if="training_session.training_session_type_id == TrainingSessionType.GROUPED || training_session.training_session_type_id == TrainingSessionType.LARGEGROUP">On-Boarding &amp; Scenario Training</h3>
                <hr class="hr-dark">
            </div>
            <div class="mx-5">
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import { defineComponent } from "vue";
    import { TrainingSessionType } from "@/enums/training-session-type"
    export default defineComponent({
        props: {
            training_session: Object,
        },
        setup(){
            return {
                TrainingSessionType
            }
        }
    });
</script>
<style lang="scss" scoped>
    @import '@/assets/_variables.scss';
    .icon-circle {
        width: 100px;
        height: 100px;
        background-color: $gray-light;
        display: flex;
        flex-direction: column;
        justify-content: center;
        i{
            font-size: 4rem;
            color: $gray-medium;
        }
    }
    .h2 {
        color: $primary;
    }
    .scen-title {
        color: $secondary;
    }
    hr.hr-dark{
        border-top: 1px solid $secondary;
        width: 95%;
    }

</style>
