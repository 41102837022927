<template>
    <div>
        <h1>Institutions</h1>
    </div>
    <div v-if="!loading && !has_error" class="overflow-auto">
        <div class="card py-2 px-2">
            <table class="table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th class="header-row py-2 px-2">Name</th>
                        <th class="header-row py-2 px-2">Royalty Rate</th>
                        <th class="header-row py-2 px-2">Edit</th>
                        <th class="header-row py-2">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="institution in institutions" v-bind:key="institution.id">
                        <td>{{institution.name}}</td>
                        <td>${{institution.royalty_rate.toFixed(2)}}</td>
                        <td><button class="btn btn-xs btn-primary" @click="updateInstitution(institution)"><i class="fas fa-pencil-alt"></i></button></td>
                        <td><button v-if="institution.deletable" class="btn btn-xs btn-primary" @click="deleteInstitution(institution)"><i class="fas fa-trash-alt"></i></button><span v-else>-</span></td>
                    </tr>
                </tbody>
            </table>
            <div v-if="!institutions.length"><p>No institutions in system.</p></div>
            <div>
                <button class="btn btn-md btn-primary" @click="createInstitution">Create Institution</button>
            </div>
        </div>
    </div>
    <div v-else-if="!loading && has_error">
        <h3>Error</h3>
        <p v-html="error_msg"></p>
    </div>
    <modal v-model="show_create_modal">
        <template v-slot:header>Create Institution</template>
        <div class="form-group">
            <label>Name<br /><input type="text" v-model="create_name" maxlength="255" @change="clearError('create_name')"/></label>
            <div v-if="create_name_error" class="alert alert-danger">{{create_name_error}}</div>
        </div>
        <div class="form-group">
            <label>Royalty Rate<br /><currency-input v-model="create_royalty_rate"/></label>
        </div>
        <div class="form-group">
            <button class="btn btn-md btn-primary float-right ml-2" @click="createInstitutionSubmit">Create</button>
            <button class="btn btn-md btn-secondary float-right" @click="createInstitution">Cancel</button>
        </div>
    </modal>
    <modal v-model="show_delete_modal">
        <template v-slot:header>Delete Institution</template>
        <p>Are you sure you would like to delete {{delete_institution.name}}?</p>
        <button class="btn btn-md btn-primary float-right ml-2" @click="deleteInstitutionSubmit">Delete</button>
        <button class="btn btn-md btn-secondary float-right" @click="deleteInstitution">Cancel</button>
    </modal>
    <modal v-model="show_update_modal">
        <template v-slot:header>Edit Institution</template>
        <div class="form-group">
            <label>Name<br /><input type="text" v-model="update_name" maxlength="255" @change="clearError('update_name')"/></label>
            <div v-if="update_name_error" class="alert alert-danger">{{update_name_error}}</div>
        </div>
        <div class="form-group">
            <label>Royalty Rate<br /><currency-input v-model="update_royalty_rate"/></label>
        </div>
        <div class="form-group">
            <button class="btn btn-md btn-primary float-right ml-2" @click="updateInstitutionSubmit">Update</button>
            <button class="btn btn-md btn-secondary float-right" @click="updateInstitution">Cancel</button>
        </div>
    </modal>
    <br/>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">

    import {defineComponent, ref} from "vue";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    import {store} from "@/store";
    import {IInstitution} from "@/interfaces/institution";
    import CurrencyInput from '@/components/currency-input.vue';
    import Institution from "@/models/institution";
    import Loading from 'vue3-loading-overlay';
    import Modal from "@/components/modal.vue";

    export default defineComponent({
        components: {CurrencyInput,Loading,Modal},
        mounted(){
            this.getInstitutions();
        },

        setup() {
            const create_name = ref<String>('');
            const create_name_error = ref<String>('');
            const create_royalty_rate = ref<Number>(0);
            const delete_institution = ref<any>(null);
            const error_msg = ref<String>('');
            const has_error = ref<Boolean>(false);
            const institutions = ref<Array<IInstitution>>([]);
            const loading = ref<Boolean>(true);
            const show_create_modal = ref<Boolean>(false);
            const show_delete_modal = ref<Boolean>(false);
            const show_update_modal = ref<Boolean>(false);
            const update_id = ref<Number>(0);
            const update_name = ref<String>('');
            const update_name_error = ref<String>('');
            const update_royalty_rate = ref<Number>(0);

            const clearError = (err_type:String) => {
                switch(err_type){
                    case 'create_name':
                        create_name_error.value = '';
                        break;
                    case 'update_name':
                        update_name_error.value = '';
                        break;
                }
            }

            const createInstitution = () => {
                show_create_modal.value = !show_create_modal.value;
            }

            const createInstitutionSubmit = async() => {
                loading.value = true;
                var has_errors = false;
                if(create_name.value == ''){
                    create_name_error.value = 'Please provide an institution name.';
                    has_errors = true;
                }
                if(create_name.value.length > 255){
                    create_name_error.value = 'Institution name can be no longer than 255 characters.';
                    has_errors = true;
                }
                if(!has_errors){
                    var post_data = {
                        'name': create_name.value,
                        'royalty_rate': create_royalty_rate.value
                    };
                    await Institution.create(post_data).then((res) => {
                        institutions.value = res.data.institutions;
                        create_name.value = '';
                        create_name_error.value = '';
                        create_royalty_rate.value = 0;
                        show_create_modal.value = false;
                        store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Institution created!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    },(err) => {
                        console.log(err);
                        var err_msg = parseError(err.response.data);
                        store.commit(MutationTypes.addSystemNotification, {title:err.response.data.message,content:err_msg,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                    });
                }
                loading.value = false;
            }

            const deleteInstitution = (institution:IInstitution) => {
                if(!show_delete_modal.value){
                    delete_institution.value = institution;
                    show_delete_modal.value = true;
                }else{
                    delete_institution.value = null;
                    show_delete_modal.value = false;
                }
            }

            const deleteInstitutionSubmit = async() => {
                loading.value = true;
                var institution_id = delete_institution.value.id;
                await Institution.delete(institution_id).then((res) => {
                    institutions.value = res.data.institutions;
                    show_delete_modal.value = false;
                    delete_institution.value = null;
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Institution deleted!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                },(err) => {
                    console.log(err);
                    store.commit(MutationTypes.addSystemNotification, {title:err.response.data.message,content:err.response.data.message,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const getInstitutions = async() => {
                await Institution.list().then((res) => {
                    institutions.value = res.data.institutions;
                    error_msg.value = '';
                    has_error.value = false;
                },(err) => {
                    error_msg.value = err.data.message;
                    has_error.value = true;
                });
                loading.value = false;
            }

            const parseError = (errs:any) => {
                let err_msg = '';
                if(errs.errors !== undefined){
                    for(const [key,value] of Object.entries(errs.errors)){
                        key;
                        //@ts-ignore
                        value.forEach((msg:any) => err_msg += msg + '<br>');
                        err_msg += '<br>';
                    }
                }else if(errs.message !== 'undefined'){
                    err_msg = errs.message;
                }else{
                    err_msg = 'Error completing request';
                }
                return err_msg;
            }

            const updateInstitution = (institution:IInstitution) => {
                if(!show_update_modal.value){
                    update_id.value = institution.id;
                    update_name.value = institution.name;
                    update_name_error.value = '';
                    update_royalty_rate.value = institution.royalty_rate;
                    show_update_modal.value = true;
                }else{
                    show_update_modal.value = false;
                    update_id.value = 0;
                    update_name.value = '';
                    update_name_error.value = '';
                    update_royalty_rate.value = 0;
                }
            }

            const updateInstitutionSubmit = async() => {
                loading.value = true;
                var has_errors = false;
                if(update_name.value == ''){
                    update_name_error.value = 'Please provide an institution name.';
                    has_errors = true;
                }
                if(update_name.value.length > 255){
                    update_name_error.value = 'Institution name can be no longer than 255 characters.';
                    has_errors = true;
                }
                if(!has_errors){
                    var post_data = {
                        'id': update_id.value,
                        'name': update_name.value,
                        'royalty_rate': update_royalty_rate.value
                    };
                    await Institution.update(update_id.value,post_data).then((res) =>{
                        institutions.value = res.data.institutions;
                        update_id.value = 0;
                        update_name.value = '';
                        update_name_error.value = '';
                        update_royalty_rate.value = 0;
                        show_update_modal.value = false;
                        store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Institution updated!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    },(err) =>{
                        console.log(err);
                        var err_msg = parseError(err.response.data);
                        store.commit(MutationTypes.addSystemNotification, {title:err.response.data.message,content:err_msg,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                    });
                }
                loading.value = false;
            }

            return {
                clearError,
                createInstitution,
                createInstitutionSubmit,
                create_name,
                create_name_error,
                create_royalty_rate,
                delete_institution,
                deleteInstitution,
                deleteInstitutionSubmit,
                error_msg,
                getInstitutions,
                has_error,
                institutions,
                loading,
                show_create_modal,
                show_delete_modal,
                show_update_modal,
                updateInstitution,
                updateInstitutionSubmit,
                update_name,
                update_name_error,
                update_royalty_rate
            };
        },
    });
</script>

<style lang="scss">

</style>
