<template>
    <div v-if="!loading && typeof(practice_visit) !== 'undefined'">
        <feedback-form :is_practice="true" :visit="practice_visit" />
    </div>
    <div v-if="!loading && typeof(practice_visit) === 'undefined'">
        <h4>Error Loading SP Scorecard Practice</h4>
        <p>{{ loadErrorMessage }}</p>
    </div>
    <br>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { defineComponent, ref } from "vue";
    import Loading from 'vue3-loading-overlay';
    import FeedbackForm from '@/views/FeedbackForm.vue';
    import TrainingSession from "@/models/training-session";
    import { useRoute } from 'vue-router';

    export default defineComponent({
        components: {FeedbackForm, Loading},
        mounted(){
            this.getFeedbackPractice();
        },
        setup() {
            const loading = ref(true);
            const loadErrorMessage = ref('');
            const route = useRoute();
            const practice_visit = ref({});

            const getFeedbackPractice = async () => {
                loading.value = true;
                await TrainingSession.getFeedbackPractice(route.params.training_session_id).then((res)=>{
                    practice_visit.value = res.data.practice_visit;
                    loading.value = false;
                },(err)=>{
                    loadErrorMessage.value = err.response.data.message;
                    loading.value = false;
                });
            }

            return {
                FeedbackForm,
                getFeedbackPractice,
                loadErrorMessage,
                loading,
                practice_visit,
            };
        },
    });
</script>
