export enum NotificationIcon{
    NONE,
    SUCCESS,
    ERROR,
    WARNING,
    USER,
    MESSAGE,
}

export enum NotificationColor{
    WHITE ,
    RED,
    YELLOW,
    GREEN,
}

export enum NotificationActionType{
    DISMISS,
    DELETE,
    LINK, //External: PAYLOAD OBJECT MUST HAVE LINK KEY/VALUE, WITH OPTIONAL PARAMETERS {"label":"Link Label","link":"http://fullURL.here/{name}/{id}","parameters":['name',2]}
    SHOW_CONTENT,// PAYLOAD OBJECT MUST HAVE CONTENT KEY/VALUE {"content":"content goes here, can be html"}
    ROUTER_LINK, //payload must contain a routerlink object {"label":"Link Label", "route":{ name: 'user', params: { userId: 123 }}}
}
export interface INotificationActionPayload {
    [key: string]: any
}

export interface INotification {
    id?:number,
    title:string,
    icon:NotificationIcon,
    color:NotificationColor,
    content:string,
    is_read?:boolean,
    is_archived?:boolean,
    type?:number,
    payload?:INotificationActionPayload,
    showDismiss?:Boolean,
    linkToNotificationCenter?:Boolean,
    createdAt?:Date,
    updatedAt?:Date,
}
