export enum UserRole{
    ZERO,
    LEARNER,
    SP,
    OBSERVER,
    TRAINER,
    ADMIN,
    HEADTRAINER,
    REVIEWER,
    AUTHOR,
    CLIENTADMIN,
    EXCLUDEFROMREPORTS,
    REPORTMANAGER,
    FINANCIALADMIN,
    PARTNER
}
