<template>
    <div v-if="!loading && !err_msg">
        <div class="row card text-white rounded-0 top-header">
            <div class="col-12 text-center">
                <h2 class="my-3 font-weight-bold">Client Admin Request</h2>
            </div>
        </div>
        <div class="row card rounded-0">
            <div class="col-12 card-header">
                <h4 class="d-inline">{{client_request.user.name}}</h4>
                <button class="btn btn-sm btn-primary float-right return-btn" @click="router.push({name:'management.clients'})">Return to Client Requests</button>
            </div>
            <div class="col-12 card-body">
                <div class="text-center">
                    <h4>{{client_request.user.name}}</h4>
                    <h5>ID: {{client_request.user.id}} | {{client_request.user.email}} | {{moment(client_request.created_at).format('MM-DD-YYYY')}} | <span v-if="client_request.is_sim">Simclusive</span><span v-else>SP-ED</span></h5>
                    <hr class="w-75" />
                </div>
                <div class="partner-selection">
                    <div class="row p-4 m-auto">
                        <div class="col-sm-12 col-md-6" v-for="partner in partners" v-bind:key="partner.id">
                            <label><input type="checkbox" v-model="selected_partners[partner.id]"> {{partner.short_name}}</label>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary btn-md" @click="approveRequest()">Approve</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="!loading && err_msg">
        <h4>Error Loading Application</h4>
        <p>{{err_msg}}</p>
    </div>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { defineComponent, ref } from "vue";
    import Loading from 'vue3-loading-overlay';
    import Request from '@/models/request';
    import { useRoute } from 'vue-router';
    import router from "@/router";
    import moment from 'moment';
    import { store } from '../../store/index';
    import {NotificationIcon,NotificationColor} from "@/interfaces/notification";
    import {MutationTypes} from "@/store/modules/ux/mutations";

    export default defineComponent({
        components: {
            Loading
        },
        mounted(){
            this.getRequest();
        },
        setup() {
            const loading = ref<Boolean>(true);
            const client_request = ref<Object>({});
            const err_msg = ref(false);
            const route = useRoute();
            const partners = ref({});
            const selected_partners = ref({});

            const approveRequest = async() => {
                loading.value = true;
                var post_data = {
                    request_id: +route.params.request_id,
                    selected_partners: selected_partners.value
                }
                await Request.approve(post_data).then(() => {
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Client Admin Approved.',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    router.push({ name: 'management.clients' });
                },(err)=>{
                    console.log(err);
                    let err_msg = parseError(err.response.data);
                    store.commit(MutationTypes.addSystemNotification, {title:err.response.data.message,content:err_msg,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const getRequest = async() => {
                loading.value = true;
                var post_data = {
                    request_id: +route.params.request_id,
                };
                await Request.getClientRequest(post_data).then((res) => {
                    client_request.value = res.data.request;
                    partners.value = res.data.partners;
                    loading.value = false;
                    err_msg.value = false;
                },(err)=>{
                    console.log(err);
                    err_msg.value = err.response.data.message;
                    loading.value = false;
                });
            }

            const parseError = (errs:any) => {
                let err_msg = '';
                if(errs.errors !== undefined){
                    for(const [key,value] of Object.entries(errs.errors)){
                        err_msg += '<strong>' + key + '</strong><br>';
                        //@ts-ignore
                        value.forEach((msg:any) => err_msg += msg + '<br>');
                        err_msg += '<br>';
                    }
                }else if(errs.message !== 'undefined'){
                    err_msg = errs.message;
                }else{
                    err_msg = 'Error completing request';
                }
                return err_msg;
            }

            return {
                approveRequest,
                client_request,
                err_msg,
                getRequest,
                loading,
                moment,
                partners,
                router,
                selected_partners,
            };
        },
    });
</script>

<style lang="scss">
    .return-btn{
        min-height: 2rem;
    }
    .fa-arrow-circle-right{
        cursor: pointer;
    }
    .partner-selection{
        height: 240px;
        border: 1px solid #ececec;
        overflow-y: scroll;
        max-width: 570px;
        width: 100%;
        margin: 0 auto;
    }
</style>
