<template>
    <div class="card shadow-sm bg-light">
        <div class="card-content p-4">
            <button class="btn btn-primary btn-md d-block m-auto"  @click="router.push({name:'scenario.list'})">Browse Scenarios</button>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import router from "@/router";

    export default defineComponent({
        setup() {
            return {
                router
            };
        }
    });
</script>
