<template>
    <div v-if="!loading && typeof(request) !== 'undefined'">
        <interview v-if="request.request_type_id == RequestType.INTERVIEW" :request="request"></interview>
        <visit v-else-if="request.request_type_id == RequestType.VISIT" :request="request"></visit>
        <div v-else>
            <p>Invalid Request Type</p>
        </div>
    </div>
    <div v-if="!loading && typeof(request) === 'undefined'">
        <h4>Error Loading Request</h4>
        <p>{{error_message}}</p>
    </div>
    <br>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { api } from "@/store";
    import { defineComponent, ref } from "vue";
    import { store } from '../../store/index';
    import Loading from 'vue3-loading-overlay';
    import { useRoute } from 'vue-router';
    import interview from './Interview.vue';
    import visit from './Visit.vue';
    import {RequestType} from "@/enums/request-type";

    export default defineComponent({
        components: {Loading, interview, visit },
        mounted(){
            this.getRequest();
        },
        setup() {
            const error_message = ref('');
            const loading = ref(false);
            const request = ref();
            const route = useRoute();

            const getRequest = async () => {
                loading.value = true;
                await api.get('sanctum/csrf-cookie');
                api.get('api/request/' + route.params.request_id,{}).then((res)=>{
                    request.value = res.data.request;
                    loading.value = false;
                },(err)=>{
                    loading.value = false;
                    error_message.value = err.response.data.message;
                });
            }

            return {
                user: store.state.auth.user,
                error_message,
                loading,
                route,
                request,
                getRequest,
                RequestType
            };
        },
    });
</script>
