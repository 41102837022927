<template>
    <input
        ref="inputRef"
        :value="formattedValue"
    >
</template>

<script>
    import useCurrencyInput from 'vue-currency-input'
    export default {
        name: 'CurrencyInput',
        props: {
            modelValue: Number,
            max: {
                type: Number,
                default: 999
            },
        },
        setup (props) {
            const { formattedValue, inputRef } = useCurrencyInput({
                currency: 'USD',
                locale: 'en-US',
                valueRange: {
                    min: 0,
                    max: props.max
                },
                distractionFree: false
            });

            return { inputRef, formattedValue }
        }
    }
</script>
