<template>
    <div class="card group scenario-field-container" :class="selected ? 'active bg-lighter' :''">
        <div class="scenario-field">
            <div class="scenario-field-header">{{label}}
                <span class="tooltip-container">
                        <i v-if="description" class="fas fa-info-circle"></i>
                        <span class="tooltiptext tooltip-right" v-html="description"></span>
                    </span>
            </div>
            <div v-if="windowWidth > 1200 && (scenario.data.status !== ScenarioStatus.DRAFT) && (scenario.data.reviewer_user_id !== null) && showCommentBubble" class="text-primary position-relative float-right d-flex justify-content-between align-items-center">
                <span class="comment-bubble">{{scenario?.getCommentsByField(field).length > 0 ? scenario?.getCommentsByField(field).length : ''}}<i class="fas fa-2x fa-comment-dots" title="View or Read Comments"></i></span>
            </div>
            <scenario-notes v-if="windowWidth < 1200 && (scenario.data.status !== ScenarioStatus.DRAFT) && (scenario.data.reviewer_user_id !== null) && showCommentBubble" :scenario="scenario" :field="field" :enable-commenting="enableComments">
                <template v-slot:trigger="triggerProps" >
                    <div @click="triggerProps.click" class="text-primary position-relative float-right d-flex justify-content-between align-items-center"><span class="comment-bubble">{{scenario?.getCommentsByField(field).length > 0 ? scenario?.getCommentsByField(field).length : ''}}<i class="fas fa-2x fa-comment-dots" title="View or Read Comments"></i></span> 
                    </div>
                </template>
            </scenario-notes>
        </div>
        <div >
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref} from "vue";
    import Scenario from '@/models/scenario';
    import ScenarioNotes from "@/components/scenarios/scenario-notes.vue";
    import {toSnakeCase} from "@/utilities/string-helpers";
    import {ScenarioStatus} from "@/enums/scenario-status";
    export default defineComponent({
        name: "scenario-field-group",
        components: {ScenarioNotes},
        props: {
            scenario: {type:Scenario},
            label: {type:String, required:true},
            description: {type:String},
            selected: {type:Boolean, default: false},
            showCommentBubble: {type:Boolean, required:true},
            enableComments: {type:Boolean, default:false}
        },
        setup(props) {
            const windowWidth = ref(0);
            const field = computed(()=>{
                return toSnakeCase(props.label);
            })
            onMounted(()=>{
                windowWidth.value = window.innerWidth;
                window.addEventListener('resize',()=>{
                    windowWidth.value = window.innerWidth;
                })
            });
            return{
                field,
                windowWidth,
                ScenarioStatus
            }
        }
    });
</script>

<style scoped>

</style>