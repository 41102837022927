<template lang="html">
    <slot name="trigger" :click="toggle"></slot>
    <modal v-if="!staticView" v-model="modal" slide-out>
        <template v-slot:header>
            <div class="text-white">{{toTitleCase(field)}}</div>
        </template>
        <template v-slot:default>
            <div class="overflow-auto h-100">
                <!-- Remove `.chat-scroll` and add `.flex-grow-1` if you don't need scroll -->
                <div class="chat-messages chat-scroll ps px-2">
                    <div class="media pb-1 mb-3" v-for="(item,index) in comments" v-bind:key="index">
                        <user-image v-if="item.user.id!==store.getters.user.id" :source="item.user.image"></user-image>
                        <div class="media-body" :class="item.user.id===store.getters.user.id ? 'text-right mr-3' : 'ml-3'">
                            <a href="javascript:void(0)">{{ item.user.name }}</a>
                            <p class="my-1">{{item.content}}</p>
                            <div class="clearfix">
                                <span class="text-muted small" :class="{'float-left':item.user.id!==store.getters.user.id}">{{ moment(item.created_at).fromNow() }}</span>
                            </div>
                        </div>
                        <user-image v-if="item.user.id===store.getters.user.id" :source="item.user.image"></user-image>
                    </div>
                </div>
            </div>
        </template>

        <template v-if="enableCommenting" v-slot:footer>
            <div class="input-group d-flex input">
                <hr class="border-light m-0">
                <input v-model="commentModel" type="text" class="form-control" placeholder="Type your message">
                <div class="input-group-append">
                    <button type="button" class="btn btn-primary rounded-0">Send</button>
                </div>
            </div>
        </template>
    </modal>

    <div v-if="classStyle.includes('card-body') && staticView" class="scenario-field-header">{{toTitleCase(field)}} Comments</div>
    <div v-if="staticView" :class="[classStyle, { 'sticky' : lockit === true}]" id="lockPosition">
        <div  v-if="!classStyle.includes('card-body')" class="scenario-field-header">{{toTitleCase(field)}} Comments</div>
        <div class="card-body chat-area">
            <Loading :active="loading" :is-full-page="false"></Loading>
            <div v-show="!loading && field" class="media pb-1 mb-3" v-for="(item,index) in scenario?.data?.comments.filter((i)=>i.field === field)" v-bind:key="index">
                <user-image v-if="item.user.id!==store.getters.user.id" :source="item.user.image"></user-image>
                <div class="media-body" :class="item.user.id===store.getters.user.id ? 'text-right mr-3' : 'ml-3'">
                    <a href="javascript:void(0)">{{ item.user.name }}</a>
                    <p class="my-1">{{item.content}}</p>
                    <div class="clearfix">
                        <span class="text-muted small" :class="{'float-left':item.user.id!==store.getters.user.id}">{{ moment(item.created_at).fromNow() }}</span>
                    </div>
                </div>
                <user-image v-if="item.user.id===store.getters.user.id" :source="item.user.image"></user-image>
            </div>
            <div v-if="comments.length === 0 && field && enableCommenting" class="align-items-center d-flex flex-column">
                <h4 class="text-secondary">Add a comment below</h4>
                <i class="fa fa-comments fa-4x text-muted"></i>
            </div>
            <div v-if="comments.length === 0 && field && !enableCommenting" class="align-items-center d-flex flex-column">
                <h4 class="text-secondary">There are no comments for this component. Click one of the green message icons <i class="fas fa-comment-dots text-primary"></i> to view comments on a specific section.</h4>
                <i class="fa fa-comments fa-4x text-muted"></i>
            </div>
            <div v-if="!field" class="align-items-center d-flex flex-column">
                <h4 v-if="scenario.data.reviewer_user_id" class="text-secondary">Select a field to see or make a comment</h4>
                <h4 v-else class="text-secondary">To start making comments you nedd to click the green button "Take Scenario Under My Review".</h4>
                <i class="fa fa-comments fa-4x text-muted"></i>
            </div>
        </div>
        <div class="card-footer" v-if="field && enableCommenting">
            <div class="">
                <textarea rows="3" v-model="commentModel" placeholder="Type your message" class="w-100" />
                <button @click="addNote" :disabled="loading" type="button" class="btn btn-block btn-primary rounded-0">Send</button>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref, watch} from "vue";
    import Scenario from "../../models/scenario";
    import Modal from "@/components/modal.vue";
    import {toTitleCase} from "@/utilities/string-helpers";
    import UserImage from "@/components/user-image.vue";
    import {store} from '@/store';
    import moment from "moment";
    import Loading from "vue3-loading-overlay";
    export default defineComponent({
        name: "scenario-notes",
        components: {UserImage, Modal,Loading},
        props:{
            scenario:{type:Scenario},
            staticView:{type:Boolean,default:false},
            field:{type:String},
            enableCommenting:{type:Boolean, required:true},
            classStyle:{types:String, default: 'card static-card authoring-comments'}
        },
        mounted(){
            this.lockComments();
        },
        setup(props){
            const modal = ref(false);
            const toggle = ()=> modal.value = !modal.value;
            const commentModel = ref('');
            const comments = computed(()=>props.field ? props.scenario?.getCommentsByField(props.field) : []);
            const loading = ref(false);
            const lockit = ref(false);
            watch(() => props.field, () => {
                loading.value=true;
                setTimeout(function(){
                    loading.value = false;
                }, 500);
            });
            //actions
            const addNote = async ()=>{
                loading.value = true;
                await Scenario.comment(props.scenario?.data?.id, commentModel.value, props.field).then(
                    (res)=>{
                        commentModel.value = '';
                        props.scenario?.addComment(res.data);
                    }
                );
                loading.value = false;
            }

            const lockComments = async() =>{
                window.addEventListener("scroll", function(){
                    if (window.scrollY > 380) {
                        lockit.value = true;
                    } else {
                        lockit.value = false;
                    }
                });
            }

            return {
                addNote,
                comments,
                commentModel,
                loading,
                lockComments,
                lockit,
                moment,
                modal,
                store,
                toTitleCase,
                toggle
            }
        }
    });
</script>

<style scoped lang="scss">
    @import "src/assets/styles/pages/chat";
    .chat-area.static-card{
        height: calc(100vh - 491px);
        overflow-y: auto;
        font-size: 14px;
    }
    #lockPosition.sticky {
        max-width: 23%;
        min-width: 23%;
        position: fixed;
        top: 60px;
    }
    @media (max-width: 768px) {
        #lockPosition.sticky {
            position: relative;
            max-width: 100%;
        }
    }
</style>