import { InjectionKey } from "vue";

import {
  createStore,
  Store as VuexStore,
  useStore as baseUseStore,
} from "vuex";


import ux, {State as UXState, Store as UXStore} from './modules/ux'
import auth, {State as AuthState, Store as AuthStore} from './modules/auth';
import axios, { AxiosInstance } from "axios";
import { ActionTypes } from '@/store/modules/auth';

const host = window.location.host;
const parts = host.split('.');
let partner_slug = process.env.VUE_APP_DEFAULT_PARTNER;
if(parts[0] !== 'app'){
    partner_slug = parts[0] + '.';
}
if(parts[1] == 'simclusive'){
    partner_slug = partner_slug + 'simclusive.';
}

//defeine axios
export const api:AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_PROTOCAL + partner_slug + process.env.VUE_APP_API_BASE,
  withCredentials:true,
});

    api.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        if((error.response.status == 401 || error.response.status == 419) && !error.response.request.responseURL.includes('auth/check')){
            store.dispatch(ActionTypes.logout);
        }
        return Promise.reject(error);
    }
);

// define state
export interface RootState {
  ux: UXState;
  auth: AuthState;
}

//define store
export type RootStore = UXStore<Pick<RootState, "ux">>
  & AuthStore<Pick<RootState, "auth">>;

// define injection key
export const key: InjectionKey<VuexStore<RootState>> = Symbol();
export const store = createStore<RootState>({
  modules: {
    ux,
    auth
    // other
  },
});
export default function useStore(): RootStore {
  return baseUseStore(key);
}
