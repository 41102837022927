<template>
    <quill-editor
        v-model:value="value"
        :options="defaults.editorOption"
        :disabled=disabled
        :style="'min-height: '+height+'px'"
    />
</template>
<script>
import { computed, defineComponent,reactive} from "vue";
import { quillEditor } from 'vue3-quill';


export default defineComponent( {
    props: {
        content: String,
        modelValue: String,
        disabled: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            required: false,
        },
        height:{
            type:Number,
            default: 100
        }
    },
    components:{
        quillEditor
    },
    setup(props,{emit}) {
        const value = computed({ 
            get: () => props.modelValue, 
            set: (value) => emit('update:modelValue', value) 
        })
        // Documentation at: https://quilljs.com/docs/modules/toolbar/
        const defaults = reactive({
            editorOption: props.options ? props.options : {
                placeholder: props.content,
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ script: 'sub' }, { script: 'super' }],
                        [{ indent: '-1' }, { indent: '+1' }],
                        [{ size: ['small', false, 'large', 'huge'] }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ['clean'],
                        //[{ header: 1 }, { header: 2 }],
                        //[{ direction: 'rtl' }],
                        //[{ header: [1, 2, 3, 4, 5, 6, false] }],
                        //[{ font: [] }],
                        //['link', 'image']
                    ]
                },
                // more options
            },
            disabled: props.disabled
        });
        return {
            defaults,
            value
        }
    }
    
});
</script>