<template>
    <div v-if="!join_event_obj.is_complete && !join_event_obj.is_canceled && !join_event_obj.learner_technical_issue">
        <div v-if="showCountdown" class="mb-3">
            <div class='countdown-text'>{{countdownText}}</div>
        </div>
        <div class="d-inline" v-if="started">
            <a v-if="has_join_link" class="btn btn-secondary btn-md w-100 mb-2" :href="join_link" target="_blank">Join {{label_string}}</a>
            <button v-else type="button" class="btn btn-secondary btn-md w-100 mb-2" @click="joinError">Join {{label_string}}</button>
            <span v-if="label == 'Interview'">
                <button v-if="user.id == join_event_obj.trainer_user_id" type="button" class="btn btn-secondary btn-xs ml-1" @click="complete">Mark Interview as Complete</button>
            </span>
        </div>
        <div class="d-inline" v-if="label == 'Visit' && user.props.id == join_event_obj.sp_user_id && can_close">
            <button type="button" class="btn btn-secondary btn-md w-100 mb-2" @click="complete"><span v-if="join_event_obj.scenario.is_feedback_form_enabled">Proceed to Scorecard &amp; </span>Mark as Complete</button>
            <button type="button" class="btn btn-secondary btn-md w-100 mb-2" @click="noShow">Mark as No Show</button>
            <button type="button" class="btn btn-secondary btn-md w-100 mb-2" @click="learnerTechIssue">Mark as Learner Technical Issue</button>
        </div>
        <div class="d-inline" v-if="label == 'Training Session' && user.props.id == join_event_obj.trainer_user_id && can_close">
            <button type="button" class="btn btn-secondary btn-md w-100 mb-2" @click="complete">Mark as Complete</button>
        </div>
    </div>
    <div v-else-if="join_event_obj.learner_technical_issue">
        <p>{{label}} was marked as a learner technical issue</p>
    </div>
    <div v-else-if="join_event_obj.no_show">
        <p>{{label}} was marked as no show.</p>
    </div>
    <div v-else-if="join_event_obj.is_complete">
        <p>{{label}} was completed.</p>
    </div>
    <div v-else-if="join_event_obj.is_canceled">
        <p>{{label}} was cancelled.</p>
    </div>
</template>

<script lang="ts">
    import { defineComponent, reactive, ref } from "vue";
    import { store } from '@/store';
    import {FormConfig} from "@/interfaces/form-config";
    import Form from "@/utilities/form";
    import moment from 'moment';
    import Visit from "@/models/visit";
    import {NotificationIcon,NotificationColor} from "@/interfaces/notification";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    export default defineComponent({
        emits: ['show-feedback-form'],
        props:{
            joinevent: {type:Object,required:true},
            label: {type:String,required:true},
            user: Object,
        },
        setup(props, {emit}){
            const join_event_obj = ref(props.joinevent);
            const label_string = ref(props.label);
            const user_obj = ref(props.user);
            const countdownText = ref('');
            const started = ref(false);
            const can_close = ref(false);
            const joinForm:FormConfig = reactive({
                type:{
                    value:label_string.value.toLowerCase().replaceAll(' ','-'),
                    valid:true,
                    validationMessage:''
                },
                event_id:{
                    value:join_event_obj.value.id,
                    valid:true,
                    validationMessage:''
                }
            });
            const joinFormHelper = new Form(joinForm);
            const form:FormConfig = reactive({
                type:{
                    value:label_string.value.toLowerCase().replaceAll(' ','-'),
                    valid:true,
                    validationMessage:''
                },
                event_id:{
                    value:join_event_obj.value.id,
                    valid:true,
                    validationMessage:''
                }
            });
            const formHelper = new Form(form);
            const has_join_link = ref<Boolean>(false);
            const join_error = ref('');
            const join_link = ref('');
            const getJoinLink = async() => {
                joinForm.interview_id = joinForm.visit_id = joinForm.training_session_id = joinForm.event_id;
                await Visit.join(joinFormHelper.mapValues()).then((res)=>{
                    if(res.data.link !== 'undefined'){
                        has_join_link.value = true;
                        join_link.value = res.data.link;
                    }else{
                        has_join_link.value = false;
                        join_error.value = 'Link not generated';
                    }
                },(err)=>{
                    has_join_link.value = false;
                    join_error.value = err;
                });
            };
            getJoinLink();
            const countdownTimer = (time:number) => {
                countdownText.value = "";
                let interval = setInterval(function(){
                    time = time - 1;
                    if(time <= 0){
                        showCountdown.value = setShowCountdown();
                        started.value = true;
                        can_close.value = true;
                        clearInterval(interval);
                    }else{
                        let minutes = Math.floor(time / 60);
                        let seconds = time % 60;
                        let text = "The " + label_string.value + " will open in " + minutes + " minute";
                        if(minutes > 1 || minutes == 0){
                            text += "s";
                        }
                        text += " and " + seconds + " second";
                        if(seconds > 1 || seconds == 0){
                            text += "s";
                        }
                        countdownText.value = text;
                    }
                }, 1000);
                interval;
            }
            const setShowCountdown = () => {
                if(!join_event_obj.value.is_complete && join_event_obj.value.local_time){
                    let now = moment();
                    let start = moment(join_event_obj.value.local_time);
                    if(props.user?.props.id == join_event_obj.value.sp_user_id){
                        start = start.subtract(10,'minutes');
                    }
                    let diff = start.diff(now,'seconds');
                    if(diff < 1200 && diff > 0){
                        countdownTimer(diff);
                        return true;
                    }else if(diff >= 1200 || diff <= -5400){
                        started.value = false;
                    }else{
                        started.value = true;
                    }
                    if(diff <= 0){
                        can_close.value = true;
                    }
                }
                return false;
            };
            const showCountdown = ref(setShowCountdown());
            const joinError = async() => {
                store.commit(MutationTypes.addSystemNotification, {title:'Error Joining Session.',content:join_error.value,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
            }
            const complete = async() => {
                form.interview_id = form.visit_id = form.training_session_id = form.event_id;
                await Visit.complete(formHelper.mapValues()).then((res)=>{
                    if(res.data.action == 'feedback'){
                        emit('show-feedback-form');
                    }else{
                        join_event_obj.value.is_complete = true;
                        store.commit(MutationTypes.addSystemNotification, {title:label_string.value + ' marked as complete.',content:'',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    }
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error completing ' + label_string.value + '.',content:err.response.data.message,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
            };
            const noShow = async() => {
                form.visit_id = form.event_id;
                await Visit.noShow(formHelper.mapValues()).then(()=>{
                    join_event_obj.value.no_show = true;
                    join_event_obj.value.is_complete = true;
                    store.commit(MutationTypes.addSystemNotification, {title:'Visit marked as no show.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error marking visit as no show.',content:err,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
            };
            const learnerTechIssue = async() => {
                form.visit_id = form.event_id;
                await Visit.learnerTechIssue(formHelper.mapValues()).then(()=>{
                    join_event_obj.value.learner_technical_issue = true;
                    store.commit(MutationTypes.addSystemNotification, {title:'Visit marked as learner technical issue.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error marking visit as learner technical issue.',content:err,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
            }
            return{
                can_close,
                complete,
                countdownText,
                has_join_link,
                joinError,
                join_event_obj,
                join_link,
                label_string,
                learnerTechIssue,
                moment,
                noShow,
                showCountdown,
                started,
                user_obj,
            }
        }
    });
</script>

<style scoped>

</style>
