<template>
    <div class="sp_terms">
        <h4 class="text-center"><strong><u>SP Service Terms and Conditions</u></strong></h4>
        <h4 class="text-center"><strong><u>HEALTHCARE PROFESSIONALS</u></strong></h4>
        <br>
        <br>
        <div>
            <p>
                <strong>
                    Your access to and use of the HealthCourse Standardized Patient Service (also referred to as &ldquo;SPVisits&rdquo;) and/or the Site (collectively, the &ldquo;SP Service&rdquo;) is governed by these SP Service Terms and Conditions. Any capitalized terms that are not defined in these SP Service Terms and Conditions shall have the meaning ascribed in the Terms of Service or Privacy Policy, as applicable. The HealthCourse Policies are hereby incorporated into and deemed part of these SP Service Terms and Conditions as though fully set forth herein. In the event of a conflict between the SP Service Terms and Conditions and the Terms of Service, the SP Service Terms and Conditions shall govern.
                </strong>
            </p>
        </div>
        <div>
            <ol type="1">
                <li><strong><u>Overview of the SP Service.</u></strong>
                    <p>
                        The SP Service is designed to assist healthcare professionals (each, a &ldquo;<strong>Healthcare Professional</strong>&rdquo;) in developing clinical and communication skills through simulated &ldquo;video visits&rdquo; with standardized patients (each, a &ldquo;<strong>Standardized Patient</strong>&rdquo;). The SP Service provides Healthcare Professionals with the ability to schedule and participate in video conferencing sessions with Standardized Patients. These video conferencing sessions are recorded by HealthCourse, and may be reviewed and evaluated by trained professionals.
                    </p>
                </li>
                <li><strong><u>Healthcare Professionals.</u></strong>
                    <p>
                        The following terms and conditions apply to you if you use the SP Service as a Healthcare Professional:
                    </p>
                    <ol type="a">
                        <li>You acknowledge and agree that videoconferencing sessions with Standardized Patients are intended for educational purposes only.</li>
                        <li>You expressly consent to the recording of the videoconferencing sessions by HealthCourse or its service providers. You acknowledge and agree that HealthCourse will own all such recordings and will only use the recordings to provide the SP Service to you, and for HealthCourse&rsquo;s and its affiliates&rsquo; internal training, and research purposes.</li>
                        <li>You agree that HealthCourse may use your evaluations or other feedback provided by HealthCourse personnel for any purpose in perpetuity, as long as such information is used in an aggregate and/or anonymous manner and does not identify you.</li>
                        <li>You acknowledge that it is your responsibility to secure a quiet professional environment with appropriate background and lighting for the videoconferencing sessions.</li>
                        <li>You acknowledge that the videoconferencing sessions require a working webcam and internet connection that supports videoconferencing.  You are responsible for securing (at your sole cost and expense) all necessary hardware, software and internet connectivity required for the videoconferencing sessions.</li>
                    </ol>
                </li>
            </ol>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent} from "vue";

    export default defineComponent({
        setup(){
            return{

            }
        }
    });
</script>

<style scoped>
    .sp_terms {
        border: 1px solid #ececec;
        border-radius: 5px;
        padding: 5px 20px 10px;
        max-height: 350px;
        overflow-y: scroll;
        background-color: #fff;
    }
</style>
