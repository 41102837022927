<template>
    <div class="scenario-field-header with-elements bg-secondary text-white">
        Scorecard Display Settings
    </div>
    <div class="card-body">
        <label>Scorecard Display Setting</label>
        <select class="w-100" v-model="display_setting">
            <option v-for="option in display_options" v-bind:key="option.id" :value="option.id">{{option.title}}</option>
        </select>
        <button class="btn btn-primary btn-md my-3 float-right" @click="saveDisplaySettings">Save</button>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Scenario from "@/models/scenario";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import { store } from '../../store/index';
    export default defineComponent({
        name: "scorecard-display-settings",
        props: {
            scenario:{type:Scenario,required:true},
            canEdit:{type: Boolean,default:false},
        },
        mounted(){
            this.getSettingOptions();
        },
        setup(props){
            const display_options = ref([]);
            const display_setting = ref(props.scenario.data.scorecard_display_type_id);
            const getSettingOptions = async() => {
                await Scenario.getScorecardDisplaySettings(props.scenario.data.id).then((res) => {
                    display_options.value = res.data.display_options;
                },(err)=>{
                    console.log(err);
                });
            }
            const saveDisplaySettings = async() => {
                let post_data = {
                    scorecard_display_type_id: display_setting.value
                };
                await Scenario.saveScorecardDisplaySettings(props.scenario.data.id,post_data).then(() => {
                    store.commit(MutationTypes.addSystemNotification, {title:'Scorecard display settings updated.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err)=>{
                    console.log(err);
                    store.commit(MutationTypes.addSystemNotification, {title:'Error updating scorecard display settings.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
            }
            return {
                display_options,
                display_setting,
                getSettingOptions,
                saveDisplaySettings
            };
        }
    });
</script>

<style scoped>

</style>
