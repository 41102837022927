//import * as types from "@/interfaces/IDebitos";
import { MutationTree } from "vuex";
import { State } from "./index";
import {IPartner} from "@/interfaces/partner";
import {api} from "@/store";
import User from "@/models/user";
// (B) - Mutações
// [B.1] inserir a definição da mutação X no enum
// ==> { X = "SET_ X" }
export enum MutationTypes {
    setAuthenticating = "SET_AUTHENTICATING",
    setAuthenticated = "SET_AUTHENTICATED",
    setError = "SET_ERROR",
    setErrorMessage = "SET_ERROR_MESSAGE",
    setUser = "SET_USER",
    setPermissions = "SET_PERMISSIONS",
    setPartner = "SET_PARTNER",
    setPartners = "SET_PARTNERS",
    setPasswordApproved = "SET_PASSWORD_APPROVED",
    setRegistationFields = "SET_REGISTRATION_FIELDS",
    setDashboardView = "SET_DASHBOARD_VIEW",
    setDashboardRoles = "SET_DASHBOARD_ROLES",
}

// [B.2] definir o tipo da mutação X
// ==> [MutationTypes.X](state: S, payload: TIPO_DE_X): void;
export type Mutations<S = State> = {
  [MutationTypes.setAuthenticating](state: S, payload: any): void;
  [MutationTypes.setAuthenticated](state: S, payload: any): void;
  [MutationTypes.setError](state: S, payload: any): void;
  [MutationTypes.setErrorMessage](state: S, payload: any): void;
  [MutationTypes.setUser](state: S, payload: any): void;
  [MutationTypes.setPermissions](state: S, payload: any): void;
  [MutationTypes.setPartner](state: S, payload:IPartner): void;
  [MutationTypes.setPartners](state: S, payload:Array<IPartner>): void;
  [MutationTypes.setPasswordApproved](stat: S, payload: Boolean): void;
  [MutationTypes.setRegistationFields](state: S,payload:Array<any>): void;
  [MutationTypes.setDashboardView](state: S, payload: any): void;
  [MutationTypes.setDashboardRoles](state: S, payload: any): void;
};

// [B.3] declarar a mutação X
// ==>  [MutationTypes.X](state, payload) {
// ==>    state.x = payload;
// ==>  },
export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.setAuthenticating](state, payload) {
        state.authenticating = payload;
    },
    [MutationTypes.setAuthenticated](state, payload) {
        state.authenticated = payload;
    },
    [MutationTypes.setError](state, payload) {
        state.error = payload;
    },
    [MutationTypes.setErrorMessage](state, payload) {
        state.errorMessage = payload;
    },
    [MutationTypes.setUser](state,payload){
        state.user = new User(payload);
    },
    [MutationTypes.setPermissions](state,payload){
        state.permissions = payload;
    },
    [MutationTypes.setPartner](state,payload){
        if(payload != undefined){
            api.defaults.headers['selected-partner'] = payload.id
            localStorage.setItem('selected-partner',JSON.stringify(payload));
        }else{
            delete api.defaults.headers['selected-partner'];
            localStorage.removeItem('selected-partner');
        }

        state.partner = payload;
    },
    [MutationTypes.setPartners](state,payload){
        state.partners = payload;
    },
    [MutationTypes.setPasswordApproved](state,payload){
        state.passwordApproved = payload;
    },
    [MutationTypes.setRegistationFields](state,payload){
        state.registation_fields = payload;
    },
    [MutationTypes.setDashboardView](state,payload){
        state.dashboard_view = payload;
    },
    [MutationTypes.setDashboardRoles](state,payload){
        state.dashboard_roles = payload;
    }
};
