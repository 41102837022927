<template>
    <div v-if="!loading" class="row date-scheduler">
        <div class="col-12 date-select mb-3">
            <span>Date &amp; Time</span>
            <div>
                <datepicker class="float-left" :lower-limit="startDate" :upper-limit="endDate" :disabled-dates="{dates: disabledDates}" placeholder="Select Date..." v-model="dateSelect" @update:modelValue="dateSelected(1)"></datepicker>
                <p v-if="timeSelect" v-html="timeSelect" @click="dateSelected(1)" class="time-select-format"></p>
            </div>
            <div class="clear-both"></div>
            <visit-time-select :times="times" :display="timeDisplay" :field="'timeSelect'" v-on:update-time="setTime" />
        </div>
        <div v-if="!is_single" class="col-12 date-select mb-3">
            <span>Alternate Date &amp; Time</span>
            <div>
                <datepicker class="float-left" :lower-limit="startDate" :upper-limit="endDate" :disabled-dates="{dates: disabledDates}" placeholder="Select Date..." v-model="altDateSelect" @update:modelValue="dateSelected(2)"></datepicker>
                <p v-if="altTimeSelect" v-html="altTimeSelect" @click="dateSelected(2)" class="time-select-format"></p>
            </div>
            <div class="clear-both"></div>
            <visit-time-select :times="altTimes" :display="altTimeDisplay" :field="'altTimeSelect'" v-on:update-time="setTime" />
        </div>
    </div>
    <Loading :active="loading" :is-full-page="true"></Loading>
</template>
<script lang="ts">
    import { store } from "@/store";
    import { defineComponent, ref } from "vue";
    import Datepicker from 'vue3-datepicker';
    import Loading from 'vue3-loading-overlay';
    import moment from 'moment';
    import { MutationTypes } from "@/store/modules/ux/mutations";
    import { NotificationColor, NotificationIcon } from "@/interfaces/notification";
    import Request from "@/models/request";
    import {RequestType} from "@/enums/request-type";
    import VisitTimeSelect from '@/components/visit-time-select.vue';
    export default defineComponent({
        components: {Datepicker, Loading, VisitTimeSelect},
        emits: ['update-date'],
        props:{
            startDate: {type:Date,required:true},
            endDate: {type:Date,required:false,default:null},
            field: {type:String,required:true},
            altField: {type:String,required:true},
            is_single: {type:Boolean,defualt:false},
            scenarioId: {type:Number,required:true},
            disabledDates: {type:Array}
        },
        methods: {
            setTime(time:any){
                if(time.field == 'timeSelect'){
                    this.timeSelect = time.time;
                    this.timeDisplay = false;
                    this.times = [];
                    let date:any = moment(this.dateSelect);
                    // @ts-ignore
                    let time_fields = this.timeSelect.split(':');
                    let min_mer = time_fields[1].split(' ');
                    time_fields[1] = min_mer[0];
                    time_fields[2] = min_mer[1];
                    if(time_fields[2] == 'AM' && time_fields[0] == '12') time_fields[0] = '00';
                    if(time_fields[2] == 'PM' && time_fields[0] != '12') time_fields[0] = parseInt(time_fields[0]) + 12
                    date.hour(time_fields[0]);
                    date.minutes(time_fields[1]);
                    date = date.format('YYYY-MM-DD HH:mm:00');
                    this.$emit('update-date',{date:date,field:'visit_date_one'});
                }else if(time.field == 'altTimeSelect'){
                    this.altTimeSelect = time.time;
                    this.altTimeDisplay = false;
                    this.altTimes = [];
                    let date:any = moment(this.altDateSelect);
                    // @ts-ignore
                    let time_fields = this.altTimeSelect.split(':');
                    let min_mer = time_fields[1].split(' ');
                    time_fields[1] = min_mer[0];
                    time_fields[2] = min_mer[1];
                    if(time_fields[2] == 'AM' && time_fields[0] == '12') time_fields[0] = '00';
                    if(time_fields[2] == 'PM' && time_fields[0] != '12') time_fields[0] = parseInt(time_fields[0]) + 12
                    date.hour(time_fields[0]);
                    date.minutes(time_fields[1]);
                    date = date.format('YYYY-MM-DD HH:mm:00');
                    this.$emit('update-date',{date:date,field:'visit_date_two'});
                }
            }
        },
        setup(props){
            const loading = ref<Boolean>(false);
            const times = ref<Array<String>>([]);
            const altTimes = ref<Array<String>>([]);
            const timeDisplay = ref<Boolean>(false);
            const altTimeDisplay = ref<Boolean>(false);
            const dateSelect = ref();
            const altDateSelect = ref();
            const timeSelect = ref(null);
            const altTimeSelect = ref(null);

            const dateSelected = async(position:Number) => {
                loading.value = true;
                let post_data: {[k: string]: any} = {};
                post_data.scenario_id = props.scenarioId;
                post_data.request_type = RequestType.VISIT;
                post_data.date = position == 1 ? dateSelect.value : altDateSelect.value;
                Request.getAvailableTimes(post_data).then((res)=>{
                    if(position == 1){
                        timeSelect.value = null
                        times.value = res.data.times;
                        timeDisplay.value = true;
                    }else if(position == 2){
                        altTimeSelect.value = null;
                        altTimes.value = res.data.times;
                        altTimeDisplay.value = true;
                    }else{
                        console.log('invalid position');
                        store.commit(MutationTypes.addSystemNotification, {title:'Error Loading Available Times',content:'Please try again.',icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                    }
                    loading.value = false;
                },(err)=>{
                    console.log(err);
                    store.commit(MutationTypes.addSystemNotification, {title:'Error Loading Available Times',content:err.response.data.message,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                    loading.value = false;
                });
            }

            return {
                altDateSelect,
                altTimeDisplay,
                altTimes,
                altTimeSelect,
                dateSelect,
                dateSelected,
                loading,
                timeDisplay,
                times,
                timeSelect
            }
        }
    });
</script>
<style scoped lang="scss">
@import '@/assets/styles/theme-paper.scss';
    :deep(.date-select) {
        input[type="text"],
        select,
        .time-select-format {
            cursor: pointer;
            border: none;
            border-bottom: solid 1px #acacac;
            margin-bottom: 5px;
            outline: none;
            &:focus{
                border-bottom: solid 2px $primary-color;
            }
        }
        .time-select-format {
            line-height: 22px;
        }
        input[type="text"]::-webkit-input-placeholder{
            font-style: italic;
        }
        input[type="text"]::-moz-placeholder{
            font-style: italic;
        }
        input[type="text"]:-ms-input-placeholder{
            font-style: italic;
        }
        input[type="text"]:-moz-placeholder{
            font-style: italic;
        }
    }
    .clear-both{
        clear: both;
    }
</style>
