<template>
    <div class="scenario-field-header with-elements bg-secondary text-white">
        Trainer
        <div v-if="!trainer" class="float-right">
            <modal v-model="modal">
                <template v-if="canEdit" v-slot:trigger="triggerProps">
                    <button @click="triggerProps.click" v-if="!scenario.data.trainer || !trainer" type="button" class="btn slim-button btn-primary">
                        <span class="fa fa-plus"></span> Add
                    </button>
                </template>
                <template v-slot:header>Select a Trainer</template>
                <template v-slot:default>
                    <Loading :active="loading" :is-full-page="false"></Loading>
                    <div class="mx-auto">
                        <select class="form-control" v-model="selected">
                            <option selected disabled hidden>-- Select A Trainer --</option>
                            <option v-for="(item,index) in trainers" v-bind:key="index" :value="item.id">{{item.name}}</option>
                        </select>
                    </div>
                </template>
                <template v-slot:footer>
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-default mx-2" @click="modal = !modal">Cancel</button>
                        <button :disabled="loading" @click="save" class="btn btn-primary">Save</button>
                    </div>
                </template>
            </modal>
        </div>
    </div>
    <div class="card-body">
        <ul class="list-group list-group-flush">
            <li v-if="trainer" class="float-right">
                <div class="media align-items-center">
                    <user-image :source="trainer.image" />
                    <div class="media-body px-2">
                        <span class="text-body">{{ trainer.name }}</span>
                    </div>
                    <a v-if="canEdit" href="javascript:void(0)" @click="remove" class="d-block text-light text-large font-weight-light">×</a>
                </div>
            </li>
            <li v-else class="list-group-item text-center"> No Trainer Selected</li>
        </ul>
    </div>
</template>

<script lang="ts">
import {defineComponent, ref, watch} from "vue";
    import Scenario from "@/models/scenario";
    import Modal from "@/components/modal.vue";
    import {IUser} from "@/interfaces/user";
    import Loading from "vue3-loading-overlay";
    import {store} from "@/store";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    import UserImage from "@/components/user-image.vue";
    export default defineComponent({
        name: "scenario-select-trainer",
        components: {UserImage, Modal, Loading},
        props: {
            scenario:{type:Scenario,required:true},
            canEdit: {type: Boolean,default:false}
        },
        setup(props){
            const modal = ref(false);
            const loading = ref(false);
            const trainers = ref<Array<IUser>>([]);
            const selected = ref();
            const trainer = ref<IUser | undefined>(props.scenario.data?.trainer);
            watch(modal, async (n) =>{
                if(trainers.value.length === 0 && n){
                    loading.value = true;
                    await Scenario.getTrainerOptions(props.scenario.data?.id).then((res)=>{
                        trainers.value = res.data;
                    });
                    loading.value = false;
                }
            });
            const save = async ()=>{
                loading.value = true;
                Scenario.addTrainer(props.scenario.data.id,selected.value).then(()=>{
                    trainer.value = trainers.value.find((i)=>i.id == selected.value);
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Trainer added!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    modal.value = !modal.value
                });
                loading.value = false;
            }
            const remove = async ()=>{
                loading.value = true;
                await props.scenario.saveField('trainer_user_id',null).then(()=>{
                    trainer.value = undefined;
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Trainer removed!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                });
                loading.value = false;
            }
            return{
                loading,
                modal,
                remove,
                save,
                selected,
                trainer,
                trainers,
            }
        }
    });
</script>

<style scoped>

</style>
