<template>
    <div id="profile-page" class="row">
        <div class="col-lg-6 col-md-12">
            <div class="card mb-4">
                <div class="card-header scenario-field-header">Personal Information</div>
                <div class="card-body">
                    <profile-image :user="user"></profile-image>
                    <edit-profile-field :user="user" field="first_name" type="text" />
                    <edit-profile-field :user="user" field="last_name" type="text" />
                    <edit-profile-field :user="user" field="email" type="text" />
                    <edit-profile-field :user="user" field="password" type="password" />
                    <edit-profile-field :user="user" field="dob" type="date" override-label="DOB" />
                    <edit-profile-field :user="user" field="mobile_number" type="mobile" :require_mobile="require_mobile" />
                    <div class="row">
                        <div class="col-md-4">
                            <span class="slider-text text-muted d-inline-block">SMS Notifications: </span>
                        </div>
                        <div class="col-md-8">
                            <label class="switch">
                                <input id="sms_checkbox" type="checkbox" :checked="user.props.enable_sms_notifications" @change="toggleSms">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-muted sms-text">You will receive visit confirmation messages and reminders via SMS when signing up for SP-ed. Message and data rates may apply. Message Frequency Varies. Reply HELP for help. Reply STOP to opt out. <a href="/#/privacy-policy" target="_blank">Privacy Policy</a> | <a href="/#/terms-of-service" target="_blank">SMS Terms of Service</a></div>
                    </div>
                </div>
            </div>
            <div v-if="store.getters.hasPermission('user.availability')" class="card mb-4">
                <div class="card-header scenario-field-header">Availability</div>
                <div class="card-body">
                    <edit-availability :user="user" field="sunday" />
                    <edit-availability :user="user" field="monday" />
                    <edit-availability :user="user" field="tuesday" />
                    <edit-availability :user="user" field="wednesday" />
                    <edit-availability :user="user" field="thursday" />
                    <edit-availability :user="user" field="friday" />
                    <edit-availability :user="user" field="saturday" />
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-header scenario-field-header">Additional Information</div>
                <div class="card-body">
                    <edit-profile-field :user="user" field="job_title" type="text" />
                    <edit-profile-field :user="user" field="company_name" type="text" />
                    <edit-profile-field :user="user" field="profession_id" type="profession" override-label="Profession" />
                    <edit-profile-field :user="user" field="specialty_id" type="specialty" override-label="Specialty" />
                    <edit-profile-field :user="user" field="medical_degree" type="text" />
                    <span v-if="(store.getters.registation_fields.length > 0)">
                        <span v-for="field in store.getters.registation_fields" :key="field.id">
                            <edit-profile-field v-if="field.field_name != 'profession' && field.field_name != 'specialty' && field.field_name != 'job_title'" :user="user" :field="field.field_name" :type="field.field_type" :registration-field-id="field.id" />
                        </span>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="card mb-4">
                <div class="card-header scenario-field-header">Address</div>
                <div class="card-body">
                    <edit-profile-field :user="user" type="text" field="address_1" />
                    <edit-profile-field :user="user" type="text" field="address_2" />
                    <edit-profile-field :user="user" type="text" field="city" />
                    <edit-profile-field :user="user" type="state" field="state_id" override-label="State" />
                    <edit-profile-field :user="user" type="country" field="country_id" override-label="Country" />
                    <edit-profile-field :user="user" type="timezone" field="timezone_id" override-label="Time Zone" />
                </div>
            </div>
            <div class="card mb-4">
                <div v-if="(!store.getters.hasRole(UserRole.LEARNER))" class="card-header scenario-field-header">Licenses & Experience</div>
                <div v-else class="card-header scenario-field-header">Licenses</div>
                <div class="card-body">
                    <edit-profile-field :user="user" field="license_number" type="text" />
                    <edit-profile-field :user="user" field="state_licensed" type="state" />
                    <edit-profile-field :user="user" field="dea_number" type="text" override-label="DEA Number" />
                    <edit-profile-field :user="user" field="npi_number" type="text" override-label="NPI Number"/>
                    <span v-if="(!store.getters.hasRole(UserRole.LEARNER))">
                        <div class="my-3"><strong>Experience</strong></div>
                        <edit-profile-field :user="user" field="years_of_experience" type="number" />
                        <edit-profile-field :user="user" field="experience" type="editor" />
                    </span>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-header scenario-field-header">Notifications</div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <span class="slider-text d-inline-block">Email Notifications</span>
                        </div>
                        <div class="col-md-8">
                            <label class="switch">
                                <input type="checkbox" :checked="user.props.enable_email_notifications" @change="toggleEmail">
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <div class="col-md-4">
                            <span class="slider-text d-inline-block">Push Notifications</span>
                        </div>
                        <div class="col-md-8">
                            <label class="switch">
                                <input type="checkbox" :checked="user.props.enable_push_notifications" @change="togglePush">
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <div class="col-md-4">
                            <span class="slider-text d-inline-block">Auto Login</span>
                        </div>
                        <div class="col-md-8">
                            <label class="switch">
                                <input type="checkbox" :checked="user.props.auto_login" @change="toggleAutoLogin">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <Loading :active="loading" :is-full-page="false"></Loading>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import { MutationTypes } from "@/store/modules/ux/mutations";
    import { NotificationColor, NotificationIcon } from "@/interfaces/notification";
    import { store } from '@/store';
    import { UserRole } from "@/enums/user-role";
    import EditAvailability from "@/components/profiles/edit-availability.vue";
    import EditProfileField from "@/components/profiles/edit-profile-field.vue";
    import Loading from 'vue3-loading-overlay';
    import ProfileImage from "@/components/profiles/profile-image.vue";

    export default defineComponent({
        name:'Profile',
        components: {ProfileImage, EditAvailability, EditProfileField, Loading},
        setup() {
            const loading = ref(false);
            const require_mobile = ref(false);
            const user = store.getters.user;

            const toggleAutoLogin = async() => {
                loading.value = true;
                user.props.auto_login = !user.props.auto_login;
                await user.saveField('auto_login', user.props.auto_login);
                loading.value = false;
            }

            const toggleEmail = async() => {
                loading.value = true;
                user.props.enable_email_notifications = !user.props.enable_email_notifications;
                await user.saveField('enable_email_notifications', user.props.enable_email_notifications);
                loading.value = false;
            }

            const togglePush = async() => {
                loading.value = true;
                user.props.enable_push_notifications = !user.props.enable_push_notifications;
                await user.saveField('enable_push_notifications', user.props.enable_push_notifications);
                loading.value = false;
            }

            const toggleSms = async() => {
                loading.value = true;
                if(!user.props.enable_sms_notifications){
                    if(user.props.mobile_number == null){
                        let checkbox = event?.target as HTMLInputElement;
                        checkbox.checked = false;
                        require_mobile.value = true;
                        store.commit(MutationTypes.addSystemNotification, {title:'Mobile Number Required',content:'In order to enable SMS notifications you must provide a valid mobile number under the Personal Information section.',icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                        loading.value = false;
                        return false;
                    }
                }
                user.props.enable_sms_notifications = !user.props.enable_sms_notifications;
                await user.saveField('enable_sms_notifications', user.props.enable_sms_notifications);
                loading.value = false;
            }

            return {
                loading,
                require_mobile,
                store,
                toggleAutoLogin,
                toggleEmail,
                togglePush,
                toggleSms,
                user,
                UserRole
            };
        },

    });
</script>
