<template>
    <div>
        <h1>Scheduling Dates</h1>
        <p><small>Set date ranges that are open for scheduling a visit for each partner.</small></p>
    </div>
    <div v-if="!loading && !has_error && partners" class="overflow-auto">
        <div class="card py-2 px-2">
            <table class="table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th class="header-row py-2 px-2">Partner Name</th>
                        <th class="header-row py-2">Partner Slug</th>
                        <th class="header-row py-2">Date Range</th>
                        <th class="header-row py-2">Disabled Date Range</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="partner in partners" v-bind:key="partner.id">
                        <td>{{partner.short_name}}</td>
                        <td>{{partner.slug}}</td>
                        <td v-if="partner.dates == null && !partner.set_dates">
                            <span>Not Set</span>
                            <span><button class="btn btn-primary ml-4" @click="setDates(partner.id)">Set Dates</button></span>
                        </td>
                        <td v-else-if="partner.dates != null && !partner.set_dates">
                            <span>{{moment(partner.dates.start_date).format('MM/DD/YY')}} - {{moment(partner.dates.end_date).format('MM/DD/YY')}}</span>
                            <br>
                            <span><button class="btn btn-primary" @click="setDates(partner.id)">Modify Dates</button></span>
                            <span><button class="btn btn-primary ml-4" @click="clearDates(partner.id)">Clear Dates</button></span>
                        </td>
                        <td v-else-if="partner.set_dates">
                            <span>
                                <datepicker class="float-left date-margin" :lower-limit="new Date()" placeholder="Select Start Date..." v-model="start_date"></datepicker>
                                <span class="float-left mx-2"> - </span>
                                <datepicker class="float-left date-margin" :lower-limit="new Date()" placeholder="Select End Date..." v-model="end_date"></datepicker>
                            </span>
                            <span><button class="btn btn-primary ml-4" @click="saveDates(partner.id)">Save</button></span>
                            <span><button class="btn btn-primary ml-4" @click="hideDates(partner.id)">Cancel</button></span>
                        </td>
                        <td v-if="partner.disabled_dates == null && !partner.set_disabled_dates">
                            <span>Not Set</span>
                            <span><button class="btn btn-primary ml-4" @click="setDisabledDates(partner.id)">Set Disabled Dates</button></span>
                        </td>
                        <td v-else-if="partner.disabled_dates != null && !partner.set_disabled_dates">
                            <span>{{moment(partner.disabled_dates.start_date).format('MM/DD/YY')}} - {{moment(partner.disabled_dates.end_date).format('MM/DD/YY')}}</span>
                            <br>
                            <span><button class="btn btn-primary" @click="setDisabledDates(partner.id)">Modify Disabled Dates</button></span>
                            <span><button class="btn btn-primary ml-4" @click="clearDisabledDates(partner.id)">Clear Disabled Dates</button></span>
                        </td>
                        <td v-else-if="partner.set_disabled_dates">
                            <span>
                                <datepicker class="float-left date-margin" :lower-limit="new Date()" placeholder="Select Start Date..." v-model="start_disabled_date"></datepicker>
                                <span class="float-left mx-2"> - </span>
                                <datepicker class="float-left date-margin" :lower-limit="new Date()" placeholder="Select End Date..." v-model="end_disabled_date"></datepicker>
                            </span>
                            <span><button class="btn btn-primary ml-4" @click="saveDisabledDates(partner.id)">Save</button></span>
                            <span><button class="btn btn-primary ml-4" @click="hideDisabledDates(partner.id)">Cancel</button></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-else-if="!loading && has_error">
        <h3>Error</h3>
        <p v-html="error_msg"></p>
    </div>
    <div v-else-if="!loading && !has_error && !partners">
        <p>No partners in system</p>
    </div>
    <br/>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">

    import {defineComponent, ref} from "vue";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    import {store} from "@/store";
    import Datepicker from 'vue3-datepicker';
    import Loading from 'vue3-loading-overlay';
    import Partner from "@/models/partner";
    import moment from 'moment';

    export default defineComponent({
        components: {Datepicker,Loading},
        mounted(){
            this.getPartnerDates();
        },

        setup() {
            const loading = ref<Boolean>(false);
            const partners = ref<Array<any>>([]);
            const has_error = ref<Boolean>(false);
            const error_msg = ref<String>('');
            const start_date = ref(null);
            const end_date = ref(null);
            const start_disabled_date = ref(null);
            const end_disabled_date = ref(null);

            const clearDates = async(partner_id:Number) => {
                loading.value = true;
                let post_data = {
                    partner_id: partner_id
                };
                Partner.clearDates(post_data).then((res)=>{
                    let partner_index = partners.value.findIndex((elm) => elm.id == partner_id);
                    partners.value[partner_index] = res.data.partner;
                    store.commit(MutationTypes.addSystemNotification, {title:'Partner Dates Cleared',content:'Partner data successfully cleared.',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err) => {
                    console.log(err);
                    store.commit(MutationTypes.addSystemNotification, {title:'Error Clearing Partner Dates',content:'',icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const clearDisabledDates = async(partner_id:Number) => {
                loading.value = true;
                let post_data = {
                    partner_id: partner_id
                };
                Partner.clearDisabledDates(post_data).then((res)=>{
                    let partner_index = partners.value.findIndex((elm) => elm.id == partner_id);
                    partners.value[partner_index] = res.data.partner;
                    store.commit(MutationTypes.addSystemNotification, {title:'Partner Disabled Dates Cleared',content:'Partner data successfully cleared.',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err) => {
                    console.log(err);
                    store.commit(MutationTypes.addSystemNotification, {title:'Error Clearing Partner Disabled Dates',content:'',icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const getPartnerDates = async() => {
                loading.value = true;
                Partner.getDates().then((res)=>{
                    has_error.value = false;
                    error_msg.value = '';
                    partners.value = res.data.partners;
                },(err)=>{
                    console.log(err);
                    has_error.value = true;
                    error_msg.value = err;
                });
                loading.value = false;
            }

            const hideDates = (partner_id:Number) => {
                let partner_index = partners.value.findIndex((elm) => elm.id == partner_id);
                if(partner_index !== -1){
                    partners.value[partner_index].set_dates = false;
                    start_date.value = null;
                    end_date.value = null;
                }
            }

            const hideDisabledDates = (partner_id:Number) => {
                let partner_index = partners.value.findIndex((elm) => elm.id == partner_id);
                if(partner_index !== -1){
                    partners.value[partner_index].set_disabled_dates = false;
                    start_disabled_date.value = null;
                    end_disabled_date.value = null;
                }
            }

            const saveDates = async(partner_id:Number) => {
                if(start_date.value == null || end_date.value == null){
                    let err_fields = [];
                    if(start_date.value == null) err_fields.push('start date');
                    if(end_date.value == null) err_fields.push('end date');
                    let save_err = 'The ';
                    err_fields.forEach((elm,ind) => {
                        if(ind != 0) save_err += 'and ';
                        save_err += elm + ' ';
                    });
                    save_err += 'field';
                    if(err_fields.length > 1){
                        save_err += 's are ';
                    }else{
                        save_err += ' is ';
                    }
                    save_err += 'required';
                    store.commit(MutationTypes.addSystemNotification, {title:'Missing Date Paramaters.',content:save_err,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                }else{
                    loading.value = true;
                    let post_data = {
                        partner_id: partner_id,
                        start_date: moment(start_date.value).format('YYYY-MM-DD'),
                        end_date: moment(end_date.value).format('YYYY-MM-DD')
                    }
                    Partner.saveDates(post_data).then((res) => {
                        let partner_index = partners.value.findIndex((elm) => elm.id == partner_id);
                        partners.value[partner_index] = res.data.partner;
                        store.commit(MutationTypes.addSystemNotification, {title:'Partner Dates Saved',content:'Partner data successfully saved.',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                    },(err) => {
                        let err_msg = '';
                        if(typeof(err.response.data.errors) != 'undefined'){
                            err_msg += '<ul>'
                            for (const [key, value] of Object.entries(err.response.data.errors)) {
                                err_msg += '<li>' + key + ': ' + value + '</li>';
                            }
                            err_msg += '</ul>';
                        }
                        store.commit(MutationTypes.addSystemNotification, {title:'Error Saving Partner Dates',content:err_msg,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    });
                    loading.value = false;
                }
            }

            const saveDisabledDates = async(partner_id:Number) => {
                if(start_disabled_date.value == null || end_disabled_date.value == null){
                    let err_fields = [];
                    if(start_disabled_date.value == null) err_fields.push('start date');
                    if(end_disabled_date.value == null) err_fields.push('end date');
                    let save_err = 'The ';
                    err_fields.forEach((elm,ind) => {
                        if(ind != 0) save_err += 'and ';
                        save_err += elm + ' ';
                    });
                    save_err += 'field';
                    if(err_fields.length > 1){
                        save_err += 's are ';
                    }else{
                        save_err += ' is ';
                    }
                    save_err += 'required';
                    store.commit(MutationTypes.addSystemNotification, {title:'Missing Date Paramaters.',content:save_err,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                }else{
                    loading.value = true;
                    let post_data = {
                        partner_id: partner_id,
                        start_disabled_date: moment(start_disabled_date.value).format('YYYY-MM-DD'),
                        end_disabled_date: moment(end_disabled_date.value).format('YYYY-MM-DD')
                    }
                    Partner.saveDisabledDates(post_data).then((res) => {
                        let partner_index = partners.value.findIndex((elm) => elm.id == partner_id);
                        partners.value[partner_index] = res.data.partner;
                        store.commit(MutationTypes.addSystemNotification, {title:'Partner Disabled Dates Saved',content:'Partner data successfully saved.',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                    },(err) => {
                        let err_msg = '';
                        if(typeof(err.response.data.errors) != 'undefined'){
                            err_msg += '<ul>'
                            for (const [key, value] of Object.entries(err.response.data.errors)) {
                                err_msg += '<li>' + key + ': ' + value + '</li>';
                            }
                            err_msg += '</ul>';
                        }
                        store.commit(MutationTypes.addSystemNotification, {title:'Error Saving Partner Disabled Dates',content:err_msg,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    });
                    loading.value = false;
                }
            }

            const setDates = (partner_id:Number) => {
                partners.value.forEach((partner) => partner.set_dates = false);
                let partner_index = partners.value.findIndex((elm) => elm.id == partner_id);
                if(partner_index !== -1){
                    if(partners.value[partner_index].dates != null){
                        //@ts-ignore
                        start_date.value = new Date(partners.value[partner_index].dates.start_date);
                        //@ts-ignore
                        end_date.value = new Date(partners.value[partner_index].dates.end_date);
                    }else{
                        start_date.value = null;
                        end_date.value = null;
                    }
                    partners.value[partner_index].set_dates = true;
                }
            }

            const setDisabledDates = (partner_id:Number) => {
                partners.value.forEach((partner) => partner.set_disabled_dates = false);
                let partner_index = partners.value.findIndex((elm) => elm.id == partner_id);
                if(partner_index !== -1){
                    if(partners.value[partner_index].disabled_dates != null){
                        //@ts-ignore
                        start_disabled_date.value = new Date(partners.value[partner_index].disabled_dates.start_date);
                        //@ts-ignore
                        end_disabled_date.value = new Date(partners.value[partner_index].disabled_dates.end_date);
                    }else{
                        start_disabled_date.value = null;
                        end_disabled_date.value = null;
                    }
                    partners.value[partner_index].set_disabled_dates = true;
                }
            }

            return {
                clearDates,
                clearDisabledDates,
                end_date,
                end_disabled_date,
                error_msg,
                getPartnerDates,
                has_error,
                hideDates,
                hideDisabledDates,
                loading,
                moment,
                partners,
                saveDates,
                saveDisabledDates,
                setDates,
                setDisabledDates,
                start_date,
                start_disabled_date,
            };
        },
    });
</script>

<style lang="scss">

</style>
