import {api} from '@/store';
import {IRequest} from "@/interfaces/request";
import {FormData} from "@/interfaces/form-config";
export default class Request{
    constructor(public request?:IRequest) {}

    static async agreeToTerms(form:FormData):Promise<any>{
        return await api.post('api/agreement/sign',form);
    }

    static async approve(form:FormData):Promise<any>{
        return await api.post('api/request/approve',form);
    }

    static async approvePartner(form:FormData):Promise<any>{
        return await api.post('api/request/approve-partner',form);
    }

    static async create(form:FormData):Promise<any>{
        return await api.post('api/request',form);
    }

    static async cancel(form:FormData):Promise<any>{
        return await api.post('api/request/cancel',form);
    }

    static async checkForPayment(form:FormData):Promise<any>{
        return await api.post('api/request/visit-payment',form);
    }

    static async getAvailableTimes(form:FormData):Promise<any>{
        return await api.post('api/request/get-times',form);
    }

    static async getClientRequest(form:FormData):Promise<any>{
        return await api.post('api/request/client',form);
    }

    static async getClientRequests(form:FormData):Promise<any>{
        return await api.post('api/request/clients',form);
    }

    static async getAgreement(form:FormData):Promise<any>{
        return await api.post('api/agreement',form);
    }

    static async getAgreements():Promise<any>{
        return await api.post('api/agreements',{});
    }

    static async getInvoice(form:FormData):Promise<any>{
        return await api.post('api/invoice',form);
    }

    static async getInvoices():Promise<any>{
        return await api.get('api/invoices');
    }

    static async getQuote(form:FormData):Promise<any>{
        return await api.post('api/request/quote',form);
    }

    static async getQuotes(form:FormData):Promise<any>{
        return await api.post('api/request/quotes',form);
    }

    static async markInvoiceAsPaid(form:FormData):Promise<any>{
        return await api.post('api/invoice/mark-as-paid',form);
    }

    static async reject(form:FormData):Promise<any>{
        return await api.post('api/request/reject',form);
    }

    static async rejectPartner(form:FormData):Promise<any>{
        return await api.post('api/request/reject-partner',form);
    }

    static async requestInvoice(form:FormData):Promise<any>{
        return await api.post('api/agreement/request-invoice',form);
    }

    static async reschedule(form:FormData):Promise<any>{
        return await api.post('api/request/reschedule',form);
    }

    static async saveQuote(form:FormData):Promise<any>{
        return await api.post('api/request/save-quote',form);
    }

    static async submitPayment(form:FormData):Promise<any>{
        return await api.post('api/agreement/submit-payment',form);
    }

    static async submitVisitRequestPayment(form:FormData):Promise<any>{
        return await api.post('api/request/visit-payment/submit',form);
    }
}
