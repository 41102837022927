import {IRubric} from "@/interfaces/rubric";
import {FormData} from "@/interfaces/form-config";
import {api} from "@/store";

export class Rubric{
    constructor(public data?:IRubric) {}

    static async list():Promise<any>{
        return api.get('api/rubric');
    }

    static async addQuestion(id:Number|undefined,form:any):Promise<any>{
        return api.post('api/rubric/'+id+'/add-question',form);
    }

    static async addToScorecard(id:Number,scorecard_id:Number):Promise<any>{
        return api.post('api/rubric/'+id+'/scorecard/'+scorecard_id,{});
    }

    static async addToParticipantFeedback(id:Number,participant_feedback_id:Number):Promise<any>{
        return api.post('api/rubric/'+id+'/participant-feedback/'+participant_feedback_id,{});
    }

    static async addToFeedbackForm(id:Number,feedback_form_id:Number):Promise<any>{
        return api.post('api/rubric/'+id+'/feedback-form/'+feedback_form_id,{});
    }

    static async clone(form:any):Promise<any>{
        return api.post('api/rubric/clone',form);
    }

    static async createRubric():Promise<any>{
        return api.post('api/rubric/create',{});
    }

    static async deleteRubric(id:Number|undefined):Promise<any>{
        return api.post('api/rubric/'+id+'/delete',{});
    }

    static async editQuestion(id:Number|undefined,form:any):Promise<any>{
        return api.post('api/rubric/'+id+'/edit-question',form);
    }

    static async getRubric(id:Number|undefined):Promise<any>{
        return api.get('api/rubric/'+id);
    }

    static async getRubrics():Promise<any>{
        return api.get('api/rubric/all');
    }

    static async publish(id:Number|undefined):Promise<any>{
        return api.post('api/rubric/'+id+'/publish',{});
    }

    static async removeQuestion(id:Number|undefined, question_id:Number):Promise<any>{
        return  api.post('api/rubric/'+id+'/remove-question/'+question_id);
    }

    static async reorder(id:Number|undefined,form:any):Promise<any>{
        return  api.post('api/rubric/'+id+'/reorder',form);
    }

    static async saveField(form:FormData):Promise<any>{
        return api.post('api/rubric/save-field',form);
    }
}
