<template>
    <div>
        <h1>Pre-Approved Email Import</h1>
    </div>
    <div v-if="!loading">
        <form @submit.prevent="upload">
            <div class="card py-2 px-2">
                <div>Select a Partner</div>
                <div>
                    <select v-model="selectedPartner">
                        <option v-for="(partner,index) in partners" :value="partner.id" v-bind:key="index">{{partner.name}}</option>
                    </select>
                </div>
                <br/>
                <div>Excel File</div>
                <input type="file" id="emailList" accept=".xlsx" @change="onAttachment" required />
                <br/>
                <div>Sample Excel file should look like this:</div>
                <img src="/img/excelsample.png" width="300" height="185" alt="Sample Excel File" title="Sample Excel File" />
                <br/>
                <button class="btn btn-primary email-button" type="submit">Upload Excel List</button>
            </div>
        </form>
    </div>
    <br/>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import {api} from "@/store";
    import {defineComponent, ref} from "vue";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    import {store} from "@/store";
    import Loading from 'vue3-loading-overlay';
    import PartnerEmail from "@/models/partner-email";

    export default defineComponent({
        components: {Loading},
        mounted(){
            this.getPartnerList();
        },
        setup() {
            const loading = ref(false);
            const partners = ref([]);
            let file = ref<File>();
            let selectedPartner = ref(1);

            const getPartnerList = async () => {
                loading.value = true;
                await api.get('sanctum/csrf-cookie');

                api.get('api/partner',{}).then((res)=>{
                    var formatted:any = [];

                    res.data.forEach(function(elm:any){

                        formatted.push({
                            'id': elm.id,
                            'name': elm.long_name,
                            'slug': elm.slug
                        });

                    });
                    partners.value = formatted;
                    loading.value = false;

                },(err)=>{
                    console.log(err);
                    loading.value = false;
                });
            }

            const onAttachment = async(event: any) => {
                file.value = event.target.files[0];
            }

            const upload = async() => {
                loading.value = true;

                let formData = new FormData();
                formData.append("file", file.value!);
                formData.append("partner_id", selectedPartner.value.toString());

                await PartnerEmail.uploadEmails(formData).then((res)=>{
                        if(res.data == 'success') {
                            store.commit(MutationTypes.addSystemNotification,{
                                title: 'SUCCESS',
                                content: 'Emails Successfully Upload.',
                                icon: NotificationIcon.SUCCESS,
                                color: NotificationColor.GREEN
                            });
                        } else {
                            store.commit(MutationTypes.addSystemNotification,{
                                title: 'ERROR',
                                content: res,
                                icon: NotificationIcon.ERROR,
                                color: NotificationColor.RED
                            });
                        }

                     });

                loading.value = false;
            }

            return {
                file,
                getPartnerList,
                loading,
                onAttachment,
                partners,
                PartnerEmail,
                selectedPartner,
                upload,
            };
        },
    });
</script>

<style lang="scss">
</style>
