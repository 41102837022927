import {api} from "@/store";
import {IRegistrationFields} from "@/interfaces/registration-fields";

export default class RegistrationFields{
    constructor(public data?:IRegistrationFields) {}

    static async addRegistrationField(partner_id:Number|undefined,form:any):Promise<any>{
        return api.post('api/registration-fields/'+partner_id+'/add-field',form);
    }

    static async getRegistrationFields(partner_id:Number):Promise<any>{
        return api.get('api/registration-fields/'+partner_id);
    }

    static async removeRegistrationField(field_id:Number,form:any):Promise<any>{
        return  api.post('api/registration-fields/remove-field/'+field_id,form);
    }

    static async updateRegistrationFields(form:any):Promise<any>{
        return  api.post('api/registration-fields/update',form);
    }
}
