<template>
    <transition enter-active-class="animate__animated animate__slideInLeft animate__faster" leave-active-class="animate__animated animate__slideOutLeft animate__faster">
        <div v-show="isOpen" id="layout-sidenav" class="layout-sidenav sidenav sidenav-vertical bg-dark" v-click-outside="hamburgerClose">
            <div class="sidenav-inner">
                <div v-if="store.getters.partners.length > 0" class="w-100">
                    <div class="d-flex justify-content-between px-4 py-3 cursor-pointer" @click="togglePartnerSelect">
                        <div>{{ store.getters.partner ? store.getters.partner.short_name : 'SP-ED' }}</div>
                        <div class="ml-2" v-if="store.getters.partners.length > 1"><i class="fa" :class="selectPartnerMenu ? 'fa-chevron-down' : 'fa-chevron-left'"></i> </div>
                    </div>
                    <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
                        <div v-show="selectPartnerMenu" class="dropdown-menu position-absolute " id="partner-select">
                            <li v-for="(item, index) of store.getters.partners" v-bind:key="index" class="">
                                <a @click="selectPartner(item)" role="menuitem" href="javascript:void(0)" class="dropdown-item d-flex justify-content-start align-items-center px-2 py-1">
                                    <div class="mr-2">
                                        <i v-if="store.getters.partner ? store.getters.partner.id === item.id : false" class="fa fa-check-circle text-primary"></i>
                                        <i v-else class="far fa-circle text-dark"></i>
                                    </div>
                                    {{item.short_name}}
                                </a>
                            </li>
                        </div>
                    </transition>

                    <div class="sidenav-divider mt-0"></div>
                </div>

                <!-- Sidebar Navigation Links -->
                <sidenav-item :to="{name:'dashboard'}" label="Dashboard" icon="fas fa-tachometer-alt"></sidenav-item>
                <sidenav-item v-if="showScenarios()" :to="{name:'scenario.list'}" label="Scenarios" icon="fas fa-laptop-medical"></sidenav-item>
                <sidenav-item v-if="store.getters.user && store.getters.user.props && store.getters.user.props.super_admin == 1" :to="{name:'rubric.list'}" label="Rubrics" icon="fas fa-poll-h"></sidenav-item>
                <sidenav-item v-if="store.getters.hasPermission('applicant.view')" group label="Recruitment"  icon="fas fa-id-card">
                    <sidenav-item label="Pending" icon="fa fa-users" :to="{name:'recruitment.list'}"></sidenav-item>
                </sidenav-item>
                <div v-if="store.getters.user && store.getters.user.props && store.getters.user.props.super_admin == 1" class="sidenav-item">
                    <li class="sidenav-divider mb-1"></li>
                    <sidenav-item group label="Partner Management"  icon="fas fa-handshake">
                        <sidenav-item label="Seat Management" icon="fas fa-chair" :to="{name:'management.seats'}"></sidenav-item>
                        <sidenav-item label="Pre-Approved Emails" icon="fas fa-thumbs-up" :to="{name:'management.pre-approved'}"></sidenav-item>
                        <sidenav-item label="Scheduling Dates" icon="fas fa-clock" :to="{name:'management.scheduling-dates'}"></sidenav-item>
                        <sidenav-item label="Registration Fields" icon="fas fa-clipboard" :to="{name:'management.registration-fields'}"></sidenav-item>
                        <sidenav-item label="Welcome Messages" icon="fas fa-door-open" :to="{name:'management.welcome-messages'}"></sidenav-item>
                        <sidenav-item label="Passwords" icon="fas fa-key" :to="{name:'management.passwords'}"></sidenav-item>
                        <sidenav-item label="Client Admin Requests" icon="fas fa-user-tie" :to="{name:'management.clients'}"></sidenav-item>
                    </sidenav-item>
                    <sidenav-item v-if="store.getters.partner && store.getters.partner.is_sim" group label="Quote Management" icon="fas fa-user-plus">
                        <sidenav-item v-if="store.getters.partner && store.getters.partner.is_sim" label="Quote Requests" icon="fas fa-money-bill" :to="{name:'management.quotes'}"></sidenav-item>
                        <sidenav-item v-if="store.getters.partner && store.getters.partner.is_sim" label="Invoice Requests" icon="fas fa-file-invoice" :to="{name:'management.invoices'}"></sidenav-item>
                    </sidenav-item>
                    <sidenav-item group label="Social Media Posting" icon="fas fa-user-friends">
                        <sidenav-item label="Social Media Posts" icon="fas fa-hashtag" :to="{name:'management.social-media'}"></sidenav-item>
                        <sidenav-item label="Articles" icon="fas fa-newspaper" :to="{name:'management.articles'}"></sidenav-item>
                    </sidenav-item>
                </div>
                <sidenav-item v-if="store.getters.hasPermission('user.view_list')" :to="{name: 'management.users'}" label="User Management" icon="fas fa-users"></sidenav-item>
                <sidenav-item v-if="store.getters.partner && store.getters.partner.is_sim && userIsClientAdmin(0)" label="Agreements" icon="fas fa-file-contract" :to="{name:'PartnerAgreements'}"></sidenav-item>
                <sidenav-item v-if="store.getters.partner && store.getters.partner.is_sim && userIsClientAdmin(1)" label="Landing Page Config" icon="fas fa-pen-fancy" :to="{name:'management.partners'}"></sidenav-item>
                <div class="sidenav-item">
                    <a href="javascript:void(0)" @click="logout"  class="sidenav-link">
                        <i class="fa fa-sign-out-alt mr-2"></i>
                        <div>Logout</div>
                    </a>
                </div>
            </div>
        </div>

    </transition>
    <div @click="toggle" class="layout-overlay layout-sidenav-toggle" v-show="isOpen"></div>

</template>

<script lang="ts">
    import {ActionTypes} from "@/store/modules/auth";
    import {computed, defineComponent, ref} from "vue";
    import {IPartner} from "@/interfaces/partner";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationIcon, NotificationColor} from "@/interfaces/notification";
    import {store} from '@/store';
    import {UserRole} from "@/enums/user-role";
    import Partner from "@/models/partner";
    import SidenavItem from "@/components/sidenav-item.vue";

    export default defineComponent({
        components: {SidenavItem},
        setup() {
            const isOpen = computed(()=>store.state.ux.sideNav);
            const toggle = () => store.commit(MutationTypes.setSideNav,!store.state.ux.sideNav);
            const selectPartnerMenu = ref(false);
            const togglePartnerSelect = ()=> {if(store.getters.partners.length > 1) selectPartnerMenu.value = !selectPartnerMenu.value};
            const partnerOptions = ref<Array<IPartner>>(store.getters.partners);
            const logout = () => store.dispatch(ActionTypes.logout);

            function selectPartner(partner:IPartner){
                Partner.switch({partner_id:partner.id}).then((res) => {
                    window.location = res.data.redirect;
                },(err) => {
                    store.commit(MutationTypes.addSystemNotification, {title:'Error changing partner.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    console.log(err);
                });
            }

            const showScenarios = () => {
                let scenarioMenuAccess = false;
                if (store.getters.user && store.getters.user.props && store.getters.user.props.super_admin == 1) {
                    scenarioMenuAccess = true;
                } else if (store.getters.user && store.getters.user.props && store.getters.user.props.roles) {
                    let found_value = store.getters.user.props.roles.find(function(item:any){
                        return (item.id === UserRole.LEARNER || item.id === UserRole.ADMIN || item.id === UserRole.HEADTRAINER || item.id === UserRole.TRAINER)
                    });
                    if (found_value) {
                        scenarioMenuAccess = true;
                    }
                }
                return scenarioMenuAccess;
            }
            const hamburgerClose = () => {
                if((event?.target as Element)?.className != 'navbar-toggler-icon'){
                    store.commit(MutationTypes.setSideNav,false);
                }
            }

            const userIsClientAdmin = (admin_check:any) => {
                var is_client_admin = false;
                if((store.getters.user && store.getters.user.props.super_admin == 1) && admin_check){
                    is_client_admin = true;
                }else{
                    if(store.getters.user && store.getters.user.props && store.getters.user.props.roles){
                        is_client_admin = store.getters.user.props.roles.find(function(item:any){
                            return item.id == UserRole.CLIENTADMIN
                        }) != undefined;
                    }
                }
                return is_client_admin;
            }

            return {
                hamburgerClose,
                isOpen,
                logout,
                partnerOptions,
                selectPartner,
                selectPartnerMenu,
                showScenarios,
                store,
                toggle,
                togglePartnerSelect,
                userIsClientAdmin,
                UserRole
            };
        },
    });
</script>
<style lang="scss">
    .layout-sidenav {transform: none}
    #layout-sidenav{
        height: 100vh;
    }
    #partner-select{
        top: 39px;
        left: 25px;
        display: initial;
    }
    @media (max-width: 991px) {
        .layout-overlay {display: block}
    }
</style>
