<template>
    <slot name="trigger" :click="open"></slot>
    <modal v-model="modal" allow-close>
        <template v-slot:header>Create Question</template>
        <div class="form-group">
            <label>Type</label>
            <select class="custom-select" :class="{'is-invalid':!validation.selectedType}" @change="changeType" v-model="selectedType" required>
                <option selected disabled hidden :value="undefined">-- Select a Question Type --</option>
                <option v-for="(item, index) in Object.values(QuestionType).filter(key => isNaN(QuestionType[key]))" :value="item" v-bind:key="index">{{Question.parseType(item)}}</option>
            </select>
            <small v-if="!validation.selectedType" class="invalid-feedback">A question type must be selected</small>
        </div>
        <div class="form-group">
            <label>Question</label>
            <textarea :class="{'is-invalid':!validation.questionText}" v-model="questionText" required cols="3" class="form-control"></textarea>
            <small v-if="!validation.questionText" class="invalid-feedback">Question text is required</small>
        </div>
        <div v-if="selectedType !== undefined && selectedType !== QuestionType.LONGFILL">
            <label>Answers:</label>
            <VueDraggableNext class="dragArea list-group w-full" v-model="answers">
                <div class="d-flex justify-content-start align-items-center mb-2" v-for="(item, index) in answers" :key="index">
                    <i class="text-primary cursor-pointer fa fa-bars"></i>
                    <input type="text" v-model="answers[index]" class="form-control mx-2" />
                    <button @click="removeAnswer(parseInt(index))" class="btn btn-primary float-right"><i class="fa fa-minus"></i> </button>
                </div>
            </VueDraggableNext>

            <button @click="addAnswer" :disabled="answers.length >= maxAnswers" class="btn btn-primary float-right"><i class="fa fa-plus"></i> </button>
            <small v-if="!validation.answers" class="d-block invalid-feedback">{{ validation.answersText }}</small>
        </div>
        <template v-slot:footer>
            <div class="d-flex justify-content-end">
                <button @click="modal = !modal" class="btn btn-default mx-2">Cancel</button>
                <button @click="save" class="btn btn-primary">Save</button>
            </div>
        </template>
    </modal>
</template>

<script lang="ts">
    import {computed, defineComponent, reactive, ref} from "vue";
    import Modal from "@/components/modal.vue";
    import {QuestionType} from "@/enums/question-type";
    import FeedbackForm from "@/models/feedback-form";
    import ParticipantFeedback from "@/models/participant-feedback";
    import {Rubric} from "@/models/rubric";
    import Scorecard from "@/models/scorecard";
    import Question from "@/models/question";
    import { VueDraggableNext } from 'vue-draggable-next'
    import {store} from "@/store";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationIcon,NotificationColor} from "@/interfaces/notification";
    import {IScorecardQuestions} from "@/interfaces/scorecard-questions";
    import {IFeedbackFormQuestions} from "@/interfaces/feedback-form-questions";
    import {IParticipantFeedbackQuestions} from "@/interfaces/participant-feedback-questions";

    export default defineComponent({
        name: "create-question",
        components: {Modal,VueDraggableNext},
        props:{
            feedback_form:{
                type:FeedbackForm,
                required:false
            },
            form_type:{
                type:String,
                required:true
            },
            participant_feedback:{
                type:ParticipantFeedback,
                required:false
            },
            scorecard:{
                type:Scorecard,
                required:false
            },
            rubric:{
                type:Object,
                required:false
            }
        },
        emits:['update'],
        setup(props, {emit}){
            const modal = ref(false);
            const open = ()=>modal.value = !modal.value;
            const selectedType = ref<QuestionType>();
            const answers = ref<Array<String>>([]);
            const questionText = ref('');
            const validation = reactive({
                'questionText':true,
                'selectedType':true,
                'answers':true,
                'answersText':'',
            })
            const maxAnswers = computed(()=>{
                switch (selectedType.value){
                    case QuestionType.MULTIPLECHOICE:
                        return 6;
                    case QuestionType.TRUEFALSE:
                        return 2;
                    case QuestionType.LIKERT:
                        return 5;
                    default:
                        return 0;
                }
            });
            const addAnswer = ()=>{
                if(answers.value.length < maxAnswers.value){
                    answers.value.push('');
                }
            }
            const resetForm = ()=>{
                selectedType.value = undefined;
                answers.value = [];
                questionText.value = '';
            }
            const resetValidation = ()=>{
                validation.questionText = true;
                validation.selectedType = true;
                validation.answers = true;
                validation.answersText = '';
            }
            const removeAnswer = (index:number)=>{
                answers.value.splice(index,1);
            }

            const changeType = ()=>{
                answers.value = [''];
                if(selectedType.value === QuestionType.TRUEFALSE){
                    answers.value = ['Yes','No'];
                }
                if(selectedType.value === QuestionType.LIKERT){
                    answers.value = ['No effort','Minimal effort','Moderate effort','Skilled effort','Exemplary effort'];
                }
            }

            const save = () =>{
                //reset validation
                resetValidation();
                //check validation
                if(questionText.value === '') validation.questionText = false;
                if(selectedType.value == undefined) validation.selectedType = false;
                if(selectedType.value == QuestionType.MULTIPLECHOICE || selectedType.value == QuestionType.LIKERT){
                    if(selectedType.value == QuestionType.MULTIPLECHOICE && answers.value.length < 2){
                        validation.answers = false;
                        validation.answersText = 'Multiple choice requires at least 2 answers';
                    }
                    if(selectedType.value == QuestionType.LIKERT && answers.value.length != 5){
                        validation.answers = false;
                        validation.answersText = 'Likert requires 5 answers';
                    }
                    if (validation.answersText == '') {
                        answers.value.forEach((i)=>{
                            if(i === ''){
                                validation.answers = false;
                                validation.answersText = 'You have empty answers';
                            }
                        });
                    }
                }
                if(validation.answers && validation.selectedType && validation.questionText){
                    //save
                    if(props.form_type === 'scorecard'){
                        Scorecard.addQuestion(props.scorecard?.data?.id, {
                            'question_type_id':selectedType.value,
                            'question':questionText.value,
                            'answers':answers.value,
                        }).then((res)=>{
                            let question:IScorecardQuestions = res.data;
                            emit('update',question);
                            modal.value = false;
                            resetForm();
                            store.commit(MutationTypes.addSystemNotification,{title:'Success',content:'Answer has been added',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                        });
                    }else if(props.form_type === 'feedback_form'){
                        FeedbackForm.addQuestion(props.feedback_form?.data?.id, {
                            'question_type_id':selectedType.value,
                            'question':questionText.value,
                            'answers':answers.value,
                        }).then((res)=>{
                            let question:IFeedbackFormQuestions = res.data;
                            emit('update',question);
                            modal.value = false;
                            resetForm();
                            store.commit(MutationTypes.addSystemNotification,{title:'Success',content:'Answer has been added',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                        });
                    }else if(props.form_type === 'participant_feedback'){
                        ParticipantFeedback.addQuestion(props.participant_feedback?.data?.id, {
                            'question_type_id':selectedType.value,
                            'question':questionText.value,
                            'answers':answers.value
                        }).then((res)=>{
                            let question:IParticipantFeedbackQuestions = res.data;
                            emit('update',question);
                            modal.value = false;
                            resetForm();
                            store.commit(MutationTypes.addSystemNotification,{title:'Success',content:'Answer has been added',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                        });
                    }else if(props.form_type === 'rubric'){
                        Rubric.addQuestion(props.rubric?.id, {
                            'question_type_id':selectedType.value,
                            'question':questionText.value,
                            'answers':answers.value
                        }).then((res)=>{
                            let question = res.data;
                            emit('update',question);
                            modal.value = false;
                            resetForm();
                            store.commit(MutationTypes.addSystemNotification,{title:'Success',content:'Question has been added',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                        });
                    }else{
                        store.commit(MutationTypes.addSystemNotification,{title:'Error',content:'Error saving answer',icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    }
                }
            }

            return{
                addAnswer,
                answers,
                changeType,
                questionText,
                maxAnswers,
                modal,
                Question,
                QuestionType,
                removeAnswer,
                save,
                selectedType,
                open,
                validation,
            }
        }
    });
</script>

<style scoped>

</style>
