<template>
	<div v-if="!loading && invoice && !has_error">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-header">Invoice Request</div>
					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<p class="mb-0">{{invoice.user.first_name}} {{invoice.user.last_name}}</p>
								<p class="mb-0"><small>{{invoice.user.job_title}}</small></p>
								<p><small>{{invoice.user.company_name}}</small></p>
								<p><strong>Payment Amount:</strong> ${{invoice.payment_amount}}</p>
								<p><strong>Invoice Requested:</strong> {{moment(invoice.invoice_requested_at).format('MM/DD/YYYY')}}</p>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<h4>Contact Information</h4>
								<p>{{invoice.user.email}}</p>
								<p class="mb-0">{{invoice.user.address_1}}</p>
								<p v-if="invoice.user.address_2 != null" class="mb-0">{{invoice.user.address_2}}</p>
								<p class="mb-0">{{invoice.user.city}}, <span v-if="invoice.user.state != null">{{invoice.user.state.short_name}}</span></p>
								<p class="mb-5">{{invoice.user.zip}}</p>
							</div>
						</div>
						<div v-if="invoice.is_payment_made" class="row">
							<div class="col-12">
								<p>Payment was submitted on {{moment(invoice.payment_made_at).format('MM/DD/YYYY')}}.</p>
							</div>
						</div>
						<div v-else class="row">
							<div class="col-12">
								<button class="btn btn-primary" @click="show_complete_modal = true;">Mark As Paid</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="has_error">
		<p class="alert alert-danger">{{err_msg}}</p>
	</div>
	<modal v-model="show_complete_modal" allow-close>
		<template v-slot:header>Mark As Paid</template>
		<div>
			<p>Are you sure you would like to mark this invoice as paid?</p>
			<p class="text-danger"><strong>Invoices should only be marked as paid if payment was received. This action cannot be undone.</strong></p>
		</div>
		<template v-slot:footer>
			<button @click="show_complete_modal = false" class="btn btn-default mx-2">Cancel</button><button @click="markAsPaid" class="btn btn-primary">Mark As Paid</button>
		</template>
	</modal>
	<loading :active="loading" :is-full-page="true"></loading>
</template>
<script lang="ts">
	import { defineComponent, ref } from "vue";
	import { IPendingPartner } from "@/interfaces/pending-partner";
	import Loading from "vue3-loading-overlay";
	import Modal from "@/components/modal.vue";
	import moment from "moment";
	import { MutationTypes } from "@/store/modules/ux/mutations";
	import { NotificationIcon, NotificationColor } from "@/interfaces/notification";
	import Request from "@/models/request";
	import { store } from '../../store/index';
	import { useRoute } from "vue-router";

	export default defineComponent({
		components: { Loading, Modal },

		mounted(){
			this.getInvoice();
		},

		setup(){
			const err_msg = ref<String>("");
			const has_error = ref<Boolean>(false);
			const invoice = ref<IPendingPartner | null>(null);
			const loading = ref<Boolean>(false);
			const route = useRoute();
			const show_complete_modal = ref<Boolean>(false);

			const getInvoice = async() => {
				loading.value = true;
				var post_data = {
					invoice_id: +route.params.invoice_id
				};
				await Request.getInvoice(post_data).then((res:any) => {
					invoice.value = res.data.invoice;
				},(err:any) => {
					console.log(err);
					err_msg.value = err;
					has_error.value = true;
					store.commit(MutationTypes.addSystemNotification, {title:'Error loading invoice request',content:err,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
				})

				loading.value = false;
			}

			const markAsPaid = async() => {
				loading.value = true;
				var post_data = {
					invoice_id: +route.params.invoice_id
				};
				await Request.markInvoiceAsPaid(post_data).then((res:any) => {
					invoice.value = res.data.invoice;
					show_complete_modal.value = false;
					store.commit(MutationTypes.addSystemNotification, {title:'Invoice marked as paid',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
				},(err:any) => {
					console.log(err);
					store.commit(MutationTypes.addSystemNotification, {title:'Error marking invoice as paid',content:err,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
				})

				loading.value = false;
			}

			return {
				err_msg,
				getInvoice,
				has_error,
				invoice,
				loading,
				markAsPaid,
				moment,
				show_complete_modal
			}
		}
	});
</script>
<style scoped lang="sass">
	
</style>