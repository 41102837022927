<template>
    <div class="card shadow-sm mb-4 training-session-schedule">
        <div class="card-header text-white bg-secondary font-weight-bold">Schedule a Training Session</div>
        <div class="card-content py-2 px-4">
            <div class="row" v-if="!schedule_loading && !loading">
                <div class="col-12">
                    <h4>Select a Training Type</h4>
                    <select v-model="type" @change="has_type_error = false">
                        <option value="null">--not set--</option>
                        <option v-for="ts_type in training_session_types" v-bind:key="ts_type.id" :value="ts_type.id">{{ts_type.name}} - {{ts_type.duration}} hrs</option>
                    </select>
                    <div v-if="has_type_error" class="alert alert-danger mt-3">{{type_error}}</div>
                    <hr class="w-75 text-center">
                </div>
                <div class="col-12">
                    <h4>Select a Date &amp; Time</h4>
                    <schedule-form :is_single="true" field="training_session_date" alt-field="none" :start-date="start_date" v-on:update-date="setDate"></schedule-form>
                    <div v-if="has_date_error" class="alert alert-danger mt-3">{{date_error}}</div>
                    <hr class="w-75 text-center">
                </div>
                <div class="col-12">
                    <h4>Select a Scenario</h4>
                    <select v-model="scenario" @change="has_scenario_error = false">
                        <option value="null">--not set--</option>
                        <option v-for="scenario in training_scenarios" v-bind:key="scenario.id" :value="scenario.id">{{scenario.patient_name}}</option>
                    </select>
                    <div v-if="has_scenario_error" class="alert alert-danger mt-3">{{scenario_error}}</div>
                    <hr class="w-75 text-center">
                </div>
                <div class="col-12">
                    <h4>Job Number</h4>
                    <input v-model="job_number" id="job_number_input" type="text" maxlength="255" />
                    <div v-if="has_job_number_error" class="alert alert-danger mt-3">{{job_number_error}}</div>
                    <hr class="w-75 text-center">
                </div>
                <div class="col-12">
                    <h4>Platform</h4>
                    <select v-model="platform">
                        <option :value="0">SP-ED</option>
                        <option :value="1">Simclusive</option>
                    </select>
                    <hr class="w-75 text-center">
                </div>
                <div class="col-12">
                    <h4>Select user(s)</h4>
                    <div class="ts-users-select pt-2">
                        <div v-if="training_session_users.length">
                            <div v-for="ts_user in training_session_users" v-bind:key="ts_user.id">
                                <label class="checkbox-custom ts-user-select w-100 px-4 pt-2 cursor-pointer">
                                    <input type="checkbox" :value="ts_user.user_id" class="float-left mr-2" width="20" v-model="training_users" @change="has_user_error = false">
                                    <span class="check-icon"><i class="fas fa-check"></i></span>
                                    <span class="checkmark"></span>
                                    <div class="ts-user-content overflow-hidden">
                                        <img :src="!ts_user.user.image ? '/img/no_image.jpg' : ts_user.user.image " class="rounded-circle float-right" width="60">
                                        <span>{{ts_user.user.first_name}} {{ts_user.user.last_name}}</span>
                                        <br>
                                        <small>{{ts_user.user.email}}</small>
                                        <br>
                                        <small v-if="ts_user.role_id == UserRole.SP">Standardized Patient</small>
                                        <small v-if="ts_user.role_id == UserRole.OBSERVER">Observer</small>
                                        <small v-if="ts_user.role_id == UserRole.TRAINER">Trainer</small>
                                        <hr class="w-75 text-center">
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div v-else>
                            <p class="p-4">No users available for training sessions.</p>
                        </div>
                    </div>
                    <div v-if="has_user_error" class="alert alert-danger mt-3">{{user_error}}</div>
                    <hr class="w-75 text-center">
                </div>
                <div class="col-12 text-center">
                    <button class="btn btn-primary btn-md my-2" @click="scheduleTrainingSession">Schedule Training Session</button>
                </div>
            </div>
            <div class="loading-card-area" v-else>
                <loading :active="schedule_loading || loading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    import {store} from "@/store";
    import {TrainingSessionType} from "@/enums/training-session-type";
    import {UserRole} from "@/enums/user-role";
    import Loading from 'vue3-loading-overlay';
    import ScheduleForm from "@/components/schedule-form.vue";
    import TrainingSession from "@/models/training-session";

    export default defineComponent({
        components: {
            Loading,
            ScheduleForm
        },
        emits: ['refresh',],
        props: {
            training_session_types: {type: Object, required: true},
            training_session_users: {type: Object, required: true},
            training_scenarios: {type: Object, required:true},
            schedule_loading: {type: Boolean, required:true}
        },
        setup(props,{emit}) {
            const loading = ref<Boolean>(false);
            const scenario = ref(null);
            const start_date = ref<Date>(new Date());
            const training_session_date = ref('');
            const training_users = ref([]);
            const type = ref(null);
            const job_number = ref('');
            const platform = ref(0);
            const has_date_error = ref<Boolean>(false);
            const date_error = ref<String>('You must set a date and time');
            const has_type_error = ref<Boolean>(false);
            const type_error = ref<String>('You must set a training session type');
            const has_scenario_error = ref<Boolean>(false);
            const scenario_error = ref<String>('You must set a scenario');
            const has_user_error = ref<Boolean>(false);
            const user_error = ref<String>('You must set users');
            const has_job_number_error = ref<Boolean>(false);
            const job_number_error = ref<String>('You must set a Job Number');

            const setDate = (date:any) => {
                training_session_date.value = date.date;
                has_date_error.value = false;
            };
            const scheduleTrainingSession = async() => {
                loading.value = true;
                let has_errors = false;
                if(training_session_date.value == ''){
                    has_errors = true;
                    has_date_error.value = true;
                }
                if(type.value == null){
                    has_errors = true;
                    has_type_error.value = true;
                }
                if(training_users.value.length == 0){
                    has_errors = true;
                    has_user_error.value = true;
                }
                if((type.value == TrainingSessionType.SCENARIO || type.value == TrainingSessionType.GROUPED || type.value == TrainingSessionType.LARGEGROUP)){
                    if(scenario.value == null){
                        has_errors = true;
                        has_scenario_error.value = true;
                    }
                    if(job_number.value == ''){
                        has_errors = true;
                        has_job_number_error.value = true;
                    }
                }
                if(has_errors){
                    loading.value = false;
                    return false;
                }

                let post_data:any = {
                    date: training_session_date.value,
                    type: type.value,
                    users: training_users.value,
                    scenario: scenario.value,
                    is_sim: platform.value
                };
                if((type.value == TrainingSessionType.SCENARIO || type.value == TrainingSessionType.GROUPED || type.value == TrainingSessionType.LARGEGROUP)){
                    post_data.job_number = job_number.value.toUpperCase();
                }
                await TrainingSession.schedule(post_data).then(()=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Training Session Scheduled',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    resetForm();
                    emit('refresh');
                    loading.value = false;
                },(err)=>{
                    console.log(err);
                    let err_body = '';
                    if(typeof(err.response.data.errors) == 'undefined'){
                        err_body = err.response.data.message;
                    }else{
                        err_body = '<ul>';
                        for (const [key,value] of Object.entries(err.response.data.errors)){
                            key;
                            err_body += '<li>' + value + '</li>';
                        }
                        err_body += '</ul>'
                    }
                    store.commit(MutationTypes.addSystemNotification, {title:'Error scheduling training session',content:err_body,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                    loading.value = false;
                });
            }

            const resetForm = () => {
                date_error.value = 'You must set a date and time';
                type_error.value = 'You must set a training session type';
                scenario_error.value = 'You must set a scenario';
                user_error.value = 'You must set users';
                job_number_error.value = 'You must set a Job Number';
                type.value = null;
                training_session_date.value = '';
                scenario.value = null;
                job_number.value = '';
                platform.value = 0;
                training_users.value = [];
            }
            return {
                date_error,
                has_date_error,
                has_job_number_error,
                has_scenario_error,
                has_type_error,
                has_user_error,
                loading,
                job_number_error,
                job_number,
                platform,
                props,
                scenario,
                scenario_error,
                ScheduleForm,
                scheduleTrainingSession,
                setDate,
                start_date,
                TrainingSessionType,
                training_users,
                type,
                type_error,
                UserRole,
                user_error
            };
        }
    });
</script>
<style scoped lang="scss">
    @import '@/assets/_variables.scss';
    .ts-users-select{
        height: 200px;
        border: 1px solid $gray-medium;
        overflow-y: scroll;
        .ts-user-select{
            .ts-user-content{
                margin-left: 3.5rem;
            }
            &:hover{
                background-color: $secondary;
                color: #fff;
                hr {
                    background-color: #fff;
                }
            }
        }
    }

    .checkbox-custom {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 6px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default radio button */
    .checkbox-custom input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 18px;
        left: 10px;
        height: 3rem;
        width: 3rem;
        background-color: $gray-light;
        border-radius: 50%;
        border: 1px solid $gray-medium;
        margin-top: 3px;
    }

    /* On mouse-over, add a grey background color */
    .checkbox-custom:hover input ~ .checkmark {
        background-color: #fff;
    }

    /* When the radio button is checked, add a blue background */
    .checkbox-custom input:checked ~ .checkmark {
        background-color: $gray-light;
        border-color: $primary
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    .check-icon{
        position: absolute;
        display: none;
        color: #fff;
        z-index: 1;
        top: 28px;
        left: 17px;
    }


    /* Show the indicator (dot/circle) when checked */
    .checkbox-custom input:checked ~ .checkmark:after,
    .checkbox-custom input:checked ~ .check-icon {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .checkbox-custom .checkmark:after {
        top: 2px;
        left: 2px;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        background: $primary;
    }

    #job_number_input {
        text-transform: uppercase;
    }
</style>
