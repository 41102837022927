
import {api} from "@/store";
import {ISpecialty} from "@/interfaces/specialty";

export class Specialty{
    constructor(public data?:ISpecialty) {}

    static async getSpecialties():Promise<any> {
        return await api.get('api/specialties');
    }

    static async getSpecialty(id: Number | undefined): Promise<any>{
        return await api.get('api/specialties/'+id);
    }
}