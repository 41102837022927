<template>
    <div v-if="showPagination && items.length >0" class="d-flex justify-content-end mx-2">
        <div class="d-flex justify-content-between align-items-center my-3">
            <div class="font-weight-semibold mr-2">Records:</div>
            <select name="limit" class="form-control" v-model="itemsPerPage">
                <option :selected="option === itemsPerPage"  v-for="(option, index) in itemsPerPageOptions" v-bind:key="index" :value="option">{{ option }}</option>
            </select>
        </div>
    </div>
    <div v-if="slots.rows" class="table-responsive">
        <table class="clients-table table table-hover m-0">
            <thead class="bg-light text-dark" v-if="slots.header">
                <slot name="header" />
            </thead>
            <tbody>
                <slot name="rows" :page-items="pageItems"/>
            </tbody>
        </table>
    </div>
    <slot v-if="slots.noItems && pageItems.length === 0" name="noItems" />
    <div v-if="showPagination && items.length >0" class="d-flex justify-content-center mt-3">
        <nav class="text-center">
            <ul class="pagination" style="">
                <li class="page-item" :class="{'disabled':currentPage === 0}">
                    <button @click="goToFirstPage" :disabled="currentPage === 0" class="page-link"><i class="fa fa-chevron-circle-left"></i> </button>
                </li>
                <li class=" page-item" :class="{'disabled':currentPage === 0}">
                    <button @click="goToPrevPage" :disabled="currentPage === 0" class="page-link"><i class="fa fa-chevron-left"></i> </button>
                </li>
                <li class="page-item" v-for="num in lastPage" v-bind:key="num" :class="{'active':currentPage+1 === num}">
                    <button @click="goToPage(num)"  class="page-link">{{ num }}</button>
                </li>
                <li class="page-item" :class="{'disabled':currentPage+1 === lastPage}">
                    <button @click="goToNextPage" :disabled="currentPage+1 === lastPage" class="page-link"><i class="fa fa-chevron-right"></i></button>
                </li>
                <li class="page-item" :class="{'disabled':currentPage+1 === lastPage}">
                    <button @click="goToLastPage" :disabled="currentPage+1 === lastPage" class="page-link"><i class="fa fa-chevron-circle-right"></i></button>
                </li>
            </ul>
            <p class="text-center col-md-12" style="">Showing {{start+1}} to {{ end }} of {{ items.length }} records.</p>
        </nav>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref} from 'vue';
    export default defineComponent({
        name: "data-grid",
        props: {
            listView: {type: Boolean, default:false},
            tableView: {type:Boolean, default:false},
            showPagination: {type:Boolean, default:true},
            items: {type:Array, required: true},
        },
        setup(props,{slots}:any){
            //check for config issues
            try {
                if(props.listView && props.tableView){
                    throw new Error('Cannot have props listView and tableView set to true.');
                }
                if(!props.listView && !props.tableView){
                    throw new Error('Need to define one variant (list-view or table-view)');
                }
            }catch (e){
                console.error(e);
            }

            //define variable
            const itemsPerPageOptions:Array<Number> = [5,10,25,50,100];
            const itemsPerPage = ref(10);
            const currentPage = ref(0);

            const start = computed(()=> currentPage.value*itemsPerPage.value);

            const lastPage = computed(()=> Math.ceil(props.items.length/itemsPerPage.value));
            const end = computed(()=> currentPage.value+1 === lastPage.value ? props.items.length : (currentPage.value+1)*itemsPerPage.value);

            const goToNextPage:Function = ()=>currentPage.value++;
            const goToPrevPage:Function = ()=>currentPage.value--;
            const goToFirstPage:Function = ()=>currentPage.value = 0;
            const goToLastPage:Function = ()=>currentPage.value = lastPage.value;
            function goToPage(value:number):void {
                currentPage.value = value-1;
            }

            const pageItems = computed(()=> {
                 return props.items.filter((v: any, i: number) => {
                     if(i >= start.value && i < end.value) return v;
                 })
             });
            return{
                currentPage,
                slots,
                pageItems,
                itemsPerPageOptions,
                itemsPerPage,
                start,
                end,
                lastPage,
                goToNextPage,
                goToPrevPage,
                goToFirstPage,
                goToLastPage,
                goToPage
            }
        }
    })
</script>

<style scoped>

</style>