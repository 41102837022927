
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'

//Theme Styles
import "bootstrap/scss/bootstrap.scss"
import "./assets/styles/appwork.scss"
import "./assets/styles/theme-paper.scss"
import "./assets/styles/colors.scss"
import "./assets/styles/uikit.scss"

//global styles
import "animate.css/animate.min.css"
import "@fortawesome/fontawesome-free/css/all.css"
import "./assets/libs/vue-notification/vue-notification.scss"
import "./assets/app.scss";

//plugins:
import './socket';
import vClickOutside from "click-outside-vue3"

//
import DashboardLayout from './layout/DashboardLayout.vue';
import EmptyLayout from './layout/EmptyLayout.vue';

const app = createApp(App);

app.use(router);
app.use(vClickOutside);
app.component('default-layout', DashboardLayout);
app.component('empty-layout', EmptyLayout);
app.mount('#app');
