import {api} from "@/store";
import {IParticipantFeedback} from "@/interfaces/participant-feedback";

export default class ParticipantFeedback{
    constructor(public data?:IParticipantFeedback) {}

    static async find(scenario_id:Number):Promise<any>{
        return api.get('api/participant-feedback/find/'+scenario_id);
    }

    static async addQuestion(id:Number|undefined,form:any):Promise<any>{
        return api.post('api/participant-feedback/'+id+'/add-question',form);
    }

    static async editQuestion(id:Number|undefined,form:any):Promise<any>{
        return api.post('api/participant-feedback/'+id+'/edit-question',form);
    }

    static async enable(scenario_id:Number|undefined):Promise<any>{
        return api.post('api/participant-feedback/' + scenario_id + '/enable');
    }

    static async removeQuestion(scenario_id:Number|undefined, question_id:Number):Promise<any>{
        return  api.post('api/participant-feedback/'+scenario_id+'/remove-question/'+question_id);
    }

    static async removeRubric(scenario_id:Number|undefined, form_id:Number):Promise<any>{
        return  api.post('api/participant-feedback/'+scenario_id+'/remove-rubric/'+form_id);
    }

    static async reorder(id:Number|undefined,form:any):Promise<any>{
        return  api.post('api/participant-feedback/'+id+'/reorder',form);
    }

    static async saveInstructions(participant_feedback_id:Number|undefined,instructions:String):Promise<any>{
        return api.post('api/participant-feedback/' + participant_feedback_id + '/instructions',{instructions:instructions});
    }

    static async submitVisitParticipantFeedback(visit_id:string|string[],form:any):Promise<any>{
        return api.post('api/visit/' + visit_id + '/participant-feedback/submit',form);
    }
}
