<template>
	<div v-if="!loading && agreements">
		<div class="row">
			<div class="col-12">
				<h2>Agreements</h2>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<table class="table">
					<thead class="bg-secondary text-white">
						<th>Title</th>
						<th>-</th>
					</thead>
					<tbody>
						<tr v-for="agreement in agreements" v-bind:key="agreement.id">
							<td>{{agreement.title}}</td>
							<td><button class="btn btn-primary" v-on:click="router.push({name:'PartnerAgreement',params:{agreement_id:agreement.id}})">View</button></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div v-else-if="err_msg != ''">
		<div class="alert alert-danger">{{err_msg}}</div>
	</div>
	<loading :active="loading" :is-full-page="true"></loading>
</template>
<script lang="ts">
	import { defineComponent, ref } from "vue";
	import { IPendingPartner } from "@/interfaces/pending-partner";
	import Loading from "vue3-loading-overlay";
	import moment from "moment";
	import Request from "@/models/request";
	import router from "@/router";

	export default defineComponent({
		components: {
			Loading,
		},
		mounted(){
			this.getAgreements();
		},
		setup(){

			const agreements = ref<Array<IPendingPartner> | null>(null);
			const err_msg = ref<String>("");
			const loading = ref<Boolean>(false);

			const getAgreements = async() => {
				loading.value = true;
				
				await Request.getAgreements().then((res) => {
					agreements.value = res.data.agreements;
				},(err) => {
					console.log(err);
					err_msg.value = err.response.data.message;
				})
				loading.value = false;
			}

			return {
				agreements,
				err_msg,
				getAgreements,
				loading,
				moment,
				router
			}
		}
	});
</script>
<style scoped lang="scss">

</style>