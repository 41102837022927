<template>
    <div class="card shadow-sm mb-4">
        <div class="card-header text-white bg-secondary font-weight-bold">Scenarios in Review</div>
        <div class="card-content">
            <div v-if="!visit_loading">
                <div v-if="Object.keys(props.scenario_reviews).length">
                    <div v-for="reviews in props.scenario_reviews" v-bind:key="reviews.id" class="row scenario-card" @click="router.push({name:'scenario.show',params:{id:reviews.id}})">

                        <div class="col-md-10">
                            <img :src="reviews.patient_image.location" class="rounded-circle float-left" width="75" />
                            <div class="font-weight-bold text-secondary">{{reviews.patient_name}}</div>
                            <div class="overflow-hidden">
                                <span>{{reviews.title}}</span>
                            </div>
                        </div>
                        <div class="col-md-2 d-flex flex-column justify-content-center">
                            <h3 class="text-center"><i class='fas fa-edit text-primary'></i></h3>
                        </div>
                        <br/>
                        <div class="col-12 thin-line"></div>

                    </div>
                    <pagination v-if="Object.keys(props.scenario_reviews).length > 10" :items="props.scenario_reviews" :page_start="page_start" :page_end="page_end" v-on:next-page="nextPage" v-on:previous-page="previousPage" :label="'Scenarios'" />
                </div>
                <p v-else class="text-center pt-3">No Scenarios in Review</p>
            </div>
            <div class="loading-card-area" v-else>
                <loading :active="visit_loading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Loading from 'vue3-loading-overlay';
    import Pagination from '@/components/dashboard/pagination.vue';
    import router from "@/router";

    export default defineComponent({
        components: {
            Loading,
            Pagination
        },
        props: {
            scenario_reviews: {type:Object, required:true},
            visit_loading: {type:Boolean, required:true}
        },
        setup(props) {
            const page_start = ref(0);
            const page_end = ref(10);
            const nextPage = async() => {
                page_start.value = page_start.value + 10;
                page_end.value = page_end.value + 10;
                if(page_end.value > Object.keys(props.scenario_reviews).length){
                    page_end.value = Object.keys(props.scenario_reviews).length;
                }
            };
            return {
                nextPage,
                page_end,
                page_start,
                props,
                router
            };
        }
    });
</script>
