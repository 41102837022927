<template>
    <div class="card p-4 text-center">
        <p class="mb-0">Access to this brand requires a passcode.</p>
        <p class="text-muted">Passcodes are provided by your institution</p>
        <div class="form-group">
            <label for='passcode'>Enter passcode</label>
            <br />
            <div class="row pb-3">
                <div class="col-md-4 offset-md-4">
                    <input class="form-control text-center" type='text' name='passcode' v-model="passcode" />
                    <p class="alert alert-danger" v-if="passcode_error !== ''">{{passcode_error}}</p>
                </div>
            </div>
            <div>
                <button type="button" class="btn btn-primary btn-sm" @click="submitPasscode" :disabled="passcode.length === 0">Submit Passcode <i class="fas fa-arrow-circle-right"></i></button>
            </div>
        </div>
    </div>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
import { ActionTypes } from "@/store/modules/auth";
import { defineComponent, ref } from "vue";
import { store } from '@/store';
import Loading from 'vue3-loading-overlay';
import User from "@/models/user";
import router from "@/router";

export default defineComponent({
    components:{Loading},
    mounted() {
        this.checkPasswordApproved();
    },
    setup() {
        const loading = ref<Boolean>(false);
        const passcode = ref<String>("");
        const passcode_error = ref<String>("");


        const checkPasswordApproved = () => {
            if(store.getters.passwordApproved){
                router.push({name: 'dashboard'});
            }
        }

        const submitPasscode = async() => {
            var post_data = {
                'passcode': passcode.value
            };
            loading.value = true;
            await User.submitPasscode(post_data).then((res)=>{
                if(res.status == 200){
                    store.dispatch(ActionTypes.user,{}).then(() => {
                        router.push({name: 'dashboard'});
                    });
                }else{
                    passcode_error.value = res.data.message;
                }
            },(err)=>{
                console.log(err);
                passcode_error.value = err.response.data.message;
            });
            loading.value = false;
        }

        return {
            checkPasswordApproved,
            loading,
            passcode,
            passcode_error,
            submitPasscode
        };
    },
});
</script>
<style lang="scss">
        
</style>
