<template>
    <div>
        <div class="px-2 py-5 scenario-share">
            <h1 class="mt-5">Preparing visit feedback...</h1>
            <h3>Please stay in Zoom Session.</h3>
            <h4>Thank you.</h4>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from "vue";
    export default defineComponent({
        setup(){
            //@ts-ignore
            document.getElementsByTagName('title')[0].innerHTML = 'Zoom Message';

            return {

            }
        }
    })
</script>

<style scoped lang="scss">
    .scenario-share {
        background: #fff;
        height: 100%;
        width: 100%;
        position: absolute;
        text-align: center;
    }
</style>
