import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "user-dashboards",
  class: "card"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_admin = _resolveComponent("admin")!
  const _component_applicant = _resolveComponent("applicant")!
  const _component_authoring = _resolveComponent("authoring")!
  const _component_learner = _resolveComponent("learner")!
  const _component_standardized_patient = _resolveComponent("standardized-patient")!
  const _component_observer = _resolveComponent("observer")!
  const _component_reviewer = _resolveComponent("reviewer")!
  const _component_trainer = _resolveComponent("trainer")!
  const _component_client_admin = _resolveComponent("client-admin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.store.getters.dashboard_view === _ctx.UserRole.ADMIN)
      ? (_openBlock(), _createBlock(_component_admin, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.store.getters.dashboard_view === _ctx.UserRole.ZERO)
      ? (_openBlock(), _createBlock(_component_applicant, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.store.getters.dashboard_view === _ctx.UserRole.AUTHOR)
      ? (_openBlock(), _createBlock(_component_authoring, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.store.getters.dashboard_view === _ctx.UserRole.LEARNER)
      ? (_openBlock(), _createBlock(_component_learner, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.store.getters.dashboard_view === _ctx.UserRole.SP)
      ? (_openBlock(), _createBlock(_component_standardized_patient, { key: 4 }))
      : _createCommentVNode("", true),
    (_ctx.store.getters.dashboard_view === _ctx.UserRole.OBSERVER)
      ? (_openBlock(), _createBlock(_component_observer, { key: 5 }))
      : _createCommentVNode("", true),
    (_ctx.store.getters.dashboard_view === _ctx.UserRole.REVIEWER)
      ? (_openBlock(), _createBlock(_component_reviewer, { key: 6 }))
      : _createCommentVNode("", true),
    (_ctx.store.getters.dashboard_view === _ctx.UserRole.TRAINER)
      ? (_openBlock(), _createBlock(_component_trainer, { key: 7 }))
      : _createCommentVNode("", true),
    (_ctx.store.getters.dashboard_view === _ctx.UserRole.HEADTRAINER)
      ? (_openBlock(), _createBlock(_component_trainer, { key: 8 }))
      : _createCommentVNode("", true),
    (_ctx.store.getters.dashboard_view === _ctx.UserRole.CLIENTADMIN)
      ? (_openBlock(), _createBlock(_component_client_admin, { key: 9 }))
      : _createCommentVNode("", true)
  ]))
}