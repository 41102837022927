<template>
	<div class="row">
		<div class="col-5 media align-items-center">{{ itemName }}</div>

		<div v-if="!edit" class="col-4 media align-items-center"><span v-if="featured == 1">Yes</span><span v-else>No</span></div>
		<div v-else class="col-4">
			<select class="form-control" v-model="featured">
				<option value="0">No</option>
				<option value="1">Yes</option>
			</select>
		</div>

		<div class="col-3 px-0">
			<button @click="edit = true" class="btn btn-link text-primary" v-if="canEdit && !edit"><i class="fa fa-edit"></i> </button> <button @click="update(item.id)" class="btn btn-link text-primary" v-if="canEdit && edit"><i class="fas fa-save"></i> </button> <a v-if="canEdit && !edit" href="javascript:void(0)" @click="remove(item.partner_id)" class="text-danger"><i class="fas fa-times"></i></a>
		</div>
	</div>
    <div class="row my-1">
        <div v-if="!edit" class="col-12">
            Job# {{item.job_number}}
        </div>
        <div v-else class="col-12">
            Job# <input v-model="job_number" maxlength="255" class="job_number_edit"/>
            <div v-if="job_number_error" class="alert alert-danger mt-3">{{job_number_error}}</div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr />
        </div>
    </div>
</template>


<script lang="ts">
	import {defineComponent, ref} from "vue";
	import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
	import {MutationTypes} from "@/store/modules/ux/mutations";
	import {store} from "@/store";
	import Scenario from "../../models/scenario";
	import Swal from 'sweetalert2';
	export default defineComponent({
		name: "scenario-partner-distribution-field",
		props: {
			canEdit: {type:Boolean,required:true},
			item: {type:Object, required:true},
			scenario:{type:Scenario,required:true},
		},
		emits:['updateFeatured', 'deletePartner'],
		setup(props,{emit}) {
			const edit = ref(false);
			const featured = ref(props.item.featured);
            const job_number = ref(props.item.job_number);
            const job_number_error = ref<any>(false);
			const selectedPartners = ref(props.scenario.data?.partners);
			const itemName = ref(props.item.partners[0].short_name);

			const remove = async (partnerId:number) =>{
				Swal.fire({
					title: 'Are you sure?',
					text: 'Delete this Distribution Partner.',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					cancelButtonText: 'No'
				}).then(async (result) => {
					if (result.value) {
						await Scenario.removePartner(props.scenario.data?.id,partnerId).then(()=>{
							selectedPartners.value?.splice(selectedPartners.value?.findIndex((i)=>i.id === partnerId),1);
							store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Partner Removed!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
						});
						Swal.close();
					} else if (result.dismiss ===  Swal.DismissReason.cancel) {
						Swal.close();
					}
					emit('deletePartner',{scenarioPartnerId:partnerId});
				})
			}

			const update = async (scenarioPartnerId:number) =>{
				if(job_number.value.replace(/\s/g, "") == ""){
					job_number_error.value = 'You must provied a job number.';
				}else{
					emit('updateFeatured',{scenarioPartnerId:scenarioPartnerId,featured:featured.value,job_number:job_number.value.toUpperCase()});
					job_number_error.value = false;
					edit.value = false;
				}
			}

			return {
				edit,
				itemName,
				remove,
				featured,
                job_number,
                job_number_error,
				update
			}
		}
	});
</script>

<style lang="scss">
    .job_number_edit{
        text-transform: uppercase;
    }
</style>
