<template>
    <div class="row">
        <div class="col-12" v-for="(question_set,key) in questions" :key="key">
            <h3 v-if="question_set.name !== null" v-html="question_set.name" class="col-12 text-center"></h3>
            <div v-if="question_set.instructions !== null" v-html="question_set.instructions" class="col-12 px-5 pt-1 pb-4 text-center font-weight-bold"></div>
            <div class="card-columns px-3">
                <div v-for="(question,k) in question_set" :key="k">
                    <div v-if="k !== 'instructions' && k !== 'name'" class="card">
                        <div class="card-header bg-secondary text-white font-weight-bold">Question {{parseInt(k) + 1}}</div>
                        <div class="card-body">
                            <span v-html="question.question"></span>
                            <hr>
                            <div v-if="question.question_type_id == QuestionType.MULTIPLECHOICE || question.question_type_id == QuestionType.TRUEFALSE">
                                <span class="text-primary" v-html='visit_form.responses.filter(response => response.question_id === question.id)[0].answer.answer'></span>
                            </div>
                            <div v-if="question.question_type_id == QuestionType.LONGFILL">
                                <span class="text-primary" v-html="visit_form.responses.filter(response => response.question_id === question.id)[0].response"></span>
                            </div>
                            <div v-if="question.question_type_id == QuestionType.LIKERT">
                                <span class="text-primary">{{visit_form.responses.filter(response => response.question_id === question.id)[0].answer.position}} ({{visit_form.responses.filter(response => response.question_id === question.id)[0].answer.answer}})</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="w-75" />
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent, ref } from "vue";
    import {QuestionType} from "@/enums/question-type";

    export default defineComponent({
        props: {
            form: {type:Object, required: true},
            visit_form: {type:Object, required: true}
        },
        mounted() {
            this.formatQuestions();
        },
        setup(props) {
            const questions = ref<Array<any>>([]);
            const formatQuestions = async() => {
                let index = 0;
                let q_index = 0;
                props.form.questions.forEach((elm:any) => {
                    if(elm.question !== null){
                        if(typeof(questions.value[index]) == 'undefined'){
                            questions.value[index] = {};
                        }
                        questions.value[index][q_index] = elm.question;
                        q_index++;
                    }else if(elm.rubric !== null){
                        if(typeof(questions.value[index]) !== 'undefined'){
                            index++;
                        }
                        questions.value[index] = {};
                        q_index = 0;
                        questions.value[index]['instructions'] = elm.rubric.learner_instructions;
                        questions.value[index]['name'] = elm.rubric.name;
                        elm.rubric.questions.forEach((rubq:any) => {
                            questions.value[index][q_index] = rubq;
                            q_index++;
                        });
                        q_index = 0;
                        index++;
                    }
                });
            }
            return {
                formatQuestions,
                questions,
                QuestionType
            };
        },
    });
</script>
<style scoped lang="scss">
    .card-columns {
        column-count: 2;
        .card{
            display: inline-block;
            textarea{
                height: 90px;
                resize: none;
                border-color: #6E6E70;
                &:focus {
                    outline: none !important;
                    border: 2px solid #6BBAA3;
                }
            }
        }
    }
    .top-header{
        background-color: #6E6E70;
    }
    .btn-group{
        height: 2rem;
        border: 1px solid #6E6E70;
        border-radius: .5rem;
        .btn{
            color: #6E6E70;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &:hover{
                background-color: #6BBAA3 !important;
                color: #fff !important;
            }
        }
        .active{
            background-color: #6BBAA3 !important;
            color: #fff !important;
        }
    }
    .radio-custom {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 6px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default radio button */
    .radio-custom input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 1.2rem;
        width: 1.2rem;
        background-color: rgba(24, 28, 33, 0.06);
        border-radius: 50%;
        border: 1px solid #6E6E70;
        margin-top: 3px;
    }

    /* On mouse-over, add a grey background color */
    .radio-custom:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .radio-custom input:checked ~ .checkmark {
        background-color: rgba(24, 28, 33, 0.06);
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .radio-custom input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .radio-custom .checkmark:after {
        top: 4px;
        left: 4px;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #6BBAA3;
    }
</style>
