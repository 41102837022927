<template>
    <div v-if="playlist.data.scenarios.length > 0" class="list-group">
        <VueDraggableNext v-if="userOwns"  v-model="scenarios" @change="reorder">

                <div v-for="(item,index) in scenarios" v-bind:key="index" class="list-group-item d-flex justify-content-between align-items-center">
                    <div class="d-flex justify-content-start align-items-center">
                        <i class="fa fa-bars cursor-move"></i>
                        <user-image :source="item.patient_image" class="ml-4" />
                        <div class="ml-4 line-height-1"><span class="text-primary">{{item.scenario.title}}</span><br /><small>{{item.scenario.patient_name}}</small></div>
                    </div>
                    <div class="text-primary">{{status(item.status)}}</div>
                    <div>
                        <button @click="view(item)" class="btn btn-sm btn-secondary mr-2"><i class="fa fa-eye"></i> </button>
                        <button @click="remove(item.id,index)" class="btn btn-sm btn-danger"><i class="fa fa-trash"></i> </button>
                    </div>

                </div>
        </VueDraggableNext>
        <div v-else v-for="(item,index) in scenarios" v-bind:key="index" class="list-group-item d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-start align-items-center">
                <user-image :source="item.patient_image" />
                <div class="ml-4 line-height-1"><span class="text-primary">{{item.scenario.title}}</span><br /><small>{{item.scenario.patient_name}}</small></div>
            </div>
            <div class="text-primary" v-if="userOwns">{{status(item.status)}}</div>
            <div>
                <button @click="view(item)" class="btn btn-sm btn-secondary mr-2"><i class="fa fa-eye"></i> </button>
                <button @click="remove(item.id,index)"  v-if="userOwns" class="btn btn-sm btn-danger"><i class="fa fa-trash"></i> </button>
            </div>

        </div>
    </div>

    <div v-else class="text-center">You have no scenarios in this list</div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref} from "vue";
    import {store} from "@/store";
    import Playlist from "@/models/playlist";
    import UserImage from "@/components/user-image.vue";
    import {VueDraggableNext} from "vue-draggable-next";
    import router from '@/router';
    import {IPlaylistScenario} from "@/interfaces/playlist-scenario";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    import {ScenarioStatus} from "@/enums/scenario-status";

    export default defineComponent({
        name: "play-list",
        components: {UserImage,VueDraggableNext},
        props:{
            playlist: {type:Playlist,required:true},
            public:{type:Boolean,default:false},
        },
        setup(props){
            const scenarios = ref(props.playlist.data?.scenarios);
            const userOwns = computed(()=>{
                if(props.public) return false;
                return store.getters.user.props.id === props.playlist.data?.user_id;
            })
            const status = (status:number)=>{
                switch (status){
                    case 1:
                        return 'SCHEDULED';
                    case 2:
                        return 'COMPLETED';
                    case 3:
                        return 'PENDING';
                    default:
                        return 'NOT SCHEDULED'
                }
            }

            const reorder = ()=>{
                let newOrder:Array<Number> = [];
                scenarios.value!.forEach((value:IPlaylistScenario) => {
                    newOrder.push(value.id);
                });
                Playlist.reorder(props.playlist.data?.id,{order:newOrder}).catch(
                    ()=>store.commit(MutationTypes.addSystemNotification,{title:'Error',content:'There was an error reordering the questions.',icon:NotificationIcon.WARNING,color:NotificationColor.YELLOW})
                );
            }
            const remove = async (id:number,index:number)=>{
                await Playlist.removeItem(props.playlist.data?.id!,id).then(()=>{
                    scenarios.value?.splice(index,1);
                });
            }

            const view = (scenario:IPlaylistScenario)=>{
                if(scenario.status !== ScenarioStatus.DRAFT){
                    router.push({name:'interview',params:{interview_id:scenario.visit_id!}})
                }else{
                    router.push({name:'scenario.overview',params:{id:scenario.scenario.id!}})
                }
            }
            return{
                reorder,
                remove,
                scenarios,
                status,
                userOwns,
                view,
            }
        }
    });
</script>

<style scoped>

</style>
