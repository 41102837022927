<template>
    <div class="visit-pagination text-center">
        <button class="btn btn-link nav-arrows mr-4" :disabled="props.page_start == 0" @click="previousPage(-10)"><i class="fas fa-angle-left"></i></button>
        <span>{{page_start + 1}} - {{page_end}}</span>
        <button class="btn btn-link nav-arrows ml-4" :disabled="props.page_end >= props.total" @click="nextPage(10)"><i class="fas fa-angle-right"></i></button>
        <div class="col-12 pagination-text">
            <i class="text-muted">Total of {{props.total}} {{props.label}}</i>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";

    export default defineComponent({
        emits: ['next-page','previous-page'],
        props: {
            total: {type:Number, required:true},
            page_start: {type:Number, default:0},
            page_end: {type:Number, default:10},
            label: {type:String, required:true}
        },
        setup(props, {emit}) {
            const nextPage = async() => {
                emit('next-page');
            };
            const previousPage = async() => {
                emit('previous-page');
            };
            return {
                nextPage,
                props,
                previousPage
            };
        }
    });
</script>
