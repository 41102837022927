<template>
    <div v-if="!updateMobile" class="card p-4 text-center">
        <p>You must verify your mobile number to proceed.</p>
        <p class="mb-0">A verification code has been sent to <strong>{{mobileNumber}}</strong>.</p>
        <p class="text-muted">
            <small>Wrong number? <button type="button" class="btn btn-link p-0" @click="editMobile">Edit Mobile Number</button></small>
        </p>
        <div class="form-group">
            <label for='verification-code_1'>Enter one-time code</label>
            <br />
            <div class="row pb-3">
                <div class="col-md-4 offset-md-4">
                    <input class="form-control text-center ver-code-input" type='text' maxlength="6" name='ver_code' v-model="code" />
                </div>
            </div>
            <div>
                <button type="button" class="btn btn-primary btn-sm" @click="verifyMobile" :disabled="code.length !== 6">Submit Code <i class="fas fa-arrow-circle-right"></i></button>
            </div>
        </div>
        <p class="text-muted">
            <small>Didn't receive a code? <button type="button" class="btn btn-link p-0" @click="generateCode">Send New Code</button></small>
        </p>
    </div>
    <div v-if="updateMobile" class="card p-4 text-center">
        <p>Enter your mobile number below.</p>
        <p class="text-muted">A new verification code will be sent via SMS to the number you enter.</p>
        <div class="row mb-3">
            <div class="col-sm-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                <vue-tel-input v-model="mobileInput" mode="international" :preferred-countries="['US','CA','MX']" @validate="updateValidation"></vue-tel-input>
                <div v-if="!validNumber" class="alert alert-danger p-1">Invalid Number</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button type="button" class="btn btn-danger btn-sm" @click="editMobile">Cancel <i class="fas fa-times"></i></button>
                <button type="button" class="btn btn-primary btn-sm ml-2" @click="submitMobile" :disabled="!validNumber">Submit <i class="fas fa-arrow-circle-right"></i></button>
            </div>
        </div>
    </div>
    <loading :active="loading"></loading>

</template>
<script lang="ts">
    import { api } from "@/store";
    import { defineComponent, reactive, ref } from "vue";
    import Loading from 'vue3-loading-overlay';
    import { store } from '@/store';
    import { VueTelInput } from 'vue3-tel-input';
    import 'vue3-tel-input/dist/vue3-tel-input.css';
    import {FormConfig} from "@/interfaces/form-config";
    import Form from "@/utilities/form";
    import {NotificationIcon, NotificationColor} from "@/interfaces/notification";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import moment from 'moment';
    import router from "@/router";

    export default defineComponent({
        components:{Loading, VueTelInput},
        mounted(){
            if(store.state.auth.user?.props.mobile_verified_at !== null && moment().diff(store.getters.user.props.mobile_verified_at,'days') <= 90){
                if('sid' in router.currentRoute.value.query){
                    router.push({ path: '/scenarios/' + router.currentRoute.value.query.sid + '/overview' });
                }else{
                    router.push({name:'dashboard'});
                }
                store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Mobile Number already Verified',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
            }else{
                this.generateCode();
            }
        },
        setup() {
            const loading = ref<boolean>(false);
            const mobileInput = ref(null);
            const updateMobile = ref<boolean>(false);
            const editMobile = () => updateMobile.value = !updateMobile.value;
            const validNumber = ref<boolean>(false);
            const mobileNumber = ref<any>(store.state.auth.user?.props.mobile_number);
            const code = ref('');
            const mobileUpdateForm:FormConfig = reactive({
                mobile_input:{
                    value:'',
                    valid:false,
                    validationMessage:''
                }
            });
            const mobileUpdateFormHelper = new Form(mobileUpdateForm);
            const verificationForm:FormConfig = reactive({
                ver_code:{
                    value:'',
                    valid:false,
                    validationMessage:''
                }
            });
            const verificationFormHelper = new Form(verificationForm);
            const updateValidation = (phoneObject: any) => {
                mobileUpdateForm.mobile_input.value = phoneObject.number;
                if(typeof(phoneObject.valid) == 'undefined'){
                    validNumber.value = false;
                    mobileUpdateForm.mobile_input.valid = false;
                }else{
                    validNumber.value = phoneObject.valid;
                    mobileUpdateForm.mobile_input.valid = phoneObject.valid;
                }
            };
            const submitMobile = async() => {
                loading.value = true;
                await api.get('sanctum/csrf-cookie');
                api.post('api/mobile/update',mobileUpdateFormHelper.mapValues()).then((res)=>{
                    updateMobile.value = false;
                    loading.value = false;
                    mobileNumber.value = mobileUpdateForm.mobile_input.value;
                    code.value = '';
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:res.data.message,icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                },(err)=>{
                    console.log(err);
                    loading.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Failure.',content:err.response.data.message,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
            }
            const verifyMobile = async() => {
                loading.value = true;
                verificationForm.ver_code.value = code.value;
                verificationForm.ver_code.valid = code.value.length === 6;
                await api.get('sanctum/csrf-cookie');
                api.post('api/mobile/verify',verificationFormHelper.mapValues()).then((res)=>{
                    loading.value = false;
                    if('sid' in router.currentRoute.value.query){
                        store.state.auth.user?.setField('mobile_verified_at',res.data.mobile_verified_at)
                        router.push({ path: '/scenarios/' + router.currentRoute.value.query.sid + '/overview', query: { schedule: 1 } });
                    }else{
                        router.push({ name:'dashboard' });
                    }
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:res.data.message,icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                },(err)=>{
                    console.log(err);
                    loading.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Failure.',content:err.response.data.message,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
            }
            const generateCode = async() => {
                loading.value = true;
                await api.get('sanctum/csrf-cookie');
                api.post('api/mobile/code').then((res)=>{
                    loading.value = false;
                    code.value = '';
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:res.data.message,icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                },(err)=>{
                    console.log(err);
                    loading.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Failure.',content:err.response.data.message,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
            }
            return {
                mobileNumber,
                loading,
                mobileInput,
                updateMobile,
                editMobile,
                validNumber,
                updateValidation,
                submitMobile,
                verifyMobile,
                generateCode,
                code
            };
        },
    });
</script>
<style lang="scss">

</style>
