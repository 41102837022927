<template>
    <div class="sp_terms">
        <h4><strong><u>Privacy Policy</u></strong></h4>
        <div class="entry-content"><p>HealthCourse (“HealthCourse”, “we”or “us”) provides web-based online continuing medical education courses, tracking and analytic tools (the “HealthCourse Technology Solution”or, the “Service”). The privacy policy below outlines the types of information we collect and receive from you while you visit this website and access the Service, how HealthCourse uses that information as well as ways in which HealthCourse shares such information and how you can correct and/or update the information gathered. This privacy policy applies to Healthcourse.com and HealthCourse’s collection and use of information gathered through your access of the Service. This policy does not apply to other websites, mobile or wireless platforms to which HealthCourse or the Service may link or to co-branded versions of the Service accessed through third party websites or mobile or wireless platforms, unless so indicated. This Privacy Policy does not apply to any form of content, business information, ideas, concepts or inventions you may communicate to HealthCourse or provide through social networking functionality or public forums which may be offered as part of the Service.</p>
<p>PLEASE REVIEW THIS PRIVACY POLICY AND THE REALCME TERMS OF SERVICE LOCATED AT&nbsp;<a title="RealCME Terms of Service" href="http://realcme.com/tos.php">WWW.HEALTHCOURSE.COM</a>&nbsp;CAREFULLY. BY USING THE SERVICE OR VISITING THIS SITE YOU SIGNIFY YOUR AGREEMENT TO THIS PRIVACY POLICY AND THE REALCME TERMS OF SERVICE. IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY OR THE REALCME TERMS OF SERVICE, PLEASE DO NOT USE THE REALCME SERVICE AND REFRAIN FROM VISITING THIS SITE.</p>
<p>HOW HEALTHCOURSE COLLECTS INFORMATION.</p>
<p>Information you provide to HealthCourse: Generally, the nature of the Service HealthCourse provides to you requires that we ask you to register for the Service and that we collect and use the information as set forth in this Privacy Policy. During the registration process and thereafter when you update your account, we ask you for personally identifiable information, including but not limited to, your first and last name, email address, date of birth, area of specialty, the school from which you received your medical degree, date of graduation and country of practice. You are responsible for ensuring the accuracy of the Personally Identifiable Information you submit to HealthCourse. Inaccurate information will affect the information you receive when using the Service as well as the results and information generated on your behalf from your access of the Service. There may be other instances when you are asked to provide information, such as when you participate in a survey. In these instances the information collected, its purpose and how/by whom it will be used will be explained to you at the point of collection.</p>
<p>While using certain tools and interactive features of the Service you may provide both personally identifiable and non personally identifiable information to HealthCourse. The HealthCourse Service provides tools designed to compile scores generated from users accessing the HealthCourse Learning Management System. These tools allow the user to use, manage, and review their account and scores collected throughout the Service.</p>
<p>The Service may also contain blogs, social networking functionalities and public forums offering users the opportunity to share comments and exchange communications with other users of the Service. Any information you provide through any public forum offered through the Service, including but not limited to personally identifiable information, is open to the pubic, and as such, HealthCourse does not control its use or dissemination by any third parties. HealthCourse has no ability or obligation to prevent third-party access to such information and RealCME undertakes no responsibility to keep such information private. HealthCourse may decide to utilize user information provided to public forums or social networking functionality of the Service to enhance the Services, and to promote the use of the Services to third parties.</p>
<p>Information Collected by HealthCourse: When you visit the HealthCourse site and when you access the Service, (regardless of what website you gained access from), HealthCourse together with third party websites offering the Service and third party vendors with whom HealthCourse has contracted to provide services, collect information on your actions. Cookies are small pieces of information that are stored by your browser on your computer’s hard drive. HealthCourseuses cookies to help us provide you with a more personal experience and allow HealthCourse (or its third party vendor ) to collect non personally identifiable information about your use of the HealthCourse Service. This non personally identifiable information is anonymous and does not contain any personal information about you. Most browsers can be set to reject all Cookies. If you choose to reject cookies you will not be unable to use the Services. As is true of many sites, HealthCourse gathers certain information automatically and stores it in its log files. This information may include IP address, browser type, ISP provider, date/time stamp. HealthCourse uses this information to analyze trends, administer the website track users’ movements and gather demographic information about our user base as a whole.</p>
<p>HealthCourse may also supplement your data with information from third parties, including CME providers and accrediting agencies.</p>
<p>HOW HealthCourse USES THE INFORMATION IT RECEIVES AND COLLECTS.</p>
<p>HealthCourse uses the information you provide for several purpose, such as communicating with you about the Service and about matters pertaining to your account and your access to the Service, as well as to provide corrections and updates to your account and provide you with information regarding the HealthCourse Site. Additionally, the information collected by HealthCourse is used to create aggregate portraits of the users who access the Service which consist of anonymous statistical demographic information. These aggregated portraits are used to determine trends, evaluate course offerings, general audience performance and scores and needs and may be shared with third parties, accredited CME providers, and third party websites and mobile or wireless providers offering the Service, in order to provide these third parties with information on who is accessing the Service and what course materials are being utilized, scores generated and other course participation information.</p>
<p>Disclosure of Information to Third Parties. HealthCourse may release personally identifiable information to third parties as set forth in this privacy policy, and (i) when disclosure is required by applicable law or pursuant to a court or similar order; (ii) in the event of a sale, merger, assignment, joint venture, or other transaction or disposition of a portion or all of the assets or stock of HealthCourse, or its affiliated entitled (including, without limitation, in connection with any bankruptcy or similar proceedings); (iii) when HealthCourse believes that such disclosure is necessary to protect property, defend or assert legal rights or to prevent personal injury; (iv) to any or all of its subsidiaries and affiliated entities .</p>
<p>Disclosure to Third party CME Providers, Service Providers and RealCME Personnel. Personally identifiable information will be shared with third parties, such as accrediting bodies, HealthCourse customer service, technology and editorial staff, technical consultants, third party auditors and other third parties who create, maintain or enhance the Service or provide associated services may also have access to your personally identifiable information.</p>
<p>Disclosure To or By Co-Branded Third Party Sites, Mobile and Wireless Platforms.HealthCourse operates a Service available to physicians and health processionals through HealthCourse.com and through a distribution network of third party websites, mobile and wireless platforms, each of which provide access to the Service through a third party environment which may be either cobranded, or include a “Powered by HealthCourse”designation . When you register and/or access the Service through a co-branded third party site, mobile or wireless platform, in addition to HealthCourse’s collection and use of such information being governed by this privacy policy, the aggregated and/or personally identifiable information collected from your access to such co-branded or powered by versions of the Service will be shared with such third parties and such third parties’ use of the information collected during your access of the Service will be subject to that third party’s own privacy practices and policies.</p>
<p>LINKS TO OTHER WEBSITES.<br>
There may be links to third party websites operated by entities that are not affiliated with and do not have contracts to provide services to HealthCourse. HealthCourse does not share personally identifiable information with these linked sites. If you link to one of these third party sites please read the privacy policy of this other site to learn how that third party website collects and uses your information. HealthCourse does not control the practices of these third party sites that you may link to from RealCME.com.</p>
<p>CHILDREN’S INFORMATION.<br>
HealthCourse's Site and Service is directed and intended for medical and health professionals and as such HealthCourse does not knowingly collect information from children.</p>
<p>ACCESS AND CORRECTION.<br>
    You may correct, update and or change your contact or other profile information previously provided. If you wish to correct, update or change your information simply update your profile. To update your account log in at&nbsp;<a title="RealCME Login" href="/#/profile/">app.sp-ed.com</a>&nbsp;and access your Profile.</p>
<p>SECURITY.<br>    
HealthCourse uses industry standard tools such as firewalls to ensure that your information is secure and confidential. Even with these efforts no website or online service is 100% secure.</p>
<p>APPLICABLE LAW.<br>
This Privacy Policy is governed by and interpreted in accordance with the laws of the state of New York, without giving effect to its conflict of laws provisions.</p>
<p>NOTICE OF CHANGES.<br>
HealthCourse reserves the right, at any time and without notice, to add to, change, modify or update this privacy policy by posting such change, update or modification on the HealthCourse site or Service. Changes, modifications, or updates will be effective immediately upon posting.</p>
<p>DATA SUBJECT ACCESS REQUEST.<br>
    In compliance with the General Data Protection Regulations (GDPR), HealthCourse, Inc. will comply with any Data Subject Access Requests (DSAR) concerning the following rights of the data subject:<br>
  <ul>
    <li>Access (a copy of the personal data undergoing processing)</li>
    <li>Rectification of personal data (correction of data stored or processed)</li>
    <li>Erasure ('right to be forgotten')</li>
    <li>Restriction of processing</li>
    <li>Notification regarding rectification or erasure</li>
    <li>Data portability (In the event of a Data Portability Request, HealthCourse, Inc. will export the customers data in an industry standard format and make it internet accessible for download only by the data subject)</li>
    <li>Objection to processing (withdrawal of consent to processing)</li>
    <li>Automated individual decision-making, including profiling</li>
  </ul>
    <br>
      To submit a DSAR, please submit a copy of the REQUEST FOR IMPLEMENTATION OF RIGHTS form and submit electronically via email to <a class="sc-1ko78hw-0 fiVZLH" title="mailto:privacy@healthcourse.com" href="mailto:privacy@healthcourse.com" data-renderer-mark="true">privacy@healthcourse.com</a> along with proper identification. Additionally, a DSAR can be submitted in written form by mail, or verbally requested by telephone. The REQUEST FOR IMPLEMENTATION OF RIGHTS form can be found here:&nbsp;<a href="https://www.healthcourse.com/wp-content/uploads/2022/03/Data-Access-Request-Form.pdf" target="_blank" rel="noopener noreferrer">Right To Erasure Request</a>
      <br><br>
      We have appointed EU and UK Representatives under Article 27 of the EU GDPR and UK GDPR respectively. Our appointed representatives are:
</p>
<p>
    <strong>Our EU Representative:</strong><br>
      Instant EU GDPR Representative Ltd.<br>
      Adam Brogden contact@gdprlocal.com<br>
      Tel +35315549700<br>
      INSTANT EU GDPR REPRESENTATIVE LTD<br>
      Office 2,<br>
      12A Lower Main Street, Lucan Co. Dublin<br>
      K78 X5P8<br>
      Ireland
      <br><br>
      <strong>Our UK Representative:</strong><br>
      GDPR Local Ltd.<br>
      Adam Brogden contact@gdprlocal.com<br>
      Tel +44 1772 217800<br>
      1st Floor Front Suite<br>
      27-29 North Street, Brighton<br>
      England<br>
      BN1 1EB
</p>
<div id="gdprVerifier"><img src="/img/gdpr-certified.png"></div>
<p>HealthCourse will only use personal data under appropriate legal bases, including legitimate basis and consent basis.</p>
<p>Legitimate basis allows for HealthCourse to provide the best experience possible for the end user. HealthCourse will consider any potential risks to the rights of end users before processing data on a legitimate basis.</p>

<p>Consent basis allows HealthCourse to engage end users with our platforms. End users are given the opportunity to opt out of any data processing that occurs on a consent basis at any time.
</p>
<p style="clear: both;">CONTACT INFORMATION.<br>
    Questions regarding this Privacy Policy should be referred to:<br>
    HealthCourse, Inc<br>
    545 West 45th Street, 8th Floor<br>
    New York, NY 10036<br />
    <a href="mailto:privacy@healthcourse.com">privacy@healthcourse.com</a></p>
</div>
    </div>
</template>

<script lang="ts">
    import { defineComponent} from "vue";

    export default defineComponent({
        setup(){
            return{

            }
        }
    });
</script>

<style scoped>
    .sp_terms {
        border: 1px solid #ececec;
        border-radius: 5px;
        padding: 5px 20px 10px;
        background-color: #fff;
    }
    #gdprVerifier {
        padding-right: 20px;
        float:left;
    }
</style>
