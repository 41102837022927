import {api} from "@/store";
import {IScorecard} from "@/interfaces/scorecard";

export default class Scorecard{
    constructor(public data?:IScorecard) {}

    static async find(scenario_id:Number):Promise<any>{
        return api.get('api/scorecard/find/'+scenario_id);
    }

    static async addQuestion(id:Number|undefined,form:any):Promise<any>{
        return api.post('api/scorecard/'+id+'/add-question',form);
    }

    static async editQuestion(id:Number|undefined, form:any):Promise<any>{
        return api.post('api/scorecard/'+id+'/edit-question', form);
    }

    static async enable(scenario_id:Number | undefined):Promise<any>{
        return api.post('api/scorecard/' + scenario_id + '/enable');
    }

    static async getVisitScorecard(visit_id:string|string[],form:any):Promise<any>{
        return api.post('api/visit/' + visit_id + '/scorecard',form);
    }

    static async removeQuestion(scorecard_id:Number|undefined, question_id:Number):Promise<any>{
        return  api.post('api/scorecard/'+scorecard_id+'/remove-question/'+question_id);
    }

    static async removeRubric(scorecard_id:Number|undefined, scorecard_question_id:Number):Promise<any>{
        return  api.post('api/scorecard/'+scorecard_id+'/remove-rubric/'+scorecard_question_id);
    }

    static async reorder(id:Number|undefined,form:any):Promise<any>{
        return  api.post('api/scorecard/'+id+'/reorder',form);
    }

    static async submitVisitScorecard(visit_id:string|string[],form:any):Promise<any>{
        return api.post('api/visit/' + visit_id + '/scorecard/submit',form);
    }

    static async saveInstructions(scorecard_id:Number|undefined,instructions:String):Promise<any>{
        return api.post('api/scorecard/' + scorecard_id + '/instructions',{instructions:instructions});
    }
}
