<template>
  <div>
    <h1>Error: {{code}} - {{data.get(code).title}}</h1>
    <p>{{data.get(code).content}}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from 'vue-router';
export default defineComponent({
    name:'Error',
    setup(){
        const router = useRouter();
        const code:String = router.currentRoute.value.params.code.toString();
        const data:Map<String,any> =  new Map([
            ['403',{title:'Access Denied',content:'You do not have permission to access this content'}]
        ]);
        return {
            code,
            data,
        }
    }
});
</script>
