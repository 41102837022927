 /* eslint-disable */
import { ActionContext, ActionTree } from "vuex";
import { Mutations } from "./mutations";
import { State } from "./index";
import { RootState } from "@/store";

export enum ActionTypes {}

// !!! AUGUMENTED ACTION CONTEXT !!!
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;


export interface Actions {}


export const actions: ActionTree<State, RootState> & Actions = {};