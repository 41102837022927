<template>
    <div v-if="!loading" class="card">
        <div id="scenario-dashboard" class="card-body">
            <tabs :options="{ useUrlFragment: false }" nav-class="nav nav-pills" nav-item-class="nav-item col-md-4 col-sm-6 col-xs-12 text-center mb-4" nav-item-link-class="nav-link btn-dashboard-sub-nav" nav-item-link-active-class="active">
                <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-edit text-primary'></i></h2>" name="Rubric Drafts">
                    <hr class="w-75 mx-auto" />
                    <div class="col-12 scenario-list-header">Rubric Drafts</div>
                    <div v-if="Object.keys(draft_rubrics).length > 0" class="row mx-auto my-3">
                        <rubric-list-item v-for="rubric in draft_rubrics.slice(draft_page_start,draft_page_end)" v-bind:key="rubric.id" :rubric="rubric" />
                        <pagination class="col-12" v-if="Object.keys(draft_rubrics).length > 10" :items="draft_rubrics" :page_start="draft_page_start" :page_end="draft_page_end" v-on:next-page="nextPage('draft')" v-on:previous-page="previousPage('draft')" :label="'Rubrics'" />
                    </div>
                    <div v-else class="row mx-auto my-3 justify-content-center">
                        <p>There are currently no rubric drafts.</p>
                    </div>
                    <div class="row justify-content-center">
                        <button class="btn btn-primary" @click="create" :disabled="loading"><i class="fa fa-plus-circle"></i> <span class="d-none d-md-inline">CREATE A NEW RUBRIC</span></button>
                    </div>
                </tab>
                <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-clipboard-list text-primary'></i></h2>" name="Published Rubrics">
                    <hr class="w-75 mx-auto" />
                    <div class="col-12 scenario-list-header">Published Rubrics</div>
                    <div v-if="Object.keys(published_rubrics).length > 0" class="row mx-auto my-3">
                        <rubric-list-item v-for="rubric in published_rubrics.slice(published_page_start,published_page_end)" v-bind:key="rubric.id" :rubric="rubric" />
                        <pagination class="col-12" v-if="Object.keys(published_rubrics).length > 10" :items="published_rubrics" :page_start="published_page_start" :page_end="published_page_end" v-on:next-page="nextPage('published')" v-on:previous-page="previousPage('published')" :label="'Rubrics'" />
                    </div>
                    <div v-else class="row mx-auto my-3 justify-content-center">
                        <p>There are currently no published rubrics.</p>
                    </div>
                    <div class="row justify-content-center">
                        <button class="btn btn-primary" @click="create" :disabled="loading"><i class="fa fa-plus-circle"></i> <span class="d-none d-md-inline">CREATE A NEW RUBRIC</span></button>
                    </div>
                </tab>
            </tabs>
        </div>
    </div>
  <Loading :active="loading" :is-full-page="true"></Loading>
</template>
<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Loading from "vue3-loading-overlay";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationIcon, NotificationColor} from "@/interfaces/notification";
    import Pagination from '@/components/dashboard/pagination.vue';
    import router from '@/router'
    import {Rubric} from "@/models/rubric";
    import RubricListItem from "@/components/rubrics/rubric-list-item.vue";
    import {store} from "@/store";
    import {Tabs, Tab} from 'vue3-tabs-component';

    export default defineComponent({
        name: "rubrics",
        components: {
            Loading,
            Pagination,
            RubricListItem,
            Tab,
            Tabs
        },
        mounted(){
            this.getRubrics();
        },
        setup(){
            const loading = ref(false);
            const draft_rubrics = ref<any>([]);
            const published_rubrics = ref<any>([]);
            const draft_page_start = ref(0);
            const draft_page_end = ref(10);
            const published_page_start = ref(0);
            const published_page_end = ref(10);

            const create = async() => {
                loading.value = true;
                await Rubric.createRubric().then((res)=>{
                    if(res.data.rubric_id !== null){
                        router.push({name:'rubric.show',params:{id:res.data.rubric_id}});
                        store.commit(MutationTypes.addSystemNotification, {title:'Rubric created.',content:'',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    }else{
                        store.commit(MutationTypes.addSystemNotification, {title:'Error creating rubric.',content:'',icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                    }
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error creating rubric.',content:err.response.statusText,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const nextPage = async(type:String) => {
                if(type == 'draft'){
                    draft_page_start.value = draft_page_start.value + 10;
                    draft_page_end.value = draft_page_end.value + 10;
                    if(draft_page_end.value > Object.keys(draft_rubrics.value).length){
                        draft_page_end.value = Object.keys(draft_rubrics.value).length;
                    }
                }else if(type == 'published'){
                    published_page_start.value = published_page_start.value + 10;
                    published_page_end.value = published_page_end.value + 10;
                    if(published_page_end.value > Object.keys(published_rubrics.value).length){
                        published_page_end.value = Object.keys(published_rubrics.value).length;
                    }
                }
            };
            const previousPage = async(type:String) => {
                if(type == 'draft'){
                    draft_page_start.value = draft_page_start.value - 10;
                    draft_page_end.value = draft_page_start.value + 10;
                }else if(type == 'published'){
                    published_page_start.value = published_page_start.value - 10;
                    published_page_end.value = published_page_start.value + 10;
                }
            };


            const getRubrics = async() => {
                loading.value = true;
                await Rubric.getRubrics().then((res)=>{
                    draft_rubrics.value = res.data.drafts;
                    published_rubrics.value = res.data.published;
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error loading rubrics.',content:err.response.statusText,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            return {
                create,
                draft_page_end,
                draft_page_start,
                draft_rubrics,
                getRubrics,
                loading,
                nextPage,
                previousPage,
                published_page_end,
                published_page_start,
                published_rubrics,
            }
        }
    });
</script>
