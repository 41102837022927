<template>
    <transition v-if="notification" leave-active-class="animate__animated animate__fadeOutRight" enter-active-class="animate__animated animate__fadeInRight">
        <div role="alert" aria-live="assertive" aria-atomic="true" class="b-toast b-toast-prepend mb-2" :class="fix_alert_ref ? 'fix-alert' : ''">
            <div tabindex="0" class="toast bs4-toast" :class="notification.getColor()">
                <header class="toast-header py-2">
                    <div class="d-flex align-items-center flex-grow-1">
                        <i v-if="notification.data.icon !== NotificationIcon.NONE" :class="notification.getIcon()"  class="mr-2 fa-2x"></i>
                        <div class="font-weight-bold mr-auto">{{notification.data.title}}</div>
                    </div>
                    <button @click="dismiss" type="button" aria-label="Close" class="close ml-auto mb-1 text-white">×</button>
                </header>
                <div class="toast-body py-3" v-html="notification.data.content"></div>
            </div>
        </div>
    </transition>
</template>

<script>
import { MutationTypes } from "@/store/modules/ux/mutations";
import { defineComponent, ref } from "vue";
import { store } from '../store/index';
import Notification from "@/models/notification";
import {NotificationColor, NotificationIcon} from '@/interfaces/notification';
export default defineComponent({
    props:{
        notification:{type:Notification,required:true},
        index:{type:Number, required:true},
        fix_alert:{type:Boolean, default:true}
    },setup(props){
        const dismiss = () => store.commit(MutationTypes.dismissNotification,props.index);
        const fix_alert_ref = ref(props.fix_alert);
        if(!props.notification.data.showDismiss){
            setTimeout(function(){ dismiss() }, 5000);
        }
        return {
            dismiss,
            fix_alert_ref,
            NotificationColor,
            NotificationIcon,
        }
    }
});
</script>

<style scoped lang="scss">
    .toast {
        opacity: 1;
        min-width: 300px;
    }

    .fix-alert{
        position: fixed;
        right: 0;
    }

</style>
