<template>
    <slot name="trigger" :click="open"></slot>
    <modal v-model="modal" allow-close>
        <template v-slot:header>Edit Social Media Post</template>
        <div class="form-group">
            <label>Title</label>
            <input type="text" name="social-title" :class="{'is-invalid':!validation.title}" class="form-control" v-model="title" required maxlength="225">
            <input type="hidden" name="post_id" v-model="id">
            <small v-if="!validation.title" class="invalid-feedback">A title is required</small>
        </div>
        <div class="form-group">
            <label>Description</label>
            <textarea :class="{'is-invalid':!validation.description}" class="form-control" v-model="description" required rows="5"></textarea>
            <small v-if="!validation.description" class="invalid-feedback">A description is required</small>
        </div>
        <div class="form-group">
            <label>Publish On</label>
            <datepicker :class="{'is-invalid':!validation.published_on}" class="form-control" :lower-limit="dateRange" v-model="published_on"></datepicker>
        </div>
        <div class="form-group">
            <label>Current Image</label><br/>
            <img :src="image_path" width="150" alt="Current Image" title="Currnet Social Media Image">
        </div>
        <div class="form-group">
            <label>Update New Image</label> <small><i>Max Size 5MB</i></small><br/>
            <input type="file" ref="imageFile" class="form-control" @change="onAttachment" accept="image/jpeg, image/png">
        </div>
        <template v-slot:footer>
            <div class="d-flex justify-content-end">
                <button @click="modal = !modal" class="btn btn-default mx-2">Cancel</button>
                <button @click="update" class="btn btn-primary">Save</button>
            </div>
        </template>
    </modal>
</template>

<script lang="ts">
    import {defineComponent, reactive, ref} from "vue";
    import Datepicker from 'vue3-datepicker';
    import Modal from "@/components/modal.vue";
    import moment from 'moment';
    import {ISocial} from '@/interfaces/social-media-post';
    import {store} from "@/store";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationIcon,NotificationColor} from "@/interfaces/notification";
    import {SocialMediaPost} from "@/models/social-media-post";

    export default defineComponent({
        name: "EditSocialPost",
        components: {
            Datepicker,
            Modal
        },
        props:{
            postObj:{type:Object, required:true},
        },
        emits:['updatePostData'],
        computed:{
            dateRange(){
                let today = new Date();
                let priorDate = new Date(new Date().setDate(today.getDate() - 30));
                return priorDate;
            }
        },
        setup(props, {emit}){
            let file = ref<File>();
            const modal = ref(false);
            const open = ()=>modal.value = !modal.value;
            const id = ref(props.postObj.id);
            const title = ref(props.postObj.title);
            const description = ref(props.postObj.description);
            const published_on = ref(new Date(props.postObj.published_on));
            const image_path = ref(props.postObj.image_path);
            const validation = reactive({
                'title':true,
                'description':true,
                'published_on':true,
            })
            const onAttachment = async(event:any) => {
                file.value = event.target.files[0];
            }
            const resetForm = (newData:ISocial)=>{
                title.value = newData.title;
                description.value = newData.description;
                published_on.value = new Date(newData.published_on);
                image_path.value = newData.image_path;
            }
            const resetValidation = ()=>{
                validation.title = true;
                validation.description = true;
                validation.published_on = true;
            }

            const update = () =>{
                //reset validation
                resetValidation();

                //check validation
                if(title.value == '') validation.title = false;
                if(description.value == '') validation.description = false;
                if(published_on.value == null) validation.published_on = false;

                if(validation.title && validation.description && validation.published_on){
                    //update
                    let formData = new FormData();
                    formData.append("id", id.value);
                    formData.append("title", title.value);
                    formData.append("description", description.value);
                    formData.append("published_on", moment(published_on.value).format('YYYY-MM-DD HH:mm:ss'));
                    formData.append("file", file.value!);

                    SocialMediaPost.updateSocialMediaPost(formData).then((res)=>{
                        let post:ISocial = res.data;
                        emit('updatePostData',post);
                        modal.value = false;
                        resetForm(post);
                        store.commit(MutationTypes.addSystemNotification,{title:'Success',content:'Social Media Post has been updated',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                    });
                }
            }

            return{
                description,
                id,
                image_path,
                modal,
                moment,
                onAttachment,
                open,
                published_on,
                update,
                SocialMediaPost,
                title,
                validation,
            }
        }
    });
</script>

<style scoped>

</style>
