import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: !$props.source ? '/img/no_image.jpg' : $props.source,
    class: _normalizeClass(["mr-3 rounded-circle", $props.size]),
    alt: "User Icon"
  }, null, 10, _hoisted_1))
}