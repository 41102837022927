import { GetterTree } from "vuex";
import { State } from "./index";
import { RootState } from "@/store";
import Notification from "@/models/notification";
// (D) - Getters
// [D.1] - Define o tipo do getter x
// => x(state: S): TIPO_DE_X;
export type Getters<S = State> = {
  sidnav(state: S): any;
  notifications(state: S): Array<Notification>;
  systemNotifications(state: S): Array<Notification>;
};

// [D.2] - Declara o getter x
// =>  x: (state) => {
// =>    return state.x;
// =>  },
export const getters: GetterTree<State, RootState> & Getters = {
  sidnav: (state) => {
    return state.sideNav;
  },
  notifications: (state) => {
    return state.notifications;
  },
  systemNotifications: (state) => {
    return state.systemNotifications;
  }
};