import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_3 = { class: "countdown-text" }
const _hoisted_4 = {
  key: 1,
  class: "d-inline"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 2 }
const _hoisted_7 = {
  key: 2,
  class: "d-inline"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 3,
  class: "d-inline"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { key: 3 }
const _hoisted_13 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.join_event_obj.is_complete && !_ctx.join_event_obj.is_canceled && !_ctx.join_event_obj.learner_technical_issue)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showCountdown)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.countdownText), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.started)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_ctx.has_join_link)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "btn btn-secondary btn-md w-100 mb-2",
                    href: _ctx.join_link,
                    target: "_blank"
                  }, "Join " + _toDisplayString(_ctx.label_string), 9, _hoisted_5))
                : (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    type: "button",
                    class: "btn btn-secondary btn-md w-100 mb-2",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.joinError && _ctx.joinError(...args)))
                  }, "Join " + _toDisplayString(_ctx.label_string), 1)),
              (_ctx.label == 'Interview')
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                    (_ctx.user.id == _ctx.join_event_obj.trainer_user_id)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          type: "button",
                          class: "btn btn-secondary btn-xs ml-1",
                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.complete && _ctx.complete(...args)))
                        }, "Mark Interview as Complete"))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.label == 'Visit' && _ctx.user.props.id == _ctx.join_event_obj.sp_user_id && _ctx.can_close)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-secondary btn-md w-100 mb-2",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.complete && _ctx.complete(...args)))
              }, [
                (_ctx.join_event_obj.scenario.is_feedback_form_enabled)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Proceed to Scorecard & "))
                  : _createCommentVNode("", true),
                _createTextVNode("Mark as Complete")
              ]),
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-secondary btn-md w-100 mb-2",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.noShow && _ctx.noShow(...args)))
              }, "Mark as No Show"),
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-secondary btn-md w-100 mb-2",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.learnerTechIssue && _ctx.learnerTechIssue(...args)))
              }, "Mark as Learner Technical Issue")
            ]))
          : _createCommentVNode("", true),
        (_ctx.label == 'Training Session' && _ctx.user.props.id == _ctx.join_event_obj.trainer_user_id && _ctx.can_close)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-secondary btn-md w-100 mb-2",
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.complete && _ctx.complete(...args)))
              }, "Mark as Complete")
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_ctx.join_event_obj.learner_technical_issue)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("p", null, _toDisplayString(_ctx.label) + " was marked as a learner technical issue", 1)
        ]))
      : (_ctx.join_event_obj.no_show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("p", null, _toDisplayString(_ctx.label) + " was marked as no show.", 1)
          ]))
        : (_ctx.join_event_obj.is_complete)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("p", null, _toDisplayString(_ctx.label) + " was completed.", 1)
            ]))
          : (_ctx.join_event_obj.is_canceled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("p", null, _toDisplayString(_ctx.label) + " was cancelled.", 1)
              ]))
            : _createCommentVNode("", true)
}