import {api} from "@/store";
import {IPartnerEmails} from "@/interfaces/partner-emails";

export default class PartnerEmails{
    constructor(data?: IPartnerEmails) {}

    static async uploadEmails(form:any):Promise<any>{
        return await api.post('api/partner/email-list',form);
    }

}