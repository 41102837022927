<template>
    <div class="card shadow-sm mb-4">
        <div class="card-header text-white bg-secondary font-weight-bold">{{status}} Participant List</div>
        <div class="card-content">
            <div class="row m-auto" v-if="!loading">
                <div class="col-12 px-5 pt-5">
                    <div v-if="Object.keys(list).length">
                        <div v-for="(partner,key) in list" v-bind:key="key">
                            <h2 class="text-secondary mb-4">{{partner.title}}</h2>
                            <div v-for="(scenario, key) in partner.list" v-bind:key="key" class="mb-5">
                                <h4>{{scenario.title}}</h4>
                                <hr />
                                <div v-if="key !== 'title'">
                                    <div v-for="(participant, key) in scenario.participants" v-bind:key="key" class="mb-2 cursor-pointer text-primary">
                                        <div v-if="key !== 'title'">
                                            <span v-if="status == 'Pending'" @click="router.push({name:'request',params:{request_id:participant.id}})">
                                                <span>
                                                    {{participant.user.name}}
                                                    <br>
                                                    <small class="text-secondary"><em>{{participant.user.email}}</em></small>
                                                </span>
                                                <i class='fas fa-arrow-circle-right text-primary float-right'></i>
                                            </span>
                                            <span v-else @click="router.push({name:'visit',params:{visit_id:participant.id}})">
                                                <div class="row">
                                                    <div class="col-7">
                                                        {{participant.learner.name}}
                                                        <br>
                                                        <small class="text-secondary"><em>{{participant.learner.email}}</em></small>
                                                    </div>
                                                    <div class="col-5">
                                                        <span class="text-secondary">{{ moment(participant.local_time).format('MM/DD/YY') }}</span>
                                                        <br>
                                                        <small class="text-primary">{{ moment(participant.local_time).format('h:mm')}}<sup>{{ moment(participant.local_time).format('a')}}</sup></small>
                                                        <i class='fas fa-arrow-circle-right text-primary float-right'></i>
                                                    </div>
                                                </div>
                                            </span>
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <p class="text-secondary mb-5">No participants at this time.</p>
                    </div>
                </div>
            </div>
            <div class="loading-area" v-else>
                <loading :active="loading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent,ref} from "vue";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationIcon, NotificationColor} from "@/interfaces/notification";
    import {store} from '../../store/index';
    import Loading from 'vue3-loading-overlay';
    import Visit from "@/models/visit";
    import router from "@/router";
    import moment from 'moment';

    export default defineComponent({
        components: {
            Loading
        },
        props: {
            report_data: {type:Object,required:true},
            status: {type:String,required:true}
        },
        watch: {
            report_data: function(newData){
                this.list = newData.list;
                this.scenarios = newData.scenarios;
                this.partners = newData.partners;
                this.loading = false;
            }
        },
        setup(props) {
            const loading = ref<boolean>(true);
            const list = ref({});
            const partners = ref({});
            const scenarios = ref({});
            const partner_id = ref(null);
            const scenario_id = ref(null);

            const getParticipantList = async() => {
                loading.value = true;
                var post_data:any = {
                    partner_id: partner_id.value,
                    scenario_id: scenario_id.value,
                    status: props.status,
                };
                Visit.getParticipantList(post_data).then((res)=>{
                    list.value = res.data.list;
                    scenarios.value = res.data.scenarios;
                    partners.value = res.data.partners;
                    loading.value = false;
                },(err)=>{
                    console.log(err);
                    store.commit(MutationTypes.addSystemNotification, {title:'Error retrieving participant list.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    loading.value = false;
                });
            };
            return {
                getParticipantList,
                partner_id,
                partners,
                scenario_id,
                scenarios,
                list,
                loading,
                moment,
                store,
                router
            };
        }
    });
</script>
<style scoped lang="scss">
    table{
        tr{
            td{
                border: 0;
            }
        }
    }
    .loading-area{
        height: 500px;
    }
</style>
