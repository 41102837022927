<template>
    <div class="card shadow-sm mb-4">
        <div class="card-header text-white bg-secondary font-weight-bold">Scenarios in Draft</div>
        <div class="card-content">
            <div v-if="!visit_loading">
                <div v-if="Object.keys(props.scenario_drafts).length">

                    <div v-for="drafts in props.scenario_drafts" v-bind:key="drafts.id" class="row scenario-card">
                        <div class="col-md-10" @click="router.push({name:'scenario.show',params:{id:drafts.id}})">
                            <img :src="drafts.patient_image.location" class="rounded-circle float-left" width="75" />
                            <div class="font-weight-bold text-secondary">{{drafts.patient_name}}</div>
                            <div class="overflow-hidden">
                                <span>{{drafts.title}}</span>
                            </div>
                        </div>
                        <div class="col-md-2 d-flex flex-column justify-content-center">
                            <h3 class="text-center">
                                <i class="fas fa-drafting-compass text-primary mr-4" @click="router.push({name:'scenario.show',params:{id:drafts.id}})" title="Make Edits"></i>
                                <i @click="showDeleteModal()" class='fas fa-trash text-primary' title="Delete"></i>
                            </h3>
                        </div>
                        <br/>
                        <div class="col-12 thin-line"></div>
                        <modal v-model="deleteModal">
                            <template v-slot:header>Permanently Delete Scenario</template>
                            <p>Are you sure you want to delete this scenario?</p>
                            <button class="btn btn-xs btn-secondary-alt float-right" @click="showDeleteModal()">Cancel</button>
                            <button type="button" class="btn btn-primary btn-xs" @click="deleteScenario(drafts.id)">Permanently Delete</button>
                        </modal>
                    </div>
                    <pagination v-if="Object.keys(props.scenario_drafts).length > 10" :items="props.scenario_drafts" :page_start="page_start" :page_end="page_end" v-on:next-page="nextPage" v-on:previous-page="previousPage" :label="'Scenarios'" />
                </div>
                <p v-else class="text-center pt-3">No Scenarios in Draft</p>
            </div>
            <div class="loading-card-area" v-else>
                <loading :active="visit_loading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Loading from 'vue3-loading-overlay';
    import Modal from "@/components/modal.vue";
    import Pagination from '@/components/dashboard/pagination.vue';
    import router from "@/router";
    import Scenario from "@/models/scenario";

    export default defineComponent({
        components: {
            Loading,
            Modal,
            Pagination
        },
        props: {
            scenario_drafts: {type:Object, required:true},
            visit_loading: {type:Boolean, required:true}
        },
        setup(props) {
            const deleteModal = ref(false);
            const page_start = ref(0);
            const page_end = ref(10);
            const scenario = ref<Scenario | undefined>();

            const nextPage = async() => {
                page_start.value = page_start.value + 10;
                page_end.value = page_end.value + 10;
                if(page_end.value > Object.keys(props.scenario_drafts).length){
                    page_end.value = Object.keys(props.scenario_drafts).length;
                }
            };

            const showDeleteModal = () => deleteModal.value = !deleteModal.value;

            const deleteScenario = async (id:number)=>{
                await Scenario.deleteScenario(id).then((res)=>{
                    scenario.value = new Scenario(res.data);
                })
                showDeleteModal();
                router.go(0);
            }
            return {
                deleteModal,
                deleteScenario,
                nextPage,
                page_end,
                page_start,
                props,
                router,
                scenario,
                showDeleteModal
            };
        }
    });
</script>
