import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row d-flex justify-content-start align-items-center" }
const _hoisted_2 = { class: "col-lg-4 col-md-12 text-muted mb-4" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "col-lg-8 col-md-12 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_my_upload = _resolveComponent("my-upload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("img", {
          src: _ctx.img_url,
          class: "d-block ui-w-80 rounded-circle cursor-pointer",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)))
        }, null, 8, _hoisted_3)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)))
      }, "Update Profile Image")
    ]),
    _createVNode(_component_my_upload, {
      field: "img",
      onCropUploadSuccess: _ctx.cropUploadSuccess,
      onCropUploadFail: _ctx.cropUploadFail,
      modelValue: _ctx.show,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.show) = $event)),
      headers: _ctx.headers,
      width: 300,
      height: 300,
      url: _ctx.baseURL + 'api/auth/user/update-profile-image',
      "img-format": "png",
      "lang-type": "en",
      withCredentials: true
    }, null, 8, ["onCropUploadSuccess", "onCropUploadFail", "modelValue", "headers", "url"])
  ]))
}