<template>
    <slot name="trigger" :click="toggle"></slot>
    <transition enter-active-class="animate__animated animate__fadeInDown animate__faster" leave-active-class="animate__animated animate__fadeOutUp">
        <div v-if="open && !slideOut && !fullScreen" class="modal">
            <div class="modal-dialog" :class="size">
                <div class="modal-content">
                    <div class="modal-header bg-primary" v-if="slots.header || allowClose">
                        <h2 class="modal-title" v-if="slots.header">
                            <slot name="header"></slot>
                        </h2>
                        <button v-if="allowClose" @click="close" type="button" class="close text-white">×</button>
                    </div>
                    <div class="modal-body">
                        <slot></slot>
                    </div>
                    <div class="modal-footer" v-if="slots.footer">
                        <slot name="footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <!-- Slide out -->
    <transition enter-active-class="animate__animated animate__slideInRight animate__faster" leave-active-class="animate__animated animate__slideOutRight">
        <div class="modal modal-slide" id="modals-slide" v-if="open && slideOut" aria-modal="true" role="dialog">
            <div class="modal-dialog bg-white d-flex flex-column justify-content-between">
                <div class="modal-header bg-primary">
                    <div class="modal-content">
                        <slot name="header">

                        </slot>
                        <button type="button" v-if="allowClose" @click="close" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="modal-content">
                        <slot></slot>
                    </div>
                </div>
                <div class="modal-footer" v-if="slots.footer">
                    <div class="modal-content pb-0">
                        <slot name="footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>

  <transition enter-active-class="animate__animated animate__slideInDown animate__faster" leave-active-class="animate__animated animate__slideOutUp animate__faster">
    <div class="modal" id="modals-full-screen" v-if="open && fullScreen" aria-modal="true" role="dialog">
      <button type="button" v-if="allowClose" @click="close" class=" btn btn-primary btn-rounded btn-icon close" data-dismiss="modal" aria-label="Close">×</button>
      <div class="modal-dialog bg-white my-0 d-flex flex-column justify-content-between">
        <slot></slot>
      </div>
    </div>
  </transition>

    <div class="modal-backdrop fade show" v-if="open && !slideOut"></div>
</template>

<script>
import {defineComponent, computed} from 'vue';
    export default defineComponent({
        props: {
            modelValue: {type:Boolean},
            allowClose:{type:Boolean, default:true},
            slideOut:{type:Boolean, default:false},
            fullScreen: {type:Boolean,default:false},
            size:{type:String, default:''}
        },
        name: "modal",
        setup(props,{slots,emit}){
            const open = computed({
                get: () => props.modelValue,
                set: (value) => emit('update:modelValue', value)
            });
            const toggle = ()=> open.value = !open.value;
            const close = ()=> {if(props.allowClose) open.value = !open.value};
            return{
                close,
                open,
                toggle,
                slots
            }
        }
    });
</script>

<style scoped lang="scss">
    .modal{
        display: initial;
    }
    .modal-backdrop {
        background-color: rgba(0,0,0,0.7);
    }
    #modals-slide{
        .modal-dialog{
            border-left: 1px solid rgba(0, 0, 0, 0.125);
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
        }
        .modal-header{
            padding-right: 1rem;
            border-radius: 0;
            .modal-content{
                height: auto;
                margin: 0;
                padding: 0;
                overflow: hidden;
                background-color: inherit;
                border: none;
                box-shadow: none;
            }
            .close{
                top: -0.375rem;
                right: 0;
            }
        }
        .modal-body{
            padding: 9px 0;
            margin:0;
            height: calc(100vh - 98px);
            .modal-content{
                padding: 1rem 0;
                border: none;
                box-shadow: none;
            }
        }
        .modal-footer{
            padding: 0;
            border-radius: 0;
            .modal-content{
                padding: 0;
                margin: 0;
            }
        }
    }
    #modals-full-screen{
        overflow: auto;
        .modal-dialog{height: 100vh;}
        button.close{
            border-radius: 4px;
            border: 0;
            position: fixed;
            right: 3px;
            top: 4px;
            padding: 4px;
        }
        @media (min-width: 576px) {
            .modal-dialog, .modal-footer {
                max-width: 100vw;
                margin: 1.75rem auto;
            }
        }
        @media (min-width: 1024px) {
            .modal-dialog, .modal-footer {
                max-width: 1024px;
                margin: 1.75rem auto;
            }
        }
    }
</style>