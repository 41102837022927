<template>
    <slot name="trigger" :click="open"></slot>
    <modal v-model="modal" allow-close>
        <template v-slot:header>Delete Social Media Post</template>
        <div class="form-group">
            <label>Title</label>
            <input type="text" name="social-title" class="form-control" v-model="title" readonly="readonly">
            <input type="hidden" name="post_id" v-model="id">
        </div>
        <div class="form-group">
            <label>Description</label>
            <textarea class="form-control" v-model="description" readonly="readonly" rows="5"></textarea>
        </div>
        <div class="form-group">
            <label>Publish On</label>
            <datepicker class="form-control" v-model="published_on" disabled="disabled"></datepicker>
        </div>
        <template v-slot:footer>
            <div class="d-flex justify-content-end">
                <button @click="modal = !modal" class="btn btn-default mx-2">Cancel</button>
                <button @click="deletePost" class="btn btn-primary">Delete Post</button>
            </div>
        </template>
    </modal>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import Datepicker from 'vue3-datepicker';
    import Modal from "@/components/modal.vue";
    import {store} from "@/store";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationIcon,NotificationColor} from "@/interfaces/notification";
    import {SocialMediaPost} from "@/models/social-media-post";

    export default defineComponent({
        name: "DeleteSocialPost",
        components: {
            Datepicker,
            Modal
        },
        props:{
            postObj:{type:Object, required:true},
        },
        emits:['deletePostData'],
        setup(props, {emit}){
            const modal = ref(false);
            const open = ()=>modal.value = !modal.value;
            const id = ref(props.postObj.id);
            const title = ref(props.postObj.title);
            const description = ref(props.postObj.description);
            const published_on = ref(new Date(props.postObj.published_on));

            const deletePost = () =>{
                SocialMediaPost.deleteSocialMediaPost({
                    'id':id.value
                }).then((res)=>{
                    emit('deletePostData',res.data);
                    modal.value = false;
                    store.commit(MutationTypes.addSystemNotification,{title:'Success',content:'Social Media Post has been Deleted',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                });
            }

            return{
                deletePost,
                description,
                id,
                modal,
                open,
                published_on,
                SocialMediaPost,
                title,
            }
        }
    });
</script>

<style scoped>

</style>
