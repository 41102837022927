<template>
    <div>
        <h1>Pending Recruitment</h1>
    </div>
    <div v-if="!loading">
        <div class="card pt-3 px-2">
            <data-grid :items="pendingUsers" listView>
                <template v-slot:rows="rowProps">
                    <recruit-list-item v-for="(item,index) in rowProps.pageItems" v-bind:key="index" :recruit="item"/>
                </template>
                <template v-slot:noItems>
                    <div class="text-center">No Pending Recruitment at this time</div>
                </template>
            </data-grid>
        </div>
    </div>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { api } from "@/store";
    import { defineComponent, ref } from "vue";
    import { store } from '@/store';
    import Loading from 'vue3-loading-overlay';
    import DataGrid from "@/components/data-grid.vue";
    import RecruitListItem from "@/components/recruit-list-item.vue";
    import moment from 'moment';
    import {UserRole} from "@/enums/user-role";
    export default defineComponent({
        components: {Loading, DataGrid, RecruitListItem},
        mounted(){
            this.getPendingUsers();
        },
        setup() {
            const loading = ref(false);
            const pendingUsers = ref([]);


            const getPendingUsers = async () => {
                loading.value = true;
                await api.get('sanctum/csrf-cookie');
                api.post('api/recruitment/get-pending',{}).then((res)=>{
                    var formatted:any = [];
                    res.data.forEach(function(elm:any){
                        let applied_array = [];
                        if(elm.user.user_roles.filter(function(role:any){return role.role_id == UserRole.SP;}).length > 0) applied_array.push('Standardized Patient');
                        if(elm.user.user_roles.filter(function(role:any){return role.role_id == UserRole.OBSERVER;}).length > 0) applied_array.push('Observer');
                        if(elm.user.user_roles.filter(function(role:any){return role.role_id == UserRole.TRAINER;}).length > 0) applied_array.push('Trainer');
                        if(elm.user.user_roles.filter(function(role:any){return role.role_id == UserRole.AUTHOR;}).length > 0) applied_array.push('Author');
                        if(elm.user.user_roles.filter(function(role:any){return role.role_id == UserRole.REVIEWER;}).length > 0) applied_array.push('Reviewer');
                        let applied_string = applied_array.join(', ');
                        formatted.push({
                            'id': elm.user.id,
                            'name': elm.user.name,
                            'email': elm.user.email,
                            'image': elm.user.image,
                            'applied': applied_string,
                            'created_at': moment(elm.created_at).format('MM/DD/YY')
                        })
                    });
                    pendingUsers.value = formatted;
                    loading.value = false;
                },(err)=>{
                    console.log(err);
                    loading.value = false;
                });
            }

            return {
                user: store.state.auth.user,
                getPendingUsers,
                pendingUsers,
                loading
            };
        },
    });
</script>
