<template>
    <slot name="trigger" :click="open"></slot>
    <modal v-model="modal">
        <template v-slot:header>Select An Image</template>
        <template v-slot:default>
            <div class="row form-row overflow-auto" style="max-height: 365px;">
                <div class="gallery-sizer col-sm-6 col-md-6 col-xl-3 position-absolute"></div>
                <div v-for="(item,index) in imageOptions" v-bind:key="index" class="gallery-thumbnail col-sm-6 col-md-6 col-xl-3 mb-2" data-tag="nature">
                    <a href="javascript:void(0)" @click="select(item)" class="img-thumbnail img-thumbnail-zoom-in">
                        <span class="img-thumbnail-overlay bg-dark opacity-25"></span>
                        <span class="img-thumbnail-content display-4 text-white">
                            <i class="fa fa-check"></i>
                        </span>
                        <img :src="item.location" class="img-fluid" />
                    </a>
                </div>
            </div>
        </template>
    </modal>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from "vue";
    import Modal from "@/components/modal.vue";
    import PatientImage from "@/models/patient-image";
    import {IPatientImage} from "@/interfaces/patient-image";
    export default defineComponent({
        name: "scenario-select-image",
        components: {Modal},
        emits:['selected','update:modelValue'],
        props:{
          modelValue:String,
        },
        setup(props,{emit}) {
            const value = computed({
              get: () => props.modelValue,
              set: (value) => emit('update:modelValue', value)
            })
            const modal= ref(false);
            const imageOptions = ref<Array<IPatientImage>>([]);

            onMounted(()=>{
                PatientImage.getList().then(res=>imageOptions.value = res.data);
            });

            const open = ()=>{
                modal.value = !modal.value;
            }

            const select = (selected:IPatientImage)=>{
                modal.value = false;
                value.value = selected.location;
                emit('selected',selected.id);
            }

            return{
                modal,
                imageOptions,
                select,
                open
            }
        }
    })
</script>

<style scoped>

</style>