<template>
    <div class="col-md-6">
        <div class="card scenario-card shadow-sm">
            <div class="card-content" @click="router.push(route)">
                <div class="row">
                    <div class="col-md-10">
                        <img :src="scenario?.patient_image?.location" class="rounded-circle float-left" width="75" />
                        <div class="font-weight-bold text-secondary">{{scenario?.patient_name}}</div>
                        <div class="overflow-hidden">
                            <span>{{scenario.title}}</span>
                        </div>
                    </div>
                    <div class="col-md-2 d-flex flex-column justify-content-center">
                        <h3 class="text-center"><i class="fas fa-arrow-circle-right text-primary"></i></h3>
                    </div>
                </div>
            </div>
            <div v-if="scenario.credit_text" class="card-footer">
                <div class="col-md-12 justify-content-end align-items-center">
                    <div class="text-success small mr-4">CREDIT AVAILABLE</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import {computed, defineComponent, PropType} from 'vue';
import {IScenario} from "@/interfaces/scenario";
import {ScenarioStatus} from '@/enums/scenario-status';
import router from '@/router'

export default defineComponent({
        components: {
            
        },
        props: {
            isLearner: {type: Boolean, default: false},
            scenario: {type: Object as PropType<IScenario>, required:true},
        },
        name: "scenario-list-item",
        setup(props){
            const route = computed(()=>(props.scenario.status === ScenarioStatus.PUBLISHED) && props.isLearner ? {name:'scenario.overview',params:{id:props.scenario.id}} : {name:'scenario.show',params:{id:props.scenario.id}})

            return{
                route,
                router,
                ScenarioStatus
            }
        }
    })
</script>

<style scoped>

</style>