<template>
    <div class="col-md-6">
        <div class="card scenario-card shadow-sm">
            <div class="card-content" @click="router.push({name:'rubric.show',params:{id:props.rubric.id}})">
                <div class="row">
                    <div class="col-md-10">
                        <div class="font-weight-bold text-secondary">{{rubric.name}}</div>
                        <div class="overflow-hidden">
                            <small v-if="rubric.creator !== null" class="text-primary">Created by <em>{{rubric.creator.name}}</em> on <em>{{moment(rubric.created_at).format('MM/DD/YY')}}</em></small>
                            <small v-else class="text-primary">Created on <em>{{moment(rubric.created_at).format('MM/DD/YY')}}</em></small>
                        </div>
                    </div>
                    <div class="col-md-2 d-flex flex-column justify-content-center">
                        <h3 class="text-center"><i class="fas fa-arrow-circle-right text-primary"></i></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import {defineComponent} from 'vue';
import moment from 'moment';
import router from '@/router'

export default defineComponent({
        props: {
            rubric: {required:true},
        },
        name: "rubric-list-item",
        setup(props){

            return{
                moment,
                props,
                router
            }
        }
    })
</script>

<style scoped>

</style>
