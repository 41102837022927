<template>
    <div class="card shadow-sm mb-4">
        <div class="card-header text-white bg-secondary font-weight-bold">Scheduled Visits</div>
        <div class="card-content p-2">
            <div v-if="!visit_loading">
                <span v-if="Object.keys(props.scheduled_visits).length">
                    <div v-for="visit in props.scheduled_visits" v-bind:key="visit.id" class="col-12" @click="router.push({name:'visit',params:{visit_id:visit.id}})">
                        <div class="row visit-card">
                            <div class="col-10">
                                <img :src="visit.scenario.patient_image.location" class="rounded-circle float-left mr-2" width="75" />
                                <div class="border-right time-slot float-left pt-3 mr-2">
                                    <span class="text-secondary font-weight-bold">{{ moment(visit.local_time).format('MM/DD/YY') }}</span>
                                    <hr class="my-1">
                                    <span class="text-primary font-weight-bold">{{ moment(visit.local_time).format('h:mm') }}<sup>{{ moment(visit.local_time).format('a') }}</sup></span>
                                </div>
                                <div class="mobile-visit-card overflow-hidden">
                                    <div class="font-weight-bold text-secondary lh-normal mb-1" v-if="!props.is_learner">{{visit.scenario.patient_name}}<br><small :class="visit.is_confirmed ? 'text-secondary' : 'text-danger'"><span v-if="!visit.is_confirmed">Not </span>Confirmed</small></div>
                                    <div class="font-weight-bold text-secondary lh-normal mb-1" v-if="props.is_learner">{{visit.scenario.patient_name}}<br><small>Scheduled<br><span v-if="!visit.is_confirmed">You will be required to confirm your participation via SMS text message three days prior to the date of the visit. PLEASE NOTE: Unconfirmed visits will be canceled 21 hours prior to start time.</span></small></div>
                                    <div>{{visit.scenario.title}}</div>
                                    <div class="mt-1" v-if="is_admin">
                                        <small class="text-secondary">{{visit.partner.slug}}</small>
                                        <br>
                                        <small v-if="!is_client_admin" class="text-secondary">Standardized Patient</small>
                                        <br v-if="!is_client_admin">
                                        <small v-if="!is_client_admin">{{visit.standardized_patient.name}} ({{visit.standardized_patient.email}})</small>
                                        <br v-if="!is_client_admin">
                                        <small class="text-secondary">Participant</small>
                                        <br>
                                        <small>{{visit.learner.name}} ({{visit.learner.email}})</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 d-flex flex-column justify-content-center">
                                <h3 class="text-center"><i class='fas fa-arrow-circle-right text-primary'></i></h3>
                            </div>
                        </div>
                        <hr class="w-75" />
                    </div>
                    <lazy-pagination v-if="scheduled_visits_total > 10" :items="props.scheduled_visits" :total="props.scheduled_visits_total" :page_start="page_start" :page_end="page_end" v-on:next-page="nextPage" v-on:previous-page="previousPage" :label="'Visits'" />
                </span>
                <p v-else class="text-center pt-3">No Scheduled Visits</p>
            </div>
            <div class="loading-card-area" v-else>
                <loading :active="visit_loading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent,ref} from "vue";
    import Loading from 'vue3-loading-overlay';
    import moment from 'moment';
    import LazyPagination from '@/components/dashboard/lazy-pagination.vue';
    import router from "@/router";

    export default defineComponent({
        emits: ['change-page'],
        components: {
            Loading,
            LazyPagination
        },
        props: {
            reset_pagination: {type:Boolean, default: false},
            scheduled_visits: {type:Object, required:true},
            scheduled_visits_total: {type:Number, required:true},
            visit_loading: {type:Boolean, required:true},
            is_learner: {type:Boolean, default:false},
            is_admin: {type:Boolean, default:false},
            is_client_admin: {type:Boolean, default:false}
        },
        watch: {
            reset_pagination: function(reset){
                if(reset){
                    this.page_start = 0;
                    this.page_end = 10;
                }
            },
        },
        setup(props,{emit}) {
            const loading = ref<Boolean>(false);
            const page_start = ref(0);
            const page_end = ref(10);
            const nextPage = async() => {
                page_start.value = page_start.value + 10;
                page_end.value = page_end.value + 10;
                if(page_end.value > props.scheduled_visits_total){
                    page_end.value = props.scheduled_visits_total;
                }
                emit('change-page',{type:'scheduled','page_start':page_start.value});
            };
            const previousPage = async() => {
                loading.value = true;
                page_start.value = page_start.value - 10;
                page_end.value = page_start.value + 10;
                emit('change-page',{type:'scheduled','page_start':page_start.value});
            };
            return {
                loading,
                moment,
                nextPage,
                page_end,
                page_start,
                previousPage,
                props,
                router
            };
        }
    });
</script>
