import { IUser } from '@/interfaces/user';
import {api} from "@/store";

export default class User{
    constructor(
        public props:IUser
    ){}

    getField(field:String){

        if(field === 'dob') {
            if (this.props.dob) {
                return this.props.dob;
            } else {
                return null;
            }
        }
        // @ts-ignore
        if(['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'].includes(field)){
           return this.props.availabilities?.find(i=>i.day === field);
        }
        // @ts-ignore
        return this.props[field];
    }

    async saveCustomField(field:Number|undefined,value:any){
        return await api.patch('api/auth/user/update-custom-field/'+field,{value:value});
    }

    async saveField(field:String,value:any){
        return await api.patch('api/auth/user/update-field/'+field,{value:value});
    }

    async savePassword(formData:any):Promise<any>{
        return await api.post('api/auth/user/update-password',formData);
    }

    static async updateImage(formData:FormData):Promise<any>{
        return await api.post('api/auth/user/update-profile-image',formData,{headers: {'Content-Type': 'multipart/form-data'}});
    }

    setField(field:String,value:any){
        // @ts-ignore
        this.props[field] = value;
    }

    static async getApplications():Promise<any>{
        return await api.get('api/user/applications');
    }

    static async getToken():Promise<any>{
        return await api.get('api/auth/user/token');
    }

    static async getUsers(formData:Object):Promise<any>{
        return await api.post('api/user/management',formData);
    }

    static async getUser(formData:Object):Promise<any>{
        return await api.post('api/user/manage',formData);
    }

    static async updateNotificationSettings(formData:Object):Promise<any>{
        return await api.post('api/user/update-notification-settings',formData);
    }

    static async updatePassword(formData:Object):Promise<any>{
        return await api.post('api/user/update-password',formData);
    }

    static async updateRoles(formData:Object):Promise<any>{
        return await api.post('api/user/update-roles',formData);
    }

    static async smsOptIn(formData:Object):Promise<any>{
        return await api.post('api/user/sms-opt-in',formData);
    }

    static async submitPasscode(form:any):Promise<any>{
        return await api.post('api/user/submit-passcode',form);
    }

    async addAvailability(formData:any){
        return await api.post('api/user/add-availability',formData);
    }

    async deleteAvailability(formData:any){
        return await api.post('api/user/delete-availability',formData);
    }

    async updateAvailability(formData:any){
        return await api.post('api/user/update-availability',formData);
    }
}
