import {api} from '@/store';
import {IInterview} from "@/interfaces/interview";
import {FormData} from "@/interfaces/form-config";
export default class Interview{
    constructor(public interview?:IInterview) {}

    static async cancel(form:FormData):Promise<any>{
        return await api.post('api/interview/cancel',form);
    }

    static async get(form:FormData):Promise<any>{
        return await api.post('api/interview/'+form.interview_id,form);
    }
}
