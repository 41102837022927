import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container mt-4" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "list-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playlists, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "list-group-item"
            }, [
              _createVNode(_component_router_link, {
                to: {name:'playlists.item',params:{id:item.data.id}}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.data.name), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ])
      ])
    ]),
    _createVNode(_component_Loading, {
      active: _ctx.loading,
      "is-full-page": true
    }, null, 8, ["active"])
  ], 64))
}