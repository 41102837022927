import Echo from 'laravel-echo';
// import { api } from './store';
// export const pusher = require('pusher-js');
//
export const echo = new Echo({
//     // broadcaster: 'pusher',
//     // key: process.env.VUE_APP_SOCKET_KEY,
//     // forceTLS: false,
//     // wsHost: process.env.VUE_APP_SOCKET_SERVER,
//     // wsPort:6001,
//     // disableStats: true,
//     // encrypted:false,
//     // cluster:process.env.VUE_APP_SOCKET_CLUSTER,
//     // enabledTransports: ['ws', 'wss'],
//     // authorizer: (channel:any, options:any) => {
//     //     return {
//     //       authorize: (socketId:any, callback:any) => {
//     //         api.post("api/broadcasting/auth",{
//     //             socket_id: socketId,
//     //             channel_name: channel.name,
//     //           },
//     //           {withCredentials: true }
//     //         )
//     //         .then((response) => {
//     //             callback(false, response.data);
//     //         }).catch((error) => {
//     //             callback(true, error);
//     //         });
//     //       },
//     //     };
//     // }
});
