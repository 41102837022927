<template>
    <div class="row mt-4">
        <div class="col-4">
            <div class="card">
                <div class="card-header bg-light">
                    <create-playlist @create="create">
                        <template v-slot:trigger="triggerProps">
                            <div class="d-flex justify-content-between align-items-center">
                                <span>Playlists</span>
                                <button @click="triggerProps.click" class="btn btn-sm btn-primary">Create</button>
                            </div>
                        </template>
                    </create-playlist>
                </div>
                <div class="card-body p-0">
                    <div class="list-group" v-if="playlists.length > 0">
                        <a href="javascript:void(0)" @click="selectedPlaylist = index" v-for="(item,index) in playlists" v-bind:key="index" class="list-group-item d-flex rounded-0 justify-content-between align-items-center list-group-item-action" :class="{'active':index === selectedPlaylist}">
                            <span>{{item.data.name}}</span>
                            <span>Scenarios: <span class="badge" :class="selectedPlaylist === index ? 'badge-light' : 'badge-primary'">{{item.data.scenarios.length}}</span></span>
                        </a>
                    </div>
                    <div class="d-flex flex-column align-items-center m-4" v-else>
                        <span class="text-primary mb-4">You Have no Playlist</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-8">
            <div class="card">
                <div class="card-header text-primary d-flex justify-content-between align-items-center" v-if="playlists.length > 0">
                    <span v-if="!editName">{{playlists[selectedPlaylist].data.name}} <a class="text-primary" href="javascript:void(0)" @click="editName = true"><i class="fa fa-edit"></i> </a> </span>
                    <div v-if="editName" class="form-group m-0">
                        <div class="input-group ">
                            <input type="text" v-model="playlists[selectedPlaylist].data.name" class="form-control form-control-sm" />
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-light" @click="editName = false"><i class="fa fa-ban"></i> </button>
                                <button class="btn btn-sm btn-primary" @click="update"><i class="fa fa-save"></i> </button>
                            </div>
                        </div>

                    </div>
                    <switch-input label="Public" v-model="playlists[selectedPlaylist].data.public" @update:modelValue="updatePublic" />
                </div>
                <div class="card-body">
                    <play-list-component v-if="playlists.length > 0" :playlist="playlists[selectedPlaylist]"></play-list-component>
                </div>
            </div>
        </div>
    </div>
    <Loading :active="loading" :is-full-page="true"></Loading>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref} from "vue";
    import {IPlaylist} from "@/interfaces/playlist";
    import Playlist from "@/models/playlist";
    import CreatePlaylist from "@/components/playlist/create-playlist.vue";
    import PlayListComponent from "@/components/playlist/play-list.vue";
    import SwitchInput from "@/components/switch-input.vue";
    import Loading from 'vue3-loading-overlay';
    export default defineComponent({
        name: "my-playlist",
        components: {SwitchInput, PlayListComponent, CreatePlaylist,Loading},
        setup(){
            const playlists = ref<Array<Playlist>>([]);
            const loading = ref(false);
            const selectedPlaylist = ref(0);
            const editName = ref(false);
            const create = (playlist:IPlaylist)=>{
                playlists.value.push(new Playlist(playlist));
            }
            const updatePublic = ()=>{
                update();
            }
            const update = async ()=>{
                loading.value = true;
                await Playlist.update(
                    playlists.value[selectedPlaylist.value].data?.id,
                    {name:playlists.value[selectedPlaylist.value].data?.name,public:playlists.value[selectedPlaylist.value].data?.public}
                ).then((res)=>{
                    playlists.value[selectedPlaylist.value] = new Playlist(res.data);
                });
                editName.value = false;
                loading.value = false;
            }
            onMounted(async ()=>{
                loading.value = true;
                await Playlist.myPlaylist().then((res)=>{
                    playlists.value = res.data.map( (i:IPlaylist)=> new Playlist(i));
                });
                loading.value = false;
            });
            return {
                create,
                editName,
                loading,
                playlists,
                selectedPlaylist,
                updatePublic,
                update
            }
        }
    });
</script>

<style scoped>

</style>