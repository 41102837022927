<template>
    <div class="card scorecard-container">
        <div class="card-body" v-if="scenario_obj.getStatus() === ScenarioStatus.DRAFT || scenario_obj.getStatus() === ScenarioStatus.EDITING">
            <div class="col-md-12">
                <label class="switch">
                    <input type="checkbox" :checked="scenario_obj.data.is_feedback_form_enabled" @change="enableFeedbackForm">
                    <span class="slider round"></span>
                </label>
                <span class="slider-text d-inline-block">Include SP Scorecard</span>
            </div>
        </div>
        <div class="card-body" v-if="scenario_obj.data.is_feedback_form_enabled && (scenario_obj.getStatus() === ScenarioStatus.DRAFT || scenario_obj.getStatus() === ScenarioStatus.EDITING)">
            <div class="col-md-12">
                <div class="d-flex justify-content-between align-items-center pb-4">
                    <div class="d-flex justify-content-start align-items-center">
                        <label class="col-form-label text-sm-right">Rubric</label>
                        <div class="mx-2">
                            <select class="form-control" v-model="selectedRubric">
                                <option hidden disabled selected>Select a Rubric</option>
                                <option v-for="(item, index) in rubrics" v-bind:key="index" :value="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                        <modal size="modal-lg" v-model="previewRubric" allow-close>
                            <template v-slot:trigger="triggerProps">
                                <button :disabled="selectedRubric == null" @click="triggerProps.click" class="btn btn-primary"><i class="fa fa-eye"></i> </button>
                            </template>
                            <template v-slot:header>{{rubrics.find((i)=>i.id === selectedRubric).name}}</template>
                            <template v-slot:default>
                                <ul class="list-unstyled">
                                    <li class="px-4 py-2" :class="index+1 === rubrics.find((i)=>i.id === selectedRubric).questions.length ? 'border-0' : 'border-bottom'" v-for="(item,index) in rubrics.find((i)=>i.id === selectedRubric).questions" v-bind:key="index">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="px-2">{{ item.question }}</div>
                                        </div>
                                    </li>
                                </ul>
                            </template>
                        </modal>
                        <button :disabled="selectedRubric == null" @click="selectRubric" class="ml-2 btn btn-primary"><i class="fa fa-plus"></i> </button>
                    </div>
                    <create-question v-if="feedback_form" @update="newQuestion" :feedback_form="feedback_form" :form_type="'feedback_form'">
                        <template v-slot:trigger="triggerProps">
                            <button @click="triggerProps.click" class="btn btn-primary"><i class="fa fa-plus"></i> Create Question </button>
                        </template>
                    </create-question>
                </div>
                <scenario-field type="editor" v-if="feedback_form" v-model="feedback_form.data.instructions" field="instructions" :can-edit="true" v-on:save="updateInstructions"/>
            </div>
        </div>

        <div class="card-body" v-if="feedback_form && scenario_obj.data.is_feedback_form_enabled">
            <div class="col-md-12">
                <!-- Comments -->
                <div v-if="(scenario.data.status !== ScenarioStatus.DRAFT) && (scenario.data.reviewer_user_id !== null) && showCommentBubble" class="text-primary position-relative float-right d-flex justify-content-between align-items-center">
                    <span class="comment-bubble">{{scenario?.getCommentsByField(field).length > 0 ? scenario?.getCommentsByField(field).length : ''}}<i class="fas fa-2x fa-comment-dots" title="View or Read Comments"></i></span>
                </div>
                <h4 class="text-secondary">Questions:</h4>
                <ul class="list-unstyled pt-2">
                    <VueDraggableNext v-model="feedback_form.data.questions" @change="reorder" :sort="scenario_obj.getStatus() !== ScenarioStatus.PUBLISHED && scenario_obj.getStatus() !== ScenarioStatus.ARCHIVED">
                      <li class="px-4 py-2 border-bottom"  :class="{'border-0':index+1 === feedback_form.data.questions.length,'bg-lighter':item.rubric}" v-for="(item,index) in feedback_form.data.questions" v-bind:key="index">
                        <div v-if="item.question" class="d-flex justify-content-between align-items-center">
                            <i class="text-primary fas fa-bars"></i>
                            <div class="px-4 w-100" v-html="item.question.question"></div>
                            <edit-question :feedback_form="feedback_form" :question="item.question" @updateQuestionData="loadQuestions" :key='item.question.id' :form_type="'feedback_form'">
                                <template v-slot:trigger="triggerProps">
                                    <i v-if="scenario_obj.getStatus() === ScenarioStatus.DRAFT || scenario_obj.getStatus() === ScenarioStatus.EDITING || userIsAdmin" @click="triggerProps.click" class="cursor-pointer text-primary fa fa-edit"></i>
                                </template>
                            </edit-question>&nbsp;&nbsp;
                            <i v-if="scenario_obj.getStatus() === ScenarioStatus.DRAFT || scenario_obj.getStatus() === ScenarioStatus.EDITING || (scenario_obj.getStatus() === ScenarioStatus.APPROVED && userIsAdmin)" @click="showDeleteQuestionModal(item.id)" class="cursor-pointer text-primary fa fa-trash"></i>
                            <modal v-model="deleteQuestion[item.id]">
                                <template v-slot:header>Permanently Delete Question</template>
                                <p>Are you sure you want to delete this Question?</p>
                                <button class="btn btn-xs btn-secondary-alt float-right" @click="showDeleteQuestionModal(item.id)">Cancel</button>
                                <button type="button" class="btn btn-primary btn-xs" @click="removeQuestion(item.question.id,index)">Permanently Delete</button>
                            </modal>
                        </div>
                        <div v-if="item.rubric" class="d-flex align-items-center">
                            <i class="text-primary fas fa-bars"></i>
                            <div class="px-4 w-100">
                                <div class="rubric-instructions" v-if="item.rubric.learner_instructions">
                                    <strong>Learner Instructions:</strong> <span v-html="item.rubric.learner_instructions"></span>
                                </div>
                                <div class="rubric-instructions" v-if="item.rubric.scorer_instructions">
                                    <strong>Scorer Instructions:</strong> <span v-html="item.rubric.scorer_instructions"></span>
                                </div>
                                <strong>Rubric: {{item.rubric.name}}</strong>
                                <ul class="list-unstyled">
                                    <li class="py-2 border-bottom" :class="{'border-0':index+1 === item.rubric.questions.length}" v-for="(rubricItem,index) in item.rubric.questions" v-bind:key="index">
                                        <span class="rubric_sp_scorecard" v-html="rubricItem.short_description"></span>
                                    </li>
                                </ul>
                            </div>
                            <i v-if="scenario_obj.getStatus() === ScenarioStatus.DRAFT || scenario_obj.getStatus() === ScenarioStatus.EDITING || (scenario_obj.getStatus() === ScenarioStatus.APPROVED && userIsAdmin)" @click="showDeleteRubricModal(item.id)" class="cursor-pointer text-primary fa fa-trash"></i>
                            <modal v-model="deleteRubric[item.id]">
                                <template v-slot:header>Permanently Delete This Rubric</template>
                                <p>Are you sure you want to delete this Rubric?</p>
                                <button class="btn btn-xs btn-secondary-alt float-right" @click="showDeleteRubricModal(item.id)">Cancel</button>
                                <button type="button" class="btn btn-primary btn-xs" @click="removeRubric(item.id,index)">Permanently Delete</button>
                            </modal>
                        </div>
                    </li>
                    </VueDraggableNext>
                </ul>
                <dynamic-form class="mt-4" label="SP Scorecard Preview" :instructions="feedback_form.data.instructions" :questions="feedback_form.data.questions"></dynamic-form>
            </div>
        </div>
    </div>
    <div class="m-4" v-if="!scenario_obj.data.is_feedback_form_enabled && (scenario_obj.getStatus() === ScenarioStatus.PUBLISHED || scenario_obj.getStatus() === ScenarioStatus.ARCHIVED)">
        <p>SP Scorecard not enabled.</p>
    </div>
    <Loading :active="loading" :is-full-page="false"></Loading>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref} from "vue";
    import {IFeedbackFormQuestions} from "@/interfaces/feedback-form-questions";
    import {IRubric} from "@/interfaces/rubric";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    import {Rubric} from "@/models/rubric";
    import {ScenarioStatus} from '@/enums/scenario-status';
    import {store} from "@/store";
    import {VueDraggableNext} from 'vue-draggable-next';
    import CreateQuestion from "@/components/create-question.vue";
    import DynamicForm from "@/components/dynamic-form/dynamic-form.vue";
    import EditQuestion from "@/components/edit-question.vue";
    import FeedbackForm from "@/models/feedback-form";
    import Loading from 'vue3-loading-overlay';
    import Modal from "@/components/modal.vue";
    import Scenario from "@/models/scenario";
    import ScenarioField from "@/components/scenarios/scenario-field.vue";

    export default defineComponent({
        components: {
            CreateQuestion,
            DynamicForm,
            EditQuestion,
            Loading,
            Modal,
            ScenarioField,
            VueDraggableNext
        },
        props: {
            enableComments: {type:Boolean, default:false},
            field: {type:String, required:true},
            label: {type:String, required:true},
            scenario: {type:Scenario},
            showCommentBubble: {type:Boolean, required:true}
        },
        name: "standardized-patient-scorecard",

        setup(props){
            const deleteRubric = ref<Array<Boolean>>([]);
            const deleteQuestion = ref<Array<Boolean>>([]);
            const feedback_form = ref<FeedbackForm | undefined>();
            const loading = ref(false);
            const previewRubric = ref(false);
            const rubrics = ref<Array<IRubric>>([]);
            const scenario_obj = ref(props.scenario);
            const selectedField = ref('');
            const selectedRubric = ref(null);
            const userIsAdmin = computed(()=>store.getters.user.props.super_admin === 1);
            const userIsAuthor = computed(()=>props.scenario?.data?.author_user_id === store.getters.user.props.id);
            const userIsReviewer = computed(()=>props.scenario?.data?.reviewer_user_id === store.getters.user.props.id && !userIsAuthor.value);
            const windowWidth = ref(0);

            onMounted(()=>{
                loadQuestions();
                windowWidth.value = window.innerWidth;
                window.addEventListener('resize',()=>{
                    windowWidth.value = window.innerWidth;
                })
            });

            const loadQuestions = async ()=>{
                loading.value = true;
                await FeedbackForm.find(props.scenario?.data?.id!).then((res:any)=>{
                    feedback_form.value = new FeedbackForm(res.data);
                    deleteQuestion.value = [];
                    deleteRubric.value = [];
                    if(feedback_form.value?.data?.questions !== undefined){
                        for(const value of feedback_form.value?.data?.questions){
                            //@ts-ignore
                            deleteQuestion.value[value.id] = false;
                            //@ts-ignore
                            deleteRubric.value[value.id] = false;
                        }
                    }
                });
                await Rubric.list().then((res:any)=>{
                    rubrics.value = res.data;
                });

                loading.value = false;
            }

            const newQuestion = (newQuestion:IFeedbackFormQuestions)=>{
                feedback_form.value?.data?.questions.push(newQuestion);
            }

            const selectRubric = async ()=>{
                loading.value = true;
                await Rubric.addToFeedbackForm(selectedRubric.value!,feedback_form.value?.data?.id!).then((res:any)=>{
                    feedback_form.value = new FeedbackForm(res.data);
                });
                loading.value = false;
            }

            const showDeleteRubricModal = (id:any) => deleteRubric.value[id] = !deleteRubric.value[id];

            const showDeleteQuestionModal = (id:any) => deleteQuestion.value[id] = !deleteQuestion.value[id];

            const removeQuestion = async (id:number,index:number)=>{
                showDeleteQuestionModal(id);
                loading.value = true;
                await FeedbackForm.removeQuestion(feedback_form.value?.data?.scenario_id,id).then((res)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:res.data.message,content:'Question successfully deleted.',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                    feedback_form.value?.data?.questions.splice(index,1);
                    loading.value = false;
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error Deleting Question.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    loading.value = false;
                });
            }

            const removeRubric = async (form_id:number,index:number)=>{
                showDeleteRubricModal(form_id);
                loading.value = true;
                await FeedbackForm.removeRubric(feedback_form.value?.data?.scenario_id,form_id).then((res)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:res.data.message,content:'Rubric successfully deleted.',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                    feedback_form.value?.data?.questions.splice(index,1);
                    loading.value = false;
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error Deleting Rubric.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    loading.value = false;
                });
            }

            const reorder = async ()=> {
                let newOrder:Array<Number> = [];
                feedback_form.value?.data?.questions.forEach((value:IFeedbackFormQuestions) => {
                    newOrder.push(value.id);
                });
                await FeedbackForm.reorder(feedback_form.value?.data?.id,{order:newOrder}).catch(
                    ()=>store.commit(MutationTypes.addSystemNotification,{title:'Error',content:'There was an error reordering the questions.',icon:NotificationIcon.WARNING,color:NotificationColor.YELLOW})
                )
            }

            const enableFeedbackForm = async() => {
                loading.value = true;
                await FeedbackForm.enable(props.scenario?.data.id).then((res)=>{
                    if(typeof(scenario_obj.value) !== 'undefined'){
                        scenario_obj.value.data.is_feedback_form_enabled = res.data.is_enabled;
                    }
                    feedback_form.value = new FeedbackForm(res.data.feedback_form);
                    store.commit(MutationTypes.addSystemNotification, {title:res.data.message,content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                    loading.value = false;
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error enabling feedback form.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    loading.value = false;
                });
            }

            const updateInstructions = async (data:any)=>{
                loading.value = true;
                await FeedbackForm.saveInstructions(feedback_form.value?.data?.id,data.value).then(()=> {
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error saving instructions.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            return{
                deleteRubric,
                deleteQuestion,
                EditQuestion,
                enableFeedbackForm,
                feedback_form,
                loading,
                loadQuestions,
                newQuestion,
                previewRubric,
                reorder,
                removeQuestion,
                removeRubric,
                rubrics,
                selectedField,
                selectRubric,
                selectedRubric,
                scenario_obj,
                ScenarioStatus,
                showDeleteRubricModal,
                showDeleteQuestionModal,
                userIsAdmin,
                userIsAuthor,
                userIsReviewer,
                updateInstructions,
                windowWidth
            }
        }
    });
</script>

<style scoped>
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 28px;
    }
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }
    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
    input:checked + .slider {
        background-color: #16AA9B;
    }
    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }
    input:checked + .slider:before {
        -webkit-transform: translateX(32px);
        -ms-transform: translateX(32px);
        transform: translateX(32px);
    }
    .slider.round {
        border-radius: 28px;
    }
    .slider.round:before {
        border-radius: 50%;
    }
    .slider-text {
        font-weight: 600;
        line-height: 30px;
        margin: .5rem 0 0 1rem;
        vertical-align: middle;
    }
</style>
