import {api} from "@/store";
import {ITimeZone} from "@/interfaces/timezone";

export class TimeZone{
    constructor(public data?:ITimeZone) {}

    static async getTimeZones():Promise<any> {
        return await api.get('api/timezones');
    }
}
