import {api} from '@/store';
import {FormData} from "@/interfaces/form-config";
export default class TrainingSession{
    constructor() {}

    static async addUsers(form:any){
        return await api.post('api/training-session/add-users',form);
    }

    static async cancel(form:FormData){
        return await api.post('api/training-session/cancel',form);
    }

    static async get(id:string | string[]):Promise<any>{
        return await api.get('api/training-session/' + id);
    }

    static async getFeedbackPractice(id:string | string[]):Promise<any>{
        return await api.get('api/training-session/' + id + '/practice/feedback');
    }

    static async getRecording(form:any):Promise<any>{
        return await api.post('api/training-session/recording',form);
    }

    static async removeUser(form:any):Promise<any>{
        return await api.post('api/training-session/remove-user',form);
    }

    static async reschedule(form:FormData):Promise<any>{
        return await api.post('api/training-session/reschedule',form);
    }

    static async schedule(form:FormData):Promise<any>{
        return await api.post('api/training-session/schedule',form);
    }

    static async updateJobNumber(id:string | string[], form:FormData):Promise<any>{
        return await api.post('api/training-session/' + id + '/update-job-number',form);
    }
}
