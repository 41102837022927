<template>
    <div v-if="!loading">
        <h1>Partner Registration Fields</h1>
        <select v-model="selected_partner" @change="getPartnerFields(selected_partner)">
            <option :value="null"> -- Select A Partner --</option>
            <option v-for="partner in partners" v-bind:key="partner.id" :value="partner.id">{{partner.name}}</option>
        </select>
        <div v-if="selected_partner" id="registration-fields">
            <div class="card py-2 px-2">
                <form @submit.prevent="submit">
                <table width="100%">
                    <tr>
                        <th class="header-row py-2">Registration Field</th>
                        <th class="header-row py-2">Show on Registration</th>
                        <th class="header-row py-2">Make Field Required</th>
                        <th class="header-row py-2">Type</th>
                        <th class="header-row py-2 text-center">Delete</th>
                    </tr>
                    <tr>
                        <td class="py-2 px-2">First Name</td>
                        <td class="py-2"><input type="checkbox" name="fname" checked disabled></td>
                        <td class="py-2"><input type="checkbox" name="fname_require" checked disabled></td>
                        <td class="py-2 text-muted">input</td>
                        <td class="py-2">&nbsp;</td>
                    </tr>
                    <tr>
                        <td class="py-2 px-2">Last Name</td>
                        <td class="py-2"><input type="checkbox" name="lname" checked disabled></td>
                        <td class="py-2"><input type="checkbox" name="lname_require" checked disabled></td>
                        <td class="py-2 text-muted">input</td>
                        <td class="py-2">&nbsp;</td>
                    </tr>
                    <tr>
                        <td class="py-2 px-2">Email</td>
                        <td class="py-2"><input type="checkbox" name="email" checked disabled></td>
                        <td class="py-2"><input type="checkbox" name="email_require" checked disabled></td>
                        <td class="py-2 text-muted">input</td>
                        <td class="py-2">&nbsp;</td>
                    </tr>
                    <tr v-for="(field,index) in existingFields" v-bind:key="field.id">
                        <td class="py-2 px-2">{{field.title}}</td>
                        <td class="py-2"><input type="checkbox" :value="field.active" name="active" v-model="field.active"></td>
                        <td class="py-2"><input type="checkbox" :value="field.active" name="required" v-model="field.required"></td>
                        <td class="py-2 text-muted">{{field.field_type}}</td>
                        <td class="hpy-2 text-center"><i v-if="field.partner_id != 0" @click="showDeleteRegistrationModal()" class="cursor-pointer text-primary fa fa-trash"></i></td>

                        <modal v-model="deleteRegistrationField">
                            <template v-slot:header>Permanently Delete Field</template>
                            <p>Are you sure you want to delete this Registration Field?</p>
                            <button type="button" class="btn btn-primary btn-xs float-right ml-4" @click="removeRegistrationField(field.id,index)">Delete</button> <button class="btn btn-xs btn-secondary-alt float-right" @click="showDeleteRegistrationModal()">Cancel</button>
                        </modal>

                    </tr>
                </table>
                </form>
            </div>
            <create-registration-field :partner-id="selected_partner" v-on:add-new-field="addRegistrationField">
                <template v-slot:trigger="triggerProps">
                    <button class="btn btn-primary mt-4 px-5 font-weight-bold" @click="triggerProps.click">Add Custom Field <i class="fas fa-plus-circle"></i></button>
                </template>
            </create-registration-field>
            <br>
            <button class="btn btn-primary mt-4 px-5 font-weight-bold" @click="saveFieldProps">Save Custom Registration</button>
        </div>
    </div>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import {api} from "@/store";
    import {defineComponent, ref} from "vue";
    import {IRegistrationFields} from "@/interfaces/registration-fields";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    import {store} from "@/store";
    import CreateRegistrationField from "@/components/create-registration-field.vue";
    import Loading from 'vue3-loading-overlay';
    import Modal from "@/components/modal.vue";
    import RegistrationFields from "@/models/registration-fields";

    export default defineComponent({
        components: {
            CreateRegistrationField,
            Loading,
            Modal
        },
        name: "registration-fields",
        mounted(){
            this.getPartners();
        },
        setup() {
            const deleteRegistrationField = ref(false);
            const err_msg = ref(false);
            const existingFields = ref<Array<any>>([]);
            const field_active = ref<Boolean>(false);
            const field_required = ref<Boolean>(false);
            const loading = ref<Boolean>(false);
            const selected_partner = ref(null);
            const partners = ref([]);
            const partnerFields = ref([]);

            const addRegistrationField = async (addRegistrationField:IRegistrationFields[]) => {
                existingFields.value = addRegistrationField;
                existingFields.value.forEach((elm:any) => {
                    if(elm.active == 1){
                        elm.active = true;
                    }else{
                        elm.active = false;
                    }
                    if(elm.required == 1){
                        elm.required = true;
                    }else{
                        elm.required = false;
                    }
                });
            }

            const getPartners = async () => {
                loading.value = true;
                await api.get('sanctum/csrf-cookie');

                api.get('api/partner',{}).then((res)=>{
                    var formatted:any = [];

                    res.data.forEach(function(elm:any){

                        formatted.push({
                            'id': elm.id,
                            'name': elm.long_name
                        });

                    });
                    partners.value = formatted;
                    loading.value = false;

                },(err)=>{
                    console.log(err);
                    loading.value = false;
                });
            }

            const getPartnerFields = async (partner_id:Number) => {
                loading.value = true;

                await RegistrationFields.getRegistrationFields(partner_id).then((res)=>{
                        existingFields.value = res.data;

                        existingFields.value.forEach((elm:any) => {
                            if(elm.active == 1){
                                elm.active = true;
                            }else{
                                elm.active = false;
                            }
                            if(elm.required == 1){
                                elm.required = true;
                            }else{
                                elm.required = false;
                            }
                        });

                    },(err)=>{
                        console.log(err);
                        err_msg.value = err.response.data.message;
                        loading.value = false;
                    });

                loading.value = false;
            }

            const removeRegistrationField = async (field_id:number,index:number)=>{
                showDeleteRegistrationModal();
                loading.value = true;

                await RegistrationFields.removeRegistrationField(field_id,{'user_id':store.getters.user.props.id}).then((res)=>{
                    if(res.data == 1) {
                        store.commit(MutationTypes.addSystemNotification,{
                            title:'SUCCESS',
                            content:'Registration field successfully deleted.',
                            icon:NotificationIcon.SUCCESS,
                            color:NotificationColor.GREEN
                        });
                        existingFields.value?.splice(index,1);
                    } else {
                        store.commit(MutationTypes.addSystemNotification,{
                            title: 'ERROR',
                            content: 'Unable to delete Fields',
                            icon: NotificationIcon.ERROR,
                            color: NotificationColor.RED
                        });
                    }
                    loading.value = false;
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error Deleting Question.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    loading.value = false;
                });
            }

            const saveFieldProps = async ()=>{
                loading.value = true;

                let form = {
                    fields: existingFields.value,
                    partner_id: selected_partner.value
                };

                await RegistrationFields.updateRegistrationFields({form}).then((res)=>{
                    if (existingFields.value[0].partner_id == 0) {
                        existingFields.value = res.data;

                        existingFields.value.forEach((elm:any) => {
                            if(elm.active == 1){
                                elm.active = true;
                            }else{
                                elm.active = false;
                            }
                            if(elm.required == 1){
                                elm.required = true;
                            }else{
                                elm.required = false;
                            }
                        });
                    } else {
                        store.commit(MutationTypes.addSystemNotification,{
                            title: 'SUCCESS',
                            content: 'Fields Successfully Updated',
                            icon: NotificationIcon.SUCCESS,
                            color: NotificationColor.GREEN
                        });
                    }
                    loading.value = false;
                 },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error Updating Fields.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    loading.value = false;
                });
            }

            const showDeleteRegistrationModal = () => deleteRegistrationField.value = !deleteRegistrationField.value;

            return {
                addRegistrationField,
                deleteRegistrationField,
                err_msg,
                existingFields,
                field_active,
                field_required,
                getPartnerFields,
                getPartners,
                loading,
                partnerFields,
                partners,
                removeRegistrationField,
                saveFieldProps,
                selected_partner,
                showDeleteRegistrationModal
            };
        },
    });
</script>

<style lang="scss">
</style>
