import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    class: _normalizeClass(_ctx.style)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index) => {
      return (_openBlock(), _createElementBlock("option", {
        key: index,
        value: item.id,
        selected: _ctx.value === item.id
      }, _toDisplayString(item.short_name) + " - " + _toDisplayString(item.title), 9, _hoisted_1))
    }), 128))
  ], 2)), [
    [_vModelSelect, _ctx.value]
  ])
}