<template>
    <tabs :options="{ useUrlFragment: false }" nav-class="nav nav-pills m-3" nav-item-class="nav-item col-md-4 col-sm-12 text-center" nav-item-link-class="nav-link btn-dashboard-sub-nav" nav-item-link-active-class="active">
        <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='far fa-clock text-primary'></i></h4>" name="Pending">
            <hr class="w-75 mx-auto" />
            <div v-if="[1,28].includes(current_partner)" class="row mx-auto my-3">
                <div class="col-sm-12 col-md-12">
                    <dashboard-filters :report_data="report_data" v-on:dashboard-change="dashboardChange" :filter_partner_id="filter_partner_id" :filter_scenario_id="filter_scenario_id" />
                </div>
            </div>
            <div v-if="current_partner == 28" class="row mx-auto my-3">
                <div class="col-sm-12 col-md-12">
                    <session-counter :is_client_admin="false" />
                </div>
            </div>
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <pending-visits :pending_visits="pending_visits" :pending_visits_total="pending_visits_total" :visit_loading="pending_visits_loading" :is_admin="true" :reset_pagination="reset_pagination" v-on:change-page="changePage" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <visit-report :is_global="true" :report_data="report_data" :filter_partner_id="filter_partner_id" :filter_scenario_id="filter_scenario_id" />
                    <financial-report :is_admin="true" v-on:financial-dates="updateFinancialDates" :financial_data="financial_data" :filter_partner_id="filter_partner_id" :filter_scenario_id="filter_scenario_id"/>
                </div>
            </div>
        </tab>
        <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='far fa-calendar-alt text-primary'></i></h4>" name="Scheduled">
            <hr class="w-75 mx-auto" />
            <div v-if="[1,28].includes(current_partner)" class="row mx-auto my-3">
                <div class="col-sm-12 col-md-12">
                    <dashboard-filters :report_data="report_data" v-on:dashboard-change="dashboardChange" :filter_partner_id="filter_partner_id" :filter_scenario_id="filter_scenario_id" />
                </div>
            </div>
            <div v-if="current_partner == 28" class="row mx-auto my-3">
                <div class="col-sm-12 col-md-12">
                    <session-counter :is_client_admin="false" />
                </div>
            </div>
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <scheduled-visits :scheduled_visits="scheduled_visits" :scheduled_visits_total="scheduled_visits_total" :visit_loading="scheduled_visits_loading" :is_admin="true" :reset_pagination="reset_pagination" v-on:change-page="changePage" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <visit-report :is_global="true" :report_data="report_data" :filter_partner_id="filter_partner_id" :filter_scenario_id="filter_scenario_id" />
                    <financial-report :is_admin="true" v-on:financial-dates="updateFinancialDates" :financial_data="financial_data" :filter_partner_id="filter_partner_id" :filter_scenario_id="filter_scenario_id" />
                    <scheduled-training-sessions :scheduled_training_sessions="scheduled_training_sessions" :scheduled_training_sessions_total="scheduled_training_sessions_total" :loading="scheduled_training_sessions_loading" :is_trainer="true" v-on:change-page="changePage" />
                </div>
            </div>
        </tab>
        <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-check text-primary'></i></h4>" name="Completed">
            <hr class="w-75 mx-auto" />
            <div v-if="[1,28].includes(current_partner)" class="row mx-auto my-3">
                <div class="col-sm-12 col-md-12">
                    <dashboard-filters :report_data="report_data" v-on:dashboard-change="dashboardChange" :filter_partner_id="filter_partner_id" :filter_scenario_id="filter_scenario_id" />
                </div>
            </div>
            <div v-if="current_partner == 28" class="row mx-auto my-3">
                <div class="col-sm-12 col-md-12">
                    <session-counter :is_client_admin="false" />
                </div>
            </div>
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <completed-visits :completed_visits="completed_visits" :completed_visits_total="completed_visits_total" :visit_loading="completed_visits_loading" :is_admin="true" :reset_pagination="reset_pagination" v-on:change-page="changePage" />
                    <completed-training-sessions :completed_training_sessions="completed_training_sessions" :completed_training_sessions_total="completed_training_sessions_total" :loading="completed_training_sessions_loading" :is_trainer="true" v-on:change-page="changePage" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <visit-report :is_global="true" :report_data="report_data" :filter_partner_id="filter_partner_id" :filter_scenario_id="filter_scenario_id" />
                    <financial-report :is_admin="true" v-on:financial-dates="updateFinancialDates" :financial_data="financial_data" :filter_partner_id="filter_partner_id" :filter_scenario_id="filter_scenario_id" />
                    <canceled-visits :canceled_visits="canceled_visits" :visit_loading="canceled_visits_loading" :is_admin="true" :reset_pagination="reset_pagination" v-on:change-page="changePage" />
                    <canceled-training-sessions :canceled_training_sessions="canceled_training_sessions" :canceled_training_sessions_total="canceled_training_sessions_total" :loading="canceled_training_sessions_loading" :is_trainer="true" v-on:change-page="changePage" />
                </div>
            </div>
        </tab>
    </tabs>
</template>

<script lang="ts">
    import {defineComponent,ref} from "vue";
    import { store } from '../../store/index';
    import {Tabs, Tab} from 'vue3-tabs-component';
    import CanceledTrainingSessions from '@/components/dashboard/canceled-training-sessions.vue';
    import CanceledVisits from '@/components/dashboard/canceled-visits.vue';
    import CompletedTrainingSessions from '@/components/dashboard/completed-training-sessions.vue';
    import CompletedVisits from '@/components/dashboard/completed-visits.vue';
    import DashboardFilters from '@/components/dashboard/dashboard-filters.vue';
    import FinancialReport from '@/components/dashboard/financial-report.vue';
    import moment from 'moment';
    import PendingVisits from '@/components/dashboard/pending-visits.vue';
    import router from "@/router";
    import ScheduledTrainingSessions from '@/components/dashboard/scheduled-training-sessions.vue';
    import ScheduledVisits from '@/components/dashboard/scheduled-visits.vue';
    import SessionCounter from '@/components/dashboard/session-counter.vue';
    import Visit from '@/models/visit';
    import VisitReport from '@/components/dashboard/visit-report.vue';


    export default defineComponent({
        components: {
            CanceledTrainingSessions,
            CanceledVisits,
            CompletedTrainingSessions,
            CompletedVisits,
            DashboardFilters,
            FinancialReport,
            PendingVisits,
            ScheduledTrainingSessions,
            ScheduledVisits,
            SessionCounter,
            Tab,
            Tabs,
            VisitReport
        },
        mounted() {
            this.getVisitData(0,'all');
            this.getFinancialData(null,null);
            this.getVisitReportData(null,null);
        },
        setup() {
            const pending_visits = ref([]);
            const pending_visits_total = ref(0);
            const pending_visits_loading = ref<Boolean>(true);
            const scheduled_visits = ref([]);
            const scheduled_visits_total = ref(0);
            const scheduled_visits_loading = ref<Boolean>(true);
            const completed_visits = ref([]);
            const completed_visits_total = ref(0);
            const completed_visits_loading = ref<Boolean>(true);
            const canceled_visits = ref([]);
            const canceled_visits_loading = ref<Boolean>(true);
            const scheduled_training_sessions = ref([]);
            const scheduled_training_sessions_total = ref(0);
            const scheduled_training_sessions_loading = ref<Boolean>(true);
            const completed_training_sessions = ref([]);
            const completed_training_sessions_total = ref(0);
            const completed_training_sessions_loading = ref<Boolean>(true);
            const canceled_training_sessions = ref([]);
            const canceled_training_sessions_total = ref(0);
            const canceled_training_sessions_loading = ref<Boolean>(true);
            const current_partner = ref(store.getters.partner?.id);
            const filter_partner_id = ref(null);
            const filter_scenario_id = ref(null);
            const financial_data = ref({});
            const report_data = ref({});
            const financial_month = ref(moment().format('M'));
            const financial_year = ref(moment().format('YYYY'));
            const reset_pagination = ref<Boolean>(false);
            const dashboardChange = async(res:any) => {
                reset_pagination.value = true;
                pending_visits_loading.value = true;
                scheduled_visits_loading.value = true;
                completed_visits_loading.value = true;
                scheduled_training_sessions_loading.value = true;
                completed_training_sessions_loading.value = true;
                canceled_training_sessions_loading.value = true;
                canceled_visits_loading.value = true;
                pending_visits.value = res.data.visit_requests;
                pending_visits_total.value = res.data.visit_requests_total;
                pending_visits_loading.value = false;
                scheduled_visits.value = res.data.scheduled_visits;
                scheduled_visits_total.value = res.data.scheduled_visits_total;
                scheduled_visits_loading.value = false;
                completed_visits.value = res.data.completed_visits;
                completed_visits_total.value = res.data.completed_visits_total;
                completed_visits_loading.value = false;
                scheduled_training_sessions.value = res.data.scheduled_training_sessions;
                scheduled_training_sessions_total.value = res.data.scheduled_training_sessions_total;
                scheduled_training_sessions_loading.value = false;
                completed_training_sessions.value = res.data.completed_training_sessions;
                completed_training_sessions_total.value = res.data.completed_training_sessions_total;
                completed_training_sessions_loading.value = false;
                canceled_training_sessions.value = res.data.canceled_training_sessions;
                canceled_training_sessions_total.value = res.data.canceled_training_sessions_total;
                canceled_training_sessions_loading.value = false;
                canceled_visits.value = res.data.canceled_visits;
                canceled_visits_loading.value = false;
                filter_partner_id.value = res.data.partner_id;
                filter_scenario_id.value = res.data.scenario_id;
                getFinancialData(res.data.partner_id, res.data.scenario_id);
                getVisitReportData(res.data.partner_id, res.data.scenario_id);
            }
            const getFinancialData = async(partner_id:any, scenario_id:any) => {
                var post_data:any = {
                    month: financial_month.value,
                    year: financial_year.value,
                    partner_id: partner_id,
                    scenario_id: scenario_id,
                    is_author: false,
                };
                if(![1,28].includes(store.getters.partner?.id)){
                    post_data.partner_id = store.getters.partner?.id;
                }
                Visit.getFinancialData(post_data).then((res)=>{
                    financial_data.value = {
                        completed_total: res.data.completed_total,
                        scorecards_total: res.data.scorecards_total,
                        training_sessions_total: res.data.training_sessions_total,
                        late_cancellations_total: res.data.late_cancellations_total,
                        early_cancellations_total: res.data.early_cancellations_total,
                        no_shows_total: res.data.no_shows_total,
                        learner_technical_issues_total: res.data.learner_technical_issues_total,
                        interviews_total: res.data.interviews_total,
                        authoring_total: res.data.authoring_total,
                        total: res.data.total,
                    };
                },(err)=>{
                    console.log(err);
                });
            };
            const getVisitData = async(page_start:Number,type:String) => {
                if(type == 'all' || type == 'requests'){
                    pending_visits_loading.value = true;
                }
                if(type == 'all' || type == 'scheduled'){
                    scheduled_visits_loading.value = true;
                }
                if(type == 'all' || type == 'completed'){
                    completed_visits_loading.value = true;
                }
                if(type == 'all' || type == 'scheduled_training_sessions'){
                    scheduled_training_sessions_loading.value = true;
                }
                if(type == 'all' || type == 'completed_training_sessions'){
                    completed_training_sessions_loading.value = true;
                }
                if(type == 'all' || type == 'canceled_training_sessions'){
                    canceled_training_sessions_loading.value = true;
                }
                if(type == 'all'){
                    canceled_visits_loading.value = true;
                }
                var post_data = {
                    user_id: store.getters.user.props.id,
                    role_id: store.getters.dashboard_view,
                    page_start: page_start,
                    type: type,
                    partner_id: filter_partner_id.value,
                    scenario_id: filter_scenario_id.value

                };
                Visit.getUserVisits(post_data).then((res)=>{
                    if(type == 'all' || type == 'requests'){
                        pending_visits.value = res.data.visit_requests;
                        pending_visits_total.value = res.data.visit_requests_total;
                        pending_visits_loading.value = false;
                    }
                    if(type == 'all' || type == 'scheduled'){
                        scheduled_visits.value = res.data.scheduled_visits;
                        scheduled_visits_total.value = res.data.scheduled_visits_total;
                        scheduled_visits_loading.value = false;
                    }
                    if(type == 'all' || type == 'completed'){
                        completed_visits.value = res.data.completed_visits;
                        completed_visits_total.value = res.data.completed_visits_total;
                        completed_visits_loading.value = false;
                    }
                    if(type == 'all' || type == 'scheduled_training_sessions'){
                        scheduled_training_sessions.value = res.data.scheduled_training_sessions;
                        scheduled_training_sessions_total.value = res.data.scheduled_training_sessions_total;
                        scheduled_training_sessions_loading.value = false;
                    }
                    if(type == 'all' || type == 'completed_training_sessions'){
                        completed_training_sessions.value = res.data.completed_training_sessions;
                        completed_training_sessions_total.value = res.data.completed_training_sessions_total;
                        completed_training_sessions_loading.value = false;
                    }
                    if(type == 'all' || type == 'canceled_training_sessions'){
                        canceled_training_sessions.value = res.data.canceled_training_sessions;
                        canceled_training_sessions_total.value = res.data.canceled_training_sessions_total;
                        canceled_training_sessions_loading.value = false;
                    }
                    if(type == 'all'){
                        canceled_visits.value = res.data.canceled_visits;
                        canceled_visits_loading.value = false;
                    }
                },(err)=>{
                    console.log(err);
                });
            };
            const getVisitReportData = async(partner_id:any, scenario_id:any) => {
                var post_data:any = {
                    partner_id: partner_id,
                    scenario_id: scenario_id,
                };
                Visit.getReportData(post_data).then((res)=>{
                    report_data.value = {
                        pending_count: res.data.pending_count,
                        scheduled_count: res.data.scheduled_count,
                        completed_count: res.data.completed_count,
                        partners: res.data.partners,
                        scenarios: res.data.scenarios
                    };
                },(err)=>{
                    console.log(err);
                })
            };

            const changePage = (vars:any) => {
                getVisitData(vars.page_start,vars.type);
                reset_pagination.value = false;
            }

            const updateFinancialDates = (dates:any) => {
                financial_month.value = dates[0];
                financial_year.value = dates[1];
            }
            return {
                changePage,
                pending_visits,
                pending_visits_total,
                pending_visits_loading,
                scheduled_visits,
                scheduled_visits_total,
                scheduled_visits_loading,
                completed_visits,
                completed_visits_total,
                completed_visits_loading,
                canceled_visits,
                canceled_visits_loading,
                current_partner,
                dashboardChange,
                scheduled_training_sessions,
                scheduled_training_sessions_total,
                scheduled_training_sessions_loading,
                completed_training_sessions,
                completed_training_sessions_total,
                completed_training_sessions_loading,
                canceled_training_sessions,
                canceled_training_sessions_total,
                canceled_training_sessions_loading,
                filter_partner_id,
                filter_scenario_id,
                financial_data,
                getFinancialData,
                getVisitData,
                getVisitReportData,
                moment,
                report_data,
                router,
                reset_pagination,
                updateFinancialDates
            };
        }
    });
</script>
