import {
    INotification,
    INotificationActionPayload,
    NotificationActionType,
    NotificationColor,
    NotificationIcon
} from '@/interfaces/notification';
import {api} from "@/store";

export default class Notification {
    public data:INotification;
    constructor(data:INotification){
        this.data = data;
    }

    static async delete(form:any):Promise<any>{
        return await api.post('api/notifications/delete',form);
    }

    getIcon():String {
        switch (this.data.icon){
            case NotificationIcon.WARNING:
                return 'fas fa-exclamation-circle';
            case NotificationIcon.SUCCESS:
                return 'fas fa-check-circle';
            case NotificationIcon.ERROR:
                return 'fas fa-times-circle';
            case NotificationIcon.MESSAGE:
                return 'far fa-comment fa-flip-horizontal';
            default:
                return '';
        }
    }

    getColor():String{
        switch (this.data.color){
            case NotificationColor.GREEN:
                return 'bg-success text-white';
            case NotificationColor.YELLOW:
                return 'bg-warning text-dark';
            case NotificationColor.RED:
                return 'bg-danger text-white';
            default:
                return 'bg-white text-white';
        }
    }

    parseActionLink(action:INotification):String{
        if(action.type !== NotificationActionType.LINK){
            return '#';
        }
        const payload = action.payload;
        //check payload items
        let linkSet = false;
        let containsParams = false;
        if(payload?.link)  linkSet = true;
        if(payload?.parameters) containsParams = true;

        if(linkSet && containsParams){
            if(payload?.link.match(/{([^}]+)}/g)){
                payload?.link.match(/{([^}]+)}/g).forEach((i:any,index:number)=>{
                    payload.link = payload.link.replace(i,payload.parameters[index]);
                });
            }
            return  payload?.link;
        }else if(linkSet){
            return payload?.link;
        }else {
            return '#';
        }

    }

    hasActionContent():Boolean {
        return this.data.type === NotificationActionType.SHOW_CONTENT;
    }
    getActionContent(): INotificationActionPayload | undefined{
        return this.data.payload;
    }

}
