    <template>
    <tabs :options="{ useUrlFragment: false }" nav-class="nav nav-pills m-3" nav-item-class="nav-item col-md-3 col-sm-12 text-center" nav-item-link-class="nav-link btn-dashboard-sub-nav" nav-item-link-active-class="active">
        <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-drafting-compass text-primary'></i></h4>" name="Drafts">
            <hr class="w-75 mx-auto" />
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <scenario-drafts :scenario_drafts="scenario_drafts" :visit_loading="visit_loading" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="dashbaord-create-container">
                        <button class="btn btn-primary" @click="create" :disabled="loading"><i class="fa fa-plus-circle"></i> <span class="d-none d-md-inline">CREATE NEW SCENARIO</span></button>
                    </div>
                    <div class="mt-4" v-if="current_partner == 28">
                        <financial-report :is_admin="false" :is_author="true" v-on:financial-dates="updateFinancialDates" :financial_data="financial_data" />
                    </div>
                </div>
            </div>
        </tab>
        <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-edit text-primary'></i></h4>" name="Reviews">
            <hr class="w-75 mx-auto" />
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <scenario-reviews :scenario_reviews="scenario_reviews" :visit_loading="visit_loading" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="dashbaord-create-container">
                        <button class="btn btn-primary" @click="create" :disabled="loading"><i class="fa fa-plus-circle"></i> <span class="d-none d-md-inline">CREATE NEW SCENARIO</span></button>
                    </div>
                    <div class="mt-4" v-if="current_partner == 28">
                        <financial-report :is_admin="false" :is_author="true" v-on:financial-dates="updateFinancialDates" :financial_data="financial_data" />
                    </div>
                </div>
            </div>
        </tab>
        <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-i-cursor text-primary'></i></h4>" name="Edits">
            <hr class="w-75 mx-auto" />
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <scenario-edits :scenario_edits="scenario_edits" :visit_loading="visit_loading" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="dashbaord-create-container">
                        <button class="btn btn-primary" @click="create" :disabled="loading"><i class="fa fa-plus-circle"></i> <span class="d-none d-md-inline">CREATE NEW SCENARIO</span></button>
                    </div>
                    <div class="mt-4" v-if="current_partner == 28">
                        <financial-report :is_admin="false" :is_author="true" v-on:financial-dates="updateFinancialDates" :financial_data="financial_data" />
                    </div>
                </div>
            </div>
        </tab>
        <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-check-circle text-primary'></i></h4>" name="Approved">
            <hr class="w-75 mx-auto" />
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <scenario-approved :scenario_approved="scenario_approved" :visit_loading="visit_loading" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="dashbaord-create-container">
                        <button class="btn btn-primary" @click="create" :disabled="loading"><i class="fa fa-plus-circle"></i> <span class="d-none d-md-inline">CREATE NEW SCENARIO</span></button>
                    </div>
                    <div class="mt-4" v-if="current_partner == 28">
                        <financial-report :is_admin="false" :is_author="true" v-on:financial-dates="updateFinancialDates" :financial_data="financial_data" />
                    </div>
                </div>
            </div>
        </tab>
    </tabs>
    <Loading :active="loading" :is-full-page="true"></Loading>
</template>

<script lang="ts">
    import {defineComponent,ref} from "vue";
    import { store } from '../../store/index';
    import {Tabs, Tab} from 'vue3-tabs-component';
    import FinancialReport from '@/components/dashboard/financial-report.vue';
    import Loading from "vue3-loading-overlay";
    import moment from 'moment';
    import router from "@/router";
    import ScenarioApproved from '@/components/dashboard/scenario-approved.vue';
    import ScenarioDrafts from '@/components/dashboard/scenario-drafts.vue';
    import ScenarioEdits from '@/components/dashboard/scenario-edits.vue';
    import ScenarioReviews from '@/components/dashboard/scenario-reviews.vue';
    import Scenario from '@/models/scenario';
    import Visit from '@/models/visit';

    export default defineComponent({
        components: {
            FinancialReport,
            Loading,
            ScenarioApproved,
            ScenarioDrafts,
            ScenarioEdits,
            ScenarioReviews,
            Tab,
            Tabs,
        },
        mounted() {
            this.getAuthoringData();
            this.getFinancialData();
        },
        setup() {
            const loading = ref(false);
            const current_partner = ref(store.getters.partner?.id);
            const scenario_approved = ref([]);
            const scenario_drafts = ref([]);
            const scenario_edits = ref([]);
            const scenario_reviews = ref([]);
            const visit_loading = ref<Boolean>(true);
            const financial_data = ref({});
            const financial_month = ref(moment().format('M'));
            const financial_year = ref(moment().format('YYYY'));
            const getAuthoringData = async() => {
                Scenario.getAuthoringData().then((res)=>{
                    scenario_drafts.value = res.data.scenario_drafts;
                    scenario_reviews.value = res.data.scenario_reviews;
                    scenario_edits.value = res.data.scenario_edits;
                    scenario_approved.value = res.data.scenario_approved;
                    visit_loading.value = false;
                },(err)=>{
                    console.log(err);
                });
            };
            const create = async ()=>{
                loading.value = true;
                Scenario.create().then((res)=>{
                  router.push({path:'/scenarios/'+res.data.id});
                });
                loading.value = false;
            }
            const getFinancialData = async() => {
                var post_data:any = {
                    month: financial_month.value,
                    year: financial_year.value,
                    is_author: true
                };
                if(![1,28].includes(store.getters.partner?.id)){
                    post_data.partner_id = store.getters.partner?.id;
                }
                Visit.getFinancialData(post_data).then((res)=>{
                    financial_data.value = {
                        completed_total: res.data.completed_total,
                        scorecards_total: res.data.scorecards_total,
                        training_sessions_total: res.data.training_sessions_total,
                        late_cancellations_total: res.data.late_cancellations_total,
                        early_cancellations_total: res.data.early_cancellations_total,
                        no_shows_total: res.data.no_shows_total,
                        learner_technical_issues_total: res.data.learner_technical_issues_total,
                        interviews_total: res.data.interviews_total,
                        authoring_total: res.data.authoring_total,
                        authoring_scenarios: res.data.authoring_scenarios,
                        total: res.data.total,
                    };
                },(err)=>{
                    console.log(err);
                });
            };
            const updateFinancialDates = (dates:any) => {
                financial_month.value = dates[0];
                financial_year.value = dates[1];
            }
            return {
                create,
                current_partner,
                financial_data,
                getFinancialData,
                loading,
                scenario_approved,
                scenario_drafts,
                scenario_edits,
                scenario_reviews,
                getAuthoringData,
                moment,
                router,
                updateFinancialDates,
                visit_loading,
            };
        }
    });
</script>
