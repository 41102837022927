<template>
    <slot name="trigger" :click="open"></slot>
    <modal v-model="modal" allow-close>
        <template v-slot:header>Edit Question</template>
        <div class="form-group">
            <label>Question</label>
            <textarea :class="{'is-invalid':!validation.questionText}" v-model="questionText" required cols="3" class="form-control"></textarea>
            <small v-if="!validation.questionText" class="invalid-feedback">Question text is required</small>
        </div>
        <div v-if="selectedType !== undefined && selectedType !== QuestionType.LONGFILL">
            <label>Answers:</label>
            <div class="d-flex justify-content-start align-items-center mb-2" v-for="(item, index) in answers" :key="item.id">
                <input type="text" v-model="item.answer" class="form-control mx-2" />
                <button @click="removeAnswer(parseInt(index))" class="btn btn-primary float-right"><i class="fa fa-minus"></i> </button>
            </div>

            <button @click="addAnswer(parseInt(index))" :disabled="answers.length >= maxAnswers" class="btn btn-primary float-right"><i class="fa fa-plus"></i> </button>
            <small :class="{'d-block':!validation.questionText}" v-if="!validation.answers" class="invalid-feedback">{{ validation.answersText }}</small>
        </div>
        <template v-slot:footer>
            <div class="d-flex justify-content-end">
                <button @click="modal = !modal, resetForm()" class="btn btn-default mx-2">Cancel</button>
                <button @click="save" class="btn btn-primary">Save</button>
            </div>
        </template>
    </modal>
</template>

<script lang="ts">
    import {computed, defineComponent, reactive, ref} from "vue";
    import {IFeedbackFormQuestions} from "@/interfaces/feedback-form-questions";
    import {IParticipantFeedbackQuestions} from "@/interfaces/participant-feedback-questions";
    import {IScorecardQuestions} from "@/interfaces/scorecard-questions";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationIcon,NotificationColor} from "@/interfaces/notification";
    import {QuestionType} from "@/enums/question-type";
    import {store} from "@/store";
    import FeedbackForm from "@/models/feedback-form";
    import Modal from "@/components/modal.vue";
    import ParticipantFeedback from "@/models/participant-feedback";
    import {Rubric} from "@/models/rubric";
    import Scorecard from "@/models/scorecard";
    export default defineComponent({
        name: "edit-question",
        components: {Modal},
        props:{
            feedback_form:{
                type:FeedbackForm,
                required:false
            },
            form_type:{
                type:String,
                required:true
            },
            participant_feedback:{
                type:ParticipantFeedback,
                required:false
            },
            scorecard:{
                type:Scorecard,
                required:false
            },
            rubric:{
                type:Object,
                required:false
            },
            question:{
                type:Object,
                required:false
            },
        },
        emits:['updateQuestionData'],
        setup(props, {emit}){
            const modal = ref(false);
            const open = ()=>modal.value = !modal.value;
            const selectedType = ref<QuestionType>(props.question?.question_type_id);
            const answers = ref(props.question?.answers);
            const questionText = ref(props.question?.question);
            const questionId = ref(props.question?.id);
            const validation = reactive({
                'questionText':true,
                'selectedType':true,
                'answers':true,
                'answersText':'',
            })
            const maxAnswers = computed(()=>{
                switch (selectedType.value){
                    case QuestionType.MULTIPLECHOICE:
                        return 6;
                    case QuestionType.TRUEFALSE:
                        return 2;
                    case QuestionType.LIKERT:
                        return 5;
                    default:
                        return 0;
                }
            });
            const addAnswer = ()=>{
                if(answers.value.length < maxAnswers.value){
                    answers.value.push({});
                }
            }
            const changeType = ()=>{
                answers.value = [''];
                if(selectedType.value === QuestionType.TRUEFALSE){
                    answers.value = ['Yes','No'];
                }
            }
            const removeAnswer = (index:number)=>{
                console.log(index);
                answers.value.splice(index,1);
            }
            const resetForm = ()=>{
                emit('updateQuestionData');
                answers.value = props.question?.answers;
                questionText.value = props.question?.question;
            }
            const resetValidation = ()=>{
                validation.questionText = true;
                validation.selectedType = true;
                validation.answers = true;
                validation.answersText = '';
            }
            const save = () =>{
                //reset validation
                resetValidation();
                //check validation
                if(questionText.value === '') validation.questionText = false;
                if(selectedType.value == undefined) validation.selectedType = false;
                if(selectedType.value == QuestionType.MULTIPLECHOICE || selectedType.value == QuestionType.LIKERT){
                    if(selectedType.value == QuestionType.MULTIPLECHOICE && answers.value.length < 2){
                        validation.answers = false;
                        validation.answersText = 'Multiple choice requires at least 2 answers';
                    }
                    if(selectedType.value == QuestionType.LIKERT && answers.value.length != 5){
                        validation.answers = false;
                        validation.answersText = 'Likert requires 5 answers';
                    }
                    answers.value.forEach((i:any)=>{
                        if(i === ''){
                            validation.answers = false;
                            validation.answersText = 'You have empty answers';
                        }
                    });
                }
                if(validation.answers && validation.selectedType && validation.questionText){
                    //save
                    if(props.form_type === 'scorecard'){
                        Scorecard.editQuestion(props.scorecard?.data?.id, {
                            'question_id':questionId.value,
                            'question_type_id':selectedType.value,
                            'question':questionText.value,
                            'answers':answers.value,
                        }).then((res)=>{
                            let question:IScorecardQuestions = res.data;
                            emit('updateQuestionData',question);
                            modal.value = false;
                            store.commit(MutationTypes.addSystemNotification,{title:'Success',content:'Question has been edited',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                        });
                    }else if(props.form_type === 'feedback_form'){
                        FeedbackForm.editQuestion(props.feedback_form?.data?.id, {
                            'question_id':questionId.value,
                            'question_type_id':selectedType.value,
                            'question':questionText.value,
                            'answers':answers.value,
                        }).then((res)=>{
                            let question:IFeedbackFormQuestions = res.data;
                            emit('updateQuestionData',question);
                            modal.value = false;
                            store.commit(MutationTypes.addSystemNotification,{title:'Success',content:'Question has been edited',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                        });
                    }else if(props.form_type === 'participant_feedback') {
                        ParticipantFeedback.editQuestion(props.participant_feedback?.data?.id, {
                            'question_id':questionId.value,
                            'question_type_id':selectedType.value,
                            'question':questionText.value,
                            'answers':answers.value,
                        }).then((res)=>{
                            let question:IParticipantFeedbackQuestions = res.data;
                            emit('updateQuestionData',question);
                            modal.value = false;
                            store.commit(MutationTypes.addSystemNotification,{title:'Success',content:'Question has been edited',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                        });
                    }else if(props.form_type === 'rubric'){
                        Rubric.editQuestion(props.rubric?.id, {
                            'question_id':questionId.value,
                            'question_type_id':selectedType.value,
                            'question':questionText.value,
                            'answers':answers.value
                        }).then((res)=>{
                            let question = res.data;
                            emit('updateQuestionData',question);
                            modal.value = false;
                            store.commit(MutationTypes.addSystemNotification,{title:'Success',content:'Question has been edited.',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                        });
                    }else{
                        store.commit(MutationTypes.addSystemNotification,{title:'Error',content:'Error saving answer',icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    }
                }
            }
            return {
                addAnswer,
                answers,
                changeType,
                questionText,
                questionId,
                maxAnswers,
                modal,
                QuestionType,
                removeAnswer,
                resetForm,
                save,
                selectedType,
                open,
                validation,
            }
        }
    });
</script>

<style scoped>

</style>
