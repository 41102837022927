<template>
    <div class="card shadow-sm mb-4">
        <div class="card-header text-white bg-secondary font-weight-bold">Canceled Visits</div>
        <div class="card-content p-2">
            <div v-if="!visit_loading">
                <span v-if="Object.keys(props.canceled_visits).length">
                    <div v-for="visit in props.canceled_visits.slice(page_start,page_end)" v-bind:key="visit.id" class="col-12" @click="router.push({name:'visit',params:{visit_id:visit.id}})">
                        <div class="row visit-card">
                            <div class="col-10">
                                <img :src="visit.scenario.patient_image.location" class="rounded-circle float-left mr-2" width="75" />
                                <div class="border-right time-slot float-left pt-3 mr-2">
                                    <span class="text-secondary font-weight-bold">{{ moment(visit.local_time).format('MM/DD/YY') }}</span>
                                    <hr class="my-1">
                                    <span class="text-primary font-weight-bold">{{ moment(visit.local_time).format('h:mm') }}<sup>{{ moment(visit.local_time).format('a') }}</sup></span>
                                </div>
                                <div class="mobile-visit-card overflow-hidden">
                                    <div class="font-weight-bold text-secondary">{{visit.scenario.patient_name}}</div>
                                    <div>
                                        <span>{{visit.scenario.title}}</span>
                                        <div class="text-primary">
                                            <span v-if="visit.no_show">No Show</span>
                                            <span v-else-if="visit.learner_technical_issue">Learner Technical Issue</span>
                                            <span v-else-if="visit.is_canceled && !visit.is_confirmed && !visit.canceled_by">System Cancellation</span>
                                            <span v-else-if="visit.is_canceled && visit.is_sp_cancellation">SP Cancellation</span>
                                            <span v-else-if="visit.is_canceled && !visit.is_sp_cancellation">Learner Cancellation</span>
                                            <span v-else>Cancellation</span>
                                        </div>
                                    </div>
                                    <div v-if="is_admin" class="mt-1">
                                        <small class="text-secondary">{{visit.partner.slug}}</small>
                                        <br>
                                        <small class="text-secondary">Standardized Patient</small>
                                        <br>
                                        <small>{{visit.standardized_patient.name}} ({{visit.standardized_patient.email}})</small>
                                        <br>
                                        <small class="text-secondary">Participant</small>
                                        <br>
                                        <small>{{visit.learner.name}} ({{visit.learner.email}})</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 d-flex flex-column justify-content-center">
                                <h3 class="text-center"><i class='far fa-window-close text-primary'></i></h3>
                            </div>
                        </div>
                        <hr class="w-75" />
                    </div>
                    <pagination v-if="Object.keys(props.canceled_visits).length > 10" :items="props.canceled_visits" :page_start="page_start" :page_end="page_end" v-on:next-page="nextPage" v-on:previous-page="previousPage" :label="'Visits'" />
                </span>
                <p v-else class="text-center pt-3">No Canceled Visits</p>
            </div>
            <div class="loading-card-area" v-else>
                <loading :active="visit_loading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent,ref} from "vue";
    import Loading from 'vue3-loading-overlay';
    import moment from 'moment';
    import Pagination from '@/components/dashboard/pagination.vue';
    import router from "@/router";

    export default defineComponent({
        components: {
            Loading,
            Pagination
        },
        props: {
            reset_pagination: {type:Boolean, default: false},
            canceled_visits: {type:Object, required:true},
            visit_loading: {type:Boolean, required:true},
            is_admin: {type:Boolean, default:false}
        },
        watch: {
            reset_pagination: function(reset){
                if(reset){
                    this.page_start = 0;
                    this.page_end = 10;
                }
            },
        },
        setup(props) {
            const page_start = ref(0);
            const page_end = ref(10);
            const nextPage = async() => {
                page_start.value = page_start.value + 10;
                page_end.value = page_end.value + 10;
                if(page_end.value > Object.keys(props.canceled_visits).length){
                    page_end.value = Object.keys(props.canceled_visits).length;
                }
            };
            const previousPage = async() => {
                page_start.value = page_start.value - 10;
                page_end.value = page_start.value + 10;
            };
            return {
                moment,
                nextPage,
                page_end,
                page_start,
                previousPage,
                props,
                router
            };
        }
    });
</script>
