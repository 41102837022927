<template>
    <div v-if="!loading && typeof(interview) !== 'undefined'">
        <div class="row" id="visit-request">
            <div class="col-12">
                <div class="card">
                    <div class="row mx-2 my-5">
                        <div class="col-sm-12 col-md-4 order-md-12">
                            <p>An interview has been scheduled for {{interview.applicant.name}} by {{interview.trainer.name}} on {{moment(interview.local_time).format('MM/DD/YY h:mm a')}}.</p>
                            <join-links :joinevent="interview" :label="'Interview'" :user="user"></join-links>
                            <button type="button" class="btn btn-lg btn-secondary w-100 mb-2" @click="showCancelModal">Cancel</button>
                        </div>
                        <div class="col-sm-12 col-md-8 order-sm-1">
                            <div class="row">
                                <div class="col-12 card">
                                    <div class="text-center mt-5">
                                        <img class="rounded-circle" width="100" :src="interview.trainer.image"/>
                                        <p class="h2">{{interview.trainer.name}}</p>
                                        <p class="applicant-contact mb-0 w-50 m-auto">{{interview.trainer.email}}</p>
                                        <p class="applicant-contact mb-5 w-50 m-auto">Trainer</p>
                                        <hr class="hr-dark">
                                    </div>
                                    <div class="mx-5">
                                        <h4>Experience</h4>
                                        <p>{{interview.trainer.experience}}</p>
                                        <br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal v-model="cancelModal">
            <template v-slot:header>Cancel Interview</template>
            <p>Are you sure you want to cancel this interview?</p>
            <button type="button" class="btn btn-primary btn-xs float-right" @click="cancelInterview">Cancel Interview</button>
        </modal>
    </div>
    <div v-if="!loading && typeof(interview) === 'undefined'">
        <p>Error Loading Interview</p>
        <p>{{ loadErrorMessage }}</p>
    </div>
    <br>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { defineComponent, reactive, ref } from "vue";
    import { store } from '@/store';
    import {FormConfig} from "@/interfaces/form-config";
    import Form from "@/utilities/form";
    import Loading from 'vue3-loading-overlay';
    import moment from 'moment';
    import {NotificationIcon, NotificationColor} from "@/interfaces/notification";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import JoinLinks from '@/components/join-links.vue';
    import { useRoute } from 'vue-router';
    import router from "@/router";
    import Modal from "@/components/modal.vue";
    import Interview from "@/models/interview";

    export default defineComponent({
        components: {Loading, JoinLinks, Modal},
        mounted(){
            this.getInterview();
        },
        setup() {
            const loading = ref(false);
            const interview = ref();
            const route = useRoute();
            const cancelModal = ref(false);
            const showCancelModal = ()=> cancelModal.value = !cancelModal.value;
            const form:FormConfig = reactive({
                interview_id: {
                    value:route.params.interview_id,
                    valid:true,
                    validationMessage:''
                }
            });
            const formHelper = new Form(form);
            const loadErrorMessage = ref('');

            const getInterview = async () => {
                loading.value = true;
                await Interview.get(formHelper.mapValues()).then((res)=>{
                    interview.value = res.data.interview;
                },(err)=>{
                    loadErrorMessage.value = err.response.statusText;
                    store.commit(MutationTypes.addSystemNotification, {title:'Error loading interview.',content:err.response.statusText,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const cancelInterview = async () => {
                loading.value = true;
                await Interview.cancel(formHelper.mapValues()).then(()=>{
                    router.push({name:'dashboard'});
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Interview has been cancelled!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    cancelModal.value = false;
                },(err:any)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error cancelling interview',content:err.response.data.message,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            return {
                user: store.state.auth.user,
                loading,
                moment,
                route,
                form,
                formHelper,
                cancelModal,
                showCancelModal,
                cancelInterview,
                getInterview,
                interview,
                loadErrorMessage
            };
        },
    });
</script>
