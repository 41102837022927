import {IPatientImage} from "@/interfaces/patient-image";
import {api} from "@/store";

export default class PatientImage{
    constructor(data:IPatientImage) {
    }

    static getList():Promise<any> {
        return api.get('api/patient-images');
    }

    static get(id:number):Promise<any> {
        return api.get('api/patient-images/'+id);
    }
}