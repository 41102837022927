<template>
    <div class="row d-flex justify-content-start align-items-center">
        <div class="col-lg-4 col-md-12 text-muted mb-4">
            <div>
                <img :src="img_url" class="d-block ui-w-80 rounded-circle cursor-pointer" @click="toggleShow">
            </div>
        </div>
        <div class="col-lg-8 col-md-12 mb-4">
            <button class="btn btn-primary" @click="toggleShow">Update Profile Image</button>
        </div>
        <my-upload field="img"
            @crop-upload-success="cropUploadSuccess"
            @crop-upload-fail="cropUploadFail"
            v-model="show"
            :headers="headers"
            :width="300"
            :height="300"
            :url="baseURL + 'api/auth/user/update-profile-image'"
            img-format="png"
            lang-type="en"
            :withCredentials="true"
        ></my-upload>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import User from "@/models/user";
    import myUpload from 'vue-image-crop-upload';
    export default defineComponent({
        name: "profile-image",
        components:{myUpload},
        mounted(){
            this.getToken()
        },
        props:{
            user: {type: User, required:true},
        },
        setup(props) {
            let host = window.location.host;
            let parts = host.split('.');
            let partner_slug = process.env.VUE_APP_DEFAULT_PARTNER;
            if(parts[0] !== 'app'){
                partner_slug = parts[0] + '.';
            }
            const baseURL = ref(process.env.VUE_APP_API_PROTOCAL + partner_slug + process.env.VUE_APP_API_BASE);
            const show = ref<Boolean>(false);
            const img_url = ref(props.user.props.image ? props.user.props.image : '/img/no_image.jpg');
            const headers = ref({'X-CSRF-TOKEN':''});

            const toggleShow = () => show.value = !show.value;

            const getToken = async() => {
                User.getToken().then((res)=>{
                    headers.value['X-CSRF-TOKEN'] = res.data.token;
                },(err)=>{
                    console.log(err);
                });
            }

            const cropUploadSuccess = async(res:any) => {
                img_url.value = res.img_url;
                props.user.setField('image',res.img_url);
            }

            const cropUploadFail = async(status:any, field:any) => {
                console.log('-------- upload fail --------');
                console.log(status);
                console.log('field: ' + field);
            }

            return {
                baseURL,
                cropUploadFail,
                cropUploadSuccess,
                getToken,
                headers,
                img_url,
                show,
                toggleShow,
            }
        }
    });
</script>

<style scoped>

</style>
