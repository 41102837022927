<template>
    <div v-if="playlist" class="container">
        <div class="d-flex justify-content-between align-items-center">
            <h2>{{playlist.data.name}}<br /><small>{{playlist.data.description}}</small></h2>
            <button v-if="!userOwns" @click="copy" class="btn btn-primary">Copy to My Playlists</button>
            <small v-else class="text-primary">You Own This Playlist</small>
        </div>

        <div class="card mt-2">
            <play-list public :playlist="playlist"></play-list>
        </div>
    </div>
    <Loading :active="loading" :is-full-page="true"></Loading>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref} from "vue";
    import Playlist from "@/models/playlist";
    import {useRoute} from "vue-router";
    import PlayList from "@/components/playlist/play-list.vue";
    import Loading from "vue3-loading-overlay";
    import router from "@/router";
    import {store} from "@/store";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    export default defineComponent({
        name: "playlist",
        components: {PlayList,Loading},
        setup(){
            const loading = ref(false);
            const playlist = ref<Playlist>();
            const route = useRoute();
            const userOwns = computed(()=>{
                return store.getters.user.props.id === playlist.value!.data?.user_id;
            })
            const copy = async ()=>{
                loading.value = true;
                Playlist.copy(parseInt(route.params.id[0])).then(()=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:"Playlist Copied!",icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    router.push({name:'my-playlists'});
                });
                loading.value = false;
            }
            onMounted(()=>{
                loading.value = true;
                Playlist.get(parseInt(route.params.id[0])).then((res)=>{
                    playlist.value = new Playlist(res.data);
                });
                loading.value = false;
            });
            return {
                copy,
                loading,
                playlist,
                route,
                userOwns
            }
        }
    });
</script>

<style scoped>

</style>