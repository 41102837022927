<template>
    <div>
        <div v-if="!loading" class="row">
            <div class="col-12 financial-calendar">
                <div class="card shadow-sm">
                    <div class="card-body">
                        <select class="border-0 text-secondary mr-2" v-model="query_platform">
                            <option :value="null">Select Platform</option>
                            <option :value="0">SP-ED</option>
                            <option :value="1">Simclusive</option>
                        </select>
                        <select class="border-0 text-secondary mr-2" v-model="query_month">
                            <option v-for="ind in ['01','02','03','04','05','06','07','08','09','10','11','12']" v-bind:key="ind" :value="ind">{{moment('2021-' + ind + '-01 00:00:00').format('MMMM')}}</option>
                        </select>
                        <select class="border-0 text-secondary mr-2" v-model="query_year">
                            <option v-for="year in years" v-bind:key="year" :value="year">{{year}}</option>
                        </select>
                        <select class="border-0 text-secondary mr-2" v-model="query_scenario">
                            <option :value="null">Select Scenario</option>
                            <option v-for="scenario in scenarios" v-bind:key="scenario.id" :value="scenario.id">{{scenario.patient_name}}</option>
                        </select>
                        <select class="border-0 text-secondary mr-2" v-model="query_partner">
                            <option :value="null">Select Partner</option>
                            <option v-for="partner in partners" v-bind:key="partner.id" :value="partner.id">{{partner.short_name}}</option>
                        </select>
                        <select class="border-0 text-secondary mr-2" v-model="query_job_number">
                            <option :value="null">Select Job Number</option>
                            <option :value="-1">Not associated to Job Number</option>
                            <option v-for="(job_number, i) in job_numbers" v-bind:key="i" :value="job_number">{{job_number}}</option>
                        </select>
                        <button class="btn btn-lg btn-primary" @click="getFinancialReport()">Generate</button>
                        <button class="btn btn-lg btn-primary ml-4" @click="exportFinancialReport()">Export</button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card shadow-sm">
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>SP Hours</th>
                                    <th>No Show Hours</th>
                                    <th>Learner Tech Issue Hours</th>
                                    <th>Late Cancellation Hours</th>
                                    <th>Early Cancellation Hours</th>
                                    <th>Observer Hours</th>
                                    <th>Trainer Hours</th>
                                    <th>Training Hours</th>
                                    <th>Interview Hours</th>
                                    <th>Author Hours</th>
                                    <th>Total Hours</th>
                                    <th>SP Rate</th>
                                    <th>No Show Rate</th>
                                    <th>Learner Tech Issue Rate</th>
                                    <th>Late Cancellation Rate</th>
                                    <th>Early Cancellation Rate</th>
                                    <th>Observer Rate</th>
                                    <th>Trainer Rate</th>
                                    <th>Training Rate</th>
                                    <th>Interview Rate</th>
                                    <th>Author Rate</th>
                                    <th>SP Total</th>
                                    <th>No Show Total</th>
                                    <th>Learner Tech Issue Total</th>
                                    <th>Late Cancellation Total</th>
                                    <th>Early Cancellation Total</th>
                                    <th>Observer Total</th>
                                    <th>Trainer Total</th>
                                    <th>Training Total</th>
                                    <th>Interview Total</th>
                                    <th>Author Total</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user in user_records" v-bind:key="user.user_id">
                                    <td>{{user.name}}</td>
                                    <td>{{user.email}}</td>
                                    <td>{{user.sp_hours}}</td>
                                    <td>{{user.no_show_hours}}</td>
                                    <td>{{user.learner_tech_hours}}</td>
                                    <td>{{user.late_canc_hours}}</td>
                                    <td>{{user.early_canc_hours}}</td>
                                    <td>{{user.scorecard_hours}}</td>
                                    <td>{{user.trainer_hours}}</td>
                                    <td>{{user.training_hours}}</td>
                                    <td>{{user.interview_hours}}</td>
                                    <td>{{user.author_hours}}</td>
                                    <td>{{user.total_hours}}</td>
                                    <td>${{user.sp_rate}}</td>
                                    <td>${{user.no_show_rate}}</td>
                                    <td>${{user.learner_tech_rate}}</td>
                                    <td>${{user.late_canc_rate}}</td>
                                    <td>${{user.early_canc_rate}}</td>
                                    <td>${{user.observer_rate}}</td>
                                    <td>${{user.trainer_rate}}</td>
                                    <td>${{user.training_rate}}</td>
                                    <td>${{user.interview_rate}}</td>
                                    <td>${{user.author_rate}}</td>
                                    <td>${{user.sp_total}}</td>
                                    <td>${{user.no_show_total}}</td>
                                    <td>${{user.learner_tech_total}}</td>
                                    <td>${{user.late_canc_total}}</td>
                                    <td>${{user.early_canc_total}}</td>
                                    <td>${{user.scorecard_total}}</td>
                                    <td>${{user.trainer_total}}</td>
                                    <td>${{user.training_total}}</td>
                                    <td>${{user.interview_total}}</td>
                                    <td>${{user.author_total}}</td>
                                    <td>${{user.total}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th class="font-weight-bold">Grand Total</th>
                                    <td class="font-weight-bold">-</td>
                                    <td class="font-weight-bold">{{grand_hours_sp}}</td>
                                    <td class="font-weight-bold">{{grand_hours_no_show}}</td>
                                    <td class="font-weight-bold">{{grand_hours_learner_tech}}</td>
                                    <td class="font-weight-bold">{{grand_hours_late_canc}}</td>
                                    <td class="font-weight-bold">{{grand_hours_early_canc}}</td>
                                    <td class="font-weight-bold">{{grand_hours_scorecard}}</td>
                                    <td class="font-weight-bold">{{grand_hours_trainer}}</td>
                                    <td class="font-weight-bold">{{grand_hours_training}}</td>
                                    <td class="font-weight-bold">{{grand_hours_interviews}}</td>
                                    <td class="font-weight-bold">{{grand_hours_authoring}}</td>
                                    <td class="font-weight-bold">{{grand_hours}}</td>
                                    <td class="font-weight-bold">-</td>
                                    <td class="font-weight-bold">-</td>
                                    <td class="font-weight-bold">-</td>
                                    <td class="font-weight-bold">-</td>
                                    <td class="font-weight-bold">-</td>
                                    <td class="font-weight-bold">-</td>
                                    <td class="font-weight-bold">-</td>
                                    <td class="font-weight-bold">-</td>
                                    <td class="font-weight-bold">-</td>
                                    <td class="font-weight-bold">-</td>
                                    <td class="font-weight-bold">${{grand_total_sp}}</td>
                                    <td class="font-weight-bold">${{grand_total_no_show}}</td>
                                    <td class="font-weight-bold">${{grand_total_learner_tech}}</td>
                                    <td class="font-weight-bold">${{grand_total_late_canc}}</td>
                                    <td class="font-weight-bold">${{grand_total_early_canc}}</td>
                                    <td class="font-weight-bold">${{grand_total_scorecard}}</td>
                                    <td class="font-weight-bold">${{grand_total_trainer}}</td>
                                    <td class="font-weight-bold">${{grand_total_training}}</td>
                                    <td class="font-weight-bold">${{grand_total_interviews}}</td>
                                    <td class="font-weight-bold">${{grand_total_authoring}}</td>
                                    <td class="font-weight-bold">${{grand_total}}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <loading :active="loading" :is-full-page="true"></loading>
    </div>
</template>

<script lang="ts">
    import {defineComponent,ref} from 'vue';
    import {jsontoexcel} from "vue-table-to-excel";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationIcon, NotificationColor} from "@/interfaces/notification";
    import {store} from '../../store/index';
    import moment from 'moment';
    import Loading from 'vue3-loading-overlay';
    import Visit from '@/models/visit';

    export default defineComponent({
        components: {
            Loading
        },
        mounted() {
            this.getFinancialReport();
        },
        setup() {
            const loading = ref<boolean>(true);
            const job_numbers = ref<Array<String>>([]);
            const partners = ref<Array<Object>>([]);
            const query_job_number = ref(null);
            const query_month = ref(moment().format('MM'));
            const query_partner = ref(null);
            const query_platform = ref(null);
            const query_scenario = ref(null);
            const query_year = ref(moment().format('YYYY'));
            const scenarios = ref<Array<Object>>([]);
            const years = ref<Array<Number>>([]);

            const grand_hours_sp = ref(0);
            const grand_hours_no_show = ref(0);
            const grand_hours_learner_tech = ref(0);
            const grand_hours_late_canc = ref(0);
            const grand_hours_early_canc = ref(0);
            const grand_hours_scorecard = ref(0);
            const grand_hours_trainer = ref(0);
            const grand_hours_training = ref(0);
            const grand_hours_interviews = ref(0);
            const grand_hours_authoring = ref(0);
            const grand_hours = ref(0);
            const grand_total_sp = ref(0);
            const grand_total_no_show = ref(0);
            const grand_total_learner_tech = ref(0);
            const grand_total_late_canc = ref(0);
            const grand_total_early_canc = ref(0);
            const grand_total_scorecard = ref(0);
            const grand_total_trainer = ref(0);
            const grand_total_training = ref(0);
            const grand_total_interviews = ref(0);
            const grand_total_authoring = ref(0);
            const grand_total = ref(0);
            const user_records = ref([]);

            let current_year = Number.parseInt(moment().format('YYYY'));
            let start_year = 2021;
            while(start_year <= current_year){
                years.value.push(start_year);
                start_year = start_year + 1;
            }

            const getFinancialReport = async() => {
                loading.value = true;
                var post_data = {
                    month: query_month.value,
                    year: query_year.value,
                    partner: query_partner.value,
                    is_sim:  query_platform.value,
                    scenario: query_scenario.value,
                    job_number: query_job_number.value,
                    get_menu_items: !partners.value.length || !scenarios.value.length
                };
                Visit.getFinancialReport(post_data).then((res)=>{
                    if(res.data.partners !== null){
                        partners.value = res.data.partners;
                    }
                    if(res.data.scenarios !== null){
                        scenarios.value = res.data.scenarios;
                    }
                    if(res.data.job_numbers !== null){
                        job_numbers.value = res.data.job_numbers;
                    }
                    grand_hours_sp.value = res.data.grand_hours_sp;
                    grand_hours_no_show.value = res.data.grand_hours_no_show;
                    grand_hours_learner_tech.value = res.data.grand_hours_learner_tech;
                    grand_hours_late_canc.value = res.data.grand_hours_late_canc;
                    grand_hours_early_canc.value = res.data.grand_hours_early_canc;
                    grand_hours_scorecard.value = res.data.grand_hours_scorecard;
                    grand_hours_trainer.value = res.data.grand_hours_trainer;
                    grand_hours_training.value = res.data.grand_hours_training;
                    grand_hours_interviews.value = res.data.grand_hours_interviews;
                    grand_hours_authoring.value = res.data.grand_hours_authoring;
                    grand_hours.value = res.data.grand_hours;
                    grand_total_sp.value = res.data.grand_total_sp;
                    grand_total_no_show.value = res.data.grand_total_no_show;
                    grand_total_learner_tech.value = res.data.grand_total_learner_tech;
                    grand_total_late_canc.value = res.data.grand_total_late_canc;
                    grand_total_early_canc.value = res.data.grand_total_early_canc;
                    grand_total_scorecard.value = res.data.grand_total_scorecard;
                    grand_total_trainer.value = res.data.grand_total_trainer;
                    grand_total_training.value = res.data.grand_total_training;
                    grand_total_interviews.value = res.data.grand_total_interviews;
                    grand_total_authoring.value = res.data.grand_total_authoring;
                    grand_total.value = res.data.grand_total;
                    user_records.value = res.data.user_records;
                    loading.value = false;
                },(err)=>{
                    loading.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Error retrieving report.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    console.log(err);
                })
            }

            const exportFinancialReport = async() => {
                loading.value = true;
                var filename = 'financial_report_' + query_month.value + '_' + query_year.value;
                var head = [
                    "Name",
                    "Email",
                    "SP Hours",
                    "No Show Hours",
                    "Learner Tech Issue Hours",
                    "Late Cancellation Hours",
                    "Early Cancellation Hours",
                    "Observer Hours",
                    "Trainer Hours",
                    "Training Hours",
                    "Interview Hours",
                    "Author Hours",
                    "Total Hours",
                    "SP Rate",
                    "No Show Rate",
                    "Learner Tech Issue Rate",
                    "Late Cancellation Rate",
                    "Early Cancellation Rate",
                    "Observer Rate",
                    "Trainer Rate",
                    "Training Rate",
                    "Interview Rate",
                    "Author Rate",
                    "SP Total",
                    "No Show Total",
                    "Learner Tech Issue Total",
                    "Late Cancellation Total",
                    "Early Cancellation Total",
                    "Observer Total",
                    "Trainer Total",
                    "Training Total",
                    "Interview Total",
                    "Author Total",
                    "Total"
                ];
                var data:any = [];
                user_records.value.forEach(function(user){
                    data.push({
                        "Name": user['name'],
                        "Email": user['email'],
                        "SP Hours": user['sp_hours'],
                        "No Show Hours": user['no_show_hours'],
                        "Learner Tech Issue Hours": user['learner_tech_hours'],
                        "Late Cancellation Hours": user['late_canc_hours'],
                        "Early Cancellation Hours": user['early_canc_hours'],
                        "Observer Hours": user['scorecard_hours'],
                        "Trainer Hours": user['trainer_hours'],
                        "Training Hours": user['training_hours'],
                        "Interview Hours": user['interview_hours'],
                        "Author Hours": user['author_hours'],
                        "Total Hours": user['total_hours'],
                        "SP Rate": "$" + user['sp_rate'],
                        "No Show Rate": "$" + user['no_show_rate'],
                        "Learner Tech Issue Rate": "$" + user['learner_tech_rate'],
                        "Late Cancellation Rate": "$" + user['late_canc_rate'],
                        "Early Cancellation Rate": "$" + user['early_canc_rate'],
                        "Observer Rate": "$" + user['observer_rate'],
                        "Trainer Rate": "$" + user['trainer_rate'],
                        "Training Rate": "$" + user['training_rate'],
                        "Interview Rate": "$" + user['interview_rate'],
                        "Author Rate": "$" + user['author_rate'],
                        "SP Total": "$" + user['sp_total'],
                        "No Show Total": "$" + user['no_show_total'],
                        "Learner Tech Issue Total": "$" + user['learner_tech_total'],
                        "Late Cancellation Total": "$" + user['late_canc_total'],
                        "Early Cancellation Total": "$" + user['early_canc_total'],
                        "Observer Total": "$" + user['scorecard_total'],
                        "Trainer Total": "$" + user['trainer_total'],
                        "Training Total": "$" + user['training_total'],
                        "Interview Total": "$" + user['interview_total'],
                        "Author Total": "$" + user['author_total'],
                        "Total": "$" + user['total']
                    });
                });
                data.push({
                    "Name": "Grand Total",
                    "Email": "-",
                    "SP Hours": grand_hours_sp.value,
                    "No Show Hours": grand_hours_no_show.value,
                    "Learner Tech Issue Hours": grand_hours_learner_tech.value,
                    "Late Cancellation Hours": grand_hours_late_canc.value,
                    "Early Cancellation Hours": grand_hours_early_canc.value,
                    "Observer Hours": grand_hours_scorecard.value,
                    "Trainer Hours": grand_hours_trainer.value,
                    "Training Hours": grand_hours_training.value,
                    "Interview Hours": grand_hours_interviews.value,
                    "Author Hours": grand_hours_authoring.value,
                    "Total Hours": grand_hours.value,
                    "SP Rate": "-",
                    "No Show Rate": "-",
                    "Learner Tech Issue Rate": "-",
                    "Late Cancellation Rate": "-",
                    "Early Cancellation Rate": "-",
                    "Observer Rate": "-",
                    "Trainer Rate": "-",
                    "Training Rate": "-",
                    "Interview Rate": "-",
                    "Author Rate": "-",
                    "SP Total": "$" + grand_total_sp.value,
                    "No Show Total": "$" + grand_total_no_show.value,
                    "Learner Tech Issue Total": "$" + grand_total_learner_tech.value,
                    "Late Cancellation Total": "$" + grand_total_late_canc.value,
                    "Early Cancellation Total": "$" + grand_total_early_canc.value,
                    "Observer Total": "$" + grand_total_scorecard.value,
                    "Trainer Total": "$" + grand_total_trainer.value,
                    "Training Total": "$" + grand_total_training.value,
                    "Interview Total": "$" + grand_total_interviews.value,
                    "Author Total": "$" + grand_total_authoring.value,
                    "Total": "$" + grand_total.value
                });
                jsontoexcel.getXlsx(data,head,filename);
                loading.value = false;
            }

            return {
                exportFinancialReport,
                getFinancialReport,
                job_numbers,
                loading,
                moment,
                partners,
                query_job_number,
                query_month,
                query_partner,
                query_platform,
                query_scenario,
                query_year,
                scenarios,
                years,
                grand_hours_sp,
                grand_hours_no_show,
                grand_hours_learner_tech,
                grand_hours_late_canc,
                grand_hours_early_canc,
                grand_hours_scorecard,
                grand_hours_trainer,
                grand_hours_training,
                grand_hours_interviews,
                grand_hours_authoring,
                grand_hours,
                grand_total_sp,
                grand_total_no_show,
                grand_total_learner_tech,
                grand_total_late_canc,
                grand_total_early_canc,
                grand_total_scorecard,
                grand_total_trainer,
                grand_total_training,
                grand_total_interviews,
                grand_total_authoring,
                grand_total,
                user_records
            };
        },
    });
</script>
