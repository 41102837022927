<template>
	<div>
		<div v-if="!loading" class="row">
			<div class="col-12 financial-calendar">
				<div class="card shadow-sm">
					<div class="card-body">
						<select class="border-0 text-secondary mr-2" v-model="query_month">
							<option v-for="ind in ['01','02','03','04','05','06','07','08','09','10','11','12']" v-bind:key="ind" :value="ind">{{moment('2021-' + ind + '-01 00:00:00').format('MMMM')}}</option>
						</select>
						<select class="border-0 text-secondary mr-2" v-model="query_year">
							<option v-for="year in years" v-bind:key="year" :value="year">{{year}}</option>
						</select>
						<select class="border-0 text-secondary mr-2" v-model="query_job_number">
							<option :value="null">Select Job Number</option>
							<option v-for="(job_number, i) in job_numbers" v-bind:key="i" :value="job_number">{{job_number}}</option>
						</select>
						<button class="btn btn-lg btn-primary" @click="getPaymentReport()">Generate</button>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="card shadow-sm">
					<div class="table-responsive">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>Job Number</th>
									<th>Amount</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value,label) in results" v-bind:key="label">
									<td>{{label}}</td>
									<td>{{value}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<loading :active="loading" :is-full-page="true"></loading>
	</div>
</template>
<script lang="ts">
	import {defineComponent,ref} from 'vue';
	import {MutationTypes} from "@/store/modules/ux/mutations";
	import {NotificationIcon, NotificationColor} from "@/interfaces/notification";
	import {store} from '../../store/index';
	import moment from 'moment';
	import Loading from 'vue3-loading-overlay';
	import Visit from '@/models/visit';

	export default defineComponent({
		components: {
			Loading
		},
		mounted() {
			this.getPaymentReport();
		},
		setup() {
			const loading = ref<boolean>(true);
			const job_numbers = ref<Array<String>>([]);
			const query_job_number = ref(null);
			const query_month = ref(moment().format('MM'));
			const query_year = ref(moment().format('YYYY'));
			const years = ref<Array<Number>>([]);

			const results = ref([]);

			let current_year = Number.parseInt(moment().format('YYYY'));
			let start_year = 2024;
			while(start_year <= current_year){
				years.value.push(start_year);
				start_year = start_year + 1;
			}

			const getPaymentReport = async() => {
				loading.value = true;
				var post_data = {
					month: query_month.value,
					year: query_year.value,
					job_number: query_job_number.value,
				};
				Visit.getPaymentReport(post_data).then((res) => {
					job_numbers.value = res.data.job_numbers;
					results.value = res.data.results;
					loading.value = false;
				},(err) => {
					loading.value = false;
					store.commit(MutationTypes.addSystemNotification, {title:'Error retrieving report.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
					console.log(err);
				});
			}

			return {
				getPaymentReport,
				job_numbers,
				loading,
				moment,
				query_job_number,
				query_month,
				query_year,
				results,
				years,
			};
		},
	});
</script>