<template>
    <div class="row date-scheduler">
        <div class="col-12 date-select mb-3">
            <span>Date &amp; Time</span>
            <date-select :startDate="startDate" :endDate="endDate" :field="field" v-on:update-date="emitDate"></date-select>
        </div>
        <div v-if="!is_single" class="col-12 date-select mb-3">
            <span>Alternate Date &amp; Time</span>
            <date-select :startDate="startDate" :endDate="endDate" :field="altField" v-on:update-date="emitDate"></date-select>
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import DateSelect from '@/components/date-select.vue';
    export default defineComponent({
        components: {DateSelect},
        emits: ['update-date'],
        props:{
            startDate: {type:Date,required:true},
            endDate: {type:Date,required:false,default:null},
            field: {type:String,required:true},
            altField: {type:String,required:true},
            is_single: {type:Boolean,defual:false}
        },
        methods: {
            emitDate(date){
                this.$emit('update-date',{date:date.date,field:date.field});
            }
        },
        setup(){
            return {

            }
        }
    });
</script>
<style scoped lang="scss">
@import '@/assets/styles/theme-paper.scss';
    :deep(.date-select) {
        input[type="text"],
        select {
            cursor: pointer;
            border: none;
            border-bottom: solid 1px #acacac;
            margin-bottom: 5px;
            outline: none;
            &:focus{
                border-bottom: solid 2px $primary-color;
            }
        }
        input[type="text"]::-webkit-input-placeholder{
            font-style: italic;
        }
        input[type="text"]::-moz-placeholder{
            font-style: italic;
        }
        input[type="text"]:-ms-input-placeholder{
            font-style: italic;
        }
        input[type="text"]:-moz-placeholder{
            font-style: italic;
        }
    }
</style>
