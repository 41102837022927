<template>
    <div v-if="!loading && !err_msg">
        <div class="row card text-white rounded-0 top-header">
            <div class="col-12 text-center">
                <h2 class="my-3 font-weight-bold">Client Admin Requests</h2>
            </div>
        </div>
        <div class="row card rounded-0">
            <div class="col-12 card-header">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <button class="btn btn-sm btn-primary mr-5 reset-btn" @click="resetRequests()">Reset Filters</button>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div class="input-icons">
                            <i class="fas fa-search" @click="getRequests()"></i>
                            <input type="text" placeholder="search by email" class="float-right" v-model="email_string" v-on:keyup.enter="getRequests()" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 card-body">
                <div v-if="requests.length">
                    <div class="row">
                        <div v-for="client_request in requests.slice(page_start,page_end)" v-bind:key="client_request.id" class="col-6 user-mgmt-card mb-2 pt-2" @click="router.push({name:'management.client',params:{request_id:client_request.id}})">
                            <div class="row">
                                <div class="col-10">
                                    <h4>{{ client_request.user.name }} <small v-if="client_request.is_sim">Simclusive</small><small v-else>SP-ED</small></h4>
                                    <p>{{ client_request.user.email }}</p>
                                    <small>Date Submitted: {{moment(client_request.created_at).format('MM-DD-YYYY')}}</small>
                                </div>
                                <div class="col-2 d-flex flex-column justify-content-center">
                                    <h3 class="text-center"><i class='fas fa-arrow-circle-right text-primary float-right'></i></h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <pagination v-if="requests.length > 20" :items="requests" :page_start="page_start" :page_end="page_end" v-on:next-page="nextPage" v-on:previous-page="previousPage" :label="'Requests'" />
                        </div>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-12">
                        No requests match search
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="!loading && err_msg">
        <h4>Error Loading Requests</h4>
        <p>{{err_msg}}</p>
    </div>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { defineComponent, ref } from "vue";
    import Loading from 'vue3-loading-overlay';
    import Request from '@/models/request';
    import Pagination from '@/components/dashboard/pagination.vue';
    import router from "@/router";
    import moment from 'moment';

    export default defineComponent({
        components: {
            Loading,
            Pagination
        },
        mounted(){
            this.getRequests();
        },
        setup() {
            const loading = ref<Boolean>(true);
            const requests = ref<Object>({});
            const err_msg = ref(false);
            const email_string = ref<String>("");
            const page_start = ref(0);
            const page_end = ref(20);

            const getRequests = async() => {
                loading.value = true;
                var post_data = {
                    email_string: email_string.value
                };
                await Request.getClientRequests(post_data).then((res) => {
                    requests.value = res.data.requests;
                    page_start.value = 0;
                    page_end.value = 20;
                    loading.value = false;
                    err_msg.value = false;
                },(err)=>{
                    console.log(err)
                    err_msg.value = err.response.data.message;
                    loading.value = false;
                });
            }

            const nextPage = async() => {
                page_start.value = page_start.value + 20;
                page_end.value = page_end.value + 20;
                if(page_end.value > Object.keys(requests.value).length){
                    page_end.value = Object.keys(requests.value).length;
                }
            };

            const previousPage = async() => {
                page_start.value = page_start.value - 20;
                page_end.value = page_start.value + 20;
            };

            const resetRequests = async() => {
                loading.value = true;
                email_string.value = "";
                getRequests();
            }

            return {
                email_string,
                err_msg,
                getRequests,
                loading,
                moment,
                nextPage,
                page_end,
                page_start,
                previousPage,
                requests,
                resetRequests,
                router,
            };
        },
    });
</script>

<style lang="scss" scoped>
    .input-icons{
        margin-bottom: 10px;
        i {
            position: absolute;
            padding: 7px;
            min-width: 40px;
            right: 5px;
        }
        input {
            width: 300px;
            max-width: 100%;
            padding-right: 28px;
            border: 0px;
            outline: none;
        }
    }
    select{
        background: transparent;
        border: none;
    }
    .reset-btn {
        min-height: 2rem !important;
    }
    .user-mgmt-card {
        height: 10rem;
        cursor: pointer;
        &:hover{
            background-color: #F0F0F1;
        }
        div{
            height: 8.5rem;
        }
    }
</style>
