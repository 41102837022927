<template>
    <!-- Layout wrapper -->
    <div class="layout-wrapper layout-2">
        <div class="layout-inner">
            <Sidebar/>
            <!-- Layout container -->
            <div class="layout-container">
                <Header />
                <!-- Layout content -->
                <div class="layout-content condensed-section">
                    <!-- Content -->
                    <div class="router-transitions container-fluid">
                        <user-subheader />
                        <slot />
                    </div>
                    <!-- / Content -->
                    <Footer/>
                </div>
                <!-- Layout content -->
            </div>
            <!-- / Layout container -->
        </div>
        <!-- Overlay -->
        <div class="top-right mr-2 mt-2">
            <span>
                <Notification v-for="(notification,index) in store.getters.systemNotifications" v-bind:key="index" :index="index" :notification="notification" />
            </span>
        </div>

    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, ref } from "vue";

    import Sidebar from "./Sidebar.vue";
    import Header from "./Header.vue";
    import { store } from '@/store';
    import Notification from "@/components/notification.vue";
    import UserSubheader from "@/components/user-subheader.vue";
    import router from '../router/index';
    import Footer from "@/layout/Footer.vue";
    import {useRouter} from "vue-router";
    export default defineComponent({
        components: {
            Footer,
            Header,
            Sidebar,
            Notification,
            UserSubheader
        },
        mounted(){
            if(process.env.VUE_APP_ENABLE_COOKIE_MANAGER == 'true'){
                let cookie_script = document.createElement('script');
                cookie_script.setAttribute('src', 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js');
                cookie_script.setAttribute('data-domain-script','1918c27c-b61b-48b4-a5d8-aceb91dd61aa');
                cookie_script.setAttribute('charset','UTF-8');
                document.head.appendChild(cookie_script);
                this.OptanonWrapper();
            }

            // IBM Watson Assistant
            (window as any).watsonAssistantChatOptions = {
                integrationID: "02b6af83-cb90-458c-b439-69e609916723",
                region: "us-east",
                serviceInstanceID: "82778dd2-330e-48af-ab45-84e78df6971f",
                onLoad: function(instance:any) { instance.render(); }
            };

            let watson_id = 'waston_ibm';
            if(document.getElementById(watson_id) === null) {
                const watson_script = document.createElement('script');
                watson_script.setAttribute('src', "https://web-chat.global.assistant.watson.appdomain.cloud/versions/" + ((window as any).watsonAssistantChatOptions.clientVersion || 'latest') + "/WatsonAssistantChatEntry.js");
                watson_script.setAttribute('id', watson_id);
                document.head.appendChild(watson_script);
            }
        },
        setup(){
            const { currentRoute } = useRouter();
            const role_id = ref(0);
            const OptanonWrapper = () => {

            };
            return {
                currentRoute,
                OptanonWrapper,
                store:store,
                router,
                title: computed(()=>router.currentRoute.value.name),
                role_id,
            }
        }
    });
</script>
<style lang="scss">
    @media (min-width: 992px) {
        .layout-fixed .layout-container, .layout-fixed-offcanvas .layout-container {
            transition: padding-left .4s ease-out;
        }
        .layout-navbar-fixed .layout-2 .layout-navbar, .layout-fixed.layout-navbar-fixed .layout-2 .layout-navbar, .layout-fixed-offcanvas.layout-navbar-fixed .layout-2 .layout-navbar {
            transition: left .4s ease-out;
        }
        .layout-footer-fixed .layout-wrapper .layout-footer {
            transition: left .4s ease-out;
        }

    }

    .top-right{
        position:absolute;
        right: 0;
        z-index: 9000;
    }

    .layout-container {
        padding-top: 60px;
    }
    .layout-content {
        padding-bottom: 53px;
    }

    // cookie manager button
    .ot-floating-button__front {
        background-image: none !important;
        background-color: #6BBAA3 !important;
        color: white !important;
        border-radius: 0 !important;
        width: fit-content !important;
        padding: 10px;
        box-shadow: none !important;

        button{
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
        }

        &:after{
            content: "Cookie\0000a0Settings";
        }
    }
    #ot-sdk-btn-floating.ot-floating-button{
        bottom: 30px !important;
        height: 35px !important;
        left: 0px !important;
        z-index: 999 !important;
    }
</style>
