import {IArticle} from "@/interfaces/article";
import {api} from "@/store";

export class Article{
    constructor(public data?:IArticle) {}

    static async deleteArticle(formData:any):Promise<any>{
        return await api.post('api/article/delete',formData);
    }

    static async saveArticle(formData:any):Promise<any>{
        return await api.post('api/article/save',formData);
    }

    static async updateArticle(formData:any):Promise<any>{
        return await api.post('api/article/update',formData);
    }

}