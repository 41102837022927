<template>
	<div v-if="!loading && landing_pages">
		<div class="row">
			<div class="col-12">
				<h2>Landing Pages</h2>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<table class="table">
					<thead class="bg-secondary text-white">
						<th>Title</th>
						<th>-</th>
					</thead>
					<tbody>
						<tr v-for="landing_page in landing_pages" v-bind:key="landing_page.id">
							<td>{{landing_page.short_name}}</td>
							<td><button class="btn btn-primary" v-on:click="router.push({name:'management.partner',params:{partner_id:landing_page.id}})">View</button></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div v-else-if="err_msg != ''">
		<div class="alert alert-danger">{{err_msg}}</div>
	</div>
	<loading :active="loading" :is-full-page="true"></loading>
</template>
<script lang="ts">
	import { defineComponent, ref } from "vue";
	import { IPartner } from "@/interfaces/partner";
	import Loading from "vue3-loading-overlay";
	import Partner from "@/models/partner";
	import router from "@/router";

	export default defineComponent({
		components: {
			Loading,
		},
		mounted(){
			this.getLandingPages();
		},
		setup(){

			const landing_pages = ref<Array<IPartner> | null>(null);
			const err_msg = ref<String>("");
			const loading = ref<Boolean>(false);

			const getLandingPages = async() => {
				loading.value = true;
				
				await Partner.getLandingPages().then((res) => {
					landing_pages.value = res.data.landing_pages;
				},(err) => {
					console.log(err);
					err_msg.value = err.response.data.message;
				})
				loading.value = false;
			}

			return {
				landing_pages,
				err_msg,
				getLandingPages,
				loading,
				router
			}
		}
	});
</script>
<style scoped lang="scss">

</style>