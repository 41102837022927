<template>
    <div v-if="!loading && !err_msg">
        <div class="row card text-white rounded-0 top-header">
            <div class="col-12 text-center">
                <h2 class="my-3 font-weight-bold">User Management</h2>
            </div>
        </div>
        <div class="row card rounded-0">
            <div class="col-12 card-header">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <button class="btn btn-sm btn-primary mr-5 reset-btn" @click="resetUsers()">Reset Filters</button>
                        <select v-model="role" @change="getUsers()">
                            <option value="0">All Roles</option>
                            <option :value="UserRole.LEARNER">Learners</option>
                            <option :value="UserRole.SP">Standardized Patients</option>
                            <option :value="UserRole.OBSERVER">Observers</option>
                            <option :value="UserRole.TRAINER">Trainers</option>
                            <option :value="UserRole.REVIEWER">Reviewers</option>
                            <option :value="UserRole.AUTHOR">Authors</option>
                            <option :value="UserRole.HEADTRAINER">Head Trainers</option>
                            <option :value="UserRole.EXCLUDEFROMREPORTS">Exclude From Reports</option>
                            <option :value="UserRole.REPORTMANAGER">Report Managers</option>
                            <option :value="UserRole.ADMIN">Admins</option>
                            <option :value="UserRole.CLIENTADMIN">Client Admins</option>
                            <option value="99">Super Admins</option>
                        </select>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div class="input-icons">
                            <i class="fas fa-search" @click="getUsers()"></i>
                            <input type="text" placeholder="search by email" class="float-right" v-model="email_string" v-on:keyup.enter="getUsers()" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 card-body">
                <div v-if="users.length">
                    <div class="row">
                        <div v-for="user in users.slice(page_start,page_end)" v-bind:key="user.id" class="col-6 user-mgmt-card mb-2 pt-2" @click="router.push({name:'management.user',params:{user_id:user.id}})">
                            <div class="row">
                                <div class="col-10">
                                    <img :src="user.image_location" class="rounded-circle float-left mr-5" width="75" />
                                    <h4>{{ user.name }}</h4>
                                    <p>{{ user.email }}</p>
                                </div>
                                <div class="col-2 d-flex flex-column justify-content-center">
                                    <h3 class="text-center"><i class='fas fa-arrow-circle-right text-primary float-right'></i></h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <pagination v-if="users.length > 20" :items="users" :page_start="page_start" :page_end="page_end" v-on:next-page="nextPage" v-on:previous-page="previousPage" :label="'Users'" />
                        </div>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-12">
                        No users match search
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="!loading && err_msg">
        <h4>Error Loading Users</h4>
        <p>{{err_msg}}</p>
    </div>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { defineComponent, ref } from "vue";
    import Loading from 'vue3-loading-overlay';
    import User from '@/models/user';
    import { UserRole } from '@/enums/user-role';
    import Pagination from '@/components/dashboard/pagination.vue';
    import router from "@/router";

    export default defineComponent({
        components: {
            Loading,
            Pagination
        },
        mounted(){
            this.getUsers();
        },
        setup() {
            const loading = ref<Boolean>(true);
            const users = ref<Object>({});
            const err_msg = ref(false);
            const role = ref<Number>(0);
            const email_string = ref<String>("");
            const page_start = ref(0);
            const page_end = ref(20);

            const getUsers = async() => {
                loading.value = true;
                var post_data = {
                    role_id: role.value,
                    email_string: email_string.value
                };
                await User.getUsers(post_data).then((res) => {
                    users.value = res.data.users;
                    page_start.value = 0;
                    page_end.value = 20;
                    loading.value = false;
                    err_msg.value = false;
                },(err)=>{
                    console.log(err)
                    err_msg.value = err.response.data.message;
                    loading.value = false;
                });
            }

            const nextPage = async() => {
                page_start.value = page_start.value + 20;
                page_end.value = page_end.value + 20;
                if(page_end.value > Object.keys(users.value).length){
                    page_end.value = Object.keys(users.value).length;
                }
            };

            const previousPage = async() => {
                page_start.value = page_start.value - 20;
                page_end.value = page_start.value + 20;
            };

            const resetUsers = async() => {
                loading.value = true;
                role.value = 0;
                email_string.value = "";
                getUsers();
            }

            return {
                email_string,
                err_msg,
                getUsers,
                loading,
                nextPage,
                page_end,
                page_start,
                previousPage,
                resetUsers,
                role,
                router,
                UserRole,
                users
            };
        },
    });
</script>

<style lang="scss" scoped>
    .input-icons{
        margin-bottom: 10px;
        i {
            position: absolute;
            padding: 7px;
            min-width: 40px;
            right: 5px;
        }
        input {
            width: 300px;
            max-width: 100%;
            padding-right: 28px;
            border: 0px;
            outline: none;
        }
    }
    select{
        background: transparent;
        border: none;
    }
    .reset-btn {
        min-height: 2rem !important;
    }
    .user-mgmt-card {
        height: 9.5rem;
        cursor: pointer;
        &:hover{
            background-color: #F0F0F1;
        }
        div{
            height: 7rem;
        }
    }
</style>
