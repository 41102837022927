<template>
    <tabs :options="{ useUrlFragment: false }" nav-class="nav nav-pills m-3" nav-item-class="nav-item col-md-6 col-sm-12 text-center" nav-item-link-class="nav-link btn-dashboard-sub-nav" nav-item-link-active-class="active">
        <tab prefix="<h4>" suffix=" / <i class='far fa-clock text-primary'></i></h4>" name="Pending">
            <hr class="w-75 mx-auto" />
            <alerts :alerts="alerts" />
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <pending-scorecards :pending_scorecards="pending_visits" :pending_scorecards_total="pending_visits_total" :visit_loading="pending_visits_loading" v-on:change-page="changePage" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <financial-report :financial_data="financial_data" />
                    <scheduled-training-sessions :scheduled_training_sessions="scheduled_training_sessions" :scheduled_training_sessions_total="scheduled_training_sessions_total" :loading="scheduled_training_sessions_loading" v-on:change-page="changePage" />
                </div>
            </div>
        </tab>
        <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-check text-primary'></i></h4>" name="Completed">
            <hr class="w-75 mx-auto" />
            <alerts :alerts="alerts" />
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <completed-scorecards :completed_scorecards="completed_visits" :completed_scorecards_total="completed_visits_total" :visit_loading="completed_visits_loading" v-on:change-page="changePage" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <financial-report :financial_data="financial_data" />
                    <completed-training-sessions :completed_training_sessions="completed_training_sessions" :completed_training_sessions_total="completed_training_sessions_total" :loading="completed_training_sessions_loading" v-on:change-page="changePage" />
                    <canceled-training-sessions :canceled_training_sessions="canceled_training_sessions" :canceled_training_sessions_total="canceled_training_sessions_total" :loading="canceled_training_sessions_loading" v-on:change-page="changePage" />
                </div>
            </div>
        </tab>
    </tabs>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import { store } from '../../store/index';
    import {Tabs, Tab} from 'vue3-tabs-component';
    import CanceledTrainingSessions from '@/components/dashboard/canceled-training-sessions.vue';
    import CompletedScorecards from '@/components/dashboard/completed-scorecards.vue';
    import CompletedTrainingSessions from '@/components/dashboard/completed-training-sessions.vue';
    import FinancialReport from '@/components/dashboard/financial-report.vue';
    import moment from 'moment';
    import PendingScorecards from '@/components/dashboard/pending-scorecards.vue';
    import router from "@/router";
    import ScheduledTrainingSessions from '@/components/dashboard/scheduled-training-sessions.vue';
    import Visit from '@/models/visit';
    import Alerts from "@/views/dashboards/alerts.vue";

    export default defineComponent({
        components: {
            Alerts,
            CanceledTrainingSessions,
            CompletedScorecards,
            CompletedTrainingSessions,
            FinancialReport,
            PendingScorecards,
            ScheduledTrainingSessions,
            Tab,
            Tabs
        },
        mounted() {
            this.getVisitData(0,'all');
            this.getFinancialData();
        },
        setup() {
            const alerts = ref({});
            const pending_visits = ref([]);
            const pending_visits_total = ref(0);
            const pending_visits_loading = ref<Boolean>(true);
            const completed_visits = ref([]);
            const completed_visits_total = ref(0);
            const completed_visits_loading = ref<Boolean>(true);
            const scheduled_training_sessions = ref([]);
            const scheduled_training_sessions_total = ref(0);
            const scheduled_training_sessions_loading = ref<Boolean>(true);
            const completed_training_sessions = ref([]);
            const completed_training_sessions_total = ref(0);
            const completed_training_sessions_loading = ref<Boolean>(true);
            const canceled_training_sessions = ref([]);
            const canceled_training_sessions_total = ref(0);
            const canceled_training_sessions_loading = ref<Boolean>(true);
            const financial_data = ref({});
            const getFinancialData = async() => {
                var post_data:any = {
                    month: moment().format('M'),
                    year: moment().format('YYYY'),
                    user_id: store.getters.user.props.id,
                    is_author: false
                };
                if(store.getters.partner.id !== 1){
                    post_data.partner_id = store.getters.partner.id;
                }
                Visit.getFinancialData(post_data).then((res)=>{
                    financial_data.value = {
                        completed_total: res.data.completed_total,
                        scorecards_total: res.data.scorecards_total,
                        training_sessions_total: res.data.training_sessions_total,
                        late_cancellations_total: res.data.late_cancellations_total,
                        early_cancellations_total: res.data.early_cancellations_total,
                        no_shows_total: res.data.no_shows_total,
                        learner_technical_issues_total: res.data.learner_technical_issues_total,
                        interviews_total: res.data.interviews_total,
                        authoring_total: res.data.authoring_total,
                        total: res.data.total,
                    };
                },(err)=>{
                    console.log(err);
                });
            };
            const getVisitData = async(page_start:Number,type:String) => {
                if(type == 'all' || type == 'pending'){
                    pending_visits_loading.value = true;
                }
                if(type == 'all' || type == 'completed'){
                    completed_visits_loading.value = true;
                }
                if(type == 'all' || type == 'scheduled_training_sessions'){
                    scheduled_training_sessions_loading.value = true;
                }
                if(type == 'all' || type == 'completed_training_sessions'){
                    completed_training_sessions_loading.value = true;
                }
                if(type == 'all' || type == 'canceled_training_sessions'){
                    canceled_training_sessions_loading.value = true;
                }
                var post_data = {
                    user_id: store.getters.user.props.id,
                    role_id: store.getters.dashboard_view,
                    page_start: page_start,
                    type: type
                };
                Visit.getUserVisits(post_data).then((res)=>{
                    if(type == 'all' || type == 'pending'){
                        pending_visits.value = res.data.pending_scorecards;
                        pending_visits_total.value = res.data.pending_scorecards_total;
                        pending_visits_loading.value = false;
                    }
                    if(type == 'all' || type == 'completed'){
                        completed_visits.value = res.data.completed_scorecards;
                        completed_visits_total.value = res.data.completed_scorecards_total;
                        completed_visits_loading.value = false;
                    }
                    if(type == 'all' || type == 'scheduled_training_sessions'){
                        scheduled_training_sessions.value = res.data.scheduled_training_sessions;
                        scheduled_training_sessions_total.value = res.data.scheduled_training_sessions_total;
                        scheduled_training_sessions_loading.value = false;
                    }
                    if(type == 'all' || type == 'completed_training_sessions'){
                        completed_training_sessions.value = res.data.completed_training_sessions;
                        completed_training_sessions_total.value = res.data.completed_training_sessions_total;
                        completed_training_sessions_loading.value = false;
                    }
                    if(type == 'all' || type == 'canceled_training_sessions'){
                        canceled_training_sessions.value = res.data.canceled_training_sessions;
                        canceled_training_sessions_total.value = res.data.canceled_training_sessions_total;
                        canceled_training_sessions_loading.value = false;
                    }
                    if(type == 'all'){
                        alerts.value = res.data.alerts;
                    }
                },(err)=>{
                    console.log(err);
                });
            };

            const changePage = (vars:any) => {
                getVisitData(vars.page_start,vars.type);
            }
            return {
                alerts,
                changePage,
                pending_visits,
                pending_visits_total,
                pending_visits_loading,
                completed_visits,
                completed_visits_total,
                completed_visits_loading,
                scheduled_training_sessions,
                scheduled_training_sessions_total,
                scheduled_training_sessions_loading,
                completed_training_sessions,
                completed_training_sessions_total,
                completed_training_sessions_loading,
                canceled_training_sessions,
                canceled_training_sessions_total,
                canceled_training_sessions_loading,
                financial_data,
                getFinancialData,
                getVisitData,
                moment,
                router,
            };
        }
    });
</script>
