import {api} from "@/store";
import {IPartner} from "@/interfaces/partner";

export default class Partner{
    constructor(data?: IPartner) {}

    static async clearBannerColor(id:number):Promise<any>{
        return await api.post('api/partner/'+id+'/clear-header-color',{});
    }

    static async clearDates(form:any):Promise<any>{
        return await api.post('api/partner/clear-dates',form);
    }

    static async clearDisabledDates(form:any):Promise<any>{
        return await api.post('api/partner/clear-disabled-dates',form);
    }

    static async getDates():Promise<any>{
        return await api.get('api/partner/dates');
    }

    static async getLandingPages():Promise<any>{
        return await api.get('api/landing-pages');
    }

    static async getPartners():Promise<any>{
        return await api.get('api/partner/all');
    }

    static async getPartnerForConfiguration(id:number):Promise<any>{
        return await api.get('api/partner/'+id+'/configuration');
    }

    static async getPasswords():Promise<any>{
        return await api.get('api/partner/passwords');
    }

    static async getSessionCounterData(form:FormData):Promise<any>{
        return await api.post('api/reports/session-counter',form);
    }

    static async list():Promise<any>{
        return await api.get('api/partner');
    }

    static async saveDates(form:any):Promise<any>{
        return await api.post('api/partner/save-dates',form);
    }

    static async saveDisabledDates(form:any):Promise<any>{
        return await api.post('api/partner/save-disabled-dates',form);
    }

    static async setBannerColor(id:number,form:any):Promise<any>{
        return await api.post('api/partner/'+id+'/set-header-color',form);
    }

    static async setLogo(id:number,form:any):Promise<any>{
        return await api.post('api/partner/'+id+'/set-logo',form);
    }

    static async setWelcomeHeader(id:number,form:any):Promise<any>{
        return await api.post('api/partner/'+id+'/set-welcome-header',form);
    }

    static async setWelcomeMessage(id:number,form:any):Promise<any>{
        return await api.post('api/partner/'+id+'/set-welcome-message',form);
    }

    static async scenarios(id:number):Promise<any>{
        return await api.get('api/partner/'+id+'/scenarios');
    }

    static async savePassword(form:any):Promise<any>{
        return await api.post('api/partner/save-password',form);
    }

    static async single(form:any):Promise<any>{
        return await api.post('api/partner/single',form);
    }

    static async updateSeats(id:number,form:any):Promise<any>{
        return await api.post('api/partner/'+id+'/udpate-seats',form);
    }

    static async updateWelcomeHeader(id:number,form:any):Promise<any>{
        return await api.post('api/partner/'+id+'/update-welcome-header',form);
    }

    static async updateWelcomeMessage(id:number,form:any):Promise<any>{
        return await api.post('api/partner/'+id+'/update-welcome-message',form);
    }

    static async switch(form:any):Promise<any>{
        return await api.post('api/partner/switch',form);
    }
}
