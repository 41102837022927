<template>
    <div class="row">
        <div v-if="applications.length || (!quote_requests.length && !quotes.length)" class="col-12">
            <div class="card shadow-sm">
                <div class="card-header text-white bg-secondary font-weight-bold">Role Applications</div>
                <div class="card-content p-2">
                    <alerts :alerts="alerts" />
                    <div v-if="applications.length">
                        <div v-for="application in applications" v-bind:key="application.id" class="p-2">
                            <div><strong>Application Submitted:</strong> {{moment(application.created_at).format('MM/DD/YY')}}</div>
                            <div>
                                <strong>Status: </strong>
                                <span v-if="application.interview == null && application.interview_requests.length === 0">Pending</span>
                                <span v-else-if="application.interview_requests.length">Interview Requested <button class="btn btn-primary btn-xs" @click="router.push({name:'request',params:{request_id:application.interview_requests[0].id}})">Respond</button></span>
                                <span v-else-if="application.interview && !application.interview.is_complete">Interview scheduled <button class="btn btn-primary btn-xs" @click="router.push({name:'interview',params:{interview_id:application.interview.id}})">View</button></span>
                                <span v-else-if="application.interview && application.interview.is_complete">Under Review</span>
                                <span v-else>Pending</span>
                            </div>
                            <h4>Role(s) Applied for:</h4>
                            <ul>
                                <li v-for="user_role in application.user.user_roles" v-bind:key="user_role.id"><span v-if="user_role.role_id == UserRole.AUTHOR && user_role.partner_id == 1">SP-ED </span><span v-else-if="user_role.role_id == UserRole.AUTHOR && user_role.partner_id == 28">Simclusive </span>{{user_role.role.name}}</li>
                            </ul>
                            <hr>
                        </div>
                    </div>
                    <div v-else class="text-center">
                        You have no role applications at this time.
                    </div>
                </div>
            </div>
        </div>
        <div v-if="applications.length && (quote_requests.length || quotes.length)" class="col-12">
            <br>
        </div>
        <div v-if="quote_requests.length || quotes.length" class="col-12">
            <div class="card shadow-sm">
                <div class="card-header text-white bg-secondary font-weight-bold">Quote Requests</div>
                <div class="card-content p-2">
                    <div v-if="quote_requests.length || quotes.length">
                        <div v-for="quote_request in quote_requests" v-bind:key="quote_request.id" class="p-2">
                            <div><strong>Quote Request Submitted:</strong> {{moment(quote_request.created_at).format('MM/DD/YY')}}</div>
                            <div>
                                <strong>Status: </strong>
                                <span>Pending</span>
                            </div>
                        </div>
                        <div v-for="quote in quotes" v-bind:key="quote.id" class="p-2">
                            <div><strong>Quote Available</strong></div>
                            <div>
                                <strong>Status: </strong>
                                <span v-if="quote.is_rejected">Rejected</span>
                                <span v-else-if="!quote.is_agreement_signed && !quote.is_payment_made">Document signing and Payment required</span>
                                <span v-else-if="quote.is_agreement_signed && !quote.is_payment_made">Payment required</span>
                                <span v-else>Agreement finalized</span>
                                <div class="mt-4"><button class="btn btn-primary btn-xs" @click="router.push({name:'PartnerAgreement',params:{agreement_id:quote.id}})">View Quote</button></div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center">
                        You have no quote requests at this time.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import {store} from "@/store";
    import {UserRole} from "@/enums/user-role";
    import moment from 'moment';
    import router from "@/router";
    import User from "@/models/user";
    import Alerts from "@/views/dashboards/alerts.vue";

    export default defineComponent({
        components: {
            Alerts,
        },
        mounted() {
            this.getApplications();
        },
        setup() {
            const alerts = ref({});
            const applications = ref([]);
            const quotes = ref([]);
            const quote_requests = ref([]);
            const getApplications = async() => {
                if(typeof(store.getters.user) !== 'undefined' && typeof(store.getters.user.props) !== 'undefined'){
                    User.getApplications().then((res)=>{
                        applications.value = res.data.applications;
                        alerts.value = res.data.alerts;
                        quote_requests.value = res.data.quote_requests;
                        quotes.value = res.data.quotes;
                    },(err)=>{
                        console.log(err);
                    });
                }
            }
            return {
                alerts,
                applications,
                getApplications,
                moment,
                quotes,
                quote_requests,
                router,
                UserRole
            };
        }
    });
</script>
