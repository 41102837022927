<template>
    <tr class="cursor-pointer" @click="router.push({name:'recruitment.pending',params:{user_id:recruit.id}})">
        <td class="align-middle py-3">
            <div class="media align-items-center">
                <user-image :source="recruit.image"/>
                <div class="media-body pl-3">
                    <div>{{ recruit.name}}</div>
                    <div class="text-muted small">{{ recruit.email }}</div>
                </div>
            </div>
        </td>
        <td class="align-middle py-3 d-none d-md-block">
            <div class="text-muted small">Applied For</div>
            <div class="small">{{recruit.applied}}</div>
        </td>
        <td class="align-middle py-3">
            <div class="text-muted small">Submitted</div>
            <div class="small">{{recruit.created_at}}</div>
        </td>
        <td class="align-middle py-3">
            <div class="d-flex justify-content-end"><i class="fa fa-chevron-right fa-2x text-light"></i></div>
        </td>
    </tr>
</template>

<script lang="ts">

    import {defineComponent} from 'vue';
    import UserImage from "@/components/user-image.vue";
    import router from "@/router";
    export default defineComponent({
        components: {UserImage},
        props: {
            recruit:{type: Object, required:true},
        },
        name: "recruit-list-item",
        setup(){
            return{
                router
            }
        }
    })
</script>

<style scoped>

</style>
