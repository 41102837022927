import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card shadow-sm bg-light" }
const _hoisted_2 = { class: "card-content p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn btn-primary btn-md d-block m-auto",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push({name:'scenario.list'})))
      }, "Browse Scenarios")
    ])
  ]))
}