import {api} from "@/store";
import {IFeedbackForm} from "@/interfaces/feedback-form";

export default class FeedbackForm{
    constructor(public data?:IFeedbackForm) {}

    static async find(scenario_id:Number):Promise<any>{
        return api.get('api/feedback-form/find/'+scenario_id);
    }

    static async addQuestion(id:Number|undefined,form:any):Promise<any>{
        return api.post('api/feedback-form/'+id+'/add-question',form);
    }

    static async editQuestion(id:Number|undefined,form:any):Promise<any>{
        return api.post('api/feedback-form/'+id+'/edit-question',form);
    }

    static async enable(scenario_id:Number|undefined):Promise<any>{
        return api.post('api/feedback-form/' + scenario_id + '/enable');
    }

    static async removeQuestion(scenario_id:Number|undefined, question_id:Number):Promise<any>{
        return  api.post('api/feedback-form/'+scenario_id+'/remove-question/'+question_id);
    }

    static async removeRubric(scenario_id:Number|undefined, form_id:Number):Promise<any>{
        return  api.post('api/feedback-form/'+scenario_id+'/remove-rubric/'+form_id);
    }

    static async reorder(id:Number|undefined,form:any):Promise<any>{
        return  api.post('api/feedback-form/'+id+'/reorder',form);
    }

    static async saveInstructions(feedback_form_id:Number|undefined,instructions:String):Promise<any>{
        return api.post('api/feedback-form/' + feedback_form_id + '/instructions',{instructions:instructions});
    }
    
    static async submitVisitFeedbackForm(visit_id:string|string[],form:any):Promise<any>{
        return api.post('api/visit/' + visit_id + '/feedback-form/submit',form);
    }

    
}
