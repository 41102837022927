<template>
    <div>
        <div class="row" id="visit-request">
            <div class="col-12">
                <div class="card">
                    <div class="row mx-2 my-5">
                        <div class="col-sm-12 col-md-4 order-md-12">
                            <p>An interview has been requested by {{interviewRequest.trainer.name}}.</p>
                            <p>Please review the below dates and approve a selection that is convienent for you.</p>
                            <p>If you are not available on either date, please reject this request and a new set will be sent to you.</p>
                            <label v-for="date in interviewRequest.dates" :key="date.id" class="d-block">
                                <input type="radio" name="request_date_id" :value="date.id" v-model="form.request_date_id.value"> {{ moment(date.local_time).format('MM/DD/YY h:mm a') }}
                            </label>
                            <button type="button" class="btn btn-lg btn-secondary w-100 mb-2" @click="showApproveModal">Accept</button>
                            <button type="button" class="btn btn-lg btn-secondary w-100 mb-2" @click="showRejectModal">Reject</button>
                        </div>
                        <div class="col-sm-12 col-md-8 order-sm-1">
                            <div class="row">
                                <div class="col-12 card">
                                    <div class="text-center mt-5">
                                        <img class="rounded-circle" width="100" :src="interviewRequest.trainer.image"/>
                                        <p class="h2">{{interviewRequest.trainer.name}}</p>
                                        <p class="applicant-contact mb-0 w-50 m-auto">{{interviewRequest.trainer.email}}</p>
                                        <p class="applicant-contact mb-5 w-50 m-auto">Trainer</p>
                                        <hr class="hr-dark">
                                    </div>
                                    <div class="mx-5">
                                        <h4>Experience</h4>
                                        <p>{{interviewRequest.trainer.experience}}</p>
                                        <br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <modal v-model="approveModal">
        <template v-slot:header>Approve Interview Request</template>
        <p>Are you sure you want to approve this request?</p>
        <button type="button" class="btn btn-primary btn-xs float-right" @click="approveRequest">Approve Interview Request</button>
    </modal>
    <modal v-model="rejectModal">
        <template v-slot:header>Reject Interview Request</template>
        <p>Are you sure you want to reject this request?</p>
        <button type="button" class="btn btn-primary btn-xs float-right" @click="rejectRequest">Reject Interview Request</button>
    </modal>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { api } from "@/store";
    import { defineComponent, reactive, ref } from "vue";
    import { store } from '../../store/index';
    import Request from "@/models/request";
    import {RequestType} from "@/enums/request-type";
    import {FormConfig} from "@/interfaces/form-config";
    import Form from "@/utilities/form";
    import Loading from 'vue3-loading-overlay';
    import moment from 'moment';
    import {NotificationIcon, NotificationColor} from "@/interfaces/notification";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import { useRoute } from 'vue-router';
    import router from "@/router";
    import Modal from "@/components/modal.vue";

    export default defineComponent({
        components: {Loading, Modal},
        mounted(){
            this.getRequest();
        },
        props: {
            request: Object
        },
        setup(props) {
            const loading = ref(false);
            const interviewRequest = ref(props.request);
            const route = useRoute();
            const approveModal = ref(false);
            const showApproveModal = ()=> approveModal.value = !approveModal.value;
            const rejectModal = ref(false);
            const showRejectModal = ()=> rejectModal.value = !rejectModal.value;
            const form:FormConfig = reactive({
                request_date_id:{
                    value:'',
                    valid:false,
                    validationMessage:''
                },
                reject:{
                    value:false,
                    valid:true,
                    validationMessage:''
                },
                request_type:{
                    value:RequestType.INTERVIEW,
                    valid:true,
                    validationMessage:''
                },
                request_id:{
                    value:route.params.request_id,
                    valid:true,
                    validationMessage:''
                }
            });
            const formHelper = new Form(form);

            const getRequest = async () => {
                loading.value = true;
                await api.get('sanctum/csrf-cookie');
                api.get('api/request/' + route.params.request_id,{}).then((res)=>{
                    interviewRequest.value = res.data.request;
                    form.request_date_id.value = res.data.request.dates.id
                    form.request_date_id.valid = true;
                    loading.value = false;
                },(err)=>{
                    console.log(err);
                    loading.value = false;
                });
            }

            const approveRequest = async() => {
                loading.value = true;
                form.reject.value = false;
                await Request.approve(formHelper.mapValues()).then(()=>{
                    router.push({name:'dashboard'});
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Interview request has been approved!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                },(err:any)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error approving interview request.',content:err.response.data.message,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const rejectRequest = async() => {
                loading.value = true;
                form.reject.value = true;
                await Request.reject(formHelper.mapValues()).then(()=>{
                    router.push({name:'dashboard'});
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Interview request has been rejected.',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                },(err:any)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error approving interview request.',content:err.response.data.message,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false
            }

            return {
                user: store.state.auth.user,
                loading,
                moment,
                route,
                form,
                formHelper,
                getRequest,
                approveRequest,
                rejectRequest,
                interviewRequest,
                approveModal,
                showApproveModal,
                rejectModal,
                showRejectModal
            };
        },
    });
</script>
