<template>
    <tabs :options="{ useUrlFragment: false }" nav-class="nav nav-pills m-3" nav-item-class="nav-item col-md-3 col-sm-12 text-center" nav-item-link-class="nav-link btn-dashboard-sub-nav" nav-item-link-active-class="active">
        <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-clipboard-list text-primary'></i></h4>" name="Awaiting Review">
            <hr class="w-75 mx-auto" />
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <scenario-need-reviews :scenario_need_reviews="scenario_need_reviews" :visit_loading="visit_loading" />
                </div>
            </div>
        </tab>
         <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-eye text-primary'></i></h4>" name="Under My Review">
            <hr class="w-75 mx-auto" />
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <scenario-in-review :scenario_in_review="scenario_in_review" :visit_loading="visit_loading" />
                </div>
            </div>
        </tab>
       <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-i-cursor text-primary'></i></h4>" name="Edits">
            <hr class="w-75 mx-auto" />
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <scenario-edits :scenario_edits="scenario_edits" :visit_loading="visit_loading" />
                </div>
            </div>
        </tab>
        <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-check-circle text-primary'></i></h4>" name="Approved">
            <hr class="w-75 mx-auto" />
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <scenario-approved :scenario_approved="scenario_approved" :visit_loading="visit_loading" />
                </div>
            </div>
        </tab>
    </tabs>
    <Loading :active="loading" :is-full-page="true"></Loading>
</template>

<script lang="ts">
    import {defineComponent,ref} from "vue";
    import {Tabs, Tab} from 'vue3-tabs-component';
    import Loading from "vue3-loading-overlay";
    import moment from 'moment';
    import router from "@/router";
    import ScenarioApproved from '@/components/dashboard/scenario-approved.vue';
    import ScenarioEdits from '@/components/dashboard/scenario-edits.vue';
    import ScenarioInReview from '@/components/dashboard/scenario-in-review.vue';
    import ScenarioNeedReviews from '@/components/dashboard/scenario-need-reviews.vue';
    import Scenario from '@/models/scenario';

    export default defineComponent({
        components: {
            Loading,
            ScenarioApproved,
            ScenarioEdits,
            ScenarioInReview,
            ScenarioNeedReviews,
            Tab,
            Tabs,
        },
        mounted() {
            this.getReviewerData();
        },
        setup() {
            const loading = ref(false);
            const scenario_approved = ref([]);
            const scenario_edits = ref([]);
            const scenario_in_review = ref([]);
            const scenario_need_reviews = ref([]);
            const visit_loading = ref<Boolean>(true);
            const getReviewerData = async() => {
                Scenario.getReviewerData().then((res)=>{
                    scenario_approved.value = res.data.scenario_approved;
                    scenario_edits.value = res.data.scenarios_in_edit;
                    scenario_in_review.value = res.data.scenarios_in_review;
                    scenario_need_reviews.value = res.data.scenarios_needing_review;
                    visit_loading.value = false;
                },(err)=>{
                    console.log(err);
                });
            };
            return {
                loading,
                scenario_approved,
                scenario_edits,
                scenario_in_review,
                scenario_need_reviews,
                getReviewerData,
                moment,
                router,
                visit_loading,
            };
        }
    });
</script>
