<template lang="html">
    <div v-if="!loading && !loading_error" class="card" id="scenario-authoring">
        <div class="card-header bg-dark">
            <h3 class="text-center text-white my-2">{{rubric.name}}</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div v-if="!rubric.is_published" class="col-12">
                    <button @click="publish" class="btn btn-primary">Publish Rubric</button>
                    <button @click="deleteRubric" class="btn btn-primary ml-2">Delete Rubric</button>
                    <br />
                    <br />
                    <hr />
                    <br />
                </div>
                <div class="col-12">
                    <button v-if="!rubric.is_published" @click="edit_name = !edit_name" class="btn btn-link text-primary btn-sm"><i class="fa fa-edit"></i> </button>
                    <label class="text-muted">Name: </label> <span v-if="!edit_name">{{rubric.name}}</span>
                    <div v-if="edit_name">
                        <input type="text" v-model="name" class="form-control">
                        <span class="float-right">
                            <button class="btn btn-primary" type="button" @click="saveName"><span class="d-none d-xl-block">Save</span><i class="fas fa-save d-block d-xl-none"></i></button>
                            <button class="btn btn-secondary" @click="edit_name = false" type="button"><span class="d-none d-xl-block">Cancel</span><i class="fas fa-ban d-block d-xl-none"></i></button>
                        </span>
                        <Loading :active="name_loading" :is-full-page="false"></Loading>
                    </div>
                </div>
                <div v-if="!rubric.is_published || (rubric.is_published && rubric.scorer_instructions !== null)" class="col-12">
                    <button v-if="!rubric.is_published" @click="edit_scorer = !edit_scorer" class="btn btn-link text-primary btn-sm"><i class="fa fa-edit"></i> </button>
                    <label class="text-muted">Scorer Instructions</label>
                    <div v-if="!edit_scorer" v-html="rubric.scorer_instructions" class="pl-4 pt-2"></div>
                    <div v-if="edit_scorer">
                        <text-editor v-model="scorer_instructions"></text-editor>
                        <div class="d-flex justify-content-end mt-2">
                            <button class="btn btn-primary" @click="saveScorerInstructions">Save</button>
                            <button class="btn btn-default mx-2" @click="edit_scorer = false">Cancel</button>
                        </div>
                    </div>
                </div>
                <div v-if="!rubric.is_published || (rubric.is_published && rubric.scorer_instructions !== null)" class="col-12">
                    <button v-if="!rubric.is_published" @click="edit_learner = !edit_learner" class="btn btn-link text-primary btn-sm"><i class="fa fa-edit"></i> </button>
                    <label class="text-muted">Learner Instructions</label>
                    <div v-if="!edit_learner" v-html="rubric.learner_instructions" class="pl-4 pt-2"></div>
                    <div v-if="edit_learner">
                        <text-editor v-model="learner_instructions"></text-editor>
                        <div class="d-flex justify-content-end mt-2">
                            <button class="btn btn-primary" @click="saveLearnerInstructions">Save</button>
                            <button class="btn btn-default mx-2" @click="edit_learner = false">Cancel</button>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <br />
                    <hr />
                    <br />
                </div>
                <div class="col-12">
                    <button @click="cloneRubric" class="btn btn-primary btn-sm">Clone Rubric</button>
                </div>
                <div class="col-12">
                    <br />
                    <hr />
                    <br />
                </div>
                <div v-if="!rubric.is_published" class="col-12">
                    <div class="d-flex justify-content-between align-items-center pb-4">
                        <create-question :form_type="'rubric'" @update="newQuestion" :rubric="rubric">
                            <template v-slot:trigger="triggerProps">
                                <button @click="triggerProps.click" class="btn btn-primary"><i class="fa fa-plus"></i> Create Question </button>
                            </template>
                        </create-question>
                    </div>
                </div>

                <div class="col-md-12">
                    <h4 class="text-secondary">Questions:</h4>
                    <ul class="list-unstyled pt-2">
                        <VueDraggableNext v-model="rubric.rubric_questions" @change="reorder" :sort="!rubric.is_published">
                          <li class="px-4 py-2 border-bottom"  :class="{'border-0':index+1 === rubric.questions.length,'bg-lighter':item.rubric}" v-for="(item,index) in rubric.rubric_questions" v-bind:key="index">
                            <div v-if="item.question" class="d-flex justify-content-between align-items-center">
                                <i class="text-primary fas fa-bars"></i>
                                <div class="px-4 w-100">{{ item.question.short_description }}</div>
                                <edit-question :rubric="rubric" :question="item.question" @updateQuestionData="getRubric" :key='item.question.id' :form_type="'rubric'">
                                    <template v-slot:trigger="triggerProps">
                                        <i v-if="!rubric.is_published" @click="triggerProps.click" class="cursor-pointer text-primary fa fa-edit"></i>
                                    </template>
                                </edit-question>&nbsp;&nbsp;
                                <i v-if="!rubric.is_published" @click="showDeleteQuestionModal(item.id)" class="cursor-pointer text-primary fa fa-trash"></i>
                                <modal v-model="delete_question[item.id]">
                                    <template v-slot:header>Permanently Delete Question</template>
                                    <p>Are you sure you want to delete this Question?</p>
                                    <button class="btn btn-xs btn-secondary-alt float-right" @click="showDeleteQuestionModal(item.id)">Cancel</button>
                                    <button type="button" class="btn btn-primary btn-xs" @click="removeQuestion(item.id,index)">Permanently Delete</button>
                                </modal>
                            </div>
                            <div v-if="item.rubric" class="d-flex align-items-center">
                                <i class="text-primary fas fa-bars"></i>
                                <div class="px-4 w-100">
                                    <strong>Rubric: {{item.rubric.name}}</strong>
                                    <ul class="list-unstyled">
                                        <li class="py-2 border-bottom" :class="{'border-0':index+1 === item.rubric.rubric_questions.length}" v-for="(rubricItem,index) in item.rubric.rubric_questions" v-bind:key="index">
                                            {{rubricItem.short_description}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        </VueDraggableNext>
                    </ul>
                    <dynamic-form class="mt-4" label="Rubric Preview" :questions="formatted_questions"></dynamic-form>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!loading && loading_error">
        <h3>Error Loading Rubric</h3>
        <p>{{loading_error_str}}</p>
    </div>
    <Loading :active="loading" :is-full-page="true"></Loading>
</template>
<script lang="ts">
    import { defineComponent, ref} from "vue";
    import CreateQuestion from "@/components/create-question.vue";
    import DynamicForm from "@/components/dynamic-form/dynamic-form.vue";
    import EditQuestion from "@/components/edit-question.vue";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    import {store} from "@/store";
    import Loading from 'vue3-loading-overlay';
    import Modal from "@/components/modal.vue";
    import router from "@/router";
    import {Rubric} from "@/models/rubric";
    import Swal from 'sweetalert2';
    import TextEditor from "@/components/text-editor.vue";
    import {VueDraggableNext} from 'vue-draggable-next';
    export default defineComponent( {
        components: {
            CreateQuestion,
            DynamicForm,
            EditQuestion,
            Loading,
            Modal,
            TextEditor,
            VueDraggableNext
        },
        name:'Rubric',
        mounted(){
            this.getRubric();
        },
        setup(){
            const edit_learner = ref(false);
            const edit_name = ref(false);
            const edit_scorer = ref(false);
            const delete_question = ref<Array<Boolean>>([]);
            const learner_instructions = ref<String>("");
            const loading = ref(false);
            const loading_error = ref<Boolean>(false);
            const loading_error_str = ref<String>("");
            const name = ref<String>("");
            const name_loading = ref<Boolean>(false);
            const rubric_id = ref<number | undefined>(router.currentRoute.value.params.id ? parseInt(router.currentRoute.value.params.id as string) : undefined);
            const rubric = ref<any>({});
            const scorer_instructions = ref<String>("");
            const formatted_questions = ref<Object>([]);
            const showDeleteQuestionModal = (id:any) => delete_question.value[id] = !delete_question.value[id];

            const cloneRubric = async() => {
                loading.value = true;
                Swal.fire({
                    title: 'Clone Rubric',
                    text: 'Are you sure you would like to clone this rubric?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Clone',
                    cancelButtonText: 'Cancel'
                }).then(async (result) => {
                    if(result.value){
                        await Rubric.clone({rubric_id:rubric.value.id}).then((res)=>{
                            store.commit(MutationTypes.addSystemNotification, {title:'Rubric Cloned',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                            router.push({path:'/rubrics/' + res.data.rubric_id});
                            rubric_id.value = res.data.rubric_id;
                            getRubric();
                        },(err)=>{
                            store.commit(MutationTypes.addSystemNotification, {title:'Error Cloning Rubric',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                        });
                        loading.value = false;
                    }else if(result.dismiss === Swal.DismissReason.cancel){
                        loading.value = false;
                        Swal.close();
                    } else {
                        loading.value = false;
                        store.commit(MutationTypes.addSystemNotification, {title:'Missing Data Warning',content:'',icon:NotificationIcon.WARNING, color:NotificationColor.YELLOW});
                        Swal.close();
                    }
                });
            }

            const deleteRubric = () => {
                if(rubric.value.is_published === 1){
                    store.commit(MutationTypes.addSystemNotification, {title:'Rubric Deletion Not Allowed',content:'Rubric already deleted.',icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                }else{
                    Swal.fire({
                        title: 'Delete Rubric',
                        text: 'Are you sure you would like to delete this rubric? This action cannot be reversed.',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'
                    }).then(async (result) => {
                        if (result.value) {
                            loading.value = true;
                            await Rubric.deleteRubric(rubric.value.id).then(()=>{
                                store.commit(MutationTypes.addSystemNotification, {title:'Rubric Deleted',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                                router.push({name:'rubric.list'});
                            },(err)=>{
                                store.commit(MutationTypes.addSystemNotification, {title:'Error Deleting Rubric',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                            });
                            loading.value = false;
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.close();
                        } else {
                            store.commit(MutationTypes.addSystemNotification, {title:'Missing Data Warning',content:'',icon:NotificationIcon.WARNING, color:NotificationColor.YELLOW});
                        }
                    });
                }
            }

            const formatQuestions = () => {
                formatted_questions.value = [
                    {
                        rubric: rubric.value,
                        question: null
                    }
                ];
            }

            const getRubric = async()=>{
                loading.value = true;
                await Rubric.getRubric(rubric_id.value).then((res)=>{
                    rubric.value = res.data.rubric;
                    name.value = rubric.value.name;
                    learner_instructions.value = rubric.value.learner_instructions;
                    scorer_instructions.value = rubric.value.scorer_instructions;
                    delete_question.value = [];
                    for(const[key,value] of Object.entries(rubric.value.rubric_questions)){
                        key;
                        //@ts-ignore
                        delete_question.value[value.id] = false;
                    }
                    formatQuestions();
                    loading_error.value = false;
                    loading_error_str.value = '';
                },(err)=>{
                    loading_error.value = true;
                    loading_error_str.value = err.response.data.message;
                    loading.value = false;
                });
                loading.value = false;
            }

            const newQuestion = (newQuestion:any) =>{
                rubric.value.rubric_questions.push(newQuestion);
                rubric.value.questions.push(newQuestion.question);
                delete_question.value[newQuestion.id] = false;
                formatQuestions();
            }

            const publish = async() => {
                if(rubric.value.rubric_questions.length === 0){
                    store.commit(MutationTypes.addSystemNotification, {title:'Rubric incomplete',content:'Please attach questions before publishing.',icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                }else{
                    Swal.fire({
                        title: 'Publish Rubric',
                        text: 'Are you sure you would like to publish this rubric?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'
                    }).then(async (result) => {
                        if (result.value) {
                            loading.value = true;
                            await Rubric.publish(rubric.value.id).then(()=>{
                                rubric.value.is_published = true;
                                store.commit(MutationTypes.addSystemNotification, {title:'Rubric Published',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                            },(err)=>{
                                store.commit(MutationTypes.addSystemNotification, {title:'Error Publishing Rubric',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                            });
                            loading.value = false;
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.close();
                        } else {
                            store.commit(MutationTypes.addSystemNotification, {title:'Missing Data Warning',content:'',icon:NotificationIcon.WARNING, color:NotificationColor.YELLOW});
                        }
                    });
                }
            }

            const removeQuestion = async (id:number,index:number)=>{
                showDeleteQuestionModal(id);
                loading.value = true;
                await Rubric.removeQuestion(rubric.value.id,id).then((res)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:res.data.message,content:'Question successfully deleted.',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                    rubric.value.rubric_questions.splice(index,1);
                    rubric.value.questions.splice(index,1);
                    formatQuestions();
                    loading.value = false;
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error Deleting Question.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    loading.value = false;
                });
                loading.value = false;
            }

            const reorder = async ()=> {
                let newOrder:Array<Number> = [];
                rubric.value?.rubric_questions.forEach((value:any) => {
                    newOrder.push(value.id);
                });
                await Rubric.reorder(rubric_id.value,{order:newOrder}).then(()=>{
                    getRubric();
                },(err)=>{
                    console.log(err);
                    store.commit(MutationTypes.addSystemNotification,{title:'Error',content:'There was an error reordering the questions.',icon:NotificationIcon.WARNING,color:NotificationColor.YELLOW});
                });
            }

            const saveName = async() => {
                loading.value = true;
                if(name.value == ""){
                    store.commit(MutationTypes.addSystemNotification, {title:'Invalid data.',content:'Please provide content for the name field.',icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                }else{
                    let post_data = {
                        rubric_id: rubric_id.value,
                        field: 'name',
                        value: name.value
                    };
                    await Rubric.saveField(post_data).then(() => {
                        rubric.value.name = name.value;
                        edit_name.value = false;
                        store.commit(MutationTypes.addSystemNotification, {title:'Rubric updated.',content:'',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    },(err) => {
                        store.commit(MutationTypes.addSystemNotification, {title:'Error saving rubric.',content:err.response.statusText,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                    });
                }
                loading.value = false
            }

            const saveLearnerInstructions = async() => {
                loading.value = true;
                let post_data = {
                    rubric_id: rubric_id.value,
                    field: 'learner_instructions',
                    value: learner_instructions.value
                };
                await Rubric.saveField(post_data).then(() => {
                    rubric.value.learner_instructions = learner_instructions.value;
                    edit_learner.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Rubric updated.',content:'',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                },(err) => {
                    store.commit(MutationTypes.addSystemNotification, {title:'Error saving rubric.',content:err.response.statusText,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const saveScorerInstructions = async() =>{
                loading.value = true;
                let post_data = {
                    rubric_id: rubric_id.value,
                    field: 'scorer_instructions',
                    value: scorer_instructions.value
                };
                await Rubric.saveField(post_data).then(() => {
                    rubric.value.scorer_instructions = scorer_instructions.value;
                    edit_scorer.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Rubric updated.',content:'',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                },(err) => {
                    store.commit(MutationTypes.addSystemNotification, {title:'Error saving rubric.',content:err.response.statusText,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            return {
                cloneRubric,
                delete_question,
                deleteRubric,
                edit_learner,
                edit_name,
                edit_scorer,
                formatted_questions,
                getRubric,
                learner_instructions,
                loading,
                loading_error,
                loading_error_str,
                name,
                name_loading,
                newQuestion,
                publish,
                removeQuestion,
                reorder,
                rubric,
                saveLearnerInstructions,
                saveName,
                saveScorerInstructions,
                scorer_instructions,
                showDeleteQuestionModal
            }
        }
    });
</script>
<style lang="scss">
    @import "src/assets/styles/pages/chat";
    @import "src/assets/styles/theme-paper";
    @import "sweetalert2/dist/sweetalert2";
</style>
