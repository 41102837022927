<template>
    <div class="card shadow-sm mb-4">
        <div class="card-header text-white bg-secondary font-weight-bold">Filters</div>
        <div class="card-content">
            <div class="row m-auto" v-if="!loading">
                <div class="col-md-12 px-5 py-2 financial-calendar">
                    <div class="row pt-4">
                        <div class="col-sm-12 col-md-8">
                            <select class="border-0 text-secondary mr-2 mb-2" v-model="partner_id">
                                <option :value="null">All Courses</option>
                                <option v-for="partner in partners" v-bind:key="partner.id" :value="partner.id">{{partner.short_name}}</option>
                            </select>
                            <select class="border-0 text-secondary mr-2" v-model="scenario_id">
                                <option :value="null">All Scenarios</option>
                                <option v-for="scenario in scenarios" v-bind:key="scenario.id" :value="scenario.id">{{scenario.patient_name}}</option>
                            </select>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <button class="btn btn-primary btn-xs mb-1 float-right" @click="updateDashboard()">Update</button>
                        </div>
                    </div>
                    <hr>
                </div>
            </div>
            <div class="loading-area" v-else>
                <loading :active="loading" :is-full-page="true"></loading>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent,ref} from "vue";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationIcon, NotificationColor} from "@/interfaces/notification";
    import {store} from '../../store/index';
    import Loading from 'vue3-loading-overlay';
    import Visit from "@/models/visit";

    export default defineComponent({
        emits: ['dashboard-change'],
        components: {
            Loading
        },
        props: {
            is_global: {type:Boolean,default:false},
            report_data: {type:Object,required:true},
            is_client_admin: {type:Boolean,default:false},
            filter_partner_id: {default:null},
            filter_scenario_id: {default:null}
        },
        watch: {
            report_data: function(newData){
                this.partners = newData.partners;
                this.scenarios = newData.scenarios;
                this.loading = false;
            },
            filter_partner_id: function(newData){
                this.partner_id = newData;
            },
            filter_scenario_id: function(newData){
                this.scenario_id = newData;
            }
        },
        setup(props,  {emit}) {
            const loading = ref<boolean>(true);
            const partners = ref({});
            const scenarios = ref({});
            const partner_id = ref(props.filter_partner_id);
            const scenario_id = ref(props.filter_scenario_id);

            const updateDashboard = async() => {
                loading.value = true;
                var post_data:any = {
                    partner_id: partner_id.value,
                    scenario_id: scenario_id.value,
                    user_id: store.getters.user.props.id,
                    role_id: store.getters.dashboard_view,
                    page_start: 0,
                    type: 'all',
                };
                props;
                Visit.updateDashboard(post_data).then((res)=>{
                    emit('dashboard-change',res);
                    loading.value = false;
                },(err)=>{
                    console.log(err);
                    store.commit(MutationTypes.addSystemNotification, {title:'Error retrieving report data.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    loading.value = false;
                });
            };
            return {
                updateDashboard,
                partner_id,
                partners,
                scenario_id,
                scenarios,
                loading,
                store,
            };
        }
    });
</script>
<style scoped lang="scss">
    table{
        tr{
            td{
                border: 0;
            }
        }
    }
    .loading-area{
        height: 500px;
    }
</style>
