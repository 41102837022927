<template>
    <div v-if="!loading && !err_msg">
        <div class="row card text-white rounded-0 top-header">
            <div class="col-12 text-center">
                <h2 class="my-3 font-weight-bold">User Management</h2>
            </div>
        </div>
        <div class="row card rounded-0">
            <div class="col-12 card-header">
                <h4 class="d-inline">{{user.name}}</h4>
                <button class="btn btn-sm btn-primary float-right return-btn" @click="router.push({name:'management.users'})">Return to User List</button>
            </div>
            <div class="col-12 card-body">
                <div class="text-center">
                    <img :src="user.image_location" class="rounded-circle" width="100" />
                    <h4>{{user.name}}</h4>
                    <h5>ID: {{user.id}} | {{user.email}} | {{user.mobile_number}}</h5>
                    <h5 v-if="user.mobile_verified_at">Mobile Verified {{moment(user.mobile_verified_at).format('MM/DD/YY h:mm a')}}</h5>
                    <h5 v-else>Mobile not verified</h5>
                    <hr class="w-75" />
                </div>
                <div>
                    <h4>Partners</h4>
                    <div v-if="user.partners.length" class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Partner</th>
                                    <th>Auto Login</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="partner in user.partners" v-bind:key="partner.id">
                                    <td>{{partner.long_name}} - {{partner.slug}}</td>
                                    <td>{{partner.al_link}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p v-else>User not associated to any partners</p>
                    <hr class="w-75" />
                </div>
                <div v-if="store.getters.hasPermission('user.edit')">
                    <h4>Update Password</h4>
                    <small class="d-block mb-4">Password must be at least 8 characters long</small>
                    <label class="d-block">Enter New Password</label>
                    <input class="d-block mb-4" type="password" v-model="password" />
                    <label class="d-block">Confirm Password</label>
                    <input class="d-block mb-4" type="password" v-model="confirm_password" />
                    <div v-if="password_error.length" class="alert alert-danger">{{password_error}}</div>
                    <button class="btn btn-primary my-4" @click="updatePassword()">Update Password</button>
                    <hr class="w-75" />
                </div>
                <div v-if="store.getters.hasPermission('user.edit')">
                    <h4>Notification Settings</h4>
                    <label class="d-block"><input type="checkbox" v-model="enable_email" /> Email</label>
                    <label class="d-block"><input type="checkbox" v-model="enable_sms" /> SMS</label>
                    <label class="d-block"><input type="checkbox" v-model="enable_autologin" /> Auto Login</label>
                    <button class="btn btn-primary my-4" @click="updateNotificationSettings()">Update Settings</button>
                    <hr class="w-75" />
                </div>
                <div>
                    <h4>Visits</h4>
                    <div v-if="visits.length" class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Scenario</th>
                                    <th>Partner</th>
                                    <th>Date</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>View</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="visit in visits.slice(visits_page_start,visits_page_end)" v-bind:key="visit.id">
                                    <td>{{visit.scenario.patient_name}}</td>
                                    <td>{{visit.partner.slug}}</td>
                                    <td>{{moment(visit.start_time).format('MM/DD/YY h:mm a')}} - {{visit.timezone.title}}</td>
                                    <td v-if="visit.learner_user_id == user.id">Learner</td>
                                    <td v-else-if="visit.sp_user_id == user.id">SP</td>
                                    <td v-else-if="visit.observer_users.filter(obs => obs.user_id == user.id)">Observer</td>
                                    <td v-else>-</td>
                                    <td v-if="visit.is_canceled">Canceled</td>
                                    <td v-else-if="visit.no_show">No Show</td>
                                    <td v-else-if="visit.learner_technical_issue">Learner Technical Issue</td>
                                    <td v-else-if="visit.is_complete">Completed</td>
                                    <td v-else>Scheduled</td>
                                    <td><i class='fas fa-arrow-circle-right text-primary' @click="router.push({name:'visit',params:{visit_id:visit.id}})"></i></td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination v-if="visits.length > 10" :items="visits" :page_start="visits_page_start" :page_end="visits_page_end" v-on:next-page="nextPageVisits" v-on:previous-page="previousPageVisits" :label="'Visits'" />
                    </div>
                    <p v-else>User not associated to any visits</p>
                    <hr class="w-75" />
                </div>
                <div>
                    <h4>Training Sessions</h4>
                    <div v-if="training_sessions.length" class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>View</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="training_session in training_sessions.slice(training_sessions_page_start,training_sessions_page_end)" v-bind:key="training_session.id">
                                    <td>{{training_session.type.name}}</td>
                                    <td>{{moment(training_session.start_time).format('MM/DD/YY h:mm a')}} - {{training_session.timezone.title}}</td>
                                    <td v-if="training_session.trainer_user_id == user.id">Trainer</td>
                                    <td v-else>Trainee</td>
                                    <td v-if="training_session.is_canceled">Canceled</td>
                                    <td v-else-if="training_session.is_complete">Complete</td>
                                    <td v-else>Scheduled</td>
                                    <td><i class='fas fa-arrow-circle-right text-primary' @click="router.push({name:'training',params:{training_session_id:training_session.id}})"></i></td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination v-if="training_sessions.length > 10" :items="training_sessions" :page_start="training_sessions_page_start" :page_end="training_sessions_page_end" v-on:next-page="nextPageTrainingSessions" v-on:previous-page="previousPageTrainingSessions" :label="'Training Sessions'" />
                    </div>
                    <p v-else>User not associated to any training sessions</p>
                    <hr class="w-75" />
                </div>
                <div v-if="availabilities.length">
                    <h4>Availabilities <small>(times are reflected in your time zone)</small></h4>
                    <div v-if="availabilities.filter(ava => ava.day == 'sunday').length" class="mb-4">
                        <p class="mb-0"><strong>Sunday</strong></p>
                        <div v-for="ava in availabilities.filter(ava => ava.day == 'sunday')" v-bind:key="ava.id">{{moment(ava.local_start_time,"HH:mm:ss").format('h:mm a')}} - {{moment(ava.local_end_time,"HH:mm:ss").format('h:mm a')}}</div>
                    </div>
                    <div v-if="availabilities.filter(ava => ava.day == 'monday').length" class="mb-4">
                        <p class="mb-0"><strong>Monday</strong></p>
                        <div v-for="ava in availabilities.filter(ava => ava.day == 'monday')" v-bind:key="ava.id">{{moment(ava.local_start_time,"HH:mm:ss").format('h:mm a')}} - {{moment(ava.local_end_time,"HH:mm:ss").format('h:mm a')}}</div>
                    </div>
                    <div v-if="availabilities.filter(ava => ava.day == 'tuesday').length" class="mb-4">
                        <p class="mb-0"><strong>Tuesday</strong></p>
                        <div v-for="ava in availabilities.filter(ava => ava.day == 'tuesday')" v-bind:key="ava.id">{{moment(ava.local_start_time,"HH:mm:ss").format('h:mm a')}} - {{moment(ava.local_end_time,"HH:mm:ss").format('h:mm a')}}</div>
                    </div>
                    <div v-if="availabilities.filter(ava => ava.day == 'wednesday').length" class="mb-4">
                        <p class="mb-0"><strong>Wednesday</strong></p>
                        <div v-for="ava in availabilities.filter(ava => ava.day == 'wednesday')" v-bind:key="ava.id">{{moment(ava.local_start_time,"HH:mm:ss").format('h:mm a')}} - {{moment(ava.local_end_time,"HH:mm:ss").format('h:mm a')}}</div>
                    </div>
                    <div v-if="availabilities.filter(ava => ava.day == 'thursday').length" class="mb-4">
                        <p class="mb-0"><strong>Thursday</strong></p>
                        <div v-for="ava in availabilities.filter(ava => ava.day == 'thursday')" v-bind:key="ava.id">{{moment(ava.local_start_time,"HH:mm:ss").format('h:mm a')}} - {{moment(ava.local_end_time,"HH:mm:ss").format('h:mm a')}}</div>
                    </div>
                    <div v-if="availabilities.filter(ava => ava.day == 'friday').length" class="mb-4">
                        <p class="mb-0"><strong>Friday</strong></p>
                        <div v-for="ava in availabilities.filter(ava => ava.day == 'friday')" v-bind:key="ava.id">{{moment(ava.local_start_time,"HH:mm:ss").format('h:mm a')}} - {{moment(ava.local_end_time,"HH:mm:ss").format('h:mm a')}}</div>
                    </div>
                    <div v-if="availabilities.filter(ava => ava.day == 'saturday').length" class="mb-4">
                        <p class="mb-0"><strong>Saturday</strong></p>
                        <div v-for="ava in availabilities.filter(ava => ava.day == 'saturday')" v-bind:key="ava.id">{{moment(ava.local_start_time,"HH:mm:ss").format('h:mm a')}} - {{moment(ava.local_end_time,"HH:mm:ss").format('h:mm a')}}</div>
                    </div>
                    <hr class="w-75" />
                </div>
                <div>
                    <h4>Interviews</h4>
                    <div v-if="interviews.length" class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>View</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="interview in interviews.slice(interviews_page_start,interviews_page_end)" v-bind:key="interview.id">
                                    <td>{{moment(interview.start_time).format('MM/DD/YY h:mm a')}} - {{interview.timezone.title}}</td>
                                    <td v-if="interview.is_canceled">Canceled</td>
                                    <td v-else-if="interview.is_complete">Complete</td>
                                    <td v-else>Scheduled</td>
                                    <td><i class='fas fa-arrow-circle-right text-primary' @click="router.push({name:'interview',params:{interview_id:interview.id}})"></i></td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination v-if="interviews.length > 10" :items="interviews" :page_start="interviews_page_start" :page_end="interviews_page_end" v-on:next-page="nextPageInterviews" v-on:previous-page="previousPageInterviews" :label="'Interviews'" />
                    </div>
                    <p v-else>User not associated to any interviews</p>
                    <hr class="w-75" />
                </div>
                <div v-if="user.resume !== null">
                    <a v-if="user.resume !==null" :href="user.resume" download>Download Resume</a>
                    <hr class="w-75" />
                </div>
                <div v-if="user.headshot !== null">
                    <p>Headshot (right click and select "Save Image As" to download)</p>
                    <img class="w-100" :src="user.headshot" />
                    <hr class="w-75" />
                </div>
                <div v-if="store.getters.hasPermission('user_role.edit')">
                    <h4>Roles</h4>
                    <div v-if="store.getters.hasPermission('user_role.standardized_patient')">
                        <label><input type="checkbox" v-model="selected_roles[UserRole.SP]"> Standardized Patient</label>
                        <div class="mb-3">
                            <small>Rate:</small> <currency-input v-model="role_rates[UserRole.SP]"/>
                        </div>
                    </div>
                    <div v-if="store.getters.hasPermission('user_role.observer')">
                        <label><input type="checkbox" v-model="selected_roles[UserRole.OBSERVER]"> Observer</label>
                        <div class="mb-3">
                            <small>Rate:</small> <currency-input v-model="role_rates[UserRole.OBSERVER]"/>
                        </div>
                    </div>
                    <div v-if="store.getters.hasPermission('user_role.trainer')">
                        <label><input type="checkbox" v-model="selected_roles[UserRole.TRAINER]"> Trainer</label>
                        <div class="mb-3">
                            <small>Rate:</small> <currency-input v-model="role_rates[UserRole.TRAINER]"/>
                        </div>
                    </div>
                    <div v-if="store.getters.hasPermission('user_role.reviewer')">
                        <label><input type="checkbox" v-model="selected_roles[UserRole.REVIEWER]"> Reviewer</label>
                    </div>
                    <div v-if="store.getters.hasPermission('user_role.author')">
                        <label><input type="checkbox" v-model="selected_roles[UserRole.AUTHOR]"> SP-ED Author</label>
                    </div>
                    <div v-if="store.getters.hasPermission('user_role.author')">
                        <label><input type="checkbox" v-model="selected_roles['simclusive_author']"> Simclusive Author</label>
                        <div class="mb-3">
                            <small>Rate:</small> <currency-input v-model="role_rates['simclusive_author']"/>
                        </div>
                    </div>
                    <div v-if="store.getters.hasPermission('user_role.admin')">
                        <label><input type="checkbox" v-model="selected_roles[UserRole.ADMIN]"> Admin</label>
                    </div>
                    <div v-if="store.getters.hasPermission('user_role.head_trainer')">
                        <label><input type="checkbox" v-model="selected_roles[UserRole.HEADTRAINER]"> Head Trainer</label>
                        <div class="mb-3">
                            <small>Rate:</small> <currency-input v-model="role_rates[UserRole.HEADTRAINER]"/>
                        </div>
                    </div>
                    <div v-if="store.getters.hasPermission('user_role.client_admin')">
                        <label><input type="checkbox" v-model="selected_roles[UserRole.CLIENTADMIN]"> SP-ED Client Admin</label>
                        <div class="mb-3 client-admin-partners py-4 px-4">
                            <div class="row">
                                <div class="col-sm-12 col-md-6" v-for="partner in partners" v-bind:key="partner.id">
                                    <label><input :disabled="!selected_roles[UserRole.CLIENTADMIN]" type="checkbox" v-model="client_partners[partner.id]"> {{partner.short_name}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="store.getters.hasPermission('user_role.client_admin')">
                        <label><input type="checkbox" v-model="selected_roles['simclusive_client_admin']"> Simclusive Client Admin</label>
                        <div class="mb-3 client-admin-partners py-4 px-4">
                            <div class="row">
                                <div class="col-sm-12 col-md-6" v-for="partner in sim_partners" v-bind:key="partner.id">
                                    <label><input :disabled="!selected_roles['simclusive_client_admin']" type="checkbox" v-model="sim_client_partners[partner.id]"> {{partner.short_name}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="store.getters.hasPermission('user_role.exclude_from_reports')">
                        <label><input type="checkbox" v-model="selected_roles[UserRole.EXCLUDEFROMREPORTS]"> Exclude From Reports</label>
                    </div>
                    <div v-if="store.getters.hasPermission('user_role.report_manager')">
                        <label><input type="checkbox" v-model="selected_roles[UserRole.REPORTMANAGER]"> Report Manager</label>
                    </div>
                    <button class="btn btn-primary my-4" @click="updateRoles">Update Roles</button>
                    <hr class="w-75" />
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="!loading && err_msg">
        <h4>Error Loading Users</h4>
        <p>{{err_msg}}</p>
    </div>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { defineComponent, ref } from "vue";
    import Loading from 'vue3-loading-overlay';
    import User from '@/models/user';
    import { UserRole } from '@/enums/user-role';
    import { useRoute } from 'vue-router';
    import router from "@/router";
    import Pagination from '@/components/dashboard/pagination.vue';
    import { store } from '../../store/index';
    import moment from 'moment';
    import CurrencyInput from '@/components/currency-input.vue';
    import {NotificationIcon,NotificationColor} from "@/interfaces/notification";
    import {MutationTypes} from "@/store/modules/ux/mutations";

    export default defineComponent({
        components: {
            CurrencyInput,
            Loading,
            Pagination
        },
        mounted(){
            this.getUser();
        },
        setup() {
            const availabilities = ref<Array<any>>([]);
            const loading = ref<Boolean>(true);
            const user = ref<Object>({});
            const err_msg = ref(false);
            const route = useRoute();
            const password = ref<String>('');
            const confirm_password = ref<String>('');
            const enable_autologin = ref<Boolean>(false);
            const enable_email = ref<Boolean>(false);
            const enable_sms = ref<Boolean>(false);
            const password_error = ref<String>('');

            const visits = ref<Object>({});
            const training_sessions = ref<Object>({});
            const interviews = ref<Object>({});
            const visits_page_start = ref(0);
            const visits_page_end = ref(10);
            const training_sessions_page_start = ref(0);
            const training_sessions_page_end = ref(10);
            const interviews_page_start = ref(0);
            const interviews_page_end = ref(10);

            const partners = ref({});
            const sim_partners = ref({});
            const client_partners = ref({});
            const sim_client_partners = ref({});

            const selected_roles = ref<any>({});
            if(store.getters.hasPermission('user_role.standardized_patient')){
                selected_roles.value[UserRole.SP] = false;
            }
            if(store.getters.hasPermission('user_role.observer')){
                selected_roles.value[UserRole.OBSERVER] = false;
            }
            if(store.getters.hasPermission('user_role.trainer')){
                selected_roles.value[UserRole.TRAINER] = false;
            }
            if(store.getters.hasPermission('user_role.reviewer')){
                selected_roles.value[UserRole.REVIEWER] = false;
            }
            if(store.getters.hasPermission('user_role.author')){
                selected_roles.value[UserRole.AUTHOR] = false;
                selected_roles.value['simclusive_author'] = false;
            }
            if(store.getters.hasPermission('user_role.admin')){
                selected_roles.value[UserRole.ADMIN] = false;
            }
            if(store.getters.hasPermission('user_role.head_trainer')){
                selected_roles.value[UserRole.HEADTRAINER] = false;
            }
            if(store.getters.hasPermission('user_role.client_admin')){
                selected_roles.value[UserRole.CLIENTADMIN] = false;
                selected_roles.value['simclusive_client_admin'] = false;
            }
            if(store.getters.hasPermission('user_role.exclude_from_reports')){
                selected_roles.value[UserRole.EXCLUDEFROMREPORTS] = false;
            }
            if(store.getters.hasPermission('user_role.report_manager')){
                selected_roles.value[UserRole.REPORTMANAGER] = false;
            }
            const role_rates = ref<any>({});
            if(store.getters.hasPermission('user_role.standardized_patient')){
                role_rates.value[UserRole.SP] = 0;
            }
            if(store.getters.hasPermission('user_role.observer')){
                role_rates.value[UserRole.OBSERVER] = 0;
            }
            if(store.getters.hasPermission('user_role.trainer')){
                role_rates.value[UserRole.TRAINER] = 0;
            }
            if(store.getters.hasPermission('user_role.head_trainer')){
                role_rates.value[UserRole.HEADTRAINER] = 0;
            }
            if(store.getters.hasPermission('user_role.author')){
                role_rates.value['simclusive_author'] = 0;
            }

            const getUser = async() => {
                loading.value = true;
                var post_data = {
                    user_id: +route.params.user_id,
                };
                await User.getUser(post_data).then((res) => {
                    user.value = res.data.user;
                    visits.value = res.data.visits;
                    interviews.value = res.data.interviews;
                    training_sessions.value = res.data.training_sessions;
                    enable_autologin.value = !!res.data.user.auto_login;
                    enable_email.value = !!res.data.user.enable_email_notifications;
                    enable_sms.value = !!res.data.user.enable_sms_notifications;
                    availabilities.value = res.data.availabilities;
                    if(store.getters.hasPermission('user_role.standardized_patient')){
                        let sp_role = res.data.user.user_roles.filter((role:any) => role.role_id == UserRole.SP && role.is_approved == 1);
                        if(sp_role.length){
                            selected_roles.value[UserRole.SP] = true;
                            role_rates.value[UserRole.SP] = sp_role[0].pay_rate;
                        }
                    }
                    if(store.getters.hasPermission('user_role.observer')){
                        let observer_role = res.data.user.user_roles.filter((role:any) => role.role_id == UserRole.OBSERVER && role.is_approved == 1);
                        if(observer_role.length){
                            selected_roles.value[UserRole.OBSERVER] = true;
                            role_rates.value[UserRole.OBSERVER] = observer_role[0].pay_rate;
                        }
                    }
                    if(store.getters.hasPermission('user_role.trainer')){
                        let trainer_role = res.data.user.user_roles.filter((role:any) => role.role_id == UserRole.TRAINER && role.is_approved == 1);
                        if(trainer_role.length){
                            selected_roles.value[UserRole.TRAINER] = true;
                            role_rates.value[UserRole.TRAINER] = trainer_role[0].pay_rate;
                        }
                    }
                    if(store.getters.hasPermission('user_role.reviewer')){
                        let reviewer_role = res.data.user.user_roles.filter((role:any) => role.role_id == UserRole.REVIEWER && role.is_approved == 1);
                        if(reviewer_role.length) selected_roles.value[UserRole.REVIEWER] = true;
                    }
                    if(store.getters.hasPermission('user_role.author')){
                        let author_role = res.data.user.user_roles.filter((role:any) => role.role_id == UserRole.AUTHOR && role.partner_id == 1 && role.is_approved == 1);
                        if(author_role.length) selected_roles.value[UserRole.AUTHOR] = true;
                        let sim_author_role = res.data.user.user_roles.filter((role:any) => role.role_id == UserRole.AUTHOR && role.partner_id == 28 && role.is_approved == 1);
                        if(sim_author_role.length){
                            selected_roles.value['simclusive_author'] = true;
                            role_rates.value['simclusive_author'] = sim_author_role[0].pay_rate;
                        }
                    }
                    if(store.getters.hasPermission('user_role.admin')){
                        let admin_role = res.data.user.user_roles.filter((role:any) => role.role_id == UserRole.ADMIN && role.is_approved == 1);
                        if(admin_role.length) selected_roles.value[UserRole.ADMIN] = true;
                    }
                    if(store.getters.hasPermission('user_role.head_trainer')){
                        let head_trainer_role = res.data.user.user_roles.filter((role:any) => role.role_id == UserRole.HEADTRAINER && role.is_approved == 1);
                        if(head_trainer_role.length){
                            selected_roles.value[UserRole.HEADTRAINER] = true;
                            role_rates.value[UserRole.HEADTRAINER] = head_trainer_role[0].pay_rate;
                        }
                    }
                    if(store.getters.hasPermission('user_role.client_admin')){
                        let client_admin_role = res.data.user.user_roles.filter((role:any) => role.role_id == UserRole.CLIENTADMIN && role.partner_id == 1 && role.is_approved == 1);
                        if(client_admin_role.length) selected_roles.value[UserRole.CLIENTADMIN] = true;
                        let sim_client_admin_role = res.data.user.user_roles.filter((role:any) => role.role_id == UserRole.CLIENTADMIN && role.partner_id == 28 && role.is_approved == 1);
                        if(sim_client_admin_role.length) selected_roles.value['simclusive_client_admin'] = true;
                    }
                    if(store.getters.hasPermission('user_role.exclude_from_reports')){
                        let exclude_from_reports_role = res.data.user.user_roles.filter((role:any) => role.role_id == UserRole.EXCLUDEFROMREPORTS && role.is_approved == 1);
                        if(exclude_from_reports_role.length) selected_roles.value[UserRole.EXCLUDEFROMREPORTS] = true;
                    }
                    if(store.getters.hasPermission('user_role.report_manager')){
                        let report_manager_role = res.data.user.user_roles.filter((role:any) => role.role_id == UserRole.REPORTMANAGER && role.is_approved == 1);
                        if(report_manager_role.length) selected_roles.value[UserRole.REPORTMANAGER] = true;
                    }
                    partners.value = res.data.partners;
                    sim_partners.value = res.data.sim_partners;
                    client_partners.value = res.data.client_partners;
                    sim_client_partners.value = res.data.sim_client_partners;
                    loading.value = false;
                    err_msg.value = false;
                },(err)=>{
                    console.log(err);
                    err_msg.value = err.response.data.message;
                    loading.value = false;
                });
            }

            const nextPageInterviews = async() => {
                interviews_page_start.value = interviews_page_start.value + 10;
                interviews_page_end.value = interviews_page_end.value + 10;
                if(interviews_page_end.value > Object.keys(interviews.value).length){
                    interviews_page_end.value = Object.keys(interviews.value).length;
                }
            };

            const nextPageTrainingSessions = async() => {
                training_sessions_page_start.value = training_sessions_page_start.value + 10;
                training_sessions_page_end.value = training_sessions_page_end.value + 10;
                if(training_sessions_page_end.value > Object.keys(training_sessions.value).length){
                    training_sessions_page_end.value = Object.keys(training_sessions.value).length;
                }
            };

            const nextPageVisits = async() => {
                visits_page_start.value = visits_page_start.value + 10;
                visits_page_end.value = visits_page_end.value + 10;
                if(visits_page_end.value > Object.keys(visits.value).length){
                    visits_page_end.value = Object.keys(visits.value).length;
                }
            };

            const previousPageInterviews = async() => {
                interviews_page_start.value = interviews_page_start.value - 10;
                interviews_page_end.value = interviews_page_start.value + 10;
            };

            const previousPageTrainingSessions = async() => {
                training_sessions_page_start.value = training_sessions_page_start.value - 10;
                training_sessions_page_end.value = training_sessions_page_start.value + 10;
            };

            const previousPageVisits = async() => {
                visits_page_start.value = visits_page_start.value - 10;
                visits_page_end.value = visits_page_start.value + 10;
            };

            const parseError = (errs:any) => {
                let err_msg = '';
                if(errs.errors !== undefined){
                    for(const [key,value] of Object.entries(errs.errors)){
                        err_msg += '<strong>' + key + '</strong><br>';
                        //@ts-ignore
                        value.forEach((msg:any) => err_msg += msg + '<br>');
                        err_msg += '<br>';
                    }
                }else if(errs.message !== 'undefined'){
                    err_msg = errs.message;
                }else{
                    err_msg = 'Error completing request';
                }
                return err_msg;
            }

            const updateNotificationSettings = async() => {
                loading.value = true;
                let post_data = {
                    enable_email: enable_email.value,
                    enable_sms: enable_sms.value,
                    enable_autologin: enable_autologin.value,
                    user_id: +route.params.user_id
                };
                await User.updateNotificationSettings(post_data).then(()=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Notification settings updated.',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                },(err:any)=>{
                    console.log(err);
                    let err_msg = parseError(err.response.data);
                    store.commit(MutationTypes.addSystemNotification, {title:err.response.data.message,content:err_msg,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const updatePassword = async() => {
                loading.value = true;
                if(password.value.length < 8){
                    password_error.value = 'Password must be 8 characters long. Current length ' + password.value.length + ' characters.';
                    loading.value = false;
                    return false;
                }
                if(password.value !== confirm_password.value){
                    password_error.value = 'Password and confirm password must match.';
                    loading.value = false;
                    return false;
                }
                password_error.value = '';
                let post_data = {
                    password: password.value,
                    password_confirmation: confirm_password.value,
                    user_id: +route.params.user_id
                };
                await User.updatePassword(post_data).then(()=>{
                    password.value = '';
                    confirm_password.value = '';
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Password updated.',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                },(err:any)=>{
                    console.log(err);
                    let err_msg = parseError(err.response.data);
                    store.commit(MutationTypes.addSystemNotification, {title:err.response.data.message,content:err_msg,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const updateRoles = async() => {
                loading.value = true;
                let post_data: {[k:string]: any} = {
                    user_id: +route.params.user_id
                };
                if(store.getters.hasPermission('user_role.standardized_patient')){
                    post_data.standardized_patient_role = selected_roles.value[UserRole.SP];
                    post_data.standardized_patient_rate = role_rates.value[UserRole.SP];
                }
                if(store.getters.hasPermission('user_role.observer')){
                    post_data.observer_role = selected_roles.value[UserRole.OBSERVER];
                    post_data.observer_rate = role_rates.value[UserRole.OBSERVER];
                }
                if(store.getters.hasPermission('user_role.trainer')){
                    post_data.trainer_role = selected_roles.value[UserRole.TRAINER];
                    post_data.trainer_rate = role_rates.value[UserRole.TRAINER];
                }
                if(store.getters.hasPermission('user_role.reviewer')) post_data.reviewer_role = selected_roles.value[UserRole.REVIEWER];
                if(store.getters.hasPermission('user_role.author')) {
                    post_data.author_role = selected_roles.value[UserRole.AUTHOR];
                    post_data.simclusive_author_role = selected_roles.value['simclusive_author'];
                    post_data.simclusive_author_rate = role_rates.value['simclusive_author'];
                }
                if(store.getters.hasPermission('user_role.admin')) post_data.admin_role = selected_roles.value[UserRole.ADMIN];
                if(store.getters.hasPermission('user_role.head_trainer')){
                    post_data.head_trainer_role = selected_roles.value[UserRole.HEADTRAINER];
                    post_data.head_trainer_rate = role_rates.value[UserRole.HEADTRAINER];
                }
                if(store.getters.hasPermission('user_role.client_admin')){
                    post_data.client_admin_role = selected_roles.value[UserRole.CLIENTADMIN];
                    post_data.client_admin_partners = client_partners.value;
                    post_data.simclusive_client_admin_role = selected_roles.value['simclusive_client_admin'];
                    post_data.simclusive_client_admin_partners = sim_client_partners.value;
                }
                if(store.getters.hasPermission('user_role.exclude_from_reports')) post_data.exclude_from_reports_role = selected_roles.value[UserRole.EXCLUDEFROMREPORTS];
                if(store.getters.hasPermission('user_role.report_manager')) post_data.report_manager_role = selected_roles.value[UserRole.REPORTMANAGER];
                await User.updateRoles(post_data).then(()=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Roles updated.',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                },(err:any)=>{
                    console.log(err);
                    let err_msg = parseError(err.response.data);
                    store.commit(MutationTypes.addSystemNotification, {title:err.response.data.message,content:err_msg,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            return {
                availabilities,
                client_partners,
                confirm_password,
                enable_autologin,
                enable_email,
                enable_sms,
                err_msg,
                getUser,
                interviews,
                interviews_page_end,
                interviews_page_start,
                loading,
                moment,
                nextPageInterviews,
                nextPageTrainingSessions,
                nextPageVisits,
                partners,
                password,
                password_error,
                previousPageInterviews,
                previousPageTrainingSessions,
                previousPageVisits,
                role_rates,
                router,
                selected_roles,
                sim_client_partners,
                sim_partners,
                store,
                training_sessions,
                training_sessions_page_end,
                training_sessions_page_start,
                updateNotificationSettings,
                updatePassword,
                updateRoles,
                UserRole,
                user,
                visits,
                visits_page_end,
                visits_page_start,
            };
        },
    });
</script>

<style lang="scss">
    .return-btn{
        min-height: 2rem;
    }
    .fa-arrow-circle-right{
        cursor: pointer;
    }
    .client-admin-partners{
        height: 120px;
        border: 1px solid #ececec;
        overflow-y: scroll;
        max-width: 570px;
        width: 100%;
    }
</style>
