import {api} from '@/store';
import {IGiftCard} from "@/interfaces/gift-card";


export default class GiftCards{
    constructor(public data:IGiftCard) {}

    static async getCampaigns():Promise<any>{
        return await api.get('api/giftcard/campaigns');
    }

}
