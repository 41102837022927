import {IState} from "@/interfaces/state";
import {api} from "@/store";

export class State{
    constructor(public data?:IState) {}

    static async getStates():Promise<any> {
        return await api.get('api/states');
    }

    static async getState(id: Number | undefined): Promise<any>{
        return await api.get('api/states/'+id);
    }
}