<template>
    <div v-if="display">
        <p>Available Times</p>
        <div v-if="times.length" class="row">
            <div v-for="(time,id) in times" v-bind:key="id" class="col-6">
                <button class="btn btn-secondary w-100 mb-2" @click="updateTime(time)">{{time}}</button>
            </div>
        </div>
        <div v-else>
            <p class="warning"><em>There are no available visit times on this date. <strong>Please select another date.</strong></em></p>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from "vue";
    export default defineComponent({
        emits: ['update-time'],
        props:{
            times: {required: true},
            display: {type:Boolean, defailt: false},
            field: {required: true}
        },
        methods: {
            updateTime(time){
                this.$emit('update-time',{time:time,field:this.field});
            }
        },
        setup(){

        }
    });
</script>

<style scoped>

</style>
