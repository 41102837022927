<template>
    <slot name="trigger" :click="open"></slot>
    <modal v-model="modal" allow-close>
        <template v-slot:header>Create New Field</template>
        <div class="form-group">
            <label><strong>Select Field Type</strong></label><br>
            <input type="radio" v-model="fieldType" value="input" :checked="fieldType == 'input'"> <label>Input Field</label><br>
            <input type="radio" v-model="fieldType" value="select"> <label>Select (Drop-down)</label><br>
            <input type="radio" v-model="fieldType" value="textbox"> <label>Textbox</label><br>
        </div>
        <div class="form-group">
            <label><strong>Please Enter A Title</strong></label>
            <input type="text" :class="{'is-invalid':!validation.fieldName}" v-model="fieldName" required class="form-control">
            <small v-if="!validation.fieldName" class="invalid-feedback">A title is required</small>
        </div>
        <div v-if="fieldType == 'select'">
            <label><strong>Drop-down Values:</strong></label>

            <VueDraggableNext class="dragArea list-group w-full" v-model="answers">
                <div class="d-flex justify-content-start align-items-center mb-2" v-for="(item, index) in answers" :key="index">
                    <i class="text-primary cursor-pointer fa fa-bars"></i>
                    <input type="text" v-model="answers[index]" class="form-control mx-2" />
                    <button @click="removeAnswer(parseInt(index))" class="btn btn-primary float-right"><i class="fa fa-minus"></i> </button>
                </div>
            </VueDraggableNext>

            <button @click="addAnswer" class="btn btn-primary float-right"><i class="fa fa-plus"></i> </button>
            <small v-if="!validation.answers" class="d-block invalid-feedback">{{ validation.answersText }}</small>
        </div>
        <template v-slot:footer>
            <div class="d-flex justify-content-end">
                <button @click="resetForm" class="btn btn-default mx-2">Cancel</button>
                <button @click="save" class="btn btn-primary">Save</button>
            </div>
        </template>
    </modal>
</template>

<script lang="ts">
    import {defineComponent, reactive, ref} from "vue";
    import {IRegistrationFields} from "@/interfaces/registration-fields";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationIcon,NotificationColor} from "@/interfaces/notification";
    import {store} from "@/store";
    import {toSnakeCase} from "@/utilities/string-helpers";
    import {VueDraggableNext} from 'vue-draggable-next'
    import Modal from "@/components/modal.vue";
    import RegistrationFields from "@/models/registration-fields";

    export default defineComponent({
        name: "create-registration-field",
        components: {
            Modal,
            VueDraggableNext
        },
        props:{
            partnerId: {type:Number,required:true},
        },
        emits:['add-new-field'],
        setup(props, {emit}){
            const answers = ref<Array<String>>([]);
            const fieldName = ref('');
            const fieldType = ref('input');
            const modal = ref(false);
            const validation = reactive({
                'fieldName':true,
                'fieldType':true,
                'answers':true,
                'answersText':'',
            })

            const addAnswer = ()=>{
                answers.value.push('');
            }

            const open = ()=>modal.value = !modal.value;

            const removeAnswer = (index:number)=>{
                answers.value.splice(index,1);
            }

            const resetForm = ()=>{
                answers.value = [];
                fieldName.value = '';
                fieldType.value = 'input';
                modal.value = !modal.value;
            }

            const resetValidation = ()=>{
                validation.fieldName = true;
                validation.answers = true;
                validation.answersText = '';
            }

            const save = () =>{
                resetValidation();

                //Validate input and fields
                if(fieldName.value === '') validation.fieldName = false;

                if(fieldType.value === 'select' && answers.value.length < 2){
                    validation.answers = false;
                    validation.answersText = 'A drop-down menu requires at least 2 options';
                }
                if(fieldType.value === 'select') {
                    answers.value.forEach((i)=>{
                        if(i === ''){
                            validation.answers = false;
                            validation.answersText = 'You have empty values';
                        }
                    });
                }

                if(validation.fieldName && validation.answers){
                    //save
                    if (fieldType.value !== 'select') {
                        RegistrationFields.addRegistrationField(props.partnerId, {
                            'field_type':fieldType.value,
                            'field_title':fieldName.value,
                            'field_slug':toSnakeCase(fieldName.value),
                            'user_id':store.getters.user.props.id
                        }).then((res)=>{
                            let registrationFields:IRegistrationFields = res.data;
                            emit('add-new-field',registrationFields);
                            resetForm();
                            store.commit(MutationTypes.addSystemNotification,{title:'Success',content:'New Field has been added',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                        });
                    } else {
                        RegistrationFields.addRegistrationField(props.partnerId, {
                            'field_type':fieldType.value,
                            'field_title':fieldName.value,
                            'field_slug':toSnakeCase(fieldName.value),
                            'answers':answers.value,
                            'user_id':store.getters.user.props.id
                        }).then((res)=>{
                            let registrationFields:IRegistrationFields = res.data;
                            emit('add-new-field',registrationFields);
                            resetForm();
                            store.commit(MutationTypes.addSystemNotification,{title:'Success',content:'New Field has been added',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                        });
                    }
                }
            }

            return{
                addAnswer,
                answers,
                fieldName,
                fieldType,
                modal,
                removeAnswer,
                resetForm,
                save,
                open,
                validation,
            }
        }
    });
</script>

<style scoped>

</style>
