<template>
    <slot name="trigger" :click="open"></slot>
    <modal v-model="modal" allow-close>
        <template v-slot:header>Create New Author</template>
        <div class="form-group">
            <label>Author Full Name</label>
            <input type="text" name="social-title" :class="{'is-invalid':!validation.full_name}" class="form-control" v-model="full_name" required maxlength="75">
            <small v-if="!validation.full_name" class="invalid-feedback">A Name is Required</small>
        </div>
        <div class="form-group">
            <label>Biosketch</label>
            <textarea :class="{'is-invalid':!validation.biosketch}" class="form-control" v-model="biosketch" maxlength="500" required rows="6"></textarea>
            <small v-if="!validation.biosketch" class="invalid-feedback">A biosketch is required</small>
        </div>
        <template v-slot:footer>
            <div class="d-flex justify-content-end">
                <button @click="modal = !modal" class="btn btn-default mx-2">Cancel</button>
                <button @click="save" class="btn btn-primary">Save</button>
            </div>
        </template>
    </modal>
</template>

<script lang="ts">
    import {defineComponent, reactive, ref} from "vue";
    import Modal from "@/components/modal.vue";
    import moment from 'moment';
    import {IAuthor} from '@/interfaces/author';
    import {store} from "@/store";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationIcon,NotificationColor} from "@/interfaces/notification";
    import {Author} from "@/models/author";

    export default defineComponent({
        name: "CreateAuthor",
        components: {
            Modal
        },
        props:{
            //No Props
        },
        emits:['update'],
        computed:{
            //NO computations
        },
        setup(props, {emit}){
            const modal = ref(false);
            const open = ()=>modal.value = !modal.value;
            const full_name = ref('');
            const biosketch = ref('');
            const validation = reactive({
                'full_name':true,
                'biosketch':true,
            })
            const resetForm = ()=>{
                full_name.value = '';
                biosketch.value = '';
            }
            const resetValidation = ()=>{
                validation.full_name = true;
                validation.biosketch = true;
            }

            const save = () =>{
                //reset validation
                resetValidation();

                //check validation
                if(full_name.value == '') validation.full_name = false;
                if(biosketch.value == '') validation.biosketch = false;

                if(validation.full_name && validation.biosketch){
                    //save
                    let formData = new FormData();
                    formData.append("full_name", full_name.value);
                    formData.append("biosketch", biosketch.value);

                    Author.saveAuthor(formData).then((res)=>{
                        let author:IAuthor = res.data;
                        emit('update',author);
                        modal.value = false;
                        resetForm();
                        store.commit(MutationTypes.addSystemNotification,{title:'Success',content:'The author has been created.',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                    });
                }
            }

            return{
                biosketch,
                full_name,
                modal,
                moment,
                open,
                save,
                validation,
            }
        }
    });
</script>

<style scoped>

</style>
