<template>
	<div v-if="!loading && !has_error">
		<div class="row">
			<div class="col-12">
				<table v-if="invoices.length" class="table">
					<thead class="bg-secondary text-white">
						<th>Title</th>
						<th>Date Requested</th>
						<th>Status</th>
						<th>-</th>
					</thead>
					<tbody>
						<tr v-for="invoice in invoices" v-bind:key="invoice.id">
							<td>{{invoice.title}}</td>
							<td>{{moment(invoice.invoice_requested_at).format('MM/DD/YYYY')}}</td>
							<td><span v-if="invoice.is_payment_made">Complete</span><span v-else>Pending</span></td>
							<td><button class="btn btn-primary" v-on:click="router.push({name:'management.invoice',params:{invoice_id:invoice.id}})">View</button></td>
						</tr>
					</tbody>
				</table>
				<p v-else>No Invoice Requests Available</p>
			</div>
		</div>
	</div>
	<div v-else-if="has_error">
		<p class="alert alert-danger">{{err_msg}}</p>
	</div>
	<loading :active="loading" :is-full-page="true"></loading>
</template>
<script lang="ts">
	import { defineComponent, ref } from "vue";
	import { IPendingPartner } from "@/interfaces/pending-partner";
	import Loading from "vue3-loading-overlay";
	import moment from "moment";
	import Request from "@/models/request";
	import router from "@/router";

	export default defineComponent({
		components: { Loading },

		mounted(){
			this.getInvoices();
		},
		
		setup(){
			const err_msg = ref<String>("");
			const has_error = ref<Boolean>(false);
			const loading = ref<Boolean>(false);
			const invoices = ref<Array<IPendingPartner>>([]);

			const getInvoices = async() => {
				loading.value = true;
				await Request.getInvoices().then((res:any) => {
					invoices.value = res.data.invoices;
				},(err:any) => {
					console.log(err);
					err_msg.value = err;
				});
				loading.value = false;
			}

			return {
				err_msg,
				getInvoices,
				has_error,
				invoices,
				loading,
				moment,
				router
			}
		}
	});
</script>
<style scoped lang="sass">
	
</style>