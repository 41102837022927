<template>
    <div v-if="alerts.length" class="row m-auto dashboard-alerts">
        <div class="col-12">
            <div class="card px-4 py-2 bg-light">
                <div class="row">
                    <div class="col-1 d-flex flex-column justify-content-center">
                        <i class="fas fa-exclamation-circle text-danger"></i>
                    </div>
                    <div class="col-11 d-flex flex-column justify-content-center">
                        <p v-for="(alert,ind) in alerts" v-bind:key="ind" class="text-danger mb-0"><span v-html="alert.message"></span> <span class="text-danger alert-link" @click="router.push({name:alert.route_name})"><u>{{alert.link_text}}</u></span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import router from "@/router";

    export default defineComponent({
        props: {
            alerts: {type:Object,required:true}
        },
        setup(props) {
            return {
                props,
                router
            };
        }
    });
</script>
<style scoped lang="scss">
    .dashboard-alerts{
        i {
            font-size: 3rem;
        }
        .alert-link {
            cursor: pointer;
        }
    }
</style>
