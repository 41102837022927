<template>
    <div class="row mb-2">
        <div v-if="!hideLabel" class="col-lg-4 col-md-12 text-muted d-flex justify-content-start align-items-center">
            <button @click="edit = true" class="btn btn-link text-primary btn-sm" v-if="!edit"><i class="fa fa-edit"></i> </button>
            <span :class="{'text-danger': require_mobile == true}">{{ labelText }}: </span>
        </div>

        <div v-if="type === 'editor' && !edit" v-html="value" class="col-lg-8 mb-4 text-muted profile-data"></div>
        <div v-else-if="(type === 'state' || type === 'country' || type === 'profession' || type === 'specialty' || type === 'timezone') && !edit" class="col-lg-8 profile-data">{{ options.find((i)=>i.id === value) ? options.find((i)=>i.id === value).title : ''  }}</div>
        <div v-else-if="type === 'time' && !edit" class="col-lg-8 profile-data">{{ startTime ? moment(startTime,["HH.mm"]).format("h:mm A") : '' }} - {{ endTime ? moment(endTime,["HH.mm"]).format("h:mm A") : '' }}</div>
        <div v-else-if="type === 'date' && !edit" class="col-lg-8 profile-data">{{ value ? value : '' }}</div>
        <div v-else-if="type === 'select' && !edit" class="col-lg-8 profile-data">{{ selectResponse(options) }}</div>
        <div v-else-if="!edit && type !== 'password'" class="col-lg-8 profile-data">{{ value }}</div>


        <div class="col-lg-8 input-group" v-if="edit && type === 'text'">
            <input type="text" v-model="value" class="form-control">
            <span>
                <button class="btn btn-primary" type="button" @click="save"><span class="d-none d-xl-block">Save</span><i class="fas fa-save d-block d-xl-none"></i></button>
                <button class="btn btn-secondary" @click="cancel" type="button"><span class="d-none d-xl-block">Cancel</span><i class="fas fa-ban d-block d-xl-none"></i></button>
            </span>
            <Loading :active="loading" :is-full-page="false"></Loading>
        </div>

        <div class="col-lg-8 input-group" v-if="edit && (type === 'textbox' || type === 'input')">
            <textarea v-if="type === 'textbox'" type="text" v-model="value" cols="3" class="form-control"></textarea>
            <input v-else type="text" v-model="value" class="form-control">
            <span>
                <button class="btn btn-primary" type="button" @click="customSave"><span class="d-none d-xl-block">Save</span><i class="fas fa-save d-block d-xl-none"></i></button>
                <button class="btn btn-secondary" @click="customCancel" type="button"><span class="d-none d-xl-block">Cancel</span><i class="fas fa-ban d-block d-xl-none"></i></button>
            </span>
            <Loading :active="loading" :is-full-page="false"></Loading>
        </div>

        <div class="col-lg-8 input-group" v-if="edit && type === 'password'">
            <div class="w-100">
                <label>New Password</label> <input type="password" v-model="value" class="form-control">
            </div>
            <div class="mt-2 w-100">
                <label>Confirm Password</label> <input type="password" v-model="confirm"  class="form-control">
            </div>
            <div class="mt-4 w-100">
                <button class="btn btn-secondary float-right" @click="cancel" type="button"><span class="">Cancel</span></button>
                <button class="btn btn-primary float-right" type="button" @click="save"><span class="">Save</span></button>
            </div>
        </div>
        <div class="col-lg-8 input-group" v-if="edit && type === 'number'">
            <input type="number" min="1" step="any" v-model="value" class="form-control">
            <span>
                <button class="btn btn-primary" type="button" @click="save"><span class="d-none d-xl-block">Save</span><i class="fas fa-save d-block d-xl-none"></i></button>
                <button class="btn btn-secondary" @click="cancel" type="button"><span class="d-none d-xl-block">Cancel</span><i class="fas fa-ban d-block d-xl-none"></i></button>
            </span>
            <Loading :active="loading" :is-full-page="false"></Loading>
        </div>
        <div class="col-lg-8 input-group" v-if="edit && type === 'time'">
            <div class="d-flex align-items-center">
                <input type="time" v-model="startTime" class="form-control profile-availability">-<input type="time" v-model="endTime" class="form-control profile-availability">
                <button class="btn btn-primary" type="button" @click="save"><i class="fas fa-save"></i></button>
                <button class="btn btn-secondary" @click="cancel" type="button"><i class="fas fa-ban"></i></button>
                <button class="btn btn-secondary" @click="deleteTime" type="button"><i class="fas fa-trash"></i></button>
            </div>
            <Loading :active="loading" :is-full-page="false"></Loading>
        </div>
        <div class="col-lg-8 h-100" v-if="edit && type === 'editor'">
            <text-editor v-model="value"></text-editor>
            <div class="d-flex justify-content-end mt-2">
                <button class="btn btn-primary" @click="save">Save</button>
                <button class="btn btn-default mx-2" @click="cancel">Cancel</button>
            </div>
        </div>
        <div class="col-lg-8 input-group" v-if="edit && type === 'date'">
            <datepicker class="form-control" v-model="value" />
            <span>
                <button class="btn btn-primary" type="button" @click="save"><span class="d-none d-xl-block">Save</span><i class="fas fa-save d-block d-xl-none"></i></button>
                <button class="btn btn-secondary" @click="cancel" type="button"><span class="d-none d-xl-block">Cancel</span><i class="fas fa-ban d-block d-xl-none"></i></button>
            </span>
        </div>
        <div class="col-lg-8 input-group" v-if="edit && (type === 'state' || type === 'country' || type == 'profession' || type == 'specialty' || type == 'timezone')">
            <custom-select v-model="value" :options="options" />
            <span>
                <button class="btn btn-primary" type="button" @click="save"><span class="d-none d-xl-block">Save</span><i class="fas fa-save d-block d-xl-none"></i></button>
                <button class="btn btn-secondary" @click="cancel" type="button"><span class="d-none d-xl-block">Cancel</span><i class="fas fa-ban d-block d-xl-none"></i></button>
            </span>
        </div>
        <div class="col-lg-8 input-group" v-if="edit && type === 'select'">
            <custom-select v-model="value" :options="options" />
            <span>
                <button class="btn btn-primary" type="button" @click="customSave"><span class="d-none d-xl-block">Save</span><i class="fas fa-save d-block d-xl-none"></i></button>
                <button class="btn btn-secondary" @click="customSelectCancel" type="button"><span class="d-none d-xl-block">Cancel</span><i class="fas fa-ban d-block d-xl-none"></i></button>
            </span>
        </div>
        <div class="col-lg-8 input-group" v-if="edit && type === 'mobile'">
            <vue-tel-input :v-model="mobile_number" :value="mobile_number" mode="international" :preferred-countries="['US','CA','MX']" @validate="setMobile"></vue-tel-input>
            <span>
                <button :disabled="!mobile_valid" class="btn btn-primary" type="button" @click="save"><span class="d-none d-xl-block">Save</span><i class="fas fa-save d-block d-xl-none"></i></button>
                <button class="btn btn-secondary" @click="cancel" type="button"><span class="d-none d-xl-block">Cancel</span><i class="fas fa-ban d-block d-xl-none"></i></button>
            </span>
            <p v-if="!mobile_valid" class="alert alert-danger">Mobile number invalid</p>
        </div>
    </div>
</template>


<script lang="ts">
    import {computed, defineComponent, onMounted, ref} from "vue";
    import {Country} from "@/models/country";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from '@/interfaces/notification';
    import {Profession} from "@/models/profession";
    import {RegistrationSelectValues} from "@/models/registration-select-values";
    import {Specialty} from "@/models/specialty";
    import {State} from "@/models/state";
    import {store} from '@/store/index';
    import {TimeZone} from "@/models/time-zone";
    import {toTitleCase} from "@/utilities/string-helpers";
    import TextEditor from "@/components/text-editor.vue";
    import User from "@/models/user";
    import Loading from "vue3-loading-overlay";
    import Datepicker from 'vue3-datepicker';
    import moment from "moment";
    import CustomSelect from "@/components/custom-select.vue";
    import {IAvailability} from "@/interfaces/availability";
    import {VueTelInput} from 'vue3-tel-input';
    import 'vue3-tel-input/dist/vue3-tel-input.css';

export default defineComponent({
        name: "edit-profile-field",
        components: {CustomSelect, Loading, TextEditor, Datepicker, VueTelInput},
        props:{
            user: {type: User, required:true},
            field: {type:String,required:true},
            items: {type:Array},
            type: {type:String,required:true},
            overrideLabel: {type:String},
            registrationFieldId: {type:Number,required:false},
            require_mobile: {type:Boolean},
            hideLabel: {type:Boolean,default:false},
        },
        setup(props){
            const loading = ref(false);
            const labelText = computed(()=>props.overrideLabel ? props.overrideLabel : toTitleCase(props.field));
            const value = ref(props.user?.getField(props.field));
            const confirm = ref('');
            const options = ref([]);
            const mobile_number = ref(props.user?.getField('mobile_number'));
            const mobile_valid = ref<Boolean>(false);
            const simpleEditorOptions = {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                    ]
                },
                // more options
            }
            const userCustomData = ref(props.user.props.user_custom_data);
            const validated = ref(true);
            const edit = ref(false);

            const startTime = ref((props.type === 'time') ? props.user.props.availabilities?.find((d)=> d.day === props.field)?.start_time : null);

            const endTime = ref((props.type === 'time') ? props.user.props.availabilities?.find((d)=> d.day === props.field)?.end_time : null);

            onMounted(()=>{
                if(props.type =='state') State.getStates().then((res)=>options.value = res.data);
                if(props.type =='country') Country.getCountries().then((res)=>options.value = res.data);
                if(props.type =='profession') Profession.getProfessions().then((res)=>options.value = res.data);
                if(props.type =='specialty') Specialty.getSpecialties().then((res)=>options.value = res.data);
                if(props.type =='timezone') TimeZone.getTimeZones().then((res)=>options.value = res.data);
                if(props.type =='select') RegistrationSelectValues.getSelectValues(props.field).then((res)=>options.value = res.data);

                if (props.registrationFieldId != null) {
                    let data = userCustomData.value ? userCustomData.value.find(function(elm:any){ return elm.registration_field_id === props.registrationFieldId}) : '';
                    if (data) {
                        value.value = props.type == 'select' ? +data.field_value : data.field_value;
                    }
                }
            })

            const cancel = ()=>{
                value.value = props.user?.getField(props.field);
                edit.value = false;
            }

            const customCancel = ()=>{
                let data = userCustomData.value ? userCustomData.value.find(function(elm:any){ return elm.registration_field_id === props.registrationFieldId}) : '';
                if (data) {
                    value.value = data.field_value;
                } else {
                    value.value = '';
                }
                edit.value = false;
            }

            const customSelectCancel = ()=>{
                let data = userCustomData.value ? userCustomData.value.find(function(elm:any){ return elm.registration_field_id === props.registrationFieldId}) : '';
                if (data) {
                    value.value = +data.field_value;
                }
                edit.value = false;
            }

            const customSave = async ()=>{
                edit.value = false;
                loading.value = true;

                await props.user?.saveCustomField(props.registrationFieldId, value.value).then((res)=>{
                    let found = userCustomData.value ? userCustomData.value.find(function(elm:any) {
                        if (elm.registration_field_id === props.registrationFieldId) {
                            elm.field_value = res.data.field_value;
                        }
                    }) : '';

                    // If not found its a new row in the user_custom_data table.
                    if (!found) {
                        userCustomData.value?.push(res.data);
                    }
                    value.value = res.data.field_value;
                });

                loading.value = false;
            }

            const save = async ()=>{
                edit.value = false;
                loading.value = true;
                let message = '';
                if(props.type == 'date') value.value = moment(value.value).format('YYYY-MM-DD');
                if(props.type == 'mobile') value.value = mobile_number.value;
                if(props.type == 'time') {
                    if (startTime.value === null || startTime.value === '') {
                        validated.value = false;
                        message = 'Please enter a Start Time before saving<br/>';
                    }
                    if (endTime.value === null || endTime.value === '') {
                        validated.value = false;
                        message += 'Please enter an Ending Time before saving<br/>';
                    }
                    if(!validated.value){
                        message += 'If you are trying to remove an availability, please use the Trash Can button to the right of the field<br/>';
                    }
                    value.value = startTime.value+'-'+endTime.value;
                }

                if (validated.value) {
                    if(props.type == 'password'){
                        var input = {
                            password: value.value,
                            password_confirmation: confirm.value
                        };
                        await props.user?.savePassword(input).then(()=>{
                            value.value = '';
                            confirm.value = '';
                        },(err:any) =>{
                            console.log(err);
                            var err_str = '<ul>';
                            //@ts-ignore
                            err.response.data.errors.password.forEach(element => err_str += '<li>' + element + '</li>');
                            err_str += '</ul>';
                            value.value = '';
                            confirm.value = '';
                            store.commit(MutationTypes.addSystemNotification, {title:'Error saving password',content:err_str,icon:NotificationIcon.WARNING, color:NotificationColor.YELLOW});
                        });
                    }else{
                        await props.user?.saveField(props.field, value.value).then((res)=>{
                            props.user?.setField(props.field,res.data);
                            if(props.type == 'time'){
                                value.value = res.data.find((i:IAvailability)=>i.day === props.field);
                                let update_index = store.getters.user.props.availabilities.findIndex((element:any) => element.day == value.value.day);
                                if(update_index !== -1){
                                    store.getters.user.props.availabilities.splice(update_index,1,value.value);
                                }else{
                                    store.getters.user.props.availabilities.push(value.value);
                                }
                            }
                        });
                    }
                } else {
                    store.commit(MutationTypes.addSystemNotification, {title:'Missing Data',content:message,icon:NotificationIcon.WARNING, color:NotificationColor.YELLOW});
                    edit.value = false;
                    validated.value = true;
                }
                loading.value = false;
            }

            const selectResponse = (options:Array<any>)=>{
                let foundyou = options.find(function(i:any){ return i.id == value.value});
                if (foundyou != null) {
                    return foundyou.title;
                }
            }

            const setMobile = (phoneObject:any) => {
                if(typeof(phoneObject.valid) == 'undefined' || !phoneObject.valid){
                    mobile_number.value = phoneObject.number;
                    mobile_valid.value = false;
                }else{
                    mobile_number.value = phoneObject.number;
                    mobile_valid.value = true;
                }
            }

            const deleteTime = async() => {
                loading.value = true;
                await props.user?.deleteAvailability({day:props.field}).then(() => {
                    value.value = null;
                    startTime.value = null;
                    endTime.value = null;
                    edit.value = false;
                    let update_index = store.getters.user.props.availabilities.findIndex((element:any) => element.day == props.field);
                    if(update_index !== -1){
                        store.getters.user.props.availabilities.splice(update_index,1);
                    }
                });
                loading.value = false;
            }

            return{
                cancel,
                confirm,
                customCancel,
                customSave,
                customSelectCancel,
                deleteTime,
                edit,
                endTime,
                labelText,
                loading,
                mobile_number,
                mobile_valid,
                moment,
                options,
                save,
                selectResponse,
                setMobile,
                simpleEditorOptions,
                startTime,
                userCustomData,
                validated,
                value
            }
        }
    });
</script>

<style lang="scss">

</style>
