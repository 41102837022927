import { GetterTree } from "vuex";
import { State } from "./index";
import { RootState } from "@/store";
import {IPartner} from "@/interfaces/partner";
import User from "@/models/user";
import {IRole} from "@/interfaces/role";
// (D) - Getters
// [D.1] - Define o tipo do getter x
// => x(state: S): TIPO_DE_X;
export type Getters<S = State> = {
    authenticated(state: S): Boolean;
    permissions(state: S):String[];
    hasPermission(state: S,permission:String):Function;
    hasRole(state: S,role:String):Function;
    user(state: S):User|undefined;
    partners(state: S):Array<IPartner>,
    partner(state: S):IPartner | undefined,
};

// [D.2] - Declara o getter x
// =>  x: (state) => {
// =>    return state.x;
// =>  },
export const getters: GetterTree<State, RootState> & Getters = {
    authenticated: (state) => {
        return state.authenticated;
    },
    permissions: (state) => {
      return state.permissions;
    },
    hasPermission:(state) => (permission:String):Boolean => {
        return  Object.values(state.permissions).indexOf(permission) > -1;
    },
    hasRole:(state) => (role:Number):Boolean => {
        return state.user?.props.roles ? state.user?.props.roles.findIndex((i:IRole) => i.id === role) > -1 : false;
    },
    user(state): User|undefined {
        return state.user;
    },
    partners(state): Array<IPartner> {
        return state.partners || [];
    },
    partner(state): IPartner | undefined {
        return state.partner;
    },
    passwordApproved(state): Boolean {
        return state.passwordApproved;
    },
    registation_fields:(state) => {
        return state.registation_fields;
    },
    dashboard_view:(state) => {
        return state.dashboard_view;
    },
    dashboard_roles:(state) => {
        return state.dashboard_roles;
    }
};
