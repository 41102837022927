<template>
    <div id="contact-us" class="row">
        <div class="col-md-8 offset-md-2">
            <div class="card mb-4">
                <div class="card-header scenario-field-header">Contact Us</div>
                <div class="card-body">
                    <form @submit.prevent="submit" v-show="showForm">
                        <div class="form-row">
                            <div class="col-md-6 form-group">
                                <label for="first_name">First Name</label>
                                <input type="text" name="first_name" v-model="first_name" class="form-control" required>
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="last_name">Last Name</label>
                                <input type="text" name="last_name" v-model="last_name" class="form-control" required>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6 form-group">
                                <label for="email">Email</label>
                                <input type="text" name="email" v-model="email" class="form-control" required>
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="mobile_number">Mobile #</label>
                                <input type="text" name="mobile_number" v-model="mobile_number" class="form-control">
                            </div>
                        </div>
                         <div class="form-group">
                            <label for="topicKind">Topic</label>
                            <select class="form-control" v-model="topicKind" id="topicKind">
                                <option value="general_inquiry">General Inquiry</option>
                                <option value="live_sp_issue">Live SP/Login Issue</option>
                                <option value="registering_issue">Signing Up or Registration Issue</option>
                                <option value="missed_and_reschedule">Missed My SP / Reschedule</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="user_message">Message</label>
                            <textarea class="form-control" name="user_message" id="user_message" v-model="user_message" rows="5" required></textarea>
                        </div>
                        <button class="btn btn-primary float-right" type="submit">Submit Form</button>
                    </form>

                    <div v-if="!showForm">
                        <h4 class="text-center">{{message}}</h4>
                        <div class="d-flex justify-content-center">
                            <router-link v-if="!showForm" class="mt-2 font-medium text-primary" to="/dashboard">Return to dashboard >></router-link>
                        </div>
                    </div>
                </div>
            </div>
            <loading :active="loading" :is-full-page="true"></loading>
        </div>
    </div>
</template>

<script lang="ts">
    import {api} from "@/store";
    import {defineComponent, ref} from "vue";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationIcon, NotificationColor} from "@/interfaces/notification";
    import {store} from '@/store';
    import Loading from "vue3-loading-overlay";

    export default defineComponent({
        name:'ContactUsForm',
        components: {Loading},
        setup() {
            const loading = ref(false);
            const user = store.getters.user;
            const first_name = ref(user.props.first_name);
            const last_name = ref(user.props.last_name);
            const email = ref(user.props.email);
            const mobile_number = ref(user.props.mobile_number);
            const topicKind = ref("general_inquiry");
            const partner_id = ref(store.getters.partner.id);
            const user_message = ref();
            const message = ref("");
            const showForm = ref(true);
            
            const submit = async ()=>{
                loading.value = true;

                var form = {
                    first_name: first_name.value,
                    last_name: last_name.value,
                    email: email.value,
                    mobile_number: mobile_number.value,
                    topicKind: topicKind.value,
                    user_message: user_message.value,
                    partner_id: partner_id.value,
                };

                await api.get('sanctum/csrf-cookie');
                api.post('api/contact-us',form).then((res)=>{
                        message.value = res.data.message;
                        showForm.value = false;
                    },(err)=>{
                        store.commit(MutationTypes.addSystemNotification,{
                            title:'Error submitting feedback.',
                            content:err.response.data.message,
                            icon:NotificationIcon.ERROR,
                            color:NotificationColor.RED
                        });
                    });

                loading.value = false;
            }

            return {
                first_name,
                last_name,
                email,
                mobile_number,
                topicKind,
                user_message,
                partner_id,
                loading,
                message,
                submit,
                showForm,
                store,
                user
            };
        },

    });
</script>
