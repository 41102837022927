<template>
    <select v-model="value" :class="style">
        <option v-for="(item,index) in options" v-bind:key="index" :value="item.id" :selected="value === item.id">{{item.short_name}} - {{item.title}}</option>
    </select>
</template>

<script lang="ts">
    import {computed, defineComponent} from "vue";

    export default defineComponent( {
        name: "custom-select",
        props:{
            modelValue:{type:Number},
            style:{type:String, default:'form-control'},
            options:{type:Array, required:true},
        },
        setup(props,{emit}){
            const value = computed({
                get: () => props.modelValue,
                set: (value) => emit('update:modelValue', value)
            })
            return{
                value
            }
        }
    });
</script>

<style scoped>

</style>