import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f0435ca2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 2,
  class: "sidenav-menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sidenav-item", {'active': !$props.group ? _ctx.$route.name === $props.to.name : false, 'open': $setup.open}])
  }, [
    ($props.group)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: "javascript:void(0)",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.toggle && $setup.toggle(...args))),
          class: "sidenav-link sidenav-toggle"
        }, [
          ($props.icon)
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                class: _normalizeClass([$props.icon, "mr-2"])
              }, null, 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, _toDisplayString($props.label), 1)
        ]))
      : (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: $props.to,
          class: "sidenav-link"
        }, {
          default: _withCtx(() => [
            ($props.icon)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: _normalizeClass([$props.icon, "mr-2"])
                }, null, 2))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, _toDisplayString($props.label), 1)
          ]),
          _: 1
        }, 8, ["to"])),
    ($props.group)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}