import {api} from "@/store";
import {IInstitution} from "@/interfaces/institution";

export default class Institution{
    constructor(public data?:IInstitution) {}

    static async list():Promise<any>{
        return await api.get('api/institutions');
    }

    static async get(id:Number):Promise<any>{
        return await api.get('api/institutions/'+id);
    }

    static async create(data:any):Promise<any> {
        return await api.post('api/institutions',data);
    }

    static async update(id:Number|undefined,data:any):Promise<any> {
        return await api.post('api/institutions/'+id,data);
    }

    static async delete(id:Number|undefined):Promise<any>{
        return  await api.delete('api/institutions/'+id);
    }
}
