<template>
    <div v-if="typeof(visit) !== 'undefined' && typeof(view) !== 'undefined'">
        <div class="row card text-white rounded-0 top-header">
            <div class="col-12 text-center">
                <h2 class="my-3 font-weight-bold">Visit Scorecard</h2>
            </div>
        </div>
        <div id="scenario-scorecard" class="row card rounded-0">
            <div class="col-12 card-header">
                <div class="row">
                    <div class="col-sm-12 col-md-8">
                        <img :src="visit.scenario.patient_image.location" class="rounded-circle float-left mr-4" width="100" />
                        <h4 class="pb-0 mb-0">{{visit.scenario.patient_name}}</h4>
                        <p class="mb-2">{{visit.scenario.title}}</p>
                        <p class="text-primary">Visit was completed on {{moment(visit.local_time).format('MM/DD/YY') }} at {{ moment(visit.local_time).format('h:mma') }}</p>
                    </div>
                    <div class="col-sm-12 col-md-4 text-right">
                        <button v-if="view === 'form' || is_learner" class="btn btn-lg btn-secondary-alt w-75 mb-2" @click="viewRecording">View Recording</button>
                        <button class="btn btn-lg btn-secondary-alt w-75" @click="viewScenario">View Scenario</button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div v-if="view === 'form'" class="row">
                    <div class="col-12 px-5 py-4 text-center font-weight-bold" v-html="visit.scenario.scorecard.instructions"></div>
                    <hr class="w-75" />
                    <div class="col-12" v-for="(question_set,key) in questions" :key="key">
                        <h3 v-if="question_set.name !== null" v-html="question_set.name" class="col-12 text-center"></h3>
                        <div v-if="question_set.instructions !== null" v-html="question_set.instructions" class="col-12 px-5 pt-1 pb-4 text-center font-weight-bold"></div>
                        <div class="card-columns px-3">
                            <div v-for="(question,k) in question_set" :key="k">
                                <div v-if="k !== 'instructions' && k !== 'name'" class="card mb-4 shadow-sm w-100">
                                    <div class="card-header bg-secondary text-white font-weight-bold">Question {{parseInt(k) + 1}}</div>
                                    <div class="card-body">
                                        <span v-html="question.question"></span>
                                        <hr>
                                        <div v-if="question.question_type_id == QuestionType.MULTIPLECHOICE">
                                            <div v-for="answer in question.answers" :key="answer.id">
                                                <label class="radio-custom"><span>{{answer.answer}}</span><input type="radio" :id="answer.id" :name="question.id" :value="answer.id" @click="clearError('error_'+question.id)"><span class="checkmark"></span></label>
                                            </div>
                                        </div>
                                        <div v-if="question.question_type_id == QuestionType.TRUEFALSE">
                                            <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
                                                <label v-for="answer in question.answers" :key="answer.id" :class="'p-3 btn btn-light btn_ques_' + question.id" :for="answer.id"><input type="radio" :id="answer.id" :name="question.id" :value="answer.id" @click="buttonUpdate">{{answer.answer}}</label>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                        <div v-if="question.question_type_id == QuestionType.LONGFILL">
                                            <textarea class="w-100" :name="question.id" @input="clearError('error_'+question.id)"/>
                                        </div>
                                        <div v-if="question.question_type_id == QuestionType.LIKERT">
                                            <div class="row">
                                                <div class="col-6">
                                                    <small>
                                                        <span v-for="(answer,index) in question.answers" :key="answer.id">
                                                            {{index + 1}} = {{answer.answer}}<span v-if="index !== 4">, </span>
                                                        </span>
                                                    </small>
                                                </div>
                                                <div class="col-6">
                                                    <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                                                        <label v-for="(answer,index) in question.answers" :key="answer.id" :class="'p-3 btn btn-light btn_ques_' + question.id" :for="'ans_' + answer.id">
                                                            <input type="radio" :id="'ans_' + answer.id" :name="question.id" :value="answer.id" @click="buttonUpdate">{{index + 1}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <error-alert :elm_id="'error_'+question.id" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="w-75" />
                    </div>
                    <div class="col-12 text-center">
                        <button class="btn btn-lg btn-primary my-4 px-5 font-weight-bold" @click="submitScorecard">SUBMIT SCORECARD</button>
                    </div>
                </div>
                <div v-else-if="view === 'observer_complete'">
                    <div class="col-12 mt-4">
                        <completed-form :form="visit.scenario.scorecard" :visit_form="visit.observer_scorecards.filter(scorecard => scorecard.scorer_user_id === user.props.id)[0]" />
                    </div>
                </div>
                <div v-else-if="view === 'learner_complete'" class="row">
                    <div v-if="visit.scenario.scorecard_display_type_id === ScorecardDisplayType.FEEDBACK" class="col-12">
                        <div class="col-12 mt-4">
                            <completed-form :form="visit.scenario.feedback_form" :visit_form="visit.feedback_form" />
                        </div>
                    </div>
                    <div v-else-if="visit.scenario.scorecard_display_type_id === ScorecardDisplayType.MERGED" class="col-12">
                        <div class="col-12 mt-4">
                            <completed-form :form="visit.scenario.scorecard" :visit_form="visit.learner_scorecard" />
                        </div>
                    </div>
                    <div v-else>
                        <div class="col-12">
                            <tabs :options="{ useUrlFragment: false }" nav-class="nav nav-pills m-3" nav-item-class="nav-item" nav-item-link-class="nav-link" nav-item-link-active-class="active">
                                <div v-if="visit.scenario.scorecard_display_type_id === ScorecardDisplayType.FEEDBACK_INDIVIDUAL" class="col-12">
                                    <tab name="SP Scorecard" active>
                                        <completed-form :form="visit.scenario.feedback_form" :visit_form="visit.feedback_form" />
                                    </tab>
                                    <div v-if="visit.is_scorecard_complete">
                                        <tab v-for="(scorecard, index) in visit.observer_scorecards" v-bind:key="scorecard.id" :name="'Scorecard ' + (index + 1)">
                                            <completed-form :form="visit.scenario.scorecard" :visit_form="scorecard" />
                                        </tab>
                                    </div>
                                </div>
                                <div v-else-if="visit.scenario.scorecard_display_type_id === ScorecardDisplayType.FEEDBACK_MERGED" class="col-12">
                                    <tab name="SP Scorecard" active>
                                        <completed-form :form="visit.scenario.feedback_form" :visit_form="visit.feedback_form" />
                                    </tab>
                                    <tab name="Merged" v-if="visit.is_scorecard_complete">
                                        <completed-form :form="visit.scenario.scorecard" :visit_form="visit.learner_scorecard" />
                                    </tab>
                                </div>
                                <div v-else-if="visit.scenario.scorecard_display_type_id === ScorecardDisplayType.FEEDBACK_INDIVIDUAL_MERGED" class="col-12">
                                    <tab name="SP Scorecard" active >
                                        <completed-form :form="visit.scenario.feedback_form" :visit_form="visit.feedback_form" />
                                    </tab>
                                    <tab name="Merged" v-if="visit.is_scorecard_complete">
                                        <completed-form :form="visit.scenario.scorecard" :visit_form="visit.learner_scorecard" />
                                    </tab>
                                    <div v-if="visit.is_scorecard_complete">
                                        <tab v-for="(scorecard, index) in visit.observer_scorecards" v-bind:key="scorecard.id" :name="'Observer Scorecard ' + (index + 1)">
                                            <completed-form :form="visit.scenario.scorecard" :visit_form="scorecard" />
                                        </tab>
                                    </div>
                                </div>
                                <div v-else-if="visit.scenario.scorecard_display_type_id === ScorecardDisplayType.INDIVIDUAL" class="col-12">
                                    <div v-if="visit.is_scorecard_complete">
                                        <tab v-for="(scorecard, index) in visit.observer_scorecards"  v-bind:key="scorecard.id" :name="'Observer Scorecard ' + (index + 1)" :active="index===0">
                                            <completed-form :form="visit.scenario.scorecard" :visit_form="scorecard" />
                                        </tab>
                                    </div>
                                </div>
                                <div v-else-if="visit.scenario.scorecard_display_type_id === ScorecardDisplayType.INDIVIDUAL_MERGED" class="col-12">
                                    <tab name="Merged" active v-if="visit.is_scorecard_complete">
                                        <completed-form :form="visit.scenario.scorecard" :visit_form="visit.learner_scorecard" />
                                    </tab>
                                    <div v-if="visit.is_scorecard_complete">
                                        <tab v-for="(scorecard, index) in visit.observer_scorecards"  v-bind:key="scorecard.id" :name="'Observer Scorecard ' + (index + 1)">
                                            <completed-form :form="visit.scenario.scorecard" :visit_form="scorecard" />
                                        </tab>
                                    </div>
                                </div>
                            </tabs>
                        </div>
                    </div>
                </div>
                <div v-else class="alert alert-danger">
                    Error Loading Scorecard
                </div>
            </div>
        </div>
    </div>
    <div v-if="!loading && typeof(visit) === 'undefined'">
        <p>Error Loading Scorecard</p>
        <p>{{ loadErrorMessage }}</p>
    </div>
    <br>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { defineComponent, reactive, ref } from "vue";
    import { store } from '../store/index';
    import {FormConfig} from "@/interfaces/form-config";
    import Form from "@/utilities/form";
    import Loading from 'vue3-loading-overlay';
    import {QuestionType} from "@/enums/question-type";
    import moment from 'moment';
    import {NotificationIcon, NotificationColor} from "@/interfaces/notification";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import { useRoute } from 'vue-router';
    import Scorecard from "@/models/scorecard";
    import Visit from "@/models/visit";
    import ErrorAlert from "@/components/error-alert.vue";
    import {ScorecardDisplayType} from "@/enums/scorecard-display-type";
    import {Tabs, Tab} from 'vue3-tabs-component';
    import CompletedForm from "@/components/completed-form.vue";
    import router from "@/router";

    export default defineComponent({
        components: { CompletedForm, ErrorAlert, Loading, Tabs, Tab },
        mounted(){
            this.getScorecard();
        },
        setup() {
            const loading = ref(false);
            const route = useRoute();
            const loadErrorMessage = ref('');
            const is_learner = ref<Boolean>(false);
            const is_observer = ref<Boolean>(false);
            const is_observer_scorecard_complete = ref<Boolean>(false);
            const is_scorecard_complete = ref<Boolean>(false);
            const questions = ref<Array<any>>([]);
            const view = ref();
            const visit = ref();
            const getForm:FormConfig = reactive({
                visit_id: {
                    value:route.params.visit_id,
                    valid:true,
                    validationMessage:''
                }
            });
            const getFormHelper = new Form(getForm);
            const submitForm:FormConfig = reactive({
                visit_id: {
                    value:route.params.visit_id,
                    valid:true,
                    validationMessage:''
                },
                responses: {
                    value:[],
                    valid:false,
                    validationMessage:''
                }
            });
            const submitFormHelper = new Form(submitForm);

            const buttonUpdate = async(event: { target: HTMLInputElement; }) => {
                let classes = event?.target.parentElement?.classList;
                let target_class = null;
                let err_class = null;
                classes?.forEach((element) => {
                    if(element.startsWith('btn_ques')){
                        target_class = element;
                        err_class = element.replace('btn_ques','error');
                    }
                });
                if(target_class !== null){
                    if(err_class !== null){
                        clearError(err_class);
                    }
                    let btn_group: Element[] = Array.from(document.getElementsByClassName(target_class));
                    for(let btn of btn_group){
                        btn.classList.remove('active');
                    }
                    event?.target.parentElement?.classList.add('active');
                }
            }

            const clearError = (err_class:string) => {
                let ques_err = document.getElementById(err_class);
                if(ques_err !== null){
                    ques_err.innerHTML = '';
                    ques_err.classList.add('d-none');
                }
            }

            const formatQuestions = async() => {
                let index = 0;
                let q_index = 0;
                visit.value.scenario.scorecard.questions.forEach((elm:any) => {
                    if(elm.question !== null){
                        if(typeof(questions.value[index]) == 'undefined'){
                            questions.value[index] = {};
                        }
                        questions.value[index][q_index] = elm.question;
                        q_index++;
                    }else if(elm.rubric !== null){
                        if(typeof(questions.value[index]) !== 'undefined'){
                            index++;
                        }
                        questions.value[index] = {};
                        q_index = 0;
                        questions.value[index]['name'] = elm.rubric.name;
                        questions.value[index]['instructions'] = elm.rubric.scorer_instructions;
                        elm.rubric.questions.forEach((rubq:any) => {
                            questions.value[index][q_index] = rubq;
                            q_index++;
                        });
                        q_index = 0;
                        index++;
                    }
                });
            }

            const getScorecard = async() => {
                loading.value = true;
                await Scorecard.getVisitScorecard(route.params.visit_id,getFormHelper.mapValues()).then((res)=>{
                    loading.value = false;
                    view.value = res.data.view;
                    visit.value = res.data.visit;
                    is_learner.value = visit.value.learner_user_id == store.state.auth.user?.props.id;
                    formatQuestions();
                },(err)=>{
                    loading.value = false;
                    loadErrorMessage.value = err.message;
                    console.log(err);
                });
            }

            const submitScorecard = async() => {
                loading.value = true;
                let responses:Array<any> = [];
                let response = '';
                let has_errors = false;
                var errors: string[] = [];
                questions.value.forEach((ques_set:any) => {
                    Object.keys(ques_set).forEach((key:any) => {
                        if(key != 'instructions' && key != 'name'){
                            let ques = ques_set[key];
                            response = '';
                            if([QuestionType.MULTIPLECHOICE, QuestionType.TRUEFALSE, QuestionType.LIKERT].includes(ques.question_type_id)){
                                let answers = document.getElementsByName(ques.id);
                                answers.forEach((ans:any) => {
                                    if(ans.checked){
                                        response = ans.value;
                                    }
                                });
                            }else if(ques.question_type_id == QuestionType.LONGFILL){
                                response = (document.getElementsByName(ques.id)[0] as HTMLInputElement).value;
                            }else{
                                has_errors = true;
                                errors[ques.id] = 'Invalid question type';
                            }
                            if(response == ''){
                                errors[ques.id] = 'Response is required';
                                has_errors = true;
                            }else{
                                responses[ques.id] = {
                                    question_id: ques.id,
                                    response: response
                                }
                            }
                        }
                    });
                });
                if(has_errors){
                    for(var key in errors){
                        let error_msg = document.getElementById('error_' + key);
                        if(error_msg !== null){
                            error_msg.innerHTML = errors[key];
                            error_msg.classList.remove('d-none');
                        }
                    }
                    store.commit(MutationTypes.addSystemNotification, {title:'All fields must be completed before submitting scorecard.',content:'',icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    loading.value = false;
                }else{
                    submitForm.responses.value = responses;
                    submitForm.responses.valid = true;
                    await Scorecard.submitVisitScorecard(route.params.visit_id,submitFormHelper.mapValues()).then((res)=>{
                        visit.value = res.data.visit;
                        view.value = res.data.view;
                        loading.value = false;
                        store.commit(MutationTypes.addSystemNotification, {title:'Scorecard submitted.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                    },(err)=>{
                        loading.value = false;
                        store.commit(MutationTypes.addSystemNotification, {title:'Error submitting scorecard.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                        console.log(err);
                    });
                }
            }

            const viewRecording = async() => {
                loading.value = true;
                var post_data = {
                    visit_id: visit.value.id
                };
                await Visit.getRecording(visit.value.id, post_data).then((res)=>{
                    loading.value = false;
                    if(res.data.link !== 'undefined'){
                        store.commit(MutationTypes.addSystemNotification, {title:'Redirecting to recording url.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                        window.open(res.data.link);
                    }else{
                        store.commit(MutationTypes.addSystemNotification, {title:'Error retrieving recording.',content:'Link not generated',icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                    }
                },(err)=>{
                    loading.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Error retrieving recording.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
            }

            const viewScenario = async() => {
                router.push({ path: '/visit/' + visit.value.id });
            }

            return {
                buttonUpdate,
                clearError,
                CompletedForm,
                ErrorAlert,
                getScorecard,
                is_learner,
                is_observer,
                is_observer_scorecard_complete,
                is_scorecard_complete,
                loadErrorMessage,
                loading,
                moment,
                QuestionType,
                questions,
                ScorecardDisplayType,
                submitScorecard,
                user: store.state.auth.user,
                view,
                visit,
                viewRecording,
                viewScenario
            };
        },
    });
</script>
<style scoped lang="scss">
    .card-columns {
        column-count: 2;
        @media (max-width: 720px){
            column-count: 1;
        }
        .card{
            display: inline-block;
            textarea{
                height: 90px;
                resize: none;
                border-color: #666666;
                &:focus {
                    outline: none !important;
                    border: 2px solid #6BBAA3;
                }
            }
        }
    }
    .top-header{
        background-color: #666666;
    }
</style>
