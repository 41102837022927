import {IAuthor} from "@/interfaces/author";
import {api} from "@/store";

export class Author{
    constructor(public data?:IAuthor) {}

    static async saveAuthor(formData:any):Promise<any>{
        return await api.post('api/author/save',formData);
    }

    static async updateAuthor(formData:any):Promise<any>{
        return await api.post('api/author/update',formData);
    }

}