<template>
    <div id="user-dashboards" class="card">
        <admin v-if="store.getters.dashboard_view === UserRole.ADMIN" />
        <applicant v-if="store.getters.dashboard_view === UserRole.ZERO" />
        <authoring v-if="store.getters.dashboard_view === UserRole.AUTHOR" />
        <learner v-if="store.getters.dashboard_view === UserRole.LEARNER" />
        <standardized-patient v-if="store.getters.dashboard_view === UserRole.SP" />
        <observer v-if="store.getters.dashboard_view === UserRole.OBSERVER" />
        <reviewer v-if="store.getters.dashboard_view === UserRole.REVIEWER" />
        <trainer v-if="store.getters.dashboard_view === UserRole.TRAINER" />
        <trainer v-if="store.getters.dashboard_view === UserRole.HEADTRAINER" />
        <client-admin v-if="store.getters.dashboard_view === UserRole.CLIENTADMIN" />
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import {store} from "@/store";
    import {UserRole} from "@/enums/user-role";
    import Admin from "@/views/dashboards/admin.vue";
    import Applicant from "@/views/dashboards/applicant.vue";
    import Authoring from "@/views/dashboards/authoring.vue";
    import ClientAdmin from "@/views/dashboards/client-admin.vue";
    import Learner from "@/views/dashboards/learner.vue";
    import Observer from "@/views/dashboards/observer.vue";
    import Reviewer from "@/views/dashboards/reviewer.vue";
    import StandardizedPatient from "@/views/dashboards/standardized-patient.vue";
    import Trainer from "@/views/dashboards/trainer.vue";

    export default defineComponent({
        components: {
            Admin,
            Applicant,
            Authoring,
            ClientAdmin,
            Learner,
            Observer,
            Reviewer,
            StandardizedPatient,
            Trainer,
        },
        setup() {
            return {
                store,
                UserRole
            };
        },
    });
</script>
