<template>
    <div class="card shadow-sm mb-4">
        <div class="card-header text-white bg-secondary font-weight-bold">Financial Overview</div>
        <div class="card-content">
            <div class="row m-auto" v-if="!loading">
                <div class="col-md-12 px-5 py-2 financial-calendar">
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <select class="border-0 text-secondary mr-2" v-model="query_month">
                                <option v-for="ind in ['01','02','03','04','05','06','07','08','09','10','11','12']" v-bind:key="ind" :value="ind">{{moment('2021-' + ind + '-01 00:00:00').format('MMMM')}}</option>
                            </select>
                            <select class="border-0 text-secondary mr-2" v-model="query_year">
                                <option v-for="year in years" v-bind:key="year" :value="year">{{year}}</option>
                            </select>
                            <button class="btn btn-primary btn-xs mb-1 float-right" @click="getFinancialData()">Update</button>
                        </div>
                        <div v-if="store.getters.hasPermission('financial_report.access') && is_admin" class="col-sm-12 mt-2 mb-1">
                            <button  class="btn btn-primary btn-xs float-right" @click="router.push({name:'financial.report'})">View Full Report</button>
                        </div>
                        <div v-if="store.getters.hasPermission('financial_report.access') && is_admin" class="col-sm-12 mt-2 mb-1">
                            <button  class="btn btn-primary btn-xs float-right" @click="router.push({name:'payment.report'})">View Payment Report</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 px-0">
                    <div class="table-responsive">
                        <table class="table mb-0">
                            <tbody class="font-weight-bold">
                                <tr class="bg-light" v-if="!is_author">
                                    <td class="pl-5" :class="store.getters.user.props.super_admin || (store.getters.hasRole(UserRole.SP) && store.getters.hasRole(UserRole.OBSERVER)) || store.getters.hasRole(UserRole.ADMIN) ? 'pb-0' : ''">Visits</td>
                                    <td class="pr-5 text-right text-secondary" :class="store.getters.user.props.super_admin || (store.getters.hasRole(UserRole.SP) && store.getters.hasRole(UserRole.OBSERVER)) || store.getters.hasRole(UserRole.ADMIN) ? 'pb-0' : ''">${{visits}}</td>
                                </tr>
                                <tr v-if="(store.getters.user.props.super_admin || (store.getters.hasRole(UserRole.SP) && store.getters.hasRole(UserRole.OBSERVER)) || store.getters.hasRole(UserRole.ADMIN)) && !is_author" class="bg-light">
                                    <td class="pl-5 py-0"><small>&ndash;&nbsp;SP</small></td>
                                    <td class="pr-5 py-0 text-right text-secondary"><small>${{sp_visits}}</small></td>
                                </tr>
                                <tr v-if="(store.getters.user.props.super_admin || (store.getters.hasRole(UserRole.SP) && store.getters.hasRole(UserRole.OBSERVER)) || store.getters.hasRole(UserRole.ADMIN)) && !is_author" class="bg-light">
                                    <td class="pl-5 py-0"><small>&ndash;&nbsp;Observer</small></td>
                                    <td class="pr-5 py-0 text-right text-secondary"><small>${{(observer_visits)}}</small></td>
                                </tr>
                                <tr class="bg-light" v-if="!is_author">
                                    <td class="pl-5">Training Sessions</td>
                                    <td class="pr-5 text-right text-secondary">${{(training_sessions)}}</td>
                                </tr>
                                <tr class="bg-light" v-if="!is_author">
                                    <td class="pl-5">Late Cancellations</td>
                                    <td class="pr-5 text-right text-secondary">${{late_cancellations}}</td>
                                </tr>
                                <tr class="bg-light" v-if="!is_author">
                                    <td class="pl-5">Early Cancellations</td>
                                    <td class="pr-5 text-right text-secondary">${{early_cancellations}}</td>
                                </tr>
                                <tr class="bg-light" v-if="!is_author">
                                    <td class="pl-5">No Shows</td>
                                    <td class="pr-5 text-right text-secondary">${{no_shows}}</td>
                                </tr>
                                <tr class="bg-light" v-if="!is_author">
                                    <td class="pl-5">Learner Technical Issues</td>
                                    <td class="pr-5 text-right text-secondary">${{learner_technical_issues}}</td>
                                </tr>
                                <tr class="bg-light" v-if="interviews && !is_author">
                                    <td class="pl-5">Interviews</td>
                                    <td class="pr-5 text-right text-secondary">${{interviews}}</td>
                                </tr>
                                <tr class="bg-light" v-if="(is_admin && authoring) || is_author">
                                    <td class="pl-5">Authoring Royalties</td>
                                    <td class="pr-5 text-right text-secondary">${{authoring}}</td>
                                </tr>
                                <tr v-for="(author_scenario, ind) in authoring_scenarios" v-bind:key="ind" class="bg-light">
                                    <td class="pl-5 py-0"><small>&ndash;&nbsp;{{author_scenario.title}} ({{author_scenario.count}} x ${{author_scenario.rate}})</small></td>
                                    <td class="pr-5 py-0 text-right text-secondary"><small>${{author_scenario.total}}</small></td>
                                </tr>
                                <tr>
                                    <td class="pl-5">TOTAL</td>
                                    <td v-if="!is_author" class="pr-5 text-right text-primary ">${{total}}</td>
                                    <td v-else class="pr-5 text-right text-primary">${{authoring}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="loading-area" v-else>
                <loading :active="loading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent,ref} from "vue";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationIcon, NotificationColor} from "@/interfaces/notification";
    import {store} from '../../store/index';
    import {UserRole} from "@/enums/user-role";
    import Loading from 'vue3-loading-overlay';
    import moment from "moment";
    import router from "@/router";
    import Visit from "@/models/visit";

    export default defineComponent({
        emits: ['financial-dates'],
        components: {
            Loading
        },
        props: {
            is_admin: {type:Boolean,default:false},
            is_author: {type:Boolean,default:false},
            financial_data: {type:Object,required:true},
            filter_partner_id: {default:null},
            filter_scenario_id: {default:null}
        },
        watch: {
            financial_data: function(newData){
                this.sp_visits = newData.completed_total;
                this.observer_visits = newData.scorecards_total;
                this.visits = newData.completed_total + newData.scorecards_total;
                this.training_sessions = newData.training_sessions_total;
                this.late_cancellations = newData.late_cancellations_total;
                this.early_cancellations = newData.early_cancellations_total;
                this.no_shows = newData.no_shows_total;
                this.learner_technical_issues = newData.learner_technical_issues_total;
                this.interviews = newData.interviews_total;
                this.authoring = newData.authoring_total;
                this.authoring_scenarios = newData.authoring_scenarios;
                this.total = newData.total;
                this.loading = false;
            },
            filter_partner_id: function(newData){
                this.partner_id = newData;
            },
            filter_scenario_id: function(newData){
                this.scenario_id = newData;
            }
        },
        setup(props, {emit}) {
            const partner_id = ref(props.filter_partner_id);
            const scenario_id = ref(props.filter_scenario_id);
            const query_month = ref(moment().format('MM'));
            const query_year = ref(moment().format('YYYY'));
            const years = ref<Array<Number>>([]);
            let current_year = Number.parseInt(moment().format('YYYY'));
            let start_year = 2021;
            while(start_year <= current_year){
                years.value.push(start_year);
                start_year = start_year + 1;
            }
            const sp_visits = ref(0);
            const observer_visits = ref(0);
            const visits = ref(0);
            const training_sessions = ref(0);
            const late_cancellations = ref(0)
            const early_cancellations = ref(0);
            const no_shows = ref(0);
            const learner_technical_issues = ref(0);
            const interviews = ref(0);
            const authoring = ref(0);
            const authoring_scenarios = ref({});
            const total = ref(0);
            const loading = ref<boolean>(true);
            const getFinancialData = async() => {
                loading.value = true;
                var post_data:any = {
                    month: query_month.value,
                    year: query_year.value,
                    partner_id: partner_id.value,
                    scenario_id: scenario_id.value,
                    is_author: props.is_author
                };
                if(!props.is_admin){
                    post_data.user_id = store.getters.user.props.id;
                }
                if(![1,28].includes(store.getters.partner.id)){
                    post_data.partner_id = store.getters.partner.id;
                }
                Visit.getFinancialData(post_data).then((res)=>{
                    sp_visits.value = res.data.completed_total;
                    observer_visits.value = res.data.scorecards_total;
                    visits.value = res.data.completed_total + res.data.scorecards_total;
                    training_sessions.value = res.data.training_sessions_total;
                    late_cancellations.value = res.data.late_cancellations_total;
                    early_cancellations.value = res.data.early_cancellations_total;
                    no_shows.value = res.data.no_shows_total;
                    learner_technical_issues.value = res.data.learner_technical_issues_total;
                    interviews.value = res.data.interviews_total;
                    authoring.value = res.data.authoring_total;
                    authoring_scenarios.value = res.data.authoring_scenarios;
                    total.value = res.data.total;
                    loading.value = false;
                    emit('financial-dates',[query_month.value,query_year.value]);
                },(err)=>{
                    console.log(err);
                    store.commit(MutationTypes.addSystemNotification, {title:'Error retrieving financial data.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    loading.value = false;
                });
            };
            return {
                getFinancialData,
                loading,
                moment,
                query_month,
                query_year,
                partner_id,
                scenario_id,
                store,
                UserRole,
                years,
                router,
                sp_visits,
                observer_visits,
                visits,
                training_sessions,
                late_cancellations,
                early_cancellations,
                no_shows,
                learner_technical_issues,
                interviews,
                authoring,
                authoring_scenarios,
                total,
            };
        }
    });
</script>
<style scoped lang="scss">
    table{
        tr{
            td{
                border: 0;
            }
        }
    }
    .loading-area{
        height: 500px;
    }
</style>
