<template>
    <div class="row mb-2 pt-2 pb-4">
        <div class="col-lg-4 col-md-12 text-muted d-flex justify-content-start align-items-center">
            <span>{{ labelText }}: </span>
        </div>
        <div>
            <div v-for="time in times" v-bind:key="time.id" class="profile-data pb-2">
                <div v-if="!time.edit" class="d-flex align-items-center">
                    {{ time.start_time ? moment(time.start_time,["HH.mm"]).format("h:mm A") : '' }} - {{ time.end_time ? moment(time.end_time,["HH.mm"]).format("h:mm A") : '' }}
                    <button v-if="!edit" class="btn btn-link text-primary btn-sm" @click="editTime(time)"><i class="fa fa-edit"></i></button>
                    <button v-if="!edit" class="btn btn-link text-primary btn-sm" @click="deleteTime(time.id)"><i class="fa fa-trash"></i></button>
                </div>
                <div v-else class="d-flex align-items-center">
                    <input type="time" v-model="start_time" step="1800" class="form-control profile-availability">-<input type="time" step="1800" v-model="end_time" class="form-control profile-availability">
                    <button class="btn btn-primary" type="button" @click="update(time)"><i class="fas fa-save"></i></button>
                    <button class="btn btn-secondary" @click="cancel(time)" type="button"><i class="fas fa-ban"></i></button>
                </div>
                <Loading :active="loading" :is-full-page="false"></Loading>
            </div>
            <div class="profile-data">
                <button v-if="!add" class="btn btn-sm btn-primary mt-3" type="button" @click="addTime()">Add Availability</button>
                <div v-else class="col-lg-8 input-group">
                    <div class="d-flex align-items-center">
                        <input type="time" v-model="start_time" step="1800" class="form-control profile-availability">-<input type="time" v-model="end_time" step="1800" class="form-control profile-availability">
                        <button class="btn btn-primary" type="button" @click="addTimeSubmit"><i class="fas fa-save"></i></button>
                        <button class="btn btn-secondary" @click="cancelAddTime" type="button"><i class="fas fa-ban"></i></button>
                    </div>
                    <Loading :active="loading" :is-full-page="false"></Loading>
                </div>
            </div>
        </div>
        <div class="row w-100 m-auto">
            <div class="col-12 w-75 m-auto">
                <hr/>
            </div>
        </div>
    </div>
</template>


<script lang="ts">
    import {computed, defineComponent, ref} from "vue";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from '@/interfaces/notification';
    import {store} from '@/store/index';
    import {toTitleCase} from "@/utilities/string-helpers";
    import User from "@/models/user";
    import Loading from "vue3-loading-overlay";
    import moment from "moment";
    import {IAvailability} from "@/interfaces/availability";

export default defineComponent({
        name: "edit-availability",
        components: {Loading},
        props:{
            user: {type: User, required:true},
            field: {type:String,required:true},
            items: {type:Array},
            overrideLabel: {type:String},
        },
        setup(props){
            const loading = ref(false);
            const labelText = computed(()=>props.overrideLabel ? props.overrideLabel : toTitleCase(props.field));
            const edit = ref(false);
            const add = ref(false);

            const start_time = ref<String | undefined | null>(null);
            const end_time = ref<String | undefined| null>(null);

            const times = ref<Array<IAvailability> | undefined>(props.user.props.availabilities?.filter((d) => d.day === props.field));

            const addTimeSubmit = async() => {
                loading.value = true;
                let message = validateTime();
                if(message != ''){
                    loading.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Missing Data',content:message,icon:NotificationIcon.WARNING, color:NotificationColor.YELLOW});
                }else{
                    var post_data = {
                        'day': props.field,
                        'start_time': start_time.value,
                        'end_time': end_time.value
                    }
                    await props.user?.addAvailability(post_data).then((res)=>{
                        store.getters.user.props.availabilities = res.data.availabilities;
                        times.value = props.user.props.availabilities?.filter((d) => d.day === props.field);
                        store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Availability added!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                        loading.value = false;
                        cancelAddTime();
                    },(err)=>{
                        console.log(err);
                        store.commit(MutationTypes.addSystemNotification, {title:'Error',content:err.response.data.message,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                        loading.value = false;
                    });
                }
            }

            const addTime = () => {
                start_time.value = null;
                end_time.value = null;
                edit.value = true;
                add.value = true;
            }

            const cancel = (time:any) => {
                time.edit = false;
                edit.value = false;
                start_time.value = null;
                end_time.value = null;
            }

            const cancelAddTime = () => {
                add.value = false;
                edit.value = false;
                start_time.value = null;
                end_time.value = null;
            }

            const editTime = (time:any) => {
                start_time.value = moment(time.start_time,["HH.mm"]).format('HH:mm');
                end_time.value = moment(time.end_time,["HH.mm"]).format('HH:mm');
                time.edit = true;
                edit.value = true;
            }

            const deleteTime = async(id:number) => {
                loading.value = true;
                await props.user?.deleteAvailability({id:id}).then(() => {
                    let update_index = store.getters.user.props.availabilities.findIndex((element:any) => element.id == id);
                    if(update_index !== -1){
                        store.getters.user.props.availabilities.splice(update_index,1);
                        times.value = props.user.props.availabilities?.filter((d) => d.day === props.field);
                    }
                });
                loading.value = false;
            }

            const update = async(time:any) => {
                loading.value = true;
                let message = validateTime();
                if(message != ''){
                    loading.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Missing Data',content:message,icon:NotificationIcon.WARNING, color:NotificationColor.YELLOW});
                }else{
                    var post_data = {
                        'ua_id': time.id,
                        'day': props.field,
                        'start_time': start_time.value,
                        'end_time': end_time.value
                    }
                    await props.user?.updateAvailability(post_data).then((res)=>{
                        store.getters.user.props.availabilities = res.data.availabilities;
                        times.value = props.user.props.availabilities?.filter((d) => d.day === props.field);
                        store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Availability added!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                        loading.value = false;
                        cancel(time);
                    },(err)=>{
                        store.commit(MutationTypes.addSystemNotification, {title:'Error',content:err.response.data.message,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                        loading.value = false;
                    });
                }
            }

            const validateTime = () => {
                let message = '';
                if (start_time.value === null || start_time.value === '' || start_time.value === undefined) {
                    message = 'Please enter a Start Time before saving<br/>';
                }
                if (end_time.value === null || end_time.value === '' || end_time.value === undefined) {
                    message += 'Please enter an Ending Time before saving<br/>';
                }
                return message;
            }

            return{
                add,
                addTime,
                addTimeSubmit,
                cancel,
                cancelAddTime,
                deleteTime,
                edit,
                editTime,
                end_time,
                labelText,
                loading,
                moment,
                start_time,
                times,
                update
            }
        }
    });
</script>

<style lang="scss">
    .availibility-hr {

    }
</style>
