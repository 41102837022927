
import {api} from "@/store";
import {ICountry} from "@/interfaces/country";

export class Country{
    constructor(public data?:ICountry) {}

    static async getCountries():Promise<any> {
        return await api.get('api/countries');
    }

    static async getCountry(id: Number | undefined): Promise<any>{
        return await api.get('api/countries/'+id);
    }
}