<template>
    <div>
        <h1>Manage Passwords</h1>
        <p><small>Update password settings for each partner.</small></p>
    </div>
    <div v-if="!loading && partners" class="overflow-auto">
        <div class="card py-2 px-2">
            <table class="table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th class="header-row py-2 px-2">Partner Name</th>
                        <th class="header-row py-2">Partner Slug</th>
                        <th class="header-row py-2 text-center">Enable Password</th>
                        <th class="header-row py-2 text-center">Password</th>
                        <th class="header-row py-2 text-center">Rotate Password</th>
                        <th class="header-row py-2 text-center">Rotation End Date</th>
                        <th class="header-row py-2 text-center">Rotation Frequency</th>
                        <th class="header-row py-2">Edit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="partner in partners" v-bind:key="partner.id">
                        <td>{{partner.short_name}}</td>
                        <td>{{partner.slug}}</td>
                        <td class="text-center">
                            <i v-if="partner.enable_password" class="text-success fas fa-check-circle"></i>
                            <i v-else class="text-danger fas fa-times-circle"></i>
                        </td>
                        <td class="text-center">{{partner.enable_password ? partner.password : '-'}}</td>
                        <td class="text-center" v-if="partner.enable_password">
                            <i v-if="partner.rotate_password" class="text-success fas fa-check-circle"></i>
                            <i v-else class="text-danger fas fa-times-circle"></i>
                        </td>
                        <td class="text-center" v-else>-</td> 
                        <td class="text-center" v-if="partner.enable_password">
                            <span v-if="partner.rotation_end_date">{{moment(partner.rotation_end_date).format("MM/DD/YYYY")}}</span>
                            <span v-else>-</span>
                        </td>
                        <td class="text-center" v-else>-</td>
                        <td class="text-center">{{partner.enable_password && partner.rotate_password ? partner.rotation_frequency + ' days' : '-' }}</td>
                        <td><button class="btn btn-xs btn-primary" v-on:click="showEditModal(partner)"><i class="fas fa-pencil-alt"></i></button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-else-if="!loading && !partners">
        <p>No partners in system</p>
    </div>
    <modal v-model="show_edit_modal">
        <template v-slot:header>Edit Partner Password Settings</template>
        <div>
            <p>{{edit_partner?.short_name}}</p>
        </div>
        <div class="form-group">
            <label>Enable Password <input type="checkbox" v-model="enable_password" /></label>
        </div>
        <div :class="enable_password ? 'form-group' : 'text-muted form-group'">
            <label>Password&nbsp;</label>
            <input type="text" maxlength="25" v-model="password" :disabled="!enable_password"/>
            <button class="btn btn-sm btn-primary ml-2" v-on:click="generatePassword" :disabled="!enable_password">Generate</button>
            <div v-if="password_error.length" class="alert alert-danger mt-2">{{password_error}}</div>
        </div>
        <div :class="enable_password ? 'form-group' : 'text-muted form-group'">
            <label>Rotate Password <input type="checkbox" v-model="rotate_password" :disabled="!enable_password" /></label>
        </div>
        <div :class="enable_password && rotate_password ? 'form-group' : 'text-muted form-group'">
            <label>Rotation Frequency</label>
            <select v-model="rotation_frequency" class="ml-2" :disabled="!enable_password || !rotate_password">
                <option v-for="day in [1,2,3,4,5,6,7]" v-bind:key="day" :value="day">{{day}} Days</option>
            </select>
        </div>
        <div :class="enable_password && rotate_password ? 'form-group' : 'text-muted form-group'">
            <label>Rotation end date&nbsp;</label>
            <div class="d-inline-block">
                <datepicker placeholder="Select Date..." v-model="rotation_end_date" :lower-limit="new Date()" :disabled="!enable_password || !rotate_password" />
            </div>
            <button type="button" class="ml-2 btn btn-sm btn-primary" v-on:click="clearDate">Clear</button>
        </div>
        <template v-slot:footer>
            <button class="btn btn-md btn-secondary" v-on:click="closeEditModal">Cancel</button>
            <button class="btn btn-md btn-primary" v-on:click="savePartner">Save</button>
        </template>
    </modal>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import Datepicker from 'vue3-datepicker';
    import {defineComponent, ref} from "vue";
    import Modal from "@/components/modal.vue";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    import {store} from "@/store";
    import Loading from 'vue3-loading-overlay';
    import moment from "moment";
    import Partner from "@/models/partner";

    export default defineComponent({
        components: {Datepicker, Loading, Modal},
        mounted(){
            this.getPartnerPasswords();
        },

        setup() {
            const edit_partner = ref<any>(null);
            const enable_password = ref<Boolean>(false);
            const password = ref<String>("");
            const rotate_password = ref<Boolean>(false);
            const rotation_end_date = ref<any>(null);
            const rotation_frequency = ref<Number>(0);
            const loading = ref<Boolean>(true);
            const partners = ref<Array<any>>([]);
            const password_error = ref<String>("");
            const show_edit_modal = ref<Boolean>(false);


            const clearDate = async() => {
                rotation_end_date.value = null;
            }

            const closeEditModal = async() => {
                show_edit_modal.value = false;
                edit_partner.value = null;
            }

            const generatePassword = async() => {
                var letters = [
                    'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','x','y','z',
                    'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','X','Y','Z'
                ];
                var chars = ['!','@','#','$','%','^','&','*','(',')','-','+','_','='];
                var rand_password = '';
                for(var i = 0; i < 12; i++){
                    if(Math.floor(Math.random() * 4) > 0){
                        rand_password += letters[Math.floor(Math.random() * letters.length)];
                    }else{
                        rand_password += chars[Math.floor(Math.random() * chars.length)];
                    }
                }
                password.value = rand_password;
            }

            const getPartnerPasswords = async() => {
                loading.value = true;
                await Partner.getPasswords().then((res) => {
                    partners.value = res.data.partners;
                },(err) => {
                    console.log(err);
                });
                loading.value = false;
            }

            const savePartner = async() => {
                loading.value = true;
                var post_data:any = {
                    partner_id: edit_partner.value.id,
                    enable_password: enable_password.value
                };
                if(enable_password.value){
                    post_data['password'] = password.value;
                    post_data['rotate_password'] = rotate_password.value;
                    if(rotate_password.value){
                        if(rotation_end_date.value){
                            post_data['rotation_end_date'] = moment(rotation_end_date.value).format('YYYY-MM-DD');
                        }
                        post_data['rotation_frequency'] = rotation_frequency.value;
                    }
                }
                await Partner.savePassword(post_data).then((res) => {
                    partners.value = res.data.partners;
                    show_edit_modal.value = false;
                    edit_partner.value = null;
                    enable_password.value = false;
                    password.value = "";
                    rotate_password.value = false;
                    rotation_end_date.value = null;
                    rotation_frequency.value = 0;
                    password_error.value = "";
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Partner Password Settings Updated!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});

                },(err:any) => {
                    console.log(err);
                    console.log(err.response);
                    if("password" in err.response.data.errors){
                        password_error.value = err.response.data.errors.password[0];
                    }
                    store.commit(MutationTypes.addSystemNotification, {title:'Error',content:'Error updating partner password settings.',icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const showEditModal = async(partner:any) => {
                edit_partner.value = partner;
                enable_password.value = partner.enable_password === 1;
                password.value = partner.password;
                rotate_password.value = partner.rotate_password === 1;
                rotation_end_date.value = partner.rotation_end_date ? new Date(partner.rotation_end_date) : null;
                rotation_frequency.value = partner.rotation_frequency;
                password_error.value = "";
                show_edit_modal.value = true;
            }

            return {
                clearDate,
                closeEditModal,
                edit_partner,
                enable_password,
                generatePassword,
                getPartnerPasswords,
                loading,
                moment,
                partners,
                password,
                password_error,
                rotate_password,
                rotation_end_date,
                rotation_frequency,
                savePartner,
                show_edit_modal,
                showEditModal,
            };
        },
    });
</script>

<style lang="scss">

</style>