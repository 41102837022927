<template>
    <div class="alert alert-danger d-none" role="alert" :id="elm_id"></div>
</template>
<script>
    import { defineComponent } from "vue";
    export default defineComponent({
        props:{
            elm_id: {type:String,required:true}
        },
        setup(){
            return [

            ]
        }
    });

</script>
