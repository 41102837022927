<template>
    <div class="container mt-4">
        <div class="card">
            <ul class="list-group">
                <li v-for="(item,index) in playlists" v-bind:key="index" class="list-group-item">
                    <router-link :to="{name:'playlists.item',params:{id:item.data.id}}">{{item.data.name}}</router-link>
                </li>
            </ul>
        </div>
    </div>
    <Loading :active="loading" :is-full-page="true"></Loading>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref} from "vue";
    import Loading from "vue3-loading-overlay";
    import Playlist from "@/models/playlist";
    import {IPlaylist} from "@/interfaces/playlist";
    export default defineComponent( {
        name: "playlists",
        components: {Loading},
        setup(){
            const loading = ref(false);
            const playlists = ref<Array<Playlist>>([]);

            onMounted(()=>{
                loading.value = true;
                Playlist.list().then((res)=>{
                   playlists.value = res.data.map((i:IPlaylist)=>new Playlist(i));
                });
                loading.value = false;
            })
            return{
                playlists,
            }
        }
    });
</script>

<style scoped>

</style>