<template>
    <div class="sidenav-item" :class="{'active': !group ? $route.name === to.name : false, 'open': open}">
        <a href="javascript:void(0)" v-if="group" @click="toggle"  class="sidenav-link sidenav-toggle">
            <i v-if="icon" :class="icon" class="mr-2"></i>
            <div>{{ label }}</div>
        </a>
        <router-link v-else :to="to"  class="sidenav-link">
            <i v-if="icon" :class="icon" class="mr-2"></i>
            <div>{{ label }}</div>
        </router-link>

        <div v-if="group" class="sidenav-menu">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
    import {ref} from "vue";

    export default {
        name: "sidenav-item",
        props: {
            label:{type:String, required: true},
            to:{type:Object},
            icon:{type:String},
            group:{type:Boolean, default:false},
        },
        setup(props:any){
            const open = ref(false);
            const toggle = ()=> {open .value= props.group ? !open.value : false};
            return {
                open,
                toggle,
            }
        }
    }
</script>

<style scoped>
    a:hover{
        text-decoration: none;
    }
</style>