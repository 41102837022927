<template>
    <div v-if="!loading && typeof(scenario) !== 'undefined'">
        <div class="row" id="scenario-page">
            <div class="col-12 card">
                <div class="row mx-2 my-5">
                    <div class="col-sm-12 col-md-8">
                        <scenario-card :scenario="scenario.data" :formatted_field_data="formatted_field_data" />
                    </div>
                    <div class="col-sm-12 col-md-4">
                        <div class="row">
                            <div class="col-12">
                                <h2 class="text-center">How To Schedule</h2>
                            </div>
                        </div>
                        <div v-if="!show_schedule && !show_playlist && !show_sms_enable" class="mx-3 text-center">
                            <div class="row mb-3">
                                <div class="col-12">
                                    <button class="btn btn-secondary btn-lg w-100" @click="showSchedule"><span v-if="show_payment">Change Dates</span><span v-else>Schedule Your Visit</span></button>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-12">
                                    <button class="btn btn-secondary btn-lg w-100" @click="showPlaylist">Add to Playlist</button>
                                </div>
                            </div> -->
                        </div>
                        <div v-if="show_schedule">
                            <div class="row">
                                <div class="col-12">
                                    <h4 class="text-center">Select convenient <u>times</u><br/>for your SP visit</h4>
                                </div>
                            </div>
                            <visit-schedule-form field="visit_date_one" alt-field="visit_date_two" :start-date="start_date" :end-date="end_date" :disabled-dates="partner_disabled_dates" v-on:update-date="setDate" :scenario-id="scenario_id"></visit-schedule-form>
                            <div v-if="scenario.data.fee > 0" class="row">
                                <div class="col-12">
                                    <p class="mb-0 text-muted small">Price: <strong>${{scenario.data.fee.toFixed(2)}}</strong></p>
                                    <p class="mb-0 text-muted small">Processing Fee: <strong>${{processing_fee.toFixed(2)}}</strong></p>
                                    <p class="mb-0 text-muted small">Total: <strong>${{total_fee.toFixed(2)}}</strong></p>
                                    <p v-if="has_credit" class="mb-0 text-muted small"><strong>Account credit of ${{payment_credit.amount.toFixed(2)}} will be applied.</strong></p>
                                    <p v-else class="mb-0 text-muted small"><strong>Payment will be collected after date/time selection.</strong></p>
                                    <p class="text-muted small"><strong>SPs will accept your session request within 24-48 hours. Once your session is accepted, you will not be able to reschedule it. Any cancellation and rescheduling will result in another charge.</strong></p>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <button v-if="scenario.data.fee > 0 && !has_credit" class="btn btn-secondary btn-lg w-100" @click="scheduleForPayment">Schedule Your Visit</button>
                                    <button v-else class="btn btn-secondary btn-lg w-100" @click="scheduleVisit">Schedule Your Visit</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label><input type="checkbox" v-model="terms_agreement" name="terms_agreement" checked="checked">&nbsp;Accept Terms and Conditions</label>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <terms v-if="show_terms" />
                                    <button v-else class="btn btn-link text-primary p-0" @click="viewTerms">View Terms and Conditions</button>
                                </div>
                            </div>
                        </div>
                        <div v-if="show_payment">
                            <div class="row">
                                <div class="col-12">
                                    <h2>Credit Card Payment</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <p class="mb-0 text-muted small">Price: <strong>${{scenario.data.fee.toFixed(2)}}</strong></p>
                                    <p class="mb-0 text-muted small">Processing Fee: <strong>${{processing_fee.toFixed(2)}}</strong></p>
                                    <p class="mb-0 text-muted small">Total: <strong>${{total_fee.toFixed(2)}}</strong></p>
                                    <p class="mb-0 text-muted small"><strong>SPs will accept your session request within 24-48 hours. Once your session is accepted, you will not be able to reschedule it. Any cancellation and rescheduling will result in another charge.</strong></p>
                                </div>
                            </div>
                            <div v-if="stripe_loaded">
                                <div class="row">
                                    <div class="col-12">
                                        <label for="name_on_card">Name as it appears on card</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <input class="mb-4" type="text" maxlength="100" name="name_on_card" v-model="name_on_card">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <StripeElements v-if="stripe_loaded" type="payment" v-slot="{ elements, instance }" ref="elms" :stripe-key="stripe_key" :instance-options="instance_options" :elements-options="elements_options">
                                            <StripeElement ref="card" :elements="elements" :options="card_options" :instance="instance" />
                                        </StripeElements>
                                    </div>
                                </div>
                                <div class="row mt-5" v-if="has_card_error">
                                    <div class="col-12">
                                        <div class="alert alert-danger">{{card_error}}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <button v-if="stripe_loaded" class="btn btn-primary mt-5" @click="pay">Submit Payment</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="show_sms_enable">
                            <p>Standardized Patient Visits require SMS notifications and confirmations. Please opt-in to SMS notifications below.</p>
                            <label>Mobile Number</label>
                            <vue-tel-input v-model="mobile_number" :value="mobile_number" mode="international" :preferred-countries="['US','CA','MX']" @validate="setMobile"></vue-tel-input>
                            <p v-if="!mobile_valid" class="alert alert-danger">Invalid Number</p>
                            <br v-else />
                            <label><input type="checkbox" v-model="mobile_agreed" /> Enable SMS Standardized Patient Visit Confirmations and Reminders</label>
                            <p><small >You will receive visit confirmation messages and reminders via SMS when signing up for SP-ED. Message and data rates may apply. Message Frequency Varies. Reply HELP for help. Reply STOP to opt out. <a :href="privacy_link" target="_blank">Privacy Policy</a> | <a :href="sms_tos_link" target="_blank">SMS Terms of Service</a></small></p>
                            <p><small>Note: SP Visits require confirmation and opting out of SMS communications may hinder receipt of confirmation messages</small></p>
                            <p><small>A valid mobile number will be required when checking this option. You will receive a verification code upon submission of this form.</small></p>
                            <div class="my-4 float-right">
                                <button class="btn btn-primary mr-4" @click="cancelSmsOptIn">Cancel</button>
                                <button :disabled="!mobile_valid || !mobile_agreed" class="btn btn-primary" @click="submitSmsOptIn">Opt-in to SMS</button>
                            </div>
                        </div>
                        <!-- <div v-if="show_playlist">
                            <hr>
                            <p>Playlist form</p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="!loading && typeof(scenario) === 'undefined'">
        <h4>Error Loading Scenario</h4>
        <p>{{loading_error}}</p>
    </div>
    <Loading :active="loading" :is-full-page="true"></Loading>
    <loading :active="card_loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { api, store } from "@/store";
    import { defineComponent, reactive, ref, onBeforeMount } from "vue";
    import {IPaymentCredit} from "@/interfaces/payment-credit";
    import {IScenario} from "@/interfaces/scenario";
    import {IScenarioFieldGroup} from "@/interfaces/scenario-field-group";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import { NotificationColor, NotificationIcon } from "@/interfaces/notification";
    import { loadStripe } from '@stripe/stripe-js';
    import { StripeElements, StripeElement } from 'vue-stripe-js';
    import Request from "@/models/request";
    import {RequestType} from "@/enums/request-type";
    import Form from "@/utilities/form";
    import {FormConfig} from "@/interfaces/form-config";
    import Loading from 'vue3-loading-overlay';
    import moment from 'moment';
    import router from "@/router";
    import Scenario from "@/models/scenario";
    import ScenarioCard from "@/components/scenario-card.vue";
    import Terms from '@/components/terms.vue';
    import User from '@/models/user';
    import VisitScheduleForm from '@/components/visit-schedule-form.vue';
    import { VueTelInput } from 'vue3-tel-input';
    import 'vue3-tel-input/dist/vue3-tel-input.css';
    export default defineComponent({
        components: {
            Loading,
            ScenarioCard,
            StripeElements,
            StripeElement,
            Terms,
            VisitScheduleForm,
            VueTelInput
        },
        methods: {
            setDate(date:any):void {
                this.scheduleForm[date.field].value = date.date;
                this.scheduleForm[date.field].valid = true;
                this.scheduleForm[date.field].validationMessage = '';
            }
        },
        mounted(){
            this.getScenario();
            this.setStartDate();
            this.showScheduler();
        },
        setup(){
            const fields = ref<Array<IScenarioFieldGroup>>([]);
            const formatted_field_data = ref<Array<any>>([]);
            const loading = ref(false);
            const loading_error = ref('');
            const mobile_valid = ref<Boolean>(false);
            const mobile_agreed = ref<Boolean>(false);
            const scenario = ref<Scenario | undefined>();
            const scenario_id = ref<number | undefined>(router.currentRoute.value.params.id ? parseInt(router.currentRoute.value.params.id as string) : undefined);
            const show_playlist = ref<Boolean>(false);
            const show_schedule = ref<Boolean>(false);
            const show_sms_enable = ref<Boolean>(false);
            const terms_agreement = ref<Boolean>(true);
            const show_terms = ref<Boolean>(false);
            const start_date = ref<Date>(new Date());
            const end_date = ref<any>(null);
            const scheduleForm:FormConfig = reactive({
                visit_date_one:{
                    value:'',
                    valid:true,
                    validationMessage:''
                },
                visit_date_two:{
                    value:'',
                    valid:true,
                    validationMessage:''
                },
                request_type: {
                    value: RequestType.VISIT,
                    valid:true,
                    validationMessage:'',
                },
                terms_agreement: {
                    value: terms_agreement.value,
                    valid: true,
                    validationMessage:''
                },
                partner_id: {
                    value: store.state.auth.partner?.id,
                    valid: true,
                    validationMessage:''
                },
                scenario_id: {
                    value: scenario_id.value,
                    valid: true,
                    validationMessage:''
                },
            });
            const scheduleFormHelper = new Form(scheduleForm);
            const mobile_number = ref<any>(null);
            const privacy_link = ref<String>(process.env.VUE_APP_API_PROTOCAL + store.getters.partner.slug + '.' + process.env.VUE_APP_API_BASE + 'privacypolicy');
            const sms_tos_link = ref<String>(process.env.VUE_APP_API_PROTOCAL + store.getters.partner.slug + '.' + process.env.VUE_APP_API_BASE + 'smstermsofservice');
            const partner_start_date = ref<any>(null);
            const partner_end_date = ref<any>(null);
            const partner_disabled_dates = ref<Array<Date>>([]);
            const processing_fee = ref<any>(0);
            const total_fee = ref<any>(0);
            const show_payment = ref<Boolean>(false);
            const payment_credit = ref<IPaymentCredit>();
            const has_credit = ref<Boolean>(false);

            const token = ref<String>("");
            const stripe_key = ref(process.env.VUE_APP_STRIPE_KEY);
            const stripe_loaded = ref(false);
            const instance_options = ref({});
            const elements_options = ref({});
            const card_options = ref({value:{}});
            const has_card_error = ref<Boolean>(false);
            const card_error = ref<String>("");
            const card = ref();
            const elms = ref();

            const name_on_card = ref<String>("");
            const card_loading = ref<Boolean>(false);

            onBeforeMount(() => {
                const stripe_promise = loadStripe(stripe_key.value);
                stripe_promise.then(() => {
                    stripe_loaded.value = true;
                },(err)=>{
                    console.log(err);
                });
            });

            const getScenario = async() =>{
                loading.value = true;
                if(store.getters.user.props.enable_sms_notifications && (store.getters.user.props.mobile_verified_at === null || moment().diff(store.getters.user.props.mobile_verified_at,'days') > 90)){
                    generateCode();
                    router.push({
                        name:'verification',
                        query: { sid: scenario_id.value }
                    });
                }else{
                    show_terms.value = store.getters.user.props.terms_agreement == null;
                    mobile_number.value = store.getters.user.props.mobile_number;
                    await Scenario.getFieldsByType(scenario_id.value,2).then((res:any)=>{
                        fields.value = res.data.map((i:any)=>i as IScenarioFieldGroup);
                    });
                    await Scenario.getForOverview(scenario_id.value).then((res:any)=>{
                        let data:IScenario = res.data.scenario;
                        scenario.value = new Scenario(data);
                        if(res.data.scheduled.type == 1){
                            router.push({ path: '/visit/' + res.data.scheduled.id });
                        }
                        if(res.data.scheduled.type == 2){
                            router.push({ path: '/request/' + res.data.scheduled.id });
                        }
                        if(res.data.partner_dates != null){
                            partner_start_date.value = moment(res.data.partner_dates.start_date);
                            partner_end_date.value = moment(res.data.partner_dates.end_date);
                            setStartDate();
                        }
                        if(res.data.partner_disabled_dates != null){
                            let first_date = moment(res.data.partner_disabled_dates.start_date);
                            let last_date = moment(res.data.partner_disabled_dates.end_date);
                            var add_dates = first_date.toString() != last_date.toString();
                            partner_disabled_dates.value.push(first_date.toDate());
                            var new_date = first_date;
                            while(add_dates){
                                new_date = new_date.add(1,'d');
                                partner_disabled_dates.value.push(new_date.toDate());
                                if(new_date.isSame(last_date,'day')){
                                    add_dates = false;
                                }
                            }
                        }
                        if(res.data.payment_credit != null){
                            payment_credit.value = res.data.payment_credit;
                        }
                        setProcessingFee();
                    },(err)=>{
                        loading_error.value = err.response.data.message;
                    });
                    mapFields();
                }
                loading.value = false;
            }

            const mapFields = () => {
                fields.value.forEach(function(group){
                    let hasData = false;
                    let formatted_fields:any = [];
                    group.fields.forEach(function(field){
                        let found_field = scenario.value?.data.fields.find(elm => elm.scenario_field_id === field.id);
                        if(found_field){
                            hasData = true;
                            if(field.label == 'Learner Scenario') field.label = 'Scenario';
                            formatted_fields.push({
                                'title':field.label,
                                'value':found_field.value
                            });
                        }
                    });
                    if(hasData){
                        let array:any = [];
                        if(group.fields.length > 1){
                            array['title'] = group.label;
                        }else{
                            array['title'] = group.fields[0].label;
                        }
                        array['fields'] = formatted_fields;
                        formatted_field_data.value.push(array);
                    }
                });
            }

            const setProcessingFee = () => {
                if(scenario.value != null && scenario.value.data.fee > 0) {
                    let fee_str = ((scenario.value.data.fee + 0.30)/0.971) - scenario.value.data.fee;
                    processing_fee.value = parseFloat((fee_str).toFixed(2));
                    total_fee.value = processing_fee.value + scenario.value.data.fee;
                    if(payment_credit.value != undefined && (payment_credit.value.amount == total_fee.value)){
                        has_credit.value = true;
                    }
                }else{
                    processing_fee.value = 0;
                    total_fee.value = 0;
                    has_credit.value = false;
                }
            }

            const setStartDate = () => {
                if(partner_start_date.value != null && partner_end_date.value != null){
                    if(partner_start_date.value.isAfter(moment().add(2,'days'))){
                        start_date.value.setMonth(partner_start_date.value.toDate().getMonth());
                        start_date.value.setDate(partner_start_date.value.toDate().getDate());
                    }else{
                        start_date.value = new Date();
                        start_date.value.setMonth(start_date.value.getMonth());
                        start_date.value.setDate(start_date.value.getDate() + 2);
                    }
                    end_date.value = new Date();
                    end_date.value.setYear(partner_end_date.value.toDate().getFullYear());
                    end_date.value.setMonth(partner_end_date.value.toDate().getMonth());
                    end_date.value.setDate(partner_end_date.value.toDate().getDate());
                }else{
                    start_date.value.setDate(start_date.value.getDate() + 2);
                    end_date.value = null;
                }
            }

            // const showPlaylist = () => {
            //     if(store.getters.user.props.mobile_verified_at === null || moment().diff(store.getters.user.props.mobile_verified_at,'days') > 90){
            //         generateCode();
            //         router.push({
            //             name:'verification',
            //             query: { sid: scenario_id.value }
            //         });
            //     }else{
            //         show_playlist.value = true;
            //     }
            // }

            const showSchedule = () => {
                if(store.getters.user.props.enable_sms_notifications && (store.getters.user.props.mobile_verified_at === null || moment().diff(store.getters.user.props.mobile_verified_at,'days') > 90)){
                    generateCode();
                    router.push({
                        name:'verification',
                        query: { sid: scenario_id.value }
                    });
                }else if(!store.getters.user.props.enable_sms_notifications){
                    show_sms_enable.value = true;
                    show_schedule.value = false;
                }else{
                    show_schedule.value = true;
                    show_sms_enable.value = false;
                }
            }

            const showScheduler = () => {
                if(!store.getters.user.props.enable_sms_notifications){
                    show_sms_enable.value = true;
                    show_schedule.value = false;
                }else if(store.getters.user.props.mobile_verified_at !== null && moment().diff(store.getters.user.props.mobile_verified_at,'days') <= 90){
                    show_schedule.value = true;
                }
            }

            const generateCode = async() => {
                loading.value = true;
                await api.get('sanctum/csrf-cookie');
                api.post('api/mobile/code').then(()=>{
                    loading.value = false;
                },(err)=>{
                    loading.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Failure.',content:err.response.data.message,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
            }

            const errorHandler = (err:any) => {
                scheduleFormHelper.validation(err);
                let err_body = '';
                if(typeof(err.response.data.errors) == 'undefined'){
                    err_body = err.response.data.message;
                }else{
                    err_body = '<ul>';
                    for(const [key,value] of Object.entries(err.response.data.errors)){
                        key;
                        err_body += '<li>' + value + '</li>';
                    }
                    err_body += '</ul>';
                }
                store.commit(MutationTypes.addSystemNotification, {title:err.response.data.message,content:err_body,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
            }

            const scheduleForPayment = async() => {
                loading.value = true;
                scheduleForm.terms_agreement.value = terms_agreement.value;
                await Request.checkForPayment(scheduleFormHelper.mapValues()).then((res)=>{
                    res;
                    show_payment.value = true;
                    show_schedule.value = false;
                },(err:any)=>{
                    errorHandler(err);
                });
                loading.value = false;
            }

            const scheduleVisit = async() => {
                loading.value = true;
                scheduleForm.terms_agreement.value = terms_agreement.value;
                let post_data = scheduleFormHelper.mapValues();
                post_data['has_credit'] = has_credit.value;
                await Request.create(post_data).then((res)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Visit request has been sent!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                    store.getters.user.props.terms_agreement = moment().format();
                    router.push({ path: '/request/' + res.data.id });
                },(err:any)=>{
                    errorHandler(err);
                });
                loading.value = false;
            }

            const submitSmsOptIn = async() => {
                let post_data = {
                    enable_sms_notifications: mobile_agreed.value,
                    mobile_number: mobile_number.value,
                };
                User.smsOptIn(post_data).then((res)=>{
                    store.state.auth.user?.setField('mobile_number',res.data.user.mobile_number);
                    store.state.auth.user?.setField('enable_sms_notifications',res.data.user.enable_sms_notifications);
                    store.state.auth.user?.setField('mobile_verified_at',null);
                    generateCode();
                    router.push({
                        name:'verification',
                        query: { sid: scenario_id.value }
                    });
                },(err:any)=>{
                    let err_body = '';
                    if(typeof(err.response.data.errors) == 'undefined'){
                        err_body = err.response.data.message;
                    }else{
                        err_body = '<ul>';
                        for (const [key,value] of Object.entries(err.response.data.errors)){
                            key;
                            err_body += '<li>' + value + '</li>';
                        }
                        err_body += '</ul>'
                    }
                    store.commit(MutationTypes.addSystemNotification, {title:err.response.data.message,content:err_body,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                })
            }

            const setMobile = (phoneObject:any) => {
                if(typeof(phoneObject.valid) == 'undefined' || !phoneObject.valid){
                    mobile_number.value = phoneObject.number;
                    mobile_valid.value = false;
                }else{
                    mobile_number.value = phoneObject.number;
                    mobile_valid.value = true;
                }
            }

            const viewTerms = async() => {
                window.open('/#/terms-of-service','_blank')?.focus();
            }

            const cancelSmsOptIn = async() => {
                show_schedule.value = false;
                show_sms_enable.value = false;
            }

            const pay = async() => {
                card_loading.value = true;
                const card_element = card.value.stripeElement;
                has_card_error.value = false;
                card_error.value = "";
                if(name_on_card.value == ""){
                    card_error.value = "Please provide the name as it appears on  the card";
                    has_card_error.value = true;
                    card_loading.value = false;
                    return false;
                }

                elms.value.instance.createToken(card_element).then((res:any) => {
                    if(typeof(res.error) != 'undefined'){
                        has_card_error.value = true;
                        card_error.value = res.error.message;
                        card_loading.value = false;
                    }else if(typeof(res.token) != 'undefined'){
                        token.value = res.token.id;
                        var post_data = scheduleFormHelper.mapValues();
                        post_data['stripe_token'] = token.value;
                        post_data['name_on_card'] = name_on_card.value;
                        Request.submitVisitRequestPayment(post_data).then((res) => {
                            store.commit(MutationTypes.addSystemNotification, {title:'Payment Submitted.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                            card_loading.value = false;
                            store.getters.user.props.terms_agreement = moment().format();
                            router.push({ path: '/request/' + res.data.id });
                        },(err) => {
                            console.log(err);
                            store.commit(MutationTypes.addSystemNotification, {title:'Error submitting payment.',content:err.response.data.error,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                            card_loading.value = false;
                        });
                    }
                },(err: object) => {
                    console.log(err);
                    store.commit(MutationTypes.addSystemNotification, {title:'Error submitting payment.',content:'Please contact support@simclusive.com for assistance',icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    card_loading.value = false;
                });
            }

            return {
                cancelSmsOptIn,
                card,
                card_error,
                card_loading,
                card_options,
                elements_options,
                elms,
                end_date,
                fields,
                formatted_field_data,
                getScenario,
                has_card_error,
                has_credit,
                instance_options,
                loading,
                loading_error,
                mobile_agreed,
                mobile_number,
                mobile_valid,
                name_on_card,
                partner_disabled_dates,
                pay,
                payment_credit,
                privacy_link,
                processing_fee,
                scenario,
                scenario_id,
                scheduleForm,
                scheduleForPayment,
                scheduleVisit,
                setMobile,
                setStartDate,
                show_playlist,
                show_schedule,
                show_sms_enable,
                // showPlaylist,
                showSchedule,
                showScheduler,
                show_payment,
                show_terms,
                sms_tos_link,
                start_date,
                stripe_key,
                stripe_loaded,
                submitSmsOptIn,
                terms_agreement,
                total_fee,
                viewTerms
            }
        }
    })
</script>
