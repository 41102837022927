import {api} from "@/store";
import {IPlaylist} from "@/interfaces/playlist";

export default class Playlist{
    constructor(public data?:IPlaylist) {}

    static async list():Promise<any>{
        return await api.get('api/playlists');
    }
    static async myPlaylist():Promise<any>{
        return await api.get('api/my-playlists');
    }

    static async get(id:Number):Promise<any>{
        return await api.get('api/playlists/'+id);
    }
    static async copy(id:Number):Promise<any>{
        return await api.post('api/playlists/'+id+'/copy',{});
    }
    static async create(data:any):Promise<any> {
        return await api.post('api/playlists',data);
    }

    static async update(id:Number|undefined,data:any):Promise<any> {
        return await api.put('api/playlists/'+id,data);
    }

    static async delete(id:Number|undefined):Promise<any>{
        return  await api.delete('api/playlist/'+id);
    }

    static async removeItem(id:number,scenario_id:number):Promise<any>{
        return await api.delete(`api/playlists/${id}/scenario/${scenario_id}`);
    }
    static async reorder(id:Number|undefined,form:any):Promise<any>{
        return  api.post('api/playlists/'+id+'/reorder',form);
    }
}