<template>
    <div class="card shadow-sm mb-4">
        <div class="card-header text-white bg-secondary font-weight-bold">Canceled Training Sessions</div>
        <div class="card-content p-2">
            <div v-if="!loading">
                <span v-if="Object.keys(props.canceled_training_sessions).length">
                    <div v-for="training_session in props.canceled_training_sessions" v-bind:key="training_session.id" class="col-12" @click="router.push({name:'training',params:{training_session_id:training_session.id}})">
                        <div class="row visit-card">
                            <div class="col-10">
                                <div class="rounded-circle float-left mr-2 icon-circle"><i class="fas fa-chalkboard-teacher mx-auto"></i></div>
                                <div class="border-right time-slot float-left pt-3 mr-2">
                                    <span class="text-secondary font-weight-bold">{{ moment(training_session.local_time).format('MM/DD/YY') }}</span>
                                    <hr class="my-1">
                                    <span class="text-primary font-weight-bold">{{ moment(training_session.local_time).format('h:mm') }}<sup>{{ moment(training_session.local_time).format('a') }}</sup></span>
                                </div>
                                <div class="overflow-hidden mobile-visit-card">
                                    <div class="font-weight-bold text-secondary lh-normal mb-1">
                                        <span v-if="training_session.training_session_type_id == TrainingSessionType.ONBOARDING">On-Boarding</span>
                                        <span v-else-if="training_session.scenario !== null">{{training_session.scenario.title}}</span>
                                        <span v-else>Scenario</span>
                                    </div>
                                    <div class="mb-2"><strong>Trainer</strong></div>
                                    <div>{{training_session.trainer.first_name}} {{training_session.trainer.last_name}}<br><small>{{training_session.trainer.email}}</small></div><br>
                                    <div v-if="is_trainer">
                                        <div class="mb-2"><strong>Participants</strong></div>
                                        <div v-for="sp_user in training_session.sp_users" v-bind:key="sp_user.id" class="mb-3">
                                            <div>{{sp_user.user.first_name}} {{sp_user.user.last_name}}</div>
                                            <div><small>{{sp_user.user.email}}</small></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 d-flex flex-column justify-content-center">
                                <h3 class="text-center"><i class='far fa-window-close text-primary'></i></h3>
                            </div>
                        </div>
                        <hr class="w-75" />
                    </div>
                    <lazy-pagination v-if="props.canceled_training_sessions_total > 10" :items="props.canceled_training_sessions" :total="props.canceled_training_sessions_total" :page_start="page_start" :page_end="page_end" v-on:next-page="nextPage" v-on:previous-page="previousPage" :label="'Training Sessions'" />
                </span>
                <p v-else class="text-center pt-3">No Canceled Training Sessions</p>
            </div>
            <div class="loading-card-area" v-else>
                <loading :active="loading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import {TrainingSessionType} from '@/enums/training-session-type';
    import Loading from 'vue3-loading-overlay';
    import moment from 'moment';
    import LazyPagination from '@/components/dashboard/lazy-pagination.vue';
    import router from "@/router";

    export default defineComponent({
        emits: ['change-page'],
        components: {
            Loading,
            LazyPagination
        },
        props: {
            canceled_training_sessions: {type:Object, required:true},
            canceled_training_sessions_total: {type:Number, required:true},
            loading: {type:Boolean, required:true},
            is_trainer: {type:Boolean, default:false}
        },
        setup(props, {emit}) {
            const page_start = ref(0);
            const page_end = ref(10);
            const nextPage = async() => {
                page_start.value = page_start.value + 10;
                page_end.value = page_end.value + 10;
                if(page_end.value > props.canceled_training_sessions_total){
                    page_end.value = props.canceled_training_sessions_total;
                }
                emit('change-page',{type:'canceled_training_sessions','page_start':page_start.value});
            };
            const previousPage = async() => {
                page_start.value = page_start.value - 10;
                page_end.value = page_start.value + 10;
                emit('change-page',{type:'canceled_training_sessions','page_start':page_start.value});
            };
            return {
                moment,
                nextPage,
                page_end,
                page_start,
                previousPage,
                props,
                router,
                TrainingSessionType
            };
        }
    });
</script>
<style scoped lang="scss">
    @import '@/assets/_variables.scss';
    .icon-circle {
        width: 75px;
        height: 75px;
        background-color: $gray-light;
        display: flex;
        flex-direction: column;
        justify-content: center;
        i{
            font-size: 4rem;
            color: $gray-medium;
        }
    }
</style>
