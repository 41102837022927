<template>
    <img :src="!source ? '/img/no_image.jpg' : source" class="mr-3 rounded-circle" :class="size" alt="User Icon">
</template>

<script lang="ts">
    export default {
        name: "user-image",
        props:{
            size:{type:String,default:'ui-w-40'},
            source: {type: String, default: null},
        }
    }
</script>

<style scoped>

</style>
