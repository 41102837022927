<template>
	<div class="row card text-white rounded-0 top-header">
			<div class="col-12 text-center">
				<h2 class="my-3 font-weight-bold">Quote Requests</h2>
			</div>
		</div>
		<div class="row card rounded-0">
			<div class="col-12 card-header">
				<div class="row">
					<div class="col-sm-12 col-md-6">
						<button class="btn btn-sm btn-primary mr-5 reset-btn" @click="resetQuotes()">Reset Filters</button>
					</div>
					<div class="col-sm-12 col-md-6">
						<div class="input-icons">
							<i class="fas fa-search" @click="getQuotes()"></i>
							<input type="text" placeholder="search by email" class="float-right" v-model="email_string" v-on:keyup.enter="getQuotes()">
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 card-body">
				<div v-if="instance_quotes.length">
					<div class="row">
						<div v-for="quote in instance_quotes.slice(page_start,page_end)" v-bind:key="quote.id" class="col-6 user-mgmt-card mb-2 pt-2" @click="router.push({name:'management.quote',params:{request_id:quote.id}})">
							<div class="row">
								<div class="col-10">
									<h4>{{ quote.user.name }}</h4>
									<p>{{quote.user.email}}</p>
									<small>Date Submitted: {{moment(quote.created_at).format('MM-DD-YYYY')}}</small>
								</div>
								<div class="col-2 d-flex flex-column justify-content-center">
									<h3 class="text-center"><i class='fas fa-arrow-circle-right text-primary float-right'></i></h3>
								</div>
							</div>
							<hr />
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<pagination v-if="instance_quotes.length > 20" :items="instance_quotes" :page_start="page_start" :page_end="page_end" v-on:next-page="nextPage" v-on:previous-page="previousPage" :label="'Quotes'" />
						</div>
					</div>
				</div>
				<div v-else class="row">
					<div class="col-12">
						No quotes found
					</div>
				</div>
			</div>
		</div>
</template>
<script lang="ts">
	import {defineComponent, ref} from "vue";
	import Pagination from "@/components/dashboard/pagination.vue";
	import Request from "@/models/request";
	import moment from "moment";
	import router from "@/router";

	export default defineComponent({
		props: {
			quotes: {
				required: true,
				type: Object
			},
			type: {
				required: true,
				type: String
			}
		},
		watch: {
			quotes: function(newData){
				this.instance_quotes = newData.quotes;
			}
		},
		components: {
			Pagination,
		},
		setup(props){
			const email_string = ref<String>("");
			const page_end = ref(20);
			const page_start = ref(0);
			const instance_quotes = ref(props.quotes);

			const getQuotes = async() => {
				var post_data = {
					type: props.type,
					email_string: email_string.value
				};
				await Request.getQuotes(post_data).then((res)=>{
					instance_quotes.value = res.data.quotes;
					page_start.value = 0;
					page_end.value = 20;
				},(err)=>{
					console.log(err);
				})
			}

			const nextPage = () => {
				page_start.value = page_start.value + 20;
				page_end.value = page_end.value + 20;
				if(page_end.value > Object.keys(instance_quotes.value).length){
					page_end.value = Object.keys(instance_quotes.value).length;
				}
			}

			const previousPage = () => {
				page_start.value = page_start.value - 20;
				page_end.value = page_start.value + 20;
			}

			const resetQuotes = async() => {
				email_string.value = "";
				getQuotes();
			}

			return {
				email_string,
				getQuotes,
				instance_quotes,
				moment,
				nextPage,
				page_end,
				page_start,
				previousPage,
				resetQuotes,
				router
			}
		}

	});
</script>
<style lang="scss" scoped>
	.input-icons{
        margin-bottom: 10px;
        i {
            position: absolute;
            padding: 7px;
            min-width: 40px;
            right: 5px;
        }
        input {
            width: 300px;
            max-width: 100%;
            padding-right: 28px;
            border: 0px;
            outline: none;
        }
    }
    select{
        background: transparent;
        border: none;
    }
    .reset-btn {
        min-height: 2rem !important;
    }
    .user-mgmt-card {
        height: 10rem;
        cursor: pointer;
        &:hover{
            background-color: #F0F0F1;
        }
        div{
            height: 8.5rem;
        }
    }
</style>