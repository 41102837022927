<template>
    <div v-if="!loading && typeof(visit) !== 'undefined'">
        <div class="row" id="visit-page" v-if="!show_feedback_form && !show_participant_feedback && !view_participant_feedback">
            <div class="col-12 card">
                <div class="row mx-2 my-5">
                    <div class="col-sm-12 col-md-4 order-md-12">
                        <h4 class="text-center mx-auto mb-0 w-75">
                            <span v-if="!visit.is_complete && !visit.is_canceled">This visit is scheduled for:</span>
                            <span v-else-if="visit.is_canceled || visit.no_show || visit.learner_technical_issue">This visit was scheduled for:</span>
                            <span v-else-if="visit.is_complete">This visit occurred on:</span>
                        </h4>
                        <h4 class="text-center mb-4"><span class="text-secondary">{{ moment(visit.local_time).format('MM/DD/YY') }}</span> <small>at</small> <span class="text-primary">{{ moment(visit.local_time).format('h:mm') }}<sup>{{ moment(visit.local_time).format('a') }}</sup></span></h4>
                        <div v-if="is_admin || is_client_admin">
                            <p class="mb-0">Participant: {{visit.learner.name}}</p><p><small class="text-muted">{{visit.learner.email}}</small></p>
                            <div v-if="show_sp_data">
                                <p class="mb-0">Standardized Patient: {{visit.standardized_patient.name}}</p><p><small class="text-muted">{{visit.standardized_patient.email}}</small></p>
                            </div>
                            <p v-if="learner_type !== ''">Learner Type: {{learner_type}}</p>
                        </div>
                        <div v-if="is_sp">
                            <p class="mb-0">Participant: {{visit.learner.name}}</p><p class="mb-0"><small class="text-muted" v-if="visit.learner.profession !== null">Profession: {{visit.learner.profession.title}}</small></p><p><small class="text-muted" v-if="visit.learner.specialty">Specialty: {{visit.learner.specialty.title}}</small></p>
                        </div>
                        <div v-if="show_cancel">
                            <p>Are you sure you want to cancel the visit?</p>
                            <p v-if="visit.scenario.fee >0"><strong>Refunds will not be issued for a canceled visit. If you submit another visit request after cancelation, you will be required to pay the full fee again.</strong></p>
                            <button class="btn btn-lg btn-secondary w-100 mb-2" @click="cancel">Cancel Visit</button>
                            <button class="btn btn-lg btn-secondary w-100 mb-2" @click="showCancelConfirm">Return to Visit Overview</button>
                        </div>
                        <div v-else-if="show_reschedule">
                            <p>Select convenient times for your SP visit</p>
                            <visit-schedule-form field="visit_date_one" alt-field="visit_date_two" :start-date="start_date" :end-date="end_date" :disabled-dates="partner_disabled_dates" v-on:update-date="setDate" :scenario-id="visit.scenario_id"></visit-schedule-form>
                            <div class="row">
                                <div class="col-12">
                                    <button class="btn btn-lg btn-secondary w-100 mb-2" @click="showRescheduleModal">Reschedule Visit</button>
                                    <button class="btn btn-lg btn-secondary w-100 mb-2" @click="showReschedule">Return to Visit Overview</button>
                                </div>
                            </div>
                            <modal v-model="reschedule_modal">
                                <template v-slot:header>Reschedule Visit</template>
                                <p>Are you sure you want to reschedule this visit?</p>
                                <button type="button" class="btn btn-lg btn-primary w-100 mb-2" @click="rescheduleVisit">Reschedule Visit</button>
                            </modal>
                        </div>
                        <div v-else-if="show_transfer_form">
                            <p>Select standardized patient to transfer visit to.</p>
                            <div class="mb-4">
                                <select v-model="transferForm.sp_id.value">
                                    <option v-for="sp in sp_users" v-bind:key="sp.id" :value="sp.id">{{sp.first_name}} {{sp.last_name}}</option>
                                </select>
                            </div>
                            <button class="btn btn-secondary btn-md w-100 mb-2" @click="transfer">Transfer Visit</button>
                            <button class="btn btn-secondary btn-md w-100 mb-2" @click="showTransferForm">Cancel</button>
                        </div>
                        <div v-else-if="show_observers">
                            <p>Observers</p>
                            <ul>
                                <li v-for="observer in visit.observer_users" v-bind:key="observer.id"><div>{{observer.user.name}}<br><small>{{observer.user.email}}</small></div> <button class="btn btn-secondary btn-xs mb-4" @click="removeObserver(observer.user.id)">Remove</button></li>
                            </ul>
                            <p>Add Observer</p>
                            <div class="mb-4">
                                <select v-model="observerForm.add_observer_id.value">
                                    <option v-for="observer in observer_users" v-bind:key="observer.id" :value="observer.id">{{observer.first_name}} {{observer.last_name}}</option>
                                </select>
                            </div>
                            <button class="btn btn-secondary btn-md w-100 mb-2" @click="addObserver">Add</button>
                            <button class="btn btn-secondary btn-md w-100 mb-2" @click="showObservers">Return to Visit</button>
                        </div>
                        <div v-else>
                            <p v-if="!is_learner && !visit.is_complete">This visit has <span v-if="visit.is_confirmed">been confirmed</span><span v-if="!visit.is_confirmed" class="text-danger">not been confirmed</span>.</p>
                            <p v-if="is_learner">This visit has been scheduled</p>
                            <div class="mb-3" v-if="is_learner && !visit.is_confirmed && !visit.is_canceled">
                                <p><small><i>You will be required to confirm your participation via SMS text message three days prior to the date of the visit.  PLEASE NOTE: Unconfirmed visits will be canceled 21 hours prior to start time.</i></small></p>
                            </div>
                            <div class="mb-3" v-if="is_learner && !visit.is_confirmed && !visit.is_canceled && can_confirm">
                                <button class="btn btn-secondary btn-md w-100 mb-2" @click="confirm">Confirm Visit</button>
                            </div>
                            <div class="d-inline" v-if="is_sp || is_learner">
                                <join-links class="d-inline" :joinevent="visit" :label="'Visit'" :user="user" v-on:show-feedback-form="showFeedbackForm"></join-links>
                            </div>
                            <div class="d-inline" v-if="is_learner && visit.is_complete == 0 && visit.is_canceled == 0">
                                <button v-if="visit.scenario.fee == 0" class="btn btn-secondary btn-md w-100 mb-2" @click="showReschedule">Reschedule</button>
                                <button class="btn btn-secondary btn-md w-100 mb-2" v-if="visit.learner_technical_issue == 0" @click="showCancelConfirm">Cancel</button>
                            </div>
                            <div class="d-inline" v-if="is_sp && visit.is_complete == 0 && visit.learner_technical_issue == 0 && visit.is_canceled == 0">
                                <button class="btn btn-secondary btn-md w-100 mb-2" @click="shareScenario">Share Learner Information</button>
                            </div>
                            <div class="d-inline" v-if="is_sp && visit.is_complete == 0 && visit.learner_technical_issue == 0 && visit.is_canceled == 0 && visit.scenario.is_feedback_form_enabled">
                                <button class="btn btn-secondary btn-md w-100 mb-2" @click="shareZoomMessage">Share Stay On Zoom Message</button>
                            </div>
                            <div class="d-inline" v-if="is_sp && has_verbal_feedback">
                                <button class="btn btn-secondary btn-md w-100 mb-2" @click="printFeedback">Print Verbal Feedback</button>
                            </div>
                            <div class="d-inline" v-if="can_transfer || can_generate_reschedule || can_edit_observers">
                                <button class="btn btn-secondary btn-md w-100 mb-2" v-if="can_transfer && visit.is_complete == 0 && visit.learner_technical_issue == 0" @click="getTransferUsers">Transfer Visit</button>
                                <button class="btn btn-secondary btn-md w-100 mb-2" v-if="can_generate_reschedule" @click="generateReschedule">Generate Reschedule Link</button>
                                <button class="btn btn-secondary btn-md w-100 mb-2" v-if="can_edit_observers" @click="getObserverUsers">Manage Observers</button>
                            </div>
                            <div v-if="reschedule_link != null" class="my-2">{{ reschedule_link }}</div>
                            <p class="text-danger mt-1"  v-if="(is_sp || is_learner) && visit.is_complete == 0 && visit.learner_technical_issue == 0 && visit.is_canceled == 0"><strong>If join link does not open try disabling browser's popup blocker</strong></p>
                        </div>
                        <div v-if="visit.is_complete && !visit.no_show">
                            <div v-if="is_observer">
                                <button class="btn btn-secondary btn-md w-100 mb-2" @click="scorecard"><span v-if="!is_observer_scorecard_complete">Complete</span><span v-else>View</span> Scorecard</button>
                            </div>
                            <div v-if="is_learner || is_client_admin">
                                <button class="btn btn-secondary btn-md w-100 mb-2" v-if="visit.is_scorecard_complete || visit.is_feedback_form_complete" @click="scorecard">View Scorecard</button>
                                <button class="btn btn-secondary btn-md w-100 mb-2" v-else-if="(visit.scenario.is_scorecard_enabled || visit.scenario.is_feedback_form_enabled) && (!visit.is_scorecard_complete && !visit.is_feedback_form_complete)" disabled>Scorecard Pending</button>
                                <button class="btn btn-secondary btn-md w-100 mb-2" v-if="is_client_admin && visit.scenario.is_participant_feedback_enabled && visit.is_participant_feedback_complete" @click="viewParticipantFeedback">View Participant Feedback</button>
                                <button class="btn btn-secondary btn-md w-100 mb-2" v-else-if="is_client_admin && visit.scenario.is_participant_feedback_enabled && !visit.is_participant_feedback_complete" disabled>Participant Feedback Pending</button>
                                <a v-if="has_recording" class="btn btn-secondary btn-md w-100 mb-2" :href="recording_url" target="_blank">View Recording</a>
                                <button v-else class="btn btn-secondary btn-md w-100 mb-2" @click="recordingError">View Recording</button>
                                <button class="btn btn-secondary btn-md w-100 mb-2" v-if="is_learner && is_participant_feedback_available" @click="showParticipantFeedback">Provide Feedback</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-8 order-sm-1">
                        <scenario-card :scenario="visit.scenario" :formatted_field_data="formatted_field_data" :is_learner="is_learner" :sp="visit.standardized_patient" />
                    </div>
                </div>
            </div>
        </div>
        <feedback-form v-if="show_feedback_form" :visit="visit" v-on:feedback-form-completed="feedbackFormCompleted" v-on:return-to-visit="showFeedbackForm" />
        <participant-feedback v-if="show_participant_feedback" :visit="visit" view="form" v-on:participant-feedback-completed="participantFeedbackCompleted" v-on:return-to-visit="showParticipantFeedback" />
        <participant-feedback v-if="view_participant_feedback" :visit="visit" view="observe" v-on:return-to-visit="viewParticipantFeedback" />
    </div>
    <div v-if="!loading && typeof(visit) === 'undefined'">
        <h4>Error Loading Visit</h4>
        <p>{{ loadErrorMessage }}</p>
    </div>
    <br>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { defineComponent, reactive, ref } from "vue";
    import { store } from '../../store/index';
    import {FormConfig} from "@/interfaces/form-config";
    import Form from "@/utilities/form";
    import Loading from 'vue3-loading-overlay';
    import moment from 'moment';
    import {NotificationIcon, NotificationColor} from "@/interfaces/notification";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import Modal from "@/components/modal.vue";
    import JoinLinks from '@/components/join-links.vue';
    import { useRoute } from 'vue-router';
    import {IScenarioFieldGroup} from "@/interfaces/scenario-field-group";
    import router from "@/router";
    import Scenario from "@/models/scenario";
    import ScenarioCard from "@/components/scenario-card.vue";
    import Visit from "@/models/visit";
    import VisitScheduleForm from "@/components/visit-schedule-form.vue";
    import FeedbackForm from "@/views/FeedbackForm.vue";
    import ParticipantFeedback from "@/views/ParticipantFeedback.vue";
    import {UserRole} from "@/enums/user-role";

    export default defineComponent({
        components: {Modal, JoinLinks, Loading, ScenarioCard, VisitScheduleForm, FeedbackForm, ParticipantFeedback},
        methods: {
            setDate(date:any):void {
                this.rescheduleForm[date.field].value = date.date;
                this.rescheduleForm[date.field].valid = true;
                this.rescheduleForm[date.field].validationMessage = '';
            }
        },
        mounted(){
            this.getVisit();
            this.setStartDate();
        },
        setup() {
            const fields = ref<Array<IScenarioFieldGroup>>([]);
            const formatted_field_data = ref<Array<any>>([]);
            const loading = ref(false);
            const visit = ref();
            const route = useRoute();
            const form:FormConfig = reactive({
                visit_id: {
                    value:route.params.visit_id,
                    valid:true,
                    validationMessage:''
                }
            });
            const formHelper = new Form(form);
            const transferForm:FormConfig = reactive({
                visit_id: {
                    value:route.params.visit_id,
                    valid:true,
                    validationMessage:''
                },
                sp_id: {
                    value:null,
                    valid:false,
                    validationMessage:''
                }
            });
            const transferFormHelper = new Form(transferForm);
            const observerForm:FormConfig = reactive({
                visit_id: {
                    value:route.params.visit_id,
                    valid:true,
                    validationMessage:''
                },
                add_observer_id: {
                    value: null,
                    valid:false,
                    validationMessage:''
                },
                remove_observer_id: {
                    value: null,
                    valid:false,
                    validationMessage:''
                }
            });
            const observerFormHelper = new Form(observerForm);
            const loadErrorMessage = ref('');
            const is_sp = ref<Boolean>(false);
            const is_learner = ref<Boolean>(false);
            const is_observer = ref<Boolean>(false);
            const is_observer_scorecard_complete = ref<Boolean>(false);
            const can_confirm = ref<Boolean>(false);
            const show_cancel = ref<Boolean>(false);
            const showCancelConfirm = ()=> show_cancel.value = !show_cancel.value;
            const reschedule_link = ref<String|null>(null);
            const can_transfer = ref<Boolean>(store.getters.hasPermission('visit.transfer'));
            const can_generate_reschedule = ref<Boolean>(store.getters.hasPermission('visit.generate_reschedule_link'));
            const can_edit_observers = ref<Boolean>(store.getters.user.props.super_admin);
            const show_transfer_form = ref<Boolean>(false);
            const showTransferForm = () => show_transfer_form.value = !show_transfer_form.value;
            const sp_users = ref<Array<any>>([]);
            const show_observers = ref<Boolean>(false);
            const showObservers = () => show_observers.value = !show_observers.value;
            const observer_users = ref<Array<any>>([]);
            const is_admin = ref<Boolean>(can_transfer.value || can_generate_reschedule.value || can_edit_observers.value);
            const is_client_admin = ref<Boolean>(store.getters.hasRole(UserRole.CLIENTADMIN) || store.getters.hasRole(UserRole.ADMIN) || store.getters.user.props.super_admin);
            const show_reschedule = ref<Boolean>(false);
            const showReschedule = () => show_reschedule.value = !show_reschedule.value;
            const reschedule_modal = ref<Boolean>(false);
            const showRescheduleModal = () => reschedule_modal.value = !reschedule_modal.value;
            const start_date = ref<Date>(new Date());
            const end_date = ref<any>(null);
            const partner_start_date = ref<any>(null);
            const partner_end_date = ref<any>(null);
            const has_verbal_feedback = ref<Boolean>(false);
            const show_sp_data = ref<Boolean>(store.getters.hasRole(UserRole.ADMIN) || store.getters.user.props.super_admin);
            const rescheduleForm:FormConfig = reactive({
                visit_id: {
                    value:route.params.visit_id,
                    valid:true,
                    validationMessage:''
                },
                visit_date_one:{
                    value:'',
                    valid:true,
                    validationMessage:''
                },
                visit_date_two:{
                    value:'',
                    valid:true,
                    validationMessage:''
                }
            });
            const rescheduleFormHelper = new Form(rescheduleForm);
            const show_feedback_form = ref<Boolean>(false);
            const showFeedbackForm = () => show_feedback_form.value = !show_feedback_form.value;
            const is_participant_feedback_available = ref<Boolean>(false);
            const show_participant_feedback = ref<Boolean>(false);
            const showParticipantFeedback = () => show_participant_feedback.value = !show_participant_feedback.value;
            const view_participant_feedback = ref<Boolean>(false);
            const viewParticipantFeedback = () => view_participant_feedback.value = !view_participant_feedback.value;
            const has_recording = ref<Boolean>(false);
            const recording_error = ref('');
            const recording_url = ref('');
            const learner_type = ref<String>('');
            const partner_disabled_dates = ref<Array<Date>>([]);

            const addObserver = async() => {
                loading.value = true;
                await Visit.addObserver(observerFormHelper.mapValues()).then((res)=>{
                    visit.value = res.data.visit;
                    store.commit(MutationTypes.addSystemNotification, {title:'Observer added to visit.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err:any)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error adding observer.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const cancel = async() => {
                loading.value = true;
                await Visit.cancel(formHelper.mapValues()).then(()=>{
                    visit.value.is_canceled = true;
                    show_cancel.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Visit has been cancelled.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error cancelling visit.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const confirm = async() => {
                loading.value = true;
                await Visit.confirm(formHelper.mapValues()).then(()=>{
                    visit.value.is_confirmed = true;
                    can_confirm.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Visit has been confirmed.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error confirming visit.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const feedbackFormCompleted = () => {
                visit.value.is_complete = true;
                show_feedback_form.value = false;
            }

            const generateReschedule = async() => {
                loading.value = true;
                await Visit.generateRescheduleLink(formHelper.mapValues()).then((res)=>{
                    reschedule_link.value = res.data.reschedule_link;
                    store.commit(MutationTypes.addSystemNotification, {title:'Reschedule link generated.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error generating reschedule link.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const getObserverUsers = async() => {
                loading.value = true;
                await Visit.getObserverUsers(formHelper.mapValues()).then((res)=>{
                    observer_users.value = res.data.observer_users;
                    show_observers.value = true;
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error retrieving observers.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const getTransferUsers = async() => {
                loading.value = true;
                await Visit.getTransferUsers(formHelper.mapValues()).then((res)=>{
                    sp_users.value = res.data.sp_users;
                    show_transfer_form.value = true;
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error retrieving transfer data.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const getVisit = async () => {
                loading.value = true;
                await Visit.get(formHelper.mapValues()).then((res)=>{
                    visit.value = res.data.visit;
                    learner_type.value = res.data.learner_type;
                    let current_time = moment();
                    let start_time = moment(res.data.visit.local_time);
                    let confirm_time = moment(res.data.visit.local_time).subtract(3,"days");
                    if(current_time.isAfter(confirm_time) && current_time.isBefore(start_time)){
                        can_confirm.value = true;
                    }
                    if(visit.value.scenario.is_participant_feedback_enabled && !visit.value.is_participant_feedback_complete && visit.value.is_complete && !visit.value.is_canceled && !visit.value.no_show && !visit.value.learner_technical_issue){
                        is_participant_feedback_available.value = true;
                    }
                    if(visit.value.sp_user_id == store.state.auth.user?.props.id) is_sp.value = true;
                    if(visit.value.learner_user_id == store.state.auth.user?.props.id) is_learner.value = true;
                    is_observer.value = visit.value.observer_users.filter(function(observer_data:any){return observer_data?.user_id == store.state.auth.user?.props.id;}).length > 0;
                    if(is_observer.value){
                        is_observer_scorecard_complete.value = visit.value.observer_users.filter(function(observer_data:any){return observer_data?.user_id == store.state.auth.user?.props.id;})[0].is_complete;
                    }
                    if(!visit.value.scenario.is_scorecard_enabled){
                        can_edit_observers.value = false;
                    }
                    if(visit.value.is_complete && !visit.value.no_show && !visit.value.learner_technical_issue){
                        can_generate_reschedule.value = false;
                    }
                    if(visit.value.is_complete && !visit.value.no_show){
                        getRecording();
                    }
                    if(res.data.partner_dates != null){
                        partner_start_date.value = moment(res.data.partner_dates.start_date);
                        partner_end_date.value = moment(res.data.partner_dates.end_date);
                        setStartDate();
                    }
                    if(res.data.partner_disabled_dates != null){
                        let first_date = moment(res.data.partner_disabled_dates.start_date);
                        let last_date = moment(res.data.partner_disabled_dates.end_date);
                        var add_dates = first_date.toString() != last_date.toString();
                        partner_disabled_dates.value.push(first_date.toDate());
                        var new_date = first_date;
                        while(add_dates){
                            new_date = new_date.add(1,'d');
                            partner_disabled_dates.value.push(new_date.toDate());
                            if(new_date.isSame(last_date,'day')){
                                add_dates = false;
                            }
                        }
                    }
                },(err)=>{
                    loadErrorMessage.value = err.response.data.message;
                    store.commit(MutationTypes.addSystemNotification, {title:'Error loading visit.',content:err.response.statusText,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                if(visit.value !== 'undefined' && (is_sp.value || is_learner.value || is_observer.value)){
                    let type = 2;
                    if(is_sp.value) type = 1;
                    await Scenario.getFieldsByType(visit.value.scenario_id, type).then((res)=>{
                        fields.value = res.data.map((i:any)=>i as IScenarioFieldGroup);
                        mapFields();
                    },(err)=>{
                        store.commit(MutationTypes.addSystemNotification, {title:'Error loading scenario details.',content:err.response.statusText,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                    });
                    await Scenario.hasVerbalFeedback(visit.value.scenario_id).then((res)=>{
                        has_verbal_feedback.value = res.data.has_verbal_feedback;
                    },(err)=>{
                        store.commit(MutationTypes.addSystemNotification, {title:'Error loading scenario details.',content:err.response.statusText,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                    });
                    rescheduleOnLoad();
                }
                loading.value = false;
            }

            const mapFields = () => {
                fields.value.forEach(function(group){
                    let hasData = false;
                    let formatted_fields:any = [];
                    group.fields.forEach(function(field){
                        let found_field = visit.value.scenario.fields.find(function(elm:any){ return elm.scenario_field_id === field.id});
                        if(found_field){
                            hasData = true;
                            if(field.label == 'Learner Scenario') field.label = 'Scenario';
                            formatted_fields.push({
                                'title':field.label,
                                'value':found_field.value
                            });
                        }
                    });
                    if(hasData){
                        let array:any = [];
                        if(group.fields.length > 1){
                            array['title'] = group.label;
                        }else{
                            array['title'] = group.fields[0].label;
                        }
                        array['fields'] = formatted_fields;
                        formatted_field_data.value.push(array);
                    }
                });
            }

            const participantFeedbackCompleted = () => {
                visit.value.is_participant_feedback_complete = true;
                is_participant_feedback_available.value = false;
                show_participant_feedback.value = false;
            }

            const printFeedback = async() => {
                loading.value = true;
                window.open('/#/scenarios/'+visit.value.scenario_id+'/print-feedback','_blank','width=1400,height=800');
                loading.value = false;
            }

            const removeObserver = async(observer_id:Number) => {
                loading.value = true;
                observerForm.remove_observer_id.value = observer_id;
                await Visit.removeObserver(observerFormHelper.mapValues()).then((res)=>{
                    visit.value = res.data.visit;
                    store.commit(MutationTypes.addSystemNotification, {title:'Observer removed from visit.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error removing observer',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const setStartDate = () => {
                if(partner_start_date.value != null && partner_end_date.value != null){
                    if(partner_start_date.value.isAfter(moment().add(2,'days'))){
                        start_date.value.setMonth(partner_start_date.value.toDate().getMonth());
                        start_date.value.setDate(partner_start_date.value.toDate().getDate());
                    }else{
                        start_date.value = new Date();
                        start_date.value.setMonth(start_date.value.getMonth());
                        start_date.value.setDate(start_date.value.getDate() + 2);
                    }
                    end_date.value = new Date();
                    end_date.value.setYear(partner_end_date.value.toDate().getFullYear());
                    end_date.value.setMonth(partner_end_date.value.toDate().getMonth());
                    end_date.value.setDate(partner_end_date.value.toDate().getDate());
                }else{
                    start_date.value.setDate(start_date.value.getDate() + 2);
                    end_date.value = null;
                }
            }

            const rescheduleVisit = async() => {
                loading.value = true;
                await Visit.reschedule(rescheduleFormHelper.mapValues()).then((res:any)=>{
                    router.push({ path: '/request/' + res.data.id });
                    store.commit(MutationTypes.addSystemNotification, {title:'Visit rescheduled.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err:any)=>{
                    reschedule_modal.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Error rescheduling visit.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const scorecard = async() => {
                router.push({name:'visit.scorecard',params: {visit_id:visit.value.id}});
            }

            const shareScenario = async() => {
                loading.value = true;
                window.open('/#/scenarios/'+visit.value.scenario_id+'/share','_blank','width=700,height=800');
                loading.value = false;
            }

            const shareZoomMessage = async() => {
                loading.value = true;
                window.open('/#/scenarios/'+visit.value.scenario_id+'/zoom-message','_blank','width=700,height=800');
                loading.value = false;
            }

            const transfer = async() => {
                loading.value = true;
                await Visit.transfer(transferFormHelper.mapValues()).then((res:any)=>{
                    visit.value = res.data.visit;
                    show_transfer_form.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Visit transferred.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err:any)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error transferring visit.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const rescheduleOnLoad = () => {
                if('r' in router.currentRoute.value.query && router.currentRoute.value.query.r){
                    if(is_learner.value && visit.value.is_complete == 0 && visit.value.is_canceled == 0){
                        show_reschedule.value = true;
                    }
                }
            }

            const getRecording = async() => {
                loading.value = true;
                var post_data = {
                    visit_id: visit.value.id
                };
                await Visit.getRecording(visit.value.id, post_data).then((res)=>{
                    if(res.data.link !== 'undefined'){
                        has_recording.value = true;
                        recording_url.value = res.data.link;
                    }else{
                        has_recording.value = false;
                        recording_error.value = 'Link not generated';
                    }
                },(err)=>{
                    has_recording.value = false;
                    recording_error.value = err.response.data.message;
                });
            }

            const recordingError = async() => {
                store.commit(MutationTypes.addSystemNotification, {title:'Error retrieving recording.',content:recording_error.value,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
            }



            return {
                addObserver,
                cancel,
                can_confirm,
                can_edit_observers,
                can_generate_reschedule,
                can_transfer,
                confirm,
                end_date,
                feedbackFormCompleted,
                formatted_field_data,
                getObserverUsers,
                generateReschedule,
                getTransferUsers,
                getVisit,
                has_recording,
                has_verbal_feedback,
                is_admin,
                is_client_admin,
                is_learner,
                is_observer,
                is_observer_scorecard_complete,
                is_participant_feedback_available,
                is_sp,
                learner_type,
                loadErrorMessage,
                loading,
                moment,
                observerForm,
                observer_users,
                participantFeedbackCompleted,
                partner_disabled_dates,
                printFeedback,
                recordingError,
                recording_url,
                removeObserver,
                rescheduleForm,
                reschedule_link,
                reschedule_modal,
                rescheduleVisit,
                scorecard,
                setStartDate,
                shareScenario,
                shareZoomMessage,
                show_cancel,
                showCancelConfirm,
                show_feedback_form,
                showFeedbackForm,
                showObservers,
                show_observers,
                showParticipantFeedback,
                show_participant_feedback,
                showReschedule,
                showRescheduleModal,
                show_reschedule,
                show_sp_data,
                showTransferForm,
                show_transfer_form,
                sp_users,
                start_date,
                transfer,
                transferForm,
                user: store.state.auth.user,
                viewParticipantFeedback,
                view_participant_feedback,
                visit,
            };
        },
    });
</script>
