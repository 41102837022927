<template>
	<td class="py-2 px-2">
        {{partnerName}}
    </td>
    <td class="py-2 d-none d-md-block">
        {{partnerSlug}}
    </td>
    <td class="py-2">
        {{usedSeats}}
    </td>
    <td class="py-2">
        <input v-model="partnerSeats" /> <button class="ml-4 btn btn-primary btn-sm" @click="updateSeats">Save Seats</button>
    </td>
    <br/>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">

    import {defineComponent, ref} from "vue";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import {NotificationColor, NotificationIcon} from "@/interfaces/notification";
    import {store} from "@/store";
    import Loading from 'vue3-loading-overlay';
    import Partner from "@/models/partner";
    import router from "@/router";

    export default defineComponent({
        components: {Loading},

        props:{
            partnerSeatsObj: {type:Object, required: true}
        },

        setup(props) {
            const partnerId = ref(props.partnerSeatsObj.id);
            const partnerName = ref(props.partnerSeatsObj.name);
            const partnerSlug = ref(props.partnerSeatsObj.slug);
            const partnerSeats = ref(props.partnerSeatsObj.seats);
            const usedSeats = ref(props.partnerSeatsObj.used_seats);
            const loading = ref(false);
            const refresh = () => router.go(0);

            const updateSeats = async() => {
                loading.value = true;

                let form = {
                    seats: partnerSeats.value
                };
                if (partnerSeats.value > 0 && partnerSeats.value >= props.partnerSeatsObj.seats && partnerId.value > 0) {
                    await Partner.updateSeats(partnerId.value,form).then((res)=>{
                        if(res.data == 'success') {
                            store.commit(MutationTypes.addSystemNotification,{
                                title: 'SUCCESS',
                                content: partnerName.value + ': Seats Successfully Updated',
                                icon: NotificationIcon.SUCCESS,
                                color: NotificationColor.GREEN
                            });
                        } else {
                            store.commit(MutationTypes.addSystemNotification,{
                                title: 'ERROR',
                                content: 'Unable to update Seat Total',
                                icon: NotificationIcon.ERROR,
                                color: NotificationColor.RED
                            });
                        }
                        
                     });
                } else {
                    let message = 'Seat Total must have an integer greater than zero.';
                    if (partnerSeats.value < ref(props.partnerSeatsObj.seats)) {
                        message = 'Sorry, you CAN NOT reduce the seat total for a partner';
                    }
                    store.commit(MutationTypes.addSystemNotification,{
                        title: 'WARNING',
                        content: message,
                        icon: NotificationIcon.ERROR,
                        color: NotificationColor.RED
                    });
                    setTimeout(() => {refresh();}, 4000);
                }
                //btnStatus.focus();
                loading.value = false;
            }

            return {
                partnerId,
                partnerName,
                partnerSeats,
                partnerSlug,
                usedSeats,
                updateSeats,
                loading
            };
        },
    });
</script>

<style lang="scss">

</style>