<template>
    <tabs :options="{ useUrlFragment: false }" nav-class="nav nav-pills m-3" nav-item-class="nav-item col-md-4 col-sm-12 text-center" nav-item-link-class="nav-link btn-dashboard-sub-nav" nav-item-link-active-class="active">
        <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='far fa-clock text-primary'></i></h4>" name="Pending">
            <hr class="w-75 mx-auto" />
            <alerts :alerts="alerts" />
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <pending-visits :pending_visits="pending_visits" :pending_visits_total="pending_visits_total" :visit_loading="pending_visits_loading" v-on:change-page="changePage" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <featured-scenarios :featured_scenarios="featured_scenarios" v-if="Object.keys(featured_scenarios).length" :visit_loading="featured_scenarios_loading" />
                    <!-- <playlists /> -->
                    <browse-scenarios />
                </div>
            </div>
        </tab>
        <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='far fa-calendar-alt text-primary'></i></h4>" name="Scheduled">
            <hr class="w-75 mx-auto" />
            <alerts :alerts="alerts" />
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <scheduled-visits :scheduled_visits="scheduled_visits" :scheduled_visits_total="scheduled_visits_total" :visit_loading="scheduled_visits_loading" :is_learner="true" v-on:change-page="changePage" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <featured-scenarios :featured_scenarios="featured_scenarios" v-if="Object.keys(featured_scenarios).length" :visit_loading="featured_scenarios_loading" />
                    <!-- <playlists /> -->
                    <browse-scenarios />
                </div>
            </div>
        </tab>
        <tab prefix="<h2 class='mt-2'>" suffix=" / <i class='fas fa-check text-primary'></i></h4>" name="Completed">
            <hr class="w-75 mx-auto" />
            <alerts :alerts="alerts" />
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <completed-visits :completed_visits="completed_visits" :completed_visits_total="completed_visits_total" :visit_loading="completed_visits_loading" v-on:change-page="changePage" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <canceled-visits :canceled_visits="canceled_visits" :visit_loading="canceled_visits_loading" />
                </div>
            </div>
        </tab>
    </tabs>
</template>

<script lang="ts">
    import {defineComponent,ref} from "vue";
    import { store } from '../../store/index';
    import {Tabs, Tab} from 'vue3-tabs-component';
    import Visit from '@/models/visit';
    import moment from 'moment';
    import BrowseScenarios from "@/components/dashboard/browse-scenarios.vue";
    import CanceledVisits from "@/components/dashboard/canceled-visits.vue";
    import CompletedVisits from "@/components/dashboard/completed-visits.vue";
    import FeaturedScenarios from "@/components/dashboard/featured-scenarios.vue";
    import PendingVisits from "@/components/dashboard/pending-visits.vue";
    // import Playlists from "@/components/dashboard/playlists.vue";
    import ScheduledVisits from "@/components/dashboard/scheduled-visits.vue";
    import Alerts from "@/views/dashboards/alerts.vue";


    export default defineComponent({
        components: {
            Alerts,
            BrowseScenarios,
            CanceledVisits,
            CompletedVisits,
            FeaturedScenarios,
            PendingVisits,
            // Playlists,
            ScheduledVisits,
            Tab,
            Tabs
        },
        mounted() {
            this.getVisitData(0,'all');
        },
        setup() {
            const alerts = ref({});
            const pending_visits = ref([]);
            const pending_visits_total = ref(0);
            const pending_visits_loading = ref<Boolean>(true);
            const scheduled_visits = ref([]);
            const scheduled_visits_total = ref(0);
            const scheduled_visits_loading = ref<Boolean>(true);
            const completed_visits = ref([]);
            const completed_visits_total = ref(0);
            const completed_visits_loading = ref<Boolean>(true);
            const canceled_visits = ref([]);
            const canceled_visits_loading = ref<Boolean>(true);
            const featured_scenarios = ref([]);
            const featured_scenarios_loading = ref<Boolean>(true);
            const setDisplayImage = (collection:any) => {
                for(let [key] of Object.entries(collection)){
                    if(collection[key].standardized_patient.image !== null){
                        collection[key].scenario.patient_image.location = collection[key].standardized_patient.image;
                    }
                }
            }
            const getVisitData = async(page_start:Number,type:String) => {
                if(type == 'all' || type == 'requests'){
                    pending_visits_loading.value = true;
                }
                if(type == 'all' || type == 'scheduled'){
                    scheduled_visits_loading.value = true;
                }
                if(type == 'all' || type == 'completed'){
                    completed_visits_loading.value = true;
                }
                if(type == 'all'){
                    canceled_visits_loading.value = true;
                    featured_scenarios_loading.value = true;
                }
                var post_data = {
                    user_id: store.getters.user.props.id,
                    role_id: store.getters.dashboard_view,
                    page_start: page_start,
                    type: type
                };
                Visit.getUserVisits(post_data).then((res)=>{
                    if(type == 'all' || type == 'requests'){
                        pending_visits.value = res.data.visit_requests;
                        pending_visits_total.value = res.data.visit_requests_total;
                        pending_visits_loading.value = false;
                    }
                    if(type == 'all' || type == 'scheduled'){
                        scheduled_visits.value = res.data.scheduled_visits;
                        scheduled_visits_total.value = res.data.scheduled_visits_total;
                        setDisplayImage(scheduled_visits.value);
                        scheduled_visits_loading.value = false;
                    }
                    if(type == 'all' || type == 'completed'){
                        completed_visits.value = res.data.completed_visits;
                        completed_visits_total.value = res.data.completed_visits_total;
                        setDisplayImage(completed_visits.value);
                        completed_visits_loading.value = false;
                    }
                    if(type == 'all'){
                        canceled_visits.value = res.data.canceled_visits;
                        canceled_visits_loading.value = false;
                        featured_scenarios.value = res.data.featured_scenarios;
                        featured_scenarios_loading.value = false;
                        alerts.value = res.data.alerts;
                    }
                },(err)=>{
                    console.log(err);
                });
            };

            const changePage = (vars:any) => {
                getVisitData(vars.page_start,vars.type);
            }
            return {
                alerts,
                changePage,
                pending_visits,
                pending_visits_total,
                pending_visits_loading,
                scheduled_visits,
                scheduled_visits_total,
                scheduled_visits_loading,
                completed_visits,
                completed_visits_total,
                completed_visits_loading,
                canceled_visits,
                canceled_visits_loading,
                featured_scenarios,
                featured_scenarios_loading,
                getVisitData,
                moment,
            };
        }
    });
</script>
