import { getters, Getters } from "./getters";
import { mutations, Mutations } from "./mutations";
import { actions, Actions, ActionTypes } from "./actions";
import {
  Store as VuexStore,
  Module,
  CommitOptions,
  DispatchOptions,
} from "vuex";
import { RootState } from "@/store";
import Notification from '@/models/notification';
import {IPartner} from "@/interfaces/partner";
import User from "@/models/user";

//Defin State Interface
interface State{
    authenticating:boolean,
    authenticated:boolean,
    error:boolean,
    errorMessage?:Notification,
    validation:boolean,
    validationError?:Notification,
    user?:User|undefined,
    passwordApproved:Boolean,
    permissions:String[],
    partner?: IPartner | undefined,
    partners?: Array<IPartner>
    registation_fields?: Array<Number>|undefined,
    dashboard_view?: Number|undefined,
    dashboard_roles?: Array<Number>|undefined,
}

//Decalare State inerface values
const state: State = {
    authenticating:false,
    authenticated:false,
    error:false,
    validation:false,
    passwordApproved:false,
    permissions:[],
    user: undefined,
    partner:undefined,
    partners:[],
    registation_fields:[],
    dashboard_view:0,
    dashboard_roles:[]
};

const auth_module: Module<State, RootState> = {
  state,
  mutations,
  actions,
  getters,
};

export { State, ActionTypes, Store };
export default auth_module;

// !!! STORE TYPE CONFIGURATION !!!
type Store<S = State> = Omit<
  VuexStore<S>,
  "commit" | "getters" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};
