<template>
    <div v-if="!loading && typeof(learner_brief_scenario) !== 'undefined'">
        <div class="px-2 scenario-share">
            <p>Welcome! You are about to meet your standardized patient. Please ensure your camera and microphone are switched on.</p>
            <p>Once you have reviewed the patient note, you can <u>begin anytime.</u><br/>
            <strong style="color:#112c54">To begin the simulation,</strong> please greet your patient by name.</p>
            <p>Today's Patient: {{ patient_name }}</p>
            <div class="px-4" v-html="learner_brief_scenario"></div>
            <p>When you feel you have met the scenario's objectives, you may wrap up the visit as you would in a typical patient encounter.</p>
        </div>
    </div>
    <div v-else-if="!loading && typeof(learner_brief_scenario) === 'undefined'">
        <p>Error Loading Scenario</p>
    </div>
    <Loading :active="loading" :is-full-page="true"></Loading>
</template>

<script lang="ts">
    import { defineComponent, ref } from "vue";
    import Loading from 'vue3-loading-overlay';
    import router from "@/router";
    import Scenario from "@/models/scenario";
    export default defineComponent({
        components: {
            Loading
        },
        mounted(){
            this.getScenarioForShare();
        },
        setup(){
            //@ts-ignore
            document.getElementsByTagName('title')[0].innerHTML = 'Share Scenario';
            const learner_brief_scenario = ref<String>("");
            const loading = ref<Boolean>(true);
            const patient_name = ref<String>("");
            const scenario_id = ref<number | undefined>(router.currentRoute.value.params.id ? parseInt(router.currentRoute.value.params.id as string) : undefined);

            const getScenarioForShare = async() =>{
                loading.value = true;
                await Scenario.getForShare(scenario_id.value).then((res:any)=>{
                    learner_brief_scenario.value = res.data.learner_brief_scenario.value;
                    patient_name.value = res.data.patient_name;
                });
                loading.value = false;
            }

            return {
                getScenarioForShare,
                learner_brief_scenario,
                loading,
                patient_name,
            }
        }
    })
</script>

<style scoped lang="scss">
    .scenario-share {
        background: #fff;
        height: 100%;
        position: absolute;
    }
</style>
