<template>
    <span>
        <div v-if="label" class="scenario-preview-header">{{label}}</div>
        <div v-if="instructions" class="rubric-dynamicform-instructions">
            <span v-html="instructions"></span>
        </div>
        <div v-if="formQuestions.length">
            <div v-if="learner_instructions" class="rubric-dynamicform-instructions">
                <strong>Learner Instructions:</strong> <span v-html="learner_instructions"></span>
            </div>
            <div v-if="scorer_instructions" class="rubric-dynamicform-instructions">
                <strong>Scorer Instructions:</strong> <span v-html="scorer_instructions"></span>
            </div>
            <div class="col-12" v-for="(question_set,key) in formQuestions" :key="key">
                <h3 v-if="question_set.name !== null" v-html="question_set.name" class="col-12 text-center"></h3>
                <div v-if="question_set.instructions !== null" v-html="question_set.instructions" class="col-12 px-5 pt-1 pb-4 text-center font-weight-bold"></div>
                <div class="card-columns px-3">
                    <div v-for="(item,index) in question_set" v-bind:key="index">
                        <div v-if="index !== 'instructions' && index !== 'name'" class="card mb-4 shadow-sm w-100">
                            <div class="card-header bg-secondary text-white font-weight-bold">Question {{ parseInt(index) + 1 }}</div>
                            <div class="card-body">
                                <span v-html="item.question"></span>
                                <hr>
                                <div v-if="item.question_type_id == QuestionType.MULTIPLECHOICE">
                                    <div v-for="answer in item.answers" :key="answer.id">
                                        <label class="radio-custom"><span>{{answer.answer}}</span><input type="radio" :id="answer.id" :name="item.id" :value="answer.id" @click="clearError('error_'+item.id)"><span class="checkmark"></span></label>
                                    </div>
                                </div>
                                <div v-if="item.question_type_id == QuestionType.TRUEFALSE">
                                    <div v-for="answer in item.answers" :key="answer.id">
                                        <label class="radio-custom"><span>{{answer.answer}}</span><input type="radio" :id="answer.id" :name="item.id" :value="answer.id" @click="clearError('error_'+item.id)"><span class="checkmark"></span></label>
                                    </div>
                                </div>
                                <div v-if="item.question_type_id == QuestionType.LONGFILL">
                                    <textarea class="w-100" :name="item.id" @input="clearError('error_'+item.id)"/>
                                </div>
                                <div v-if="item.question_type_id == QuestionType.LIKERT">
                                    <div class="row">
                                        <div class="col-6">
                                            <small>
                                                <span v-for="(answer,index) in item.answers" :key="answer.id">
                                                    {{index + 1}} = {{answer.answer}}<span v-if="index !== 4">, </span>
                                                </span>
                                            </small>
                                        </div>
                                        <div class="col-6">
                                            <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                                                <label v-for="(answer,index) in item.answers" :key="answer.id" :class="'p-3 btn btn-light btn_ques_' + item.id" :for="'ans_' + answer.id">
                                                    <input type="radio" :id="'ans_' + answer.id" :name="item.id" :value="answer.id" @click="buttonUpdate">{{index + 1}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <error-alert :elm_id="'error_'+item.id" />
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="w-75" />
            </div>
        </div>
        <div v-else>
            <div class="card-body">
                There are currently no questions. Select an existing rubric from the drop-down or click the "+ Create Question" button to create new questions.
            </div>
        </div>
    </span>
</template>

<script lang="ts">
    import {computed, defineComponent, ref} from "vue";
    import {QuestionType} from "@/enums/question-type";
    import ErrorAlert from "@/components/error-alert.vue";
    export default defineComponent( {
        name: "dynamic-form",
        components: {ErrorAlert},
        props:{
            instructions:{type:String},
            label:{type:String},
            questions:{type:Array,required:true}
        },
        setup(props){
            const truefalse = ref(false);
            const multiplechoice = ref();
            const likert = ref();
            const learner_instructions = ref();
            const scorer_instructions = ref();

            const formQuestions = computed<Array<any>>(()=> {
                let questions:Array<any> = [];
                let index = 0;
                let q_index = 0;
                props.questions.forEach((elm:any) => {
                    if(elm.question !== null){
                        if(typeof(questions[index]) == 'undefined'){
                            questions[index] = {};
                        }
                        questions[index][q_index] = elm.question;
                        q_index++;
                    }else if(elm.rubric !== null){
                        if(typeof(questions[index]) !== 'undefined'){
                            index++;
                        }
                        questions[index] = {};
                        q_index = 0;
                        questions[index]['name'] = elm.rubric.name;
                        questions[index]['instructions'] = elm.rubric.scorer_instructions;
                        elm.rubric.questions.forEach((rubq:any) => {
                            questions[index][q_index] = rubq;
                            q_index++;
                        });
                        q_index = 0;
                        index++;
                    }
                });
                return questions;
            })

            return{
                ErrorAlert,
                formQuestions,
                learner_instructions,
                likert,
                multiplechoice,
                QuestionType,
                scorer_instructions,
                truefalse,
            }
        }
    });
</script>

<style scoped>

</style>
