<template>
    <label class="switcher switcher-{{color}}">
        <input type="checkbox" class="switcher-input" v-model="value" :checked="value">
        <span class="switcher-indicator">
              <span class="switcher-yes">
                <span class="fa fa-check"></span>
              </span>
              <span class="switcher-no">
                <span class="fa fa-times"></span>
              </span>
            </span>
        <span class="switcher-label">{{ label }}</span>
    </label>
</template>

<script lang="ts">
import {computed, defineComponent} from "vue";

    export default defineComponent( {
        name: "switch-input",
        props:{
            modelValue:Boolean,
            label:{
                type:String, required:true
            },
            color:{type:String,default:'success'}
        },
        emits:['update:modelValue'],
        setup(props,{emit}){
            const value = computed({
                get: () => props.modelValue,
                set: (value) => emit('update:modelValue', value)
            });
            return{
                value,
            }
        }
    });
</script>

<style scoped>

</style>