<template>
    <div>
        <h1>Seat Management</h1>
    </div>
    <div v-if="!loading">
        <div v-if="seats" class="card py-2 px-2">
            <table>
                <tr>
                    <th class="header-row py-2 px-2">Partner Name</th>
                    <th class="header-row py-2">Partner Slug</th>
                    <th class="header-row py-2">Used Seats</th>
                    <th class="header-row py-2">Seat Total</th>
                </tr>
                <tr v-for="(seat, index) in seats" v-bind:key="index">
                    <partner-seats :partner-seats-obj="seat"></partner-seats>
                </tr>
            </table>
        </div>
        <div v-else class="card py-2">
            <div class="text-center">No data for Partner Seats was returned at this time.</div>
        </div>
    </div>
    <br/>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { api } from "@/store";
    import { defineComponent, ref } from "vue";
    import { store } from '@/store';
    import Loading from 'vue3-loading-overlay';
    import PartnerSeats from '@/views/management/partnerSeats.vue';

    export default defineComponent({
        components: {Loading, PartnerSeats},
        mounted(){
            this.getPartnerSeats();
        },
        setup() {
            const loading = ref(false);
            const seats = ref([]);

            const getPartnerSeats = async () => {
                loading.value = true;
                await api.get('sanctum/csrf-cookie');

                api.get('api/partner',{}).then((res)=>{
                    var formatted:any = [];

                    res.data.forEach(function(elm:any){

                        formatted.push({
                            'id': elm.id,
                            'name': elm.long_name,
                            'slug': elm.slug,
                            'seats': elm.seats,
                            'used_seats': elm.used_seats,
                        });

                    });
                    seats.value = formatted;
                    loading.value = false;

                },(err)=>{
                    console.log(err);
                    loading.value = false;
                });
            }

            return {
                user: store.state.auth.user,
                getPartnerSeats,
                PartnerSeats,
                seats,
                loading
            };
        },
    });
</script>

<style lang="scss">
    .header-row {
        background-color: #eeeeee;
    }
</style>
