<template>
    <!-- Layout navbar -->
    <nav class="layout-navbar d-flex justify-content-between navbar align-items-center bg-white container-p-x" id="layout-navbar">
        <div class="d-flex justify-content-between align-items-center">
            <a href="javascript:void(0)" @click="sideNavToggle">
                <span class="navbar-toggler-icon"></span>
            </a>
            <img v-if="store.getters.partner && store.getters.partner.image && (store.getters.partner.image.startsWith('http://') || store.getters.partner.image.startsWith('https://'))" class="ml-4 cursor-pointer" :src="store.getters.partner.image" :alt="store.getters.partner.short_name +' Logo'" :title="store.getters.partner.short_name +' Logo'" height="45" @click="router.push({name:'dashboard'});">
            <img v-else-if="store.getters.partner && store.getters.partner.image" class="ml-4 cursor-pointer" :src="'/img/partners/'+ store.getters.partner.image" :alt="store.getters.partner.short_name +' Logo'" :title="store.getters.partner.short_name +' Logo'" height="45" @click="router.push({name:'dashboard'});">
            <img v-else-if="store.getters.partner && !store.getters.partner.image && store.getters.partner.is_sim" class="ml-4 cursor-pointer" src="/img/partners/SIMclusive_Logo.png" alt="Simclusive Logo" title="Simclusive Logo" height="45" @click="router.push({name:'dashboard'});">
            <img v-else class="ml-4 cursor-pointer" src="/img/partners/sp-logo.svg" alt="SP-ED Logo" title="SP-ED Logo" height="45" @click="router.push({name:'dashboard'});">
        </div>
        <div v-if="store.getters.user" class="d-flex justify-content-between align-items-center">
            <div class="d-sm-block d-none" v-if="store.getters.partner && store.getters.partner.is_sim && store.getters.partner.slug != 'simclusive' && store.getters.partner.image">
                <img class="mr-4" src="/img/partners/SIMclusive_Logo.png" alt="Simclusive Logo" title="Simclusive Logo" height="45">
            </div>
            <div class="nav-item dropdown">
                <a @click="userMenuIsOpen = !userMenuIsOpen" class="nav-link border-none text-primary p-0" href="javascript:void(0)" data-sideNavToggle="dropdown">
                <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                    <user-image size="ui-w-50" :source="store.getters.user?.props?.image" />
                    <span class="d-none d-md-block logged-in-name">{{store.getters.user?.props?.first_name + ' '+store.getters.user?.props?.last_name}}</span>
                </span>
                </a>
                <transition enter-active-class="animate__animated animate__flipInY animate__faster" leave-active-class="animate__animated animate__flipOutY animate__faster">
                    <div v-if="userMenuIsOpen" class="dropdown-menu dropdown-menu-right d-block" v-click-outside="profileClose">
                        <router-link to="/profile" class="dropdown-item" role="menuitem">
                            <i class="fa fa-user text-lightest"></i> &nbsp; My Profile
                        </router-link>
                        <div class="dropdown-divider"></div>
                        <a href="javascript:void(0)" @click="logout" class="dropdown-item"><i class="fa fa-sign-out-alt text-lightest"></i> &nbsp; Log Out</a>
                    </div>
                </transition>

            </div>
        </div>

    </nav>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import {store} from '@/store';
import { MutationTypes } from "@/store/modules/ux/mutations";
import { ActionTypes } from "@/store/modules/auth";
import Notification from "@/models/notification";
/* import HeaderNotificationItem from "@/components/header-notification-item.vue"; */
import router from "@/router";
import UserImage from "@/components/user-image.vue";

export default defineComponent({
    components: {UserImage},
    setup() {
        const userMenuIsOpen = ref(false);
        const notificationsIsOpen = ref(false);
        const sideNavIsOpen = computed(()=>store.state.ux.sideNav);
        const sideNavToggle = () => store.commit(MutationTypes.setSideNav,!store.state.ux.sideNav);
        const logout = () => store.dispatch(ActionTypes.logout);
        const notifications = computed(()=>store.getters.notifications.filter((i:Notification)=>!i.data.is_archived && !i.data.is_read));

        const notificationsClose = () => {
            notificationsIsOpen.value = false;
        }

        const profileClose = () => {
            userMenuIsOpen.value = false;
        }

        return {
            sideNavIsOpen,
            sideNavToggle,
            userMenuIsOpen,
            router,
            store,
            notificationsClose,
            notificationsIsOpen,
            notifications,
            logout,
            profileClose
        };
    },
});
</script>
<style lang="scss">
    #notification-dropdwon{
        margin-top: .5rem;
        width: 22rem;
    }

    .notification-bubble{
        position: fixed;
        margin-left: 1px;
        font-size: 10px;
        padding: 2px 4px;
        line-height: 10px;
        color: #fff;
        background-color: #ff0000;
        font-weight: bold;
    }
</style>
