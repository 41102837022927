<template>
    <div>
        <div class="row" id="visit-request">
            <div class="card">
                <div class="row mx-2 my-5">
                    <div class="col-sm-12 col-md-4 order-md-12">
                        <h4 class="text-center mx-auto mb-3 w-75">This visit has been requested for:</h4>
                        <div v-for="(date,index) in visit_request.dates" :key="date.id">
                            <h4 class="text-center mb-3"><span class="text-secondary">{{ moment(date.local_time).format('MM/DD/YYYY') }}</span> <small>at</small> <span class="text-primary">{{ moment(date.local_time).format('h:mm') }}<sup>{{ moment(date.local_time).format('a') }}</sup></span></h4>
                            <p v-if="index === 0" class="text-center mb-2">or</p>
                        </div>
                        <div v-if="show_user_data" class="text-center">
                            <p class="mb-0">Visit requested by</p>
                            <p>{{visit_request.user.name}} ({{visit_request.user.email}})</p>
                        </div>
                        <div v-if="is_admin && learner_type !== ''" class="text-center">
                            <p>Learner Type: {{learner_type}}</p>
                        </div>
                        <div v-if="is_learner">
                            <p class="text-small text-muted">We are in the process of assigning a Standardized Patient to your request.</p>
                            <p class="text-small text-danger">You will receive a notification of the appointment time from your SP through an SMS Text to your cell phone.</p>
                            <div v-if="!show_reschedule" class="mb-4">
                                <button class="btn btn-secondary btn-md w-100 my-2" @click="showCancelModal">Cancel Request</button>
                                <button class="btn btn-secondary btn-md w-100 mb-2" @click="showReschedule">Reschedule Request</button>
                            </div>
                        </div>
                        <div v-if="is_sp">
                            <p>A Visit has been requested.</p>
                        </div>
                        <div v-if="is_learner">
                            <div v-if="show_reschedule" class="row mb-3">
                                <div class="col-md-12">
                                    <visit-schedule-form field="visit_date_one" alt-field="visit_date_two" :start-date="start_date" :end-date="end_date" :disabled-dates="partner_disabled_dates" v-on:update-date="setDate" :scenario-id="visit_request.scenario_id"></visit-schedule-form>
                                </div>
                                <div class="col-md-12">
                                    <button class="btn btn-secondary btn-md w-100 mb-2" @click="showRescheduleModal">Reschedule Visit Request</button>
                                    <button class="btn btn-secondary btn-md w-100" @click="showReschedule">Cancel</button>
                                    <modal v-model="reschedule_modal">
                                        <template v-slot:header>Reschedule Visit Request</template>
                                        <p>Are you sure you want to reschedule this request?</p>
                                        <button type="button" class="btn btn-primary btn-md float-right" @click="rescheduleRequest">Reschedule Visit Request</button>
                                    </modal>
                                </div>
                            </div>
                            <modal v-model="cancel_modal">
                                <template v-slot:header>Cancel Visit Request</template>
                                <p>Are you sure you want to cancel this request? <span v-if="visit_request.payment_amount > 0">Your account will be credited for ${{(visit_request.payment_amount).toFixed(2)}}. This credit can only be used to reschedule for this scenario.</span></p>
                                <button type="button" class="btn btn-primary btn-md float-right" @click="cancelRequest">Cancel Visit Request</button>
                            </modal>
                        </div>
                        <div v-if="is_sp">
                            <div class="row">
                                <div class="col-md-12">
                                    <p>Please review the below dates and approve a selection that is convienent for you.</p>
                                    <label v-for="date in visit_request.dates" :key="date.id" class="d-block">
                                        <input type="radio" name="request_date_id" :value="date.id" v-model="form.request_date_id.value"> {{ moment(date.local_time).format('MM/DD/YY h:mm a') }}
                                    </label>
                                </div>
                                <div class="col-md-12">
                                    <button type="button" class="btn btn-sm btn-secondary btn-md w-100" @click="showApproveModal">Accept</button>
                                </div>
                            </div>
                            <modal v-model="approve_modal">
                                <template v-slot:header>Approve Visit Request</template>
                                <p>Are you sure you want to approve this request?</p>
                                <button type="button" class="btn btn-primary btn-md float-right" @click="approveRequest">Approve Visit Request</button>
                            </modal>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-8 order-sm-1">
                        <scenario-card :scenario="visit_request.scenario" :formatted_field_data="formatted_field_data" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { api } from "@/store";
    import { defineComponent, reactive, ref } from "vue";
    import { store } from '../../store/index';
    import Request from "@/models/request";
    import {RequestType} from "@/enums/request-type";
    import {FormConfig} from "@/interfaces/form-config";
    import Form from "@/utilities/form";
    import Loading from 'vue3-loading-overlay';
    import moment from 'moment';
    import {NotificationIcon, NotificationColor} from "@/interfaces/notification";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import { useRoute } from 'vue-router';
    import router from "@/router";
    import Modal from "@/components/modal.vue";
    import Scenario from "@/models/scenario";
    import ScenarioCard from "@/components/scenario-card.vue";
    import {IScenarioFieldGroup} from "@/interfaces/scenario-field-group";
    import {UserRole} from "@/enums/user-role";
    import VisitScheduleForm from "@/components/visit-schedule-form.vue";

    export default defineComponent({
        components: {Loading, Modal, ScenarioCard, VisitScheduleForm},
        mounted(){
            this.getRequest();
            this.setStartDate();
        },
        props: {
            request: Object
        },
        methods: {
            setDate(date:any):void {
                this.rescheduleForm[date.field].value = date.date;
                this.rescheduleForm[date.field].valid = true;
                this.rescheduleForm[date.field].validationMessage = '';
            }
        },
        setup(props) {
            const loading = ref<Boolean>(false);
            const visit_request = ref(props.request);
            const route = useRoute();
            const user = store.state.auth.user;
            const approve_modal = ref<Boolean>(false);
            const cancel_modal = ref<Boolean>(false);
            const showApproveModal = () => approve_modal.value = !approve_modal.value;
            const showCancelModal = () => cancel_modal.value = !cancel_modal.value;
            const showReschedule = () => show_reschedule.value = !show_reschedule.value;
            const showRescheduleModal = () => reschedule_modal.value = !reschedule_modal.value;
            const form:FormConfig = reactive({
                request_date_id:{
                    value:'',
                    valid:false,
                    validationMessage:''
                },
                reject:{
                    value:false,
                    valid:true,
                    validationMessage:''
                },
                request_type:{
                    value:RequestType.VISIT,
                    valid:true,
                    validationMessage:''
                },
                request_id:{
                    value:route.params.request_id,
                    valid:true,
                    validationMessage:''
                }
            });
            const formHelper = new Form(form);
            const rescheduleForm:FormConfig = reactive({
                request_id:{
                    value:route.params.request_id,
                    valid:true,
                    validationMessage:''
                },
                request_type:{
                    value:RequestType.VISIT,
                    valid:true,
                    validationMessage:''
                },
                visit_date_one:{
                    value:'',
                    valid:true,
                    validationMessage:''
                },
                visit_date_two:{
                    value:'',
                    valid:true,
                    validationMessage:''
                }
            });
            const rescheduleFormHelper = new Form(rescheduleForm);
            const is_learner = ref<Boolean>(false);
            const is_sp = ref<Boolean>(false);
            const fields = ref<Array<IScenarioFieldGroup>>([]);
            const formatted_field_data = ref<Array<any>>([]);
            const show_reschedule = ref<Boolean>(false);
            const start_date = ref<Date>(new Date());
            const end_date = ref<any>(null);
            const partner_start_date = ref<any>(null);
            const partner_end_date = ref<any>(null);
            const reschedule_modal = ref<Boolean>(false);
            const show_user_data = ref<Boolean>(false);
            const is_admin = ref<Boolean>(store.getters.hasRole(UserRole.CLIENTADMIN) || store.getters.hasRole(UserRole.ADMIN) || store.getters.user.props.super_admin);
            const learner_type = ref<String>('');
            const partner_disabled_dates = ref<Array<Date>>([]);

            const getRequest = async () => {
                loading.value = true;
                await api.get('sanctum/csrf-cookie');
                await api.get('api/request/' + route.params.request_id,{}).then((res)=>{
                    visit_request.value = res.data.request;
                    learner_type.value = res.data.learner_type;
                    form.request_date_id.value = res.data.request.dates.id
                    form.request_date_id.valid = true;
                    is_learner.value = res.data.request.user_id == user?.props.id;
                    is_sp.value = res.data.request.scenario.sp_users.filter(function(sp_user:any){return sp_user?.user_id == user?.props?.id;}).length > 0;
                    show_user_data.value = store.getters.hasRole(UserRole.ADMIN) || store.getters.hasRole(UserRole.HEADTRAINER) || store.getters.hasRole(UserRole.CLIENTADMIN) || store.getters.user?.props.super_admin;
                    if(res.data.request.is_expired){
                        let redirect_path = is_sp.value ? '/dashboard' : '/scenarios/' + res.data.request.scenario_id + '/overview';
                        router.push({ path: redirect_path });
                        let err_mes = is_sp.value ? 'Request is no longer available to approve.' : 'Please make a new scheduling request.';
                        store.commit(MutationTypes.addSystemNotification, {title:'Request is expired.',content:err_mes,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                    }else{
                        form.request_date_id.value = res.data.request.dates.id
                        form.request_date_id.valid = true;
                    }
                    if(res.data.partner_dates != null){
                        partner_start_date.value = moment(res.data.partner_dates.start_date);
                        partner_end_date.value = moment(res.data.partner_dates.end_date);
                        setStartDate();
                    }
                    if(res.data.partner_disabled_dates != null){
                        let first_date = moment(res.data.partner_disabled_dates.start_date);
                        let last_date = moment(res.data.partner_disabled_dates.end_date);
                        var add_dates = first_date.toString() != last_date.toString();
                        partner_disabled_dates.value.push(first_date.toDate());
                        var new_date = first_date;
                        while(add_dates){
                            new_date = new_date.add(1,'d');
                            partner_disabled_dates.value.push(new_date.toDate());
                            if(new_date.isSame(last_date,'day')){
                                add_dates = false;
                            }
                        }
                    }
                },(err)=>{
                    console.log(err);
                });
                let requested_field_type = is_sp.value ? 1 : 2;
                await Scenario.getFieldsByType(visit_request.value?.scenario_id,requested_field_type).then((res:any)=>{
                    fields.value = res.data.map((i:any)=>i as IScenarioFieldGroup);
                });
                mapFields();
                loading.value = false;
            }

            const approveRequest = async() => {
                loading.value = true;
                form.reject.value = false;
                await Request.approve(formHelper.mapValues()).then((res)=>{
                    router.push({ path: '/visit/' + res.data.visit_id });
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Visit request has been approved!',icon:NotificationIcon.SUCCESS, color:NotificationColor.GREEN});
                },(err:any)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error approving visit request.',content:err.response.data.message,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const cancelRequest = async() => {
                loading.value = true;
                await Request.cancel(formHelper.mapValues()).then(()=>{
                    let schedule_fee = visit_request.value != undefined ? visit_request.value.payment_amount : 0;
                    let success_message = 'Visit request has been cancelled.';
                    if(schedule_fee > 0) success_message += ' $' + schedule_fee.toFixed(2) + ' has been credited to your account.';
                    router.push({ path: '/scenarios/' + visit_request.value?.scenario_id + '/overview'});
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:success_message,icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err:any)=>{
                    cancel_modal.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Error cancelling visit request.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const mapFields = () => {
                fields.value.forEach(function(group){
                    let hasData = false;
                    let formatted_fields:any = [];
                    group.fields.forEach(function(field){
                        let found_field = visit_request.value?.scenario.fields.find(function(elm:any){return elm.scenario_field_id === field.id;});
                        if(found_field){
                            hasData = true;
                            if(field.label == 'Learner Scenario') field.label = 'Scenario';
                            formatted_fields.push({
                                'title':field.label,
                                'value':found_field.value
                            });
                        }
                    });
                    if(hasData){
                        let array:any = [];
                        if(group.fields.length > 1){
                            array['title'] = group.label;
                        }else{
                            array['title'] = group.fields[0].label;
                        }
                        array['fields'] = formatted_fields;
                        formatted_field_data.value.push(array);
                    }
                });
            }

            const rescheduleRequest = async() => {
                loading.value = true;
                await Request.reschedule(rescheduleFormHelper.mapValues()).then((res)=>{
                    visit_request.value = res.data.visit_request;
                    reschedule_modal.value = false;
                    show_reschedule.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Success',content:'Visit request has been rescheduled.',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err:any)=>{
                    reschedule_modal.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Error rescheduling visit request.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const setStartDate = () => {
                if(partner_start_date.value != null && partner_end_date.value != null){
                    if(partner_start_date.value.isAfter(moment().add(2,'days'))){
                        start_date.value.setMonth(partner_start_date.value.toDate().getMonth());
                        start_date.value.setDate(partner_start_date.value.toDate().getDate());
                    }else{
                        start_date.value = new Date();
                        start_date.value.setMonth(start_date.value.getMonth());
                        start_date.value.setDate(start_date.value.getDate() + 2);
                    }
                    end_date.value = new Date();
                    end_date.value.setYear(partner_end_date.value.toDate().getFullYear());
                    end_date.value.setMonth(partner_end_date.value.toDate().getMonth());
                    end_date.value.setDate(partner_end_date.value.toDate().getDate());
                }else{
                    start_date.value.setDate(start_date.value.getDate() + 2);
                    end_date.value = null;
                }
            }

            return {
                approve_modal,
                approveRequest,
                cancel_modal,
                cancelRequest,
                end_date,
                form,
                formatted_field_data,
                formHelper,
                getRequest,
                is_admin,
                is_learner,
                is_sp,
                learner_type,
                loading,
                moment,
                partner_disabled_dates,
                rescheduleForm,
                reschedule_modal,
                rescheduleRequest,
                route,
                setStartDate,
                showApproveModal,
                showCancelModal,
                showReschedule,
                showRescheduleModal,
                show_reschedule,
                show_user_data,
                start_date,
                user,
                visit_request,
            };
        },
    });
</script>
