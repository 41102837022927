<template>
    <div v-if="!loading">
        <div class="row">
            <div class="col-12">
                <h1>Partner Configuration</h1>
            </div>
        </div>
        <div class="row" v-if="!has_error">
            <div class="col-12">
                <div class="row my-5">
                    <div class="col-md-10 offset-md-1 col-sm-12">
                        <div id="landing_preview">
                            <div class="mb-3" id="header_preview">
                                <div class="py-3" :style="'background-color:' + partner_color" id="header_subbar">
                                    <img height="60" :alt="partner?.short_name" :title="partner?.short_name" :src="partner_image" class="preview_logo" />
                                    <div class="mt-2">
                                        <button class="btn btn-primary text-center" @click="set_logo = true;">Upload Logo</button>
                                    </div>
                                    <div>
                                        <div class="float-right">
                                            <button class="btn btn-primary mr-2" @click="setBannerColor">Set Banner Color</button>
                                            <button v-if="partner_color != 'transparent'" class="btn btn-primary mr-2" @click="clear_banner_color = true;">Clear Banner Color</button>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                            <div id="body_preview">
                                <div class="row">
                                    <div class="col-12 mt-1">
                                        <div v-if="set_welcome_header" class="text-editor-container text-center">
                                            <div class="mt-3">
                                                <input class="w-50" type="text" maxlength="255" v-model="welcome_header_update" placeholder="enter custom header text..." />
                                            </div>
                                            <div class="my-4">
                                                <button class="btn btn-primary" @click="set_welcome_header = false">Cancel</button>
                                                <button class="btn btn-primary ml-2" @click="submitWelcomeHeader">Save Welcome Header</button>
                                            </div>
                                        </div>
                                        <h1 v-if="!set_welcome_header" class="text-center header-padding">{{partner?.welcome_header}}</h1>
                                        <div v-if="!set_welcome_header" class="text-center mb-4">
                                            <button class="btn btn-primary" @click="set_welcome_header = true">Set Welcome Header</button>
                                        </div>
                                        <div class="green-line align-center"></div>
                                        <div v-if="set_welcome_message" class="text-editor-container">
                                            <div class="mt-3">
                                                <text-editor v-model="welcome_message_update" />
                                            </div>
                                            <div class="my-4 text-center">
                                                <button class="btn btn-primary" @click="set_welcome_message = false">Cancel</button>
                                                <button class="btn btn-primary ml-2" @click="submitWelcomeMessage">Save Welcome Message</button>
                                            </div>
                                        </div>
                                        <div v-if="!set_welcome_message" class="welcome-text text-center" v-html="partner?.welcome_message"></div>
                                        <div v-if="!set_welcome_message" class="text-center mt-4">
                                            <button class="btn btn-primary" @click="set_welcome_message = true">Set Welcome Message</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <a :href="partner_url" target="_blank" class="btn btn-primary">View Active Landing Page</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-12">
                <div class="alert alert-danger">{{error_msg}}</div>
            </div>
        </div>
        <modal v-model="set_logo" allow-close>
            <template v-slot:header>Upload Logo</template>
            <div>
                <p class="text-danger"><strong>Note: Any existing logo will be permanently removed after uploading a new logo</strong></p>
                <p class="mb-0">Suggested Dimensions: max 120px height, max 300px width</p>
                <p><small>Height will be adjusted to 60px</small></p>
                <div class="form-group">
                    <label for="logo_file">Select Logo File</label>
                    <div>
                        <input ref="logo_file" type="file" name="logo_file" v-on:change="setLogoFile" accept=".jpeg, .jpg, .png, .svg" />
                    </div>
                    <p class="mb-0"><small>Formats: JPEG, PNG, SVG</small></p>
                    <p><small>Max File Size: 4MB</small></p>
                </div>
            </div>
            <template v-slot:footer>
                <button  @click="set_logo = false" class="btn btn-default mx-2">Cancel</button>
                <button @click="submitLogo" class="btn btn-primary">Upload Logo</button>
            </template>
        </modal>
        <modal v-model="set_banner_color" allow-close>
            <template v-slot:header>Set Banner Color</template>
            <div>
                <color-picker :is-widget="true" picker-type="chrome" :disable-history="true" :disable-alpha="true" v-model:pureColor="pure_color" v-model:gradientColor="gradient_color"/>
            </div>
            <template v-slot:footer>
                <div class="d-flex justify-content-end">
                    <button @click="set_banner_color = false" class="btn btn-default mx-2">Cancel</button>
                    <button @click="submitBannerColor" class="btn btn-primary">Save Banner Color</button>
                </div>
            </template>
        </modal>
        <modal v-model="clear_banner_color" allow-close>
            <template v-slot:header>Clear Banner Color</template>
            <div>
                <p>Are you sure you want to clear the banner color?</p>
            </div>
            <template v-slot:footer>
                <button @click="clear_banner_color = false" class="btn btn-default mx-2">Cancel</button>
                <button @click="submitClearBannerColor" class="btn btn-primary">Clear Banner</button>
            </template>
        </modal>
    </div>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    // import { ColorInputWithoutInstance } from "tinycolor2";
    import { ColorPicker } from "vue3-colorpicker";
    import "vue3-colorpicker/style.css";
    import { defineComponent, ref } from "vue";
    import { IPartner } from "@/interfaces/partner";
    import Loading from 'vue3-loading-overlay';
    import Modal from "@/components/modal.vue";
    import { MutationTypes } from "@/store/modules/ux/mutations";
    import { NotificationIcon, NotificationColor } from "@/interfaces/notification";
    import Partner from '@/models/partner';
    import { store } from '../../store/index';
    import TextEditor from "@/components/text-editor.vue";
    import { useRoute } from "vue-router";

    export default defineComponent({
        components: {ColorPicker, Loading, Modal, TextEditor},
        mounted(){
            this.getPartner();
        },
        setup() {
            const clear_banner_color = ref<Boolean>(false);
            const error_msg = ref<String>("");
            const has_error = ref<Boolean>(false);
            const loading = ref<Boolean>(false);
            const partner = ref<IPartner|null>(null);
            const partner_color = ref<String>('transparent');
            const partner_image = ref<String>('/img/partners/SIMclusive_Logo.png');
            const partner_url = ref<String>('');
            const route = useRoute();
            const set_banner_color = ref<Boolean>(false);
            const set_logo = ref<Boolean>(false);
            const set_welcome_header = ref<Boolean>(false);
            const set_welcome_message = ref<Boolean>(false);
            const welcome_header_update = ref<String>("");
            const welcome_message_update = ref<String>("");
            const logo_file = ref<any>(null);

            const pure_color = ref("black");
            const gradient_color = ref("linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%)");

            const getPartner = async() => {
                loading.value = true;
                var partner_id = +route.params.partner_id;
                await Partner.getPartnerForConfiguration(partner_id).then((res) => {
                    partner.value = res.data.partner;
                    if(partner.value?.header_color != null){
                        partner_color.value = '#' + partner.value.header_color;
                    }
                    if(partner.value?.image != null){
                        partner_image.value = partner.value.image;
                    }
                    partner_url.value = process.env.VUE_APP_API_PROTOCAL + partner.value?.slug + '.' + process.env.VUE_APP_SIM_URL;
                    welcome_header_update.value = res.data.partner.welcome_header;
                    welcome_message_update.value = res.data.partner.welcome_message;
                },(err) => {
                    console.log(err);
                    error_msg.value = err.response.data.message;
                    has_error.value = true;
                });
                loading.value = false;
            }

            const setBannerColor = () => {
                set_banner_color.value = true;
                setTimeout(() => {
                    //@ts-ignore
                    document.getElementsByClassName('vc-hue-slider__bar')[0].style.background = '-webkit-linear-gradient(left,red 0%,yellow 16.66%,lime 33.33%,aqua 50%,blue 66.66%,fuchsia 83.33%,red 100%)';
                },200);
            }

            const submitBannerColor = async() => {
                loading.value = true;
                var rgb = pure_color.value;
                //convert it to hex
                var a:any = rgb.split("(")[1].split(")")[0];
                a = a.split(",");
                var b = a.map(function(x:any){         //For each array element
                    x = parseInt(x).toString(16);      //Convert to a base16 string
                    return (x.length==1) ? "0"+x : x;  //Add zero if we get only one character
                });
                var hex = b.join("");
                var post_data = {
                    header_color: hex
                };
                var partner_id = +route.params.partner_id;
                await Partner.setBannerColor(partner_id,post_data).then((res:any) => {
                    partner.value = res.data.partner;
                    partner_color.value = '#' + res.data.partner.header_color;
                    set_banner_color.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Banner Color Updated.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err:any) => {
                    console.log(err);
                    store.commit(MutationTypes.addSystemNotification, {title:'Error updating banner color.',content:err,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const submitClearBannerColor = async() => {
                loading.value = true;
                var partner_id = +route.params.partner_id;
                await Partner.clearBannerColor(partner_id).then((res:any) => {
                    partner.value = res.data.partner;
                    partner_color.value = 'transparent';
                    clear_banner_color.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Banner Color Cleared.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err:any) => {
                    console.log(err);
                    store.commit(MutationTypes.addSystemNotification, {title:'Error clearing banner color.',content:err,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const submitLogo = async() => {
                loading.value = true;
                if(logo_file.value != null && !logo_file.value.files.length){
                    store.commit(MutationTypes.addSystemNotification, {title:'Please select a logo file to upload.',content:'',icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                }else{
                    //check overall size
                    var fsize = logo_file.value.files[0].size;
                    var file = Math.round((fsize / (1024*1024)));
                    if(file > 4){
                        store.commit(MutationTypes.addSystemNotification, {title:'File is larger than 4MB, please select a smaller file.',content:'',icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                    }else{
                        let formData = new FormData();
                        formData.append("logo_file",logo_file.value.files[0]);
                        var partner_id = +route.params.partner_id;
                        await Partner.setLogo(partner_id,formData).then((res:any) => {
                            partner.value = res.data.partner;
                            partner_image.value = res.data.partner.image;
                            set_logo.value = false;
                            store.commit(MutationTypes.addSystemNotification, {title:'Logo Uploaded',content:'',icon:NotificationIcon.ERROR,color:NotificationColor.GREEN});
                        },(err:any) => {
                            console.log(err);
                            store.commit(MutationTypes.addSystemNotification, {title:'Error uploading logo.',content:err,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                        })
                    }
                }
                loading.value = false;
            }

            const submitWelcomeHeader = async() => {
                loading.value = true;
                var post_data = {
                    welcome_header: welcome_header_update.value
                }
                var partner_id = +route.params.partner_id;
                await Partner.setWelcomeHeader(partner_id,post_data).then((res) => {
                    partner.value = res.data.partner;
                    set_welcome_header.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Welcome Header Updated.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err) => {
                    console.log(err);
                    store.commit(MutationTypes.addSystemNotification, {title:'Error updating welcome header.',content:err,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const submitWelcomeMessage = async() => {
                loading.value = true;
                var post_data = {
                    welcome_message: welcome_message_update.value
                }
                var partner_id = +route.params.partner_id;
                await Partner.setWelcomeMessage(partner_id,post_data).then((res) => {
                    partner.value = res.data.partner;
                    set_welcome_message.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Welcome Message Updated.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err) => {
                    console.log(err);
                    store.commit(MutationTypes.addSystemNotification, {title:'Error updated welcome message.',content:err,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            return {
                clear_banner_color,
                error_msg,
                getPartner,
                gradient_color,
                has_error,
                loading,
                logo_file,
                partner,
                partner_color,
                partner_image,
                partner_url,
                pure_color,
                set_banner_color,
                setBannerColor,
                set_logo,
                set_welcome_header,
                set_welcome_message,
                submitBannerColor,
                submitClearBannerColor,
                submitLogo,
                submitWelcomeHeader,
                submitWelcomeMessage,
                welcome_header_update,
                welcome_message_update,
            };
        },
    });
</script>

<style scoped lang="scss">
    #landing_preview {
        min-height: 500px;
        border: 4px solid #9c9c9c;
        #header_preview {
            text-align: center;
            #header_subbar {
                .preview_logo {
                    height: 60px;
                }
            }
        }
        .header-padding {
            padding-bottom: 1rem;
        }
        .green-line {
            border-top: 4px solid #6bbaa3;
            height: 24px;
            width: 50px;
        }
        .green-line.align-center {
            margin: 0 auto;
        }
        .text-editor-container {
            width: 100%;
            div{
                width: 75%;
                margin: 0 auto;
            }
        }
    }
    .vc-hue-slider__bar{
        background: -webkit-linear-gradient(left,red 0%,yellow 16.66%,lime 33.33%,aqua 50%,blue 66.66%,fuchsia 83.33%,red 100%);
    }
</style>