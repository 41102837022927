<template>
	<div id="agreement" v-if="!loading && agreement">
		<div v-if="agreement.is_rejected">
			<h2 class="mb-0">Agreement Rejected</h2>
			<p>Your requested quote has been rejected</p>
		</div>
		<div v-else>
			<div v-if="!agreement.is_agreement_signed || view_agreement">
				<div class="row">
					<div class="col-12">
						<h2 class="mb-0">Simclusive Services Agreement</h2>
						<p><small>You <span v-if="view_agreement">have agreed</span><span v-else>are required to agree</span> to the following terms and conditions to license a co-brand.</small></p>
					</div>
				</div>
				<div class="row" v-if="view_agreement">
					<div class="col-12">
						<button class="btn btn-primary" @click="viewAgreement">View Receipt</button>
					</div>
				</div>
				<div class="row">
					<div class="col-12 text-center">
						<iframe :src="pdf_path" width="70%" height="800"></iframe>
					</div>
				</div>
				<div class="row">
					<div class="col-12 text-center">
						<br>
						<p v-if="agreement.is_agreement_signed">You agreed to this document on {{moment(agreement.agreement_signed_at).format('MM/DD/YYYY')}}.</p>
						<div v-else>
							<div class="mb-5">
								<p style="line-height: 29px;">Do you need a separate billing contact?
									<label class="switch">
										<input id="billing_address_checkbox" type="checkbox" :checked="billing_address" @change="toggleBillingAddress">
										<span class="slider round"></span>
									</label>
								</p>
								<div v-if="billing_address" class="billing-address-block">
									<div class="row mb-4">
										<div class="col-12">
											<label class="d-block" for="billing_name">Name <span class="text-danger">*</span></label>
											<input class="w-100" type="text" maxlength="255" name="billing_name" v-model="billing_name">
										</div>
									</div>
									<div class="row mb-4">
										<div class="col-12">
											<label class="d-block" for="billing_email">Email <span class="text-danger">*</span></label>
											<input class="w-100" type="email" maxlength="255" name="billing_email" v-model="billing_email">
										</div>
									</div>
									<div class="row mb-4">
										<div class="col-12">
											<label class="d-block" for="billing_phone">Phone <span class="text-danger">*</span></label>
											<vue-tel-input :v-model="billing_phone" :value="billing_phone" mode="international" :preferred-countries="['US','CA','MX']" @validate="setBillingPhone"></vue-tel-input>
										</div>
									</div>
									<div class="row mb-4">
										<div class="col-sm-12 col-md-6">
											<label class="d-block" for="billing_address_1">Address 1 <span class="text-danger">*</span></label>
											<input class="w-100" type="text" maxlength="255" name="billing_address_1" v-model="billing_address_1">
										</div>
										<div class="col-sm-12 col-md-6">
											<label class="d-block" for="billing_address_2">Address 2</label>
											<input class="w-100" type="text" maxlength="255" name="billing_address_2" v-model="billing_address_2">
										</div>
									</div>
									<div class="row mb-4">
										<div class="col-sm-12 col-md-5">
											<label class="d-block" for="billing_city">City <span class="text-danger">*</span></label>
											<input class="w-100" type="text" maxlength="255" name="billing_city" v-model="billing_city">
										</div>
										<div class="col-sm-12 col-md-3">
											<label class="d-block" for="billing_state">State <span class="text-danger">*</span></label>
											<select class="w-100" name="billing_state" v-model="billing_state">
												<option v-for="state in states" v-bind:key="state.id" :value="state.short_name">{{state.short_name}}</option>
											</select>
										</div>
										<div class="col-sm-12 col-md-4">
											<label class="d-block" for="billing_zip">Zip Code <span class="text-danger">*</span></label>
											<input class="w-100" type="text" maxlength="25" name="billing_zip" v-model="billing_zip">
										</div>
									</div>
								</div>
							</div>
							<p>By entering your name in the boxes below,<br>you acknowledge that you agree to the terms in the document above.</p>
							<div class="row mb-4">
								<div class="offset-3 col-6">
									<div class="row">
										<div class="col-6">
											<div class="text-center"><label for="first_name">First Name</label></div>
											<input name="first_name" type="text" v-model="first_name" />
										</div>
										<div class="col-6">
											<div class="text-center"><label for="last_name">Last Name</label></div>
											<input name="last_name" type="text" v-model="last_name" />
										</div>
									</div>
								</div>
							</div>
							<div class="row mb-4">
								<div class="col-12">
									<button class="btn btn-primary" @click="agreeToTerms">I agree to the terms above</button>
								</div>
							</div>
							<div class="row mb-4">
								<div class="col-12">
									<p>On the next page, you will be able to pay via a Credit Card<br>
										in the amount of  <strong>${{ agreement.total_amount }} USD</strong> to finalize the agreement.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else-if="!agreement.is_payment_made">
				<div class="row">
					<div class="col-12">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-6">
										<div class="row">
											
										</div>
										<h2 class="mb-0">Credit Card Payment</h2>
										<p><small>You are requested to pay <strong>${{ agreement.total_amount }} USD</strong> to finalize the agreement</small></p>

										<div class="row" v-if="agreement.invoice_requested">
										<div class="col-12">
											<p>You requested an invoice. You will be notified by email once the invoice is available. If you require additional assistance, please contact <a href="mailto:simclusive@sp-ed.com">support@simclusive.com</a>. Note that your partner URL/name will not be available until payment has been submitted.</p>
										</div>
									</div>
									<div v-else>
										<div class="row" :v-if="stripe_loaded">
											<div class="col-12">
												<label for="name_on_card">Name as it appears on card</label>
											</div>
										</div>
										<div class="row" :v-if="stripe_loaded">
											<div class="col-12">
												<input class="mb-4" type="text" maxlength="100" name="name_on_card" v-model="name_on_card">
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<StripeElements v-if="stripe_loaded" type="payment" v-slot="{ elements, instance }" ref="elms" :stripe-key="stripe_key" :instance-options="instance_options" :elements-options="elements_options">
													<StripeElement ref="card" :elements="elements" :options="card_options" :instance="instance" />
												</StripeElements>
											</div>
										</div>
										<div class="row mt-5" v-if="has_card_error">
											<div class="col-12">
												<div class="alert alert-danger">{{card_error}}</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<button v-if="stripe_loaded" class="btn btn-primary mt-5" @click="pay">Submit Payment</button>
											</div>
										</div>
									</div>

									</div>
									<div class="col-6">
										<h2 class="mb-0">Invoice Payment</h2>
										<p>Would you like to request an invoice instead?</p>
										<button class="btn btn-primary" @click="requestInvoice">Request Invoice</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="row">
					<div class="col-12">
						<h2>Agreement Finalized</h2>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<h3 class="mb-0">Thank you</h3>
								<h4 class="pt-0 pb-4">Welcome to Simclusive</h4>
								<h3>{{store.getters.user.props.company_name}}</h3>
								<p><small><a :href="partner_url" target="_blank">{{partner_url}}</a></small></p>
								<p><strong>Payment:</strong> ${{ agreement.total_amount }} on {{moment(agreement['payment_made_at']).format('MM/DD/YYYY')}}</p>
								<p><strong>Number of Sessions:</strong> {{agreement['number_of_sessions']}}</p>
								<p><strong>Scenarios</strong></p>
								<ul class="mb-5">
									<li v-for="scenario in scenarios" v-bind:key="scenario.id" class="mb-2">{{scenario.patient_name}}</li>
								</ul>
								<button class="btn btn-primary mb-4" @click="viewAgreement">View Agreement</button>
								<p>Agreement signed {{moment(agreement['agreement_signed_at']).format('MM/DD/YYYY')}}</p>
								<div v-if="password != ''">
									<p class="mb-0"><strong>Passcode: </strong>{{password}}</p>
									<p><small>In order to sign up for sessions, participants will be required to enter above passcode.</small></p>
								</div>
								<div class="text-center">
									<h2><strong>NEXT STEP:</strong></h2>
									<button class="btn btn-primary" @click="configureLandingPage">Configure Your Landing Page</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="err_msg != ''">
		<div class="alert alert-danger">{{err_msg}}</div>
	</div>
	<loading :active="loading" :is-full-page="true"></loading>
	<loading :active="card_loading" :is-full-page="true"></loading>
</template>
<script lang="ts">
	import { defineComponent, ref, onBeforeMount } from "vue";
	import { loadStripe } from '@stripe/stripe-js';
	import { StripeElements, StripeElement } from 'vue-stripe-js';
	import { IPendingPartner } from "@/interfaces/pending-partner";
	import Loading from "vue3-loading-overlay";
	import moment from "moment";
	import { MutationTypes } from "@/store/modules/ux/mutations";
	import { NotificationIcon, NotificationColor } from "@/interfaces/notification";
	import Request from "@/models/request";
	import router from "@/router";
	import { store } from '../store/index';
	import { ActionTypes } from '@/store/modules/auth';
	import { useRoute } from "vue-router";
	import {VueTelInput} from 'vue3-tel-input';

	export default defineComponent({
		components: {
			Loading,
			StripeElements,
			StripeElement,
			VueTelInput
		},
		mounted(){
			this.getAgreement();
		},
		setup(){
			const stripe_key = ref(process.env.VUE_APP_STRIPE_KEY);
			const stripe_loaded = ref(false);
			const instance_options = ref({});
			const elements_options = ref({});
			const card_options = ref({value:{}});
			const has_card_error = ref<Boolean>(false);
			const card_error = ref<String>("");
			const card = ref();
			const elms = ref();

			onBeforeMount(() => {
				const stripe_promise = loadStripe(stripe_key.value);
				stripe_promise.then(() => {
					stripe_loaded.value = true;
				},(err)=>{
					console.log(err);
				});
			});

			const agreement = ref<IPendingPartner | null>(null);
			const scenarios = ref([]);
			const err_msg = ref<String>("");
			const first_name = ref<String>("");
			const last_name = ref<String>("");
			const name_on_card = ref<String>("");
			const loading = ref<Boolean>(false);
			const route = useRoute();
			const token = ref<String>("");
			const partner_url = ref<String>("");
			const view_agreement = ref<Boolean>(false);
			const card_loading = ref<Boolean>(false);
			const pdf_path = ref<String>("");
			const states = ref([]);
			const billing_address = ref<Boolean>(false);
			const billing_name = ref<String>("");
			const billing_email = ref<String>("");
			const billing_phone = ref<String>("");
			const billing_phone_valid = ref<Boolean>(false);
			const billing_address_1 = ref<String>("");
			const billing_address_2 = ref<String>("");
			const billing_city = ref<String>("");
			const billing_state = ref<String>("");
			const billing_zip = ref<String>("");
			const password = ref<String>("");

			const agreeToTerms = async() => {
				loading.value = true;
				var send = true;
				var err_msg = '<ul>';
				if(first_name.value.trim() == ""){
					send = false;
					err_msg += '<li>Please enter your first name</li>';
				}
				if(last_name.value.trim() == ""){
					send = false;
					err_msg += '<li>Please enter your last name</li>';
				}
				if(billing_address.value){
					if(billing_name.value.trim() == ""){
						send = false;
						err_msg += '<li>Please enter billing contact name</li>';
					}
					if(billing_email.value.trim() == ""){
						send = false;
						err_msg += '<li>Please enter a billing email</li>';
					}
					if(!billing_phone_valid.value){
						send = false;
						err_msg += '<li>Please enter a valid billing phone number</li>';
					}
					if(billing_address_1.value.trim() == ""){
						send = false;
						err_msg += '<li>Please enter a billing address</li>';
					}
					if(billing_city.value.trim() == ""){
						send = false;
						err_msg += '<li>Please enter a billing city</li>';
					}
					if(billing_state.value.trim() == ""){
						send = false;
						err_msg += '<li>Please select a billing state</li>';
					}
					if(billing_zip.value.trim() == ""){
						send = false;
						err_msg += '<li>Please enter a billing zip code</li>';
					}
				}
				err_msg += '</ul>';
				if(send){
					var post_data:any = {
						agreement_id: +route.params.agreement_id,
						first_name: first_name.value,
						last_name: last_name.value	
					};
					if(billing_address.value){
						post_data.billing_address = {
							name: billing_name.value,
							email: billing_email.value,
							phone: billing_phone.value,
							address_1: billing_address_1.value,
							address_2: billing_address_2.value,
							city: billing_city.value,
							state: billing_state.value,
							zip: billing_zip.value
						};
					}
					await Request.agreeToTerms(post_data).then((res) =>{
						agreement.value = res.data.agreement;
						store.commit(MutationTypes.addSystemNotification, {title:'Agreement signed.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
					},(err:any) => {
						console.log(err);
						store.commit(MutationTypes.addSystemNotification, {title:'Error signing agreement.',content:err,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
					});
				}else{
					store.commit(MutationTypes.addSystemNotification, {title:'Error signing agreement.',content:err_msg,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
				}

				loading.value = false;
			}

			const buildPartnerUrl = () => {
				var partner_href = '';
				partner_href = process.env.VUE_APP_API_PROTOCAL + agreement.value?.slug + '.' + process.env.VUE_APP_SIM_URL;
				partner_url.value = partner_href;
			}

			const configureLandingPage = () => {
				//@ts-ignore
				router.push({name:"management.partner",params:{partner_id:agreement.value?.partner_id}});
			}

			const getAgreement = async() => {
				loading.value = true;
				var post_data = {
					agreement_id: +route.params.agreement_id
				}
				await Request.getAgreement(post_data).then((res) => {
					agreement.value = res.data.agreement;
					password.value = res.data.password;
					scenarios.value = res.data.scenarios;
					states.value = res.data.states;
					pdf_path.value = 'https://s3.amazonaws.com/media.' + process.env.VUE_APP_API_BASE + 'agreements/' + agreement.value?.agreement_path;
					buildPartnerUrl();
				},(err) => {
					console.log(err);
					err_msg.value = err.response.data.message;
				})
				loading.value = false;
			}

			const pay = async() => {
				card_loading.value = true;
				const card_element = card.value.stripeElement;
				has_card_error.value = false;
				card_error.value = "";
				if(name_on_card.value == ""){
					card_error.value = "Please provide the name as it appears on  the card";
					has_card_error.value = true;
					card_loading.value = false;
					return false;
				}

				elms.value.instance.createToken(card_element).then((res:any) => {
					if(typeof(res.error) != 'undefined'){
						has_card_error.value = true;
						card_error.value = res.error.message;
						card_loading.value = false;
					}else if(typeof(res.token) != 'undefined'){
						token.value = res.token.id;
						var post_data = {
							agreement_id: +route.params.agreement_id, 
							stripe_token: token.value,
							name_on_card: name_on_card.value
						};
						Request.submitPayment(post_data).then((res) => {
							agreement.value = res.data.pending_partner;
							password.value = res.data.password;
							buildPartnerUrl();
							store.commit(MutationTypes.addSystemNotification, {title:'Payment Submitted.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
							store.dispatch(ActionTypes.user);
							card_loading.value = false;
						},(err) => {
							console.log(err);
							store.commit(MutationTypes.addSystemNotification, {title:'Error submitting payment.',content:err.response.data.error,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
							card_loading.value = false;
						});
					}
				},(err: object) => {
					console.log(err);
					store.commit(MutationTypes.addSystemNotification, {title:'Error submitting payment.',content:'Please contact support@simclusive.com for assistance',icon:NotificationIcon.ERROR,color:NotificationColor.RED});
					card_loading.value = false;
				});
			}

			const requestInvoice = async() => {
				loading.value = true;
				var post_data = {
					agreement_id: +route.params.agreement_id
				};
				await Request.requestInvoice(post_data).then((res) => {
					agreement.value = res.data.agreement;
					store.commit(MutationTypes.addSystemNotification, {title:'Invoice Requested.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
				},(err) => {
					console.log(err);
					store.commit(MutationTypes.addSystemNotification, {title:'Error requesting invoice.',content:err,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
				});
				loading.value = false;
			}

			const setBillingPhone = (phoneObject:any) => {
                if(typeof(phoneObject.valid) == 'undefined' || !phoneObject.valid){
                    billing_phone.value = phoneObject.number;
                    billing_phone_valid.value = false;
                }else{
                    billing_phone.value = phoneObject.number;
                    billing_phone_valid.value = true;
                }
            }

			const toggleBillingAddress = () => {
				billing_address.value = !billing_address.value;
			}

			const viewAgreement = () => {
				view_agreement.value = !view_agreement.value;
			}


			return {
				agreement,
				agreeToTerms,
				billing_address,
				billing_name,
				billing_email,
				billing_phone,
				billing_address_1,
				billing_address_2,
				billing_city,
				billing_state,
				billing_zip,
				card,
				card_error,
				card_loading,
				card_options,
				configureLandingPage,
				elements_options,
				elms,
				err_msg,
				first_name,
				getAgreement,
				has_card_error,
				instance_options,
				last_name,
				loading,
				moment,
				name_on_card,
				partner_url,
				password,
				pay,
				pdf_path,
				requestInvoice,
				scenarios,
				setBillingPhone,
				states,
				store,
				stripe_key,
				stripe_loaded,
				toggleBillingAddress,							
				view_agreement,
				viewAgreement
			}
		}
	});
</script>
<style scoped lang="scss">
	.billing-address-block{
		width: 70%;
		text-align: left;
		margin: 0 auto;
	}
</style>