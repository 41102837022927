import { MutationTree } from "vuex";
import { State } from "./index";
import { INotification } from '@/interfaces/notification';
import Notification from "@/models/notification";
// (B) - Mutações
// [B.1] inserir a definição da mutação X no enum
// ==> { X = "SET_ X" }
export enum MutationTypes {
  setSideNav = "SET_SIDENAV",
  addNotification = "ADD_NOTIFICATION",
  setNotifications = "SET_NOTIFICATIONS",
  dismissNotification = "DISMISS_NOTIFICATION",
  addSystemNotification = "ADD_SYSTEM_NOTIFICATION",
  setCurrentPageTitle = "SET_CURRENT_PAGE_TITLE",
}

// [B.2] definir o tipo da mutação X
// ==> [MutationTypes.X](state: S, payload: TIPO_DE_X): void;
export type Mutations<S = State> = {
  [MutationTypes.setSideNav](state: S, payload: any): void;
  [MutationTypes.addNotification] (state: S, payload:INotification): void;
  [MutationTypes.setNotifications] (state: S, payload:Array<INotification>): void;
  [MutationTypes.addSystemNotification] (state: S, payload:INotification): void;
  [MutationTypes.dismissNotification] (state: S, payload:Number): void;
  [MutationTypes.setCurrentPageTitle] (state: S, Payload:String): void;
};

// [B.3] declarar a mutação X
// ==>  [MutationTypes.X](state, payload) {
// ==>    state.x = payload;
// ==>  },
export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.setSideNav](state, payload) {
    state.sideNav = payload;
    if(payload){
      document.getElementsByTagName('html')[0].classList.remove('layout-collapsed')
    }else{
      document.getElementsByTagName('html')[0].classList.add('layout-collapsed')
    }
  },
  [MutationTypes.addNotification](state,payload:INotification){
    state.notifications.push(new Notification(payload));
  },
  [MutationTypes.setNotifications](state,payload:Array<INotification>){
    const tempNots:Array<Notification> = [];
      payload.forEach((i)=>{
        tempNots.push(new Notification(i));
      });
      state.notifications = tempNots;
  },
  [MutationTypes.addSystemNotification](state,payload:INotification){
    state.systemNotifications.push(new Notification(payload));
  },
  [MutationTypes.dismissNotification](state,payload:number){
    state.systemNotifications.shift();
  },
  [MutationTypes.setCurrentPageTitle](state,payload:String){
    state.currentPageTitle = payload;
  },
};
