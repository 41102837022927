<template>
    <div class="card shadow-sm mb-4">
        <div class="card-header text-white bg-secondary font-weight-bold">Visit Stats</div>
        <div class="card-content">
            <div class="row m-auto" v-if="!loading">
                <div class="col-12 px-0 pt-4">
                    <div class="row px-2 text-center">
                        <div class="col-sm-12 col-md-4">
                            <h3>Pending</h3>
                            <p>{{pending_count}}</p>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <h3>Scheduled</h3>
                            <p>{{scheduled_count}}</p>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <h3>Completed</h3>
                            <p>{{completed_count}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="loading-area" v-else>
                <loading :active="loading" :is-full-page="false"></loading>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent,ref} from "vue";
    import {store} from '../../store/index';
    import Loading from 'vue3-loading-overlay';

    export default defineComponent({
        components: {
            Loading
        },
        props: {
            is_global: {type:Boolean,default:false},
            report_data: {type:Object,required:true},
            is_client_admin: {type:Boolean,default:false},
        },
        watch: {
            report_data: function(newData){
                this.pending_count = newData.pending_count;
                this.scheduled_count = newData.scheduled_count;
                this.completed_count = newData.completed_count;
                this.partners = newData.partners;
                this.scenarios = newData.scenarios;
                this.loading = false;
            }
        },
        setup() {
            const loading = ref<boolean>(false);
            const pending_count = ref(0);
            const scheduled_count = ref(0);
            const completed_count = ref(0);
            const partners = ref({});
            const scenarios = ref({});
            const partner_id = ref(null);
            const scenario_id = ref(null);

            return {
                completed_count,
                partner_id,
                partners,
                pending_count,
                scenario_id,
                scenarios,
                scheduled_count,
                loading,
                store,
            };
        }
    });
</script>
<style scoped lang="scss">
    table{
        tr{
            td{
                border: 0;
            }
        }
    }
    .loading-area{
        height: 500px;
    }
</style>
