
import {api} from "@/store";
import {IRegistrationSelectValues} from "@/interfaces/registration-select-values";

export class RegistrationSelectValues{
    constructor(public data?:IRegistrationSelectValues) {}

    static async getSelectValues(field_name:string):Promise<any> {
        return await api.get('api/registration-select-values/'+field_name);
    }

}