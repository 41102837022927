<template>
    <tabs :options="{ useUrlFragment: false }" nav-class="nav nav-pills m-3" nav-item-class="nav-item col-md-6 col-sm-12 text-center" nav-item-link-class="nav-link btn-dashboard-sub-nav" nav-item-link-active-class="active">
        <tab prefix="<h4>" suffix=" / <i class='far fa-calendar-alt text-primary'></i></h4>" name="Scheduled">
            <hr class="w-75 mx-auto" />
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <scheduled-training-sessions :scheduled_training_sessions="scheduled_training_sessions" :scheduled_training_sessions_total="scheduled_training_sessions_total" :loading="scheduled_training_sessions_loading" :is_trainer="true" v-on:change-page="changePage" />
                    <scheduled-interviews v-if="store.getters.dashboard_view == UserRole.HEADTRAINER" :scheduled_interviews="scheduled_interviews" :scheduled_interviews_total="scheduled_interviews_total" :loading="scheduled_interviews_loading" v-on:change-page="changePage" />
                    <pending-interviews v-if="store.getters.dashboard_view == UserRole.HEADTRAINER" :pending_interviews="pending_interviews" :pending_interviews_total="pending_interviews_total" :loading="pending_interviews_loading" v-on:change-page="changePage" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <schedule-training-session :training_session_types="training_session_types" :training_session_users="training_session_users" :training_scenarios="training_scenarios" :schedule_loading="schedule_loading" v-on:refresh="refresh" />
                    <financial-report :financial_data="financial_data" />
                </div>
            </div>
        </tab>
        <tab prefix="<h4>" suffix=" / <i class='fas fa-check text-primary'></i></h4>" name="Completed">
            <hr class="w-75 mx-auto" />
            <div class="row mx-auto my-3">
                <div class="col-sm-12 col-md-6">
                    <completed-training-sessions :completed_training_sessions="completed_training_sessions" :completed_training_sessions_total="completed_training_sessions_total" :loading="completed_training_sessions_loading" :is_trainer="true" v-on:change-page="changePage" />
                    <completed-interviews v-if="store.getters.dashboard_view == UserRole.HEADTRAINER" :completed_interviews="completed_interviews" :completed_interviews_total="completed_interviews_total" :loading="completed_interviews_loading" v-on:change-page="changePage" />
                </div>
                <div class="col-sm-12 col-md-6">
                    <schedule-training-session :training_session_types="training_session_types" :training_session_users="training_session_users" :training_scenarios="training_scenarios" :schedule_loading="schedule_loading" v-on:refresh="refresh" />
                    <financial-report :financial_data="financial_data" />
                    <canceled-training-sessions :canceled_training_sessions="canceled_training_sessions" :canceled_training_sessions_total="canceled_training_sessions_total" :loading="canceled_training_sessions_loading" :is_trainer="true" v-on:change-page="changePage" />
                </div>
            </div>
        </tab>
    </tabs>
</template>

<script lang="ts">
    import {defineComponent,ref} from "vue";
    import { store } from '../../store/index';
    import {Tabs, Tab} from 'vue3-tabs-component';
    import CanceledTrainingSessions from '@/components/dashboard/canceled-training-sessions.vue';
    import CompletedInterviews from '@/components/dashboard/completed-interviews.vue';
    import CompletedTrainingSessions from '@/components/dashboard/completed-training-sessions.vue';
    import FinancialReport from '@/components/dashboard/financial-report.vue';
    import moment from 'moment';
    import PendingInterviews from '@/components/dashboard/pending-interviews.vue';
    import ScheduledInterviews from '@/components/dashboard/scheduled-interviews.vue';
    import ScheduledTrainingSessions from '@/components/dashboard/scheduled-training-sessions.vue';
    import ScheduleTrainingSession from '@/components/dashboard/schedule-training-session.vue';
    import {UserRole} from "@/enums/user-role";
    import Visit from '@/models/visit';

    export default defineComponent({
        components: {
            CanceledTrainingSessions,
            CompletedInterviews,
            CompletedTrainingSessions,
            FinancialReport,
            PendingInterviews,
            ScheduledInterviews,
            ScheduledTrainingSessions,
            ScheduleTrainingSession,
            Tab,
            Tabs,
        },
        mounted() {
            this.getTrainingData(0,'all');
            this.getFinancialData();
        },
        setup() {
            const financial_data = ref({});
            const schedule_loading = ref<Boolean>(true);
            const training_session_types = ref({});
            const training_session_users = ref({});
            const training_scenarios = ref({});
            const scheduled_interviews = ref([]);
            const scheduled_interviews_total = ref(0);
            const scheduled_interviews_loading = ref<Boolean>(true);
            const scheduled_training_sessions = ref([]);
            const scheduled_training_sessions_total = ref(0);
            const scheduled_training_sessions_loading = ref<Boolean>(true);
            const completed_interviews = ref([]);
            const completed_interviews_total = ref(0);
            const completed_interviews_loading = ref<Boolean>(true);
            const completed_training_sessions = ref([]);
            const completed_training_sessions_total = ref(0);
            const completed_training_sessions_loading = ref<Boolean>(true);
            const canceled_training_sessions = ref([]);
            const canceled_training_sessions_total = ref(0);
            const canceled_training_sessions_loading = ref<Boolean>(true);
            const pending_interviews = ref([]);
            const pending_interviews_total = ref(0);
            const pending_interviews_loading = ref<Boolean>(true);
            const getFinancialData = async() => {
                var post_data:any = {
                    month: moment().format('M'),
                    year: moment().format('YYYY'),
                    user_id: store.getters.user.props.id,
                    is_author: false
                };
                if(store.getters.partner.id !== 1){
                    post_data.partner_id = store.getters.partner.id;
                }
                Visit.getFinancialData(post_data).then((res)=>{
                    financial_data.value = {
                        completed_total: res.data.completed_total,
                        scorecards_total: res.data.scorecards_total,
                        training_sessions_total: res.data.training_sessions_total,
                        late_cancellations_total: res.data.late_cancellations_total,
                        early_cancellations_total: res.data.early_cancellations_total,
                        no_shows_total: res.data.no_shows_total,
                        learner_technical_issues_total: res.data.learner_technical_issues_total,
                        interviews_total: res.data.interviews_total,
                        authoring_total: res.data.authoring_total,
                        total: res.data.total,
                    };
                },(err)=>{
                    console.log(err);
                });
            };
            const getTrainingData = async(page_start:Number,type:String) => {
                if(type == 'all'){
                    schedule_loading.value = true;
                }
                if(type == 'all' || type == 'scheduled_interviews'){
                    scheduled_interviews_loading.value = true;
                }
                if(type == 'all' || type == 'scheduled_training_sessions'){
                    scheduled_training_sessions_loading.value = true;
                }
                if(type == 'all' || type == 'completed_interviews'){
                    completed_interviews_loading.value = true;
                }
                if(type == 'all' || type == 'completed_training_sessions'){
                    completed_training_sessions_loading.value = true;
                }
                if(type == 'all' || type == 'canceled_training_sessions'){
                    canceled_training_sessions_loading.value = true;
                }
                if(type == 'all' || type == 'pending_interviews'){
                    pending_interviews_loading.value = true;
                }
                var post_data = {
                    user_id: store.getters.user.props.id,
                    role_id: store.getters.dashboard_view,
                    page_start: page_start,
                    type: type
                };
                Visit.getUserVisits(post_data).then((res)=>{
                    if(type == 'all'){
                        training_session_types.value = res.data.training_session_types;
                        training_session_users.value = res.data.training_session_users;
                        training_scenarios.value = res.data.training_scenarios;
                        schedule_loading.value = false;
                    }
                    if(type == 'all' || type == 'scheduled_interviews'){
                        scheduled_interviews.value = res.data.scheduled_interviews;
                        scheduled_interviews_total.value = res.data.scheduled_interviews_total;
                        scheduled_interviews_loading.value = false;
                    }
                    if(type == 'all' || type == 'scheduled_training_sessions'){
                        scheduled_training_sessions.value = res.data.scheduled_training_sessions;
                        scheduled_training_sessions_total.value = res.data.scheduled_training_sessions_total;
                        scheduled_training_sessions_loading.value = false;
                    }
                    if(type == 'all' || type == 'completed_interviews'){
                        completed_interviews.value = res.data.completed_interviews;
                        completed_interviews_total.value = res.data.completed_interviews_total;
                        completed_interviews_loading.value = false;
                    }
                    if(type == 'all' || type == 'completed_training_sessions'){
                        completed_training_sessions.value = res.data.completed_training_sessions;
                        completed_training_sessions_total.value = res.data.completed_training_sessions_total;
                        completed_training_sessions_loading.value = false;
                    }
                    if(type == 'all' || type == 'canceled_training_sessions'){
                        canceled_training_sessions.value = res.data.canceled_training_sessions;
                        canceled_training_sessions_total.value = res.data.canceled_training_sessions_total;
                        canceled_training_sessions_loading.value = false;
                    }
                    if(type == 'all' || type == 'pending_interviews'){
                        pending_interviews.value = res.data.pending_interviews;
                        pending_interviews_total.value = res.data.pending_interviews_total;
                        pending_interviews_loading.value = false;
                    }
                },(err)=>{
                    console.log(err);
                });
            };

            const changePage = (vars:any) => {
                getTrainingData(vars.page_start,vars.type);
            }
            const refresh = async() => {
                getTrainingData(0,'all');
            }
            return {
                changePage,
                financial_data,
                getFinancialData,
                getTrainingData,
                refresh,
                schedule_loading,
                training_session_types,
                training_session_users,
                training_scenarios,
                store,
                scheduled_interviews,
                scheduled_interviews_total,
                scheduled_interviews_loading,
                scheduled_training_sessions,
                scheduled_training_sessions_total,
                scheduled_training_sessions_loading,
                completed_interviews,
                completed_interviews_total,
                completed_interviews_loading,
                completed_training_sessions,
                completed_training_sessions_total,
                completed_training_sessions_loading,
                canceled_training_sessions,
                canceled_training_sessions_total,
                canceled_training_sessions_loading,
                pending_interviews,
                pending_interviews_total,
                pending_interviews_loading,
                UserRole
            };
        }
    });
</script>
