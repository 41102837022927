<template>
    <div v-if="!loading && typeof(training_session) !== 'undefined'">
        <div class="row" id="training-session-page">
            <div class="col-12 card">
                <div class="row mx-2 my-5">
                    <div class="col-sm-12 col-md-4 order-md-12">
                        <h4 class="text-center mx-auto mb-0 w-75">
                            <span v-if="!training_session.is_complete && !training_session.is_canceled">This training session is scheduled for:</span>
                            <span v-else-if="training_session.is_canceled">This training session was scheduled for:</span>
                            <span v-else-if="training_session.is_complete">This training session occurred on:</span>
                        </h4>
                        <h4 class="text-center mb-4"><span class="text-secondary">{{ moment(training_session.local_time).format('MM/DD/YY') }}</span> <small>at</small> <span class="text-primary">{{ moment(training_session.local_time).format('h:mm') }}<sup>{{ moment(training_session.local_time).format('a') }}</sup></span></h4>
                        <p class="text-center">
                            <small>
                                Duration
                                <span v-if="training_session.training_session_type_id == TrainingSessionType.SCENARIO">2 hours</span>
                                <span v-else-if="training_session.training_session_type_id == TrainingSessionType.ONBOARDING">1 hour</span>
                                <span v-else-if="training_session.training_session_type_id == TrainingSessionType.GROUPED">3 hours</span>
                                <span v-else-if="training_session.training_session_type_id == TrainingSessionType.LARGEGROUP">4 hours</span>
                            </small>
                        </p>
                        <div v-if="show_cancel">
                            <p>Are you sure you want to cancel the training session?</p>
                            <button class="btn btn-lg btn-secondary w-100 mb-2" @click="cancel">Cancel Training Session</button>
                            <button class="btn btn-lg btn-secondary w-100 mb-4" @click="showCancelConfirm">Return to Training Session Overview</button>
                        </div>
                        <div v-else-if="show_reschedule">
                            <p>Select convenient times for your training session</p>
                            <schedule-form field="training_session_date" alt-field="" :start-date="start_date" v-on:update-date="setDate" :is_single="true"></schedule-form>
                            <div class="row">
                                <div class="col-12">
                                    <div v-if="reschedule_error" class="alert alert-danger">{{ reschedule_error }}</div>
                                    <button class="btn btn-lg btn-secondary w-100 mb-2" @click="showRescheduleModal">Reschedule Training Session</button>
                                    <button class="btn btn-lg btn-secondary w-100 mb-4" @click="showReschedule">Return to Training Session Overview</button>
                                </div>
                            </div>
                            <modal v-model="reschedule_modal">
                                <template v-slot:header>Reschedule Training Session</template>
                                <p>Are you sure you want to reschedule this training session?</p>
                                <button type="button" class="btn btn-lg btn-primary w-100 mb-2" @click="rescheduleTrainingSession">Reschedule Training Session</button>
                            </modal>
                        </div>
                        <div v-else-if="show_edit_job_number">
                            <p><label for="job_number_edit">Edit Job#</label></p>
                            <div class="mb-4">
                                <input v-model="job_number_field" name="job_number_edit" id="job_number_edit" type="text" maxlength="255" />
                            </div>
                            <div v-if="job_number_error != ''" class="alert alert-danger mt-3">{{job_number_error}}</div>
                            <button class="btn btn-lg btn-secondary w-100 mb-2" @click="updateJobNumber">Submit Job#</button>
                            <button class="btn btn-lg btn-secondary w-100 mb-4" @click="showEditJobNumber">Return to Training Session Overview</button>
                        </div>
                        <div v-else>
                            <div class="d-inline" v-if="is_trainer || is_participant">
                                <join-links class="d-inline" :joinevent="training_session" :label="'Training Session'" :user="user"></join-links>
                            </div>
                            <div class="d-inline" v-if="is_trainer && training_session.is_complete == 0 && training_session.is_canceled == 0">
                                <button class="btn btn-secondary-alt btn-md w-100 mb-2" @click="showReschedule">Reschedule</button>
                                <button class="btn btn-secondary-alt btn-md w-100 mb-2" @click="showCancelConfirm">Cancel</button>
                            </div>
                            <div class="d-inline" v-if="training_session.scenario !== null">
                                <button class="btn btn-secondary-alt btn-md w-100 mb-2" @click="printScenario">Print Scenario</button>
                            </div>
                            <div v-if="is_trainer">
                                <p class="text-center" v-if="training_session.job_number !== null"><small>Job# {{training_session.job_number}}</small></p>
                                <button class="btn btn-secondary-alt btn-md w-100 mb-2" @click="showEditJobNumber"><span v-if="training_session.job_number !== null">Edit Job#</span><span v-else>Provide Job#</span></button>
                            </div>
                            <div class="d-inline" v-if="training_session.scenario !== null && training_session.is_complete == 0 && training_session.is_canceled == 0">
                                <h4 class="text-center">Practice Tools</h4>
                                <button class="btn btn-secondary btn-md w-100 mb-2" @click="shareScenario">Share Learner Information</button>
                                <button v-if="training_session.scenario.is_feedback_form_enabled" class="btn btn-secondary btn-md w-100 mb-2" @click="showFeedback">SP Scorecard</button>
                                <button v-if="has_verbal_feedback" class="btn btn-secondary btn-md w-100 mb-2" @click="printFeedback">Print Verbal Feedback</button>
                                <button class="btn btn-secondary btn-md w-100 mb-2" @click="shareZoomMessage">Share Stay On Zoom Message</button>
                            </div>
                            <a v-if="has_recording && training_session.is_complete == 1 && (is_trainer || is_participant)" class="btn btn-secondary btn-md w-100 mb-2" :href="recording_url" target="_blank">View Recording</a>
                            <button v-else-if="!has_recording && training_session.is_complete == 1 && (is_trainer || is_participant)" class="btn btn-secondary btn-md w-100 mb-2" @click="recordingError">View Recording</button>
                            <p class="text-muted small mt-1"  v-if="(is_trainer || is_participant) && training_session.is_complete == 0 && training_session.is_canceled == 0">If join link does not open try disabling browser's popup blocker</p>
                        </div>
                        <div class="text-center bg-light p-4 mb-4">
                            <p class="text-secondary"><strong>Trainer</strong></p>
                            <p class="mb-0">{{training_session.trainer.name}}</p><p class="mb-5"><small class="text-muted">{{training_session.trainer.email}}</small></p>
                            <div v-if="!is_participant">
                                <p class="text-secondary"><strong>Participants</strong></p>
                                <div v-for="participant in training_session.sp_users" v-bind:key="participant.id">
                                    <p class="mb-0">{{participant.user.name}}</p><p><small class="text-muted">{{participant.user.email}}</small></p>
                                </div>
                            </div>
                        </div>
                        <div v-if="is_trainer && !training_session.is_complete">
                            <button v-if="!show_edit_participants" class="btn btn-secondary-alt btn-md w-100 mb-2" @click="showEditParticipants">Edit Participants</button>
                            <div v-if="show_edit_participants">
                                <h4 class="mt-0 pt-0 mb-4">Remove Participants</h4>
                                <div v-for="participant in training_session.sp_users" v-bind:key="participant.id">
                                    <p class="mb-0">{{participant.user.name}}</p><p><small class="text-muted">{{participant.user.email}}</small></p>
                                    <button class="btn btn-primary btn-md" @click="removeUser(participant.id)">Remove</button>
                                    <hr />
                                </div>
                                <h4>Add Participants</h4>
                                <div>
                                    <h4>Select user(s)</h4>
                                    <div class="ts-users-select pt-2">
                                        <div v-if="available_users.length">
                                            <div v-for="ts_user in available_users" v-bind:key="ts_user.id">
                                                <label class="checkbox-custom ts-user-select w-100 px-4 pt-2 cursor-pointer">
                                                    <input type="checkbox" :value="ts_user.user_id" class="float-left mr-2" width="20" v-model="training_users" @change="has_user_error = false">
                                                    <span class="check-icon"><i class="fas fa-check"></i></span>
                                                    <span class="checkmark"></span>
                                                    <div class="ts-user-content overflow-hidden">
                                                        <img :src="!ts_user.user.image ? '/img/no_image.jpg' : ts_user.user.image " class="rounded-circle float-right" width="60">
                                                        <span>{{ts_user.user.first_name}} {{ts_user.user.last_name}}</span>
                                                        <br>
                                                        <small>{{ts_user.user.email}}</small>
                                                        <br>
                                                        <small v-if="ts_user.role_id == UserRole.SP">Standardized Patient</small>
                                                        <small v-if="ts_user.role_id == UserRole.OBSERVER">Observer</small>
                                                        <small v-if="ts_user.role_id == UserRole.TRAINER">Trainer</small>
                                                        <hr class="w-75 text-center">
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <p class="p-4">No users available for training sessions.</p>
                                        </div>
                                    </div>
                                    <div v-if="has_user_error" class="alert alert-danger mt-3">You must set users</div>
                                    <button class="btn btn-secondary-alt btn-md w-100 mt-4 mb-2" @click="addUsers">Add Users</button>
                                    <hr class="w-75 text-center">
                                </div>
                                <button class="btn btn-secondary-alt btn-md w-100 mb-2" @click="showEditParticipants">Return to Training Session Details</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-8 order-sm-1">
                        <scenario-card v-if="training_session.scenario !== null" :scenario="training_session.scenario" :formatted_field_data="formatted_field_data" />
                        <training-card v-else :training_session="training_session" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!loading && typeof(training_session) === 'undefined'">
        <h4>Error Loading Training Session</h4>
        <p>{{ loadErrorMessage }}</p>
    </div>
    <br>
    <loading :active="loading" :is-full-page="true"></loading>
</template>

<script lang="ts">
    import { defineComponent, ref } from "vue";
    import { store } from '../../store/index';
    import Loading from 'vue3-loading-overlay';
    import moment from 'moment';
    import {NotificationIcon, NotificationColor} from "@/interfaces/notification";
    import {MutationTypes} from "@/store/modules/ux/mutations";
    import Modal from "@/components/modal.vue";
    import JoinLinks from '@/components/join-links.vue';
    import {IScenarioFieldGroup} from "@/interfaces/scenario-field-group";
    import Scenario from "@/models/scenario";
    import ScenarioCard from "@/components/scenario-card.vue";
    import ScheduleForm from '@/components/schedule-form.vue';
    import TrainingCard from "@/components/training-card.vue";
    import TrainingSession from "@/models/training-session";
    import {TrainingSessionType} from "@/enums/training-session-type";
    import { useRoute } from 'vue-router';
    import {UserRole} from "@/enums/user-role";

    export default defineComponent({
        components: {Modal, JoinLinks, Loading, ScenarioCard, ScheduleForm, TrainingCard},
        mounted(){
            this.getTrainingSession();
            this.setStartDate();
        },
        setup() {
            const fields = ref<Array<IScenarioFieldGroup>>([]);
            const formatted_field_data = ref<Array<any>>([]);
            const loading = ref(false);
            const training_session = ref();
            const loadErrorMessage = ref('');
            const is_admin = ref<Boolean>(false);
            const is_trainer = ref<Boolean>(false);
            const is_participant = ref<Boolean>(false);
            const job_number_error = ref<String>("");
            const job_number_field = ref<String>("");
            const show_cancel = ref<Boolean>(false);
            const showCancelConfirm = ()=> show_cancel.value = !show_cancel.value;
            const show_reschedule = ref<Boolean>(false);
            const showReschedule = () => show_reschedule.value = !show_reschedule.value;
            const reschedule_modal = ref<Boolean>(false);
            const reschedule_date = ref<any>(null);
            const reschedule_error = ref<any>(false);
            const route = useRoute();
            const showRescheduleModal = () => reschedule_modal.value = !reschedule_modal.value;
            const start_date = ref<Date>(new Date());
            const has_verbal_feedback = ref<Boolean>(false);
            const temp_visit = ref({});
            const show_edit_job_number = ref<Boolean>(false);
            const show_edit_participants = ref<Boolean>(false);
            const showEditParticipants = () => show_edit_participants.value = !show_edit_participants.value;
            const available_users = ref({});
            const training_users = ref([]);
            const has_user_error = ref<Boolean>(false);

            const addUsers = async() => {
                if(!training_users.value.length){
                    has_user_error.value = true;
                    return false;
                }
                loading.value = true;
                var post_data = {
                    training_session_id: route.params.training_session_id,
                    users: training_users.value
                };
                await TrainingSession.addUsers(post_data).then(()=>{
                    training_users.value = [];
                    getTrainingSession();
                    store.commit(MutationTypes.addSystemNotification, {title:'User(s) added to Training Session.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err:any)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error adding user(s) from Training Session.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }
            const has_recording = ref<Boolean>(false);
            const recording_error = ref('');
            const recording_url = ref('');

            const cancel = async() => {
                loading.value = true;
                let post_data = {
                    training_session_id: route.params.training_session_id
                };
                await TrainingSession.cancel(post_data).then(()=>{
                    training_session.value.is_canceled = true;
                    show_cancel.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Training Session has been cancelled.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error cancelling training session.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const getTrainingSession = async () => {
                loading.value = true;
                await TrainingSession.get(route.params.training_session_id).then((res)=>{
                    training_session.value = res.data.training_session;
                    if(training_session.value.trainer_user_id == store.state.auth.user?.props.id) is_trainer.value = true;
                    is_participant.value = training_session.value.sp_users.filter(function(participant_data:any){return participant_data?.user_id == store.state.auth.user?.props.id;}).length > 0;
                    available_users.value = res.data.available_users;
                    if(training_session.value.is_complete){
                        getRecording();
                    }
                    job_number_field.value = training_session.value.job_number;
                },(err)=>{
                    loadErrorMessage.value = err.response.data.message;
                    loading.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Error loading training session.',content:err.response.statusText,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                });
                if(training_session.value !== 'undefined' && training_session.value.scenario !== null){
                    await Scenario.getFieldsByType(training_session.value.scenario_id, 1).then((res)=>{
                        fields.value = res.data.map((i:any)=>i as IScenarioFieldGroup);
                        mapFields();
                    },(err)=>{
                        store.commit(MutationTypes.addSystemNotification, {title:'Error loading scenario details.',content:err.response.statusText,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                    });
                    await Scenario.hasVerbalFeedback(training_session.value.scenario_id).then((res)=>{
                        has_verbal_feedback.value = res.data.has_verbal_feedback;
                    },(err)=>{
                        store.commit(MutationTypes.addSystemNotification, {title:'Error loading scenario details.',content:err.response.statusText,icon:NotificationIcon.ERROR, color:NotificationColor.RED});
                    });
                }
                loading.value = false;
            }

            const mapFields = () => {
                fields.value.forEach(function(group){
                    let hasData = false;
                    let formatted_fields:any = [];
                    group.fields.forEach(function(field){
                        let found_field = training_session.value.scenario.fields.find(function(elm:any){ return elm.scenario_field_id === field.id});
                        if(found_field){
                            hasData = true;
                            if(field.label == 'Learner Scenario') field.label = 'Scenario';
                            formatted_fields.push({
                                'title':field.label,
                                'value':found_field.value
                            });
                        }
                    });
                    if(hasData){
                        let array:any = [];
                        if(group.fields.length > 1){
                            array['title'] = group.label;
                        }else{
                            array['title'] = group.fields[0].label;
                        }
                        array['fields'] = formatted_fields;
                        formatted_field_data.value.push(array);
                    }
                });
            }

            const printScenario = async() => {
                loading.value = true;
                window.open('/#/scenarios/'+training_session.value.scenario_id+'/print','_blank','width=1400,height=800');
                loading.value = false;
            }

            const setDate = (date:any) => {
                reschedule_date.value = date.date
                reschedule_error.value = false;
            }

            const setStartDate = () => {
                start_date.value.setDate(start_date.value.getDate());
            }

            const shareZoomMessage = async() => {
                loading.value = true;
                window.open('/#/scenarios/1/zoom-message','_blank','width=700,height=800');
                loading.value = false;
            }

            const showEditJobNumber = () => {
                job_number_field.value = training_session.value.job_number;
                show_edit_job_number.value = !show_edit_job_number.value;
                job_number_error.value = "";
            }

            const showFeedback = () => {
                window.open('/#/training/'+training_session.value.id+'/practice/feedback','_blank');
            }

            const removeUser = async(training_user_id:Number) => {
                loading.value = true;
                let post_data = {
                    training_session_id: route.params.training_session_id,
                    training_user_id: training_user_id
                };
                await TrainingSession.removeUser(post_data).then(()=>{
                    getTrainingSession();
                    store.commit(MutationTypes.addSystemNotification, {title:'User removed from Training Session.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err:any)=>{
                    store.commit(MutationTypes.addSystemNotification, {title:'Error removing user from Training Session.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const rescheduleTrainingSession = async() => {
                loading.value = true;
                if(reschedule_date.value == null){
                    reschedule_error.value = 'Please provide a date for reschedule';
                    reschedule_modal.value = false;
                    loading.value = false;
                    return false;
                }
                let post_data = {
                    date: reschedule_date.value,
                    training_session_id: route.params.training_session_id
                };
                await TrainingSession.reschedule(post_data).then((res:any)=>{
                    training_session.value = res.data.training_session;
                    reschedule_modal.value = false;
                    show_reschedule.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Training Session rescheduled.',content:'',icon:NotificationIcon.SUCCESS,color:NotificationColor.GREEN});
                },(err:any)=>{
                    reschedule_modal.value = false;
                    store.commit(MutationTypes.addSystemNotification, {title:'Error rescheduling training session.',content:err.response.data.message,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
                });
                loading.value = false;
            }

            const shareScenario = async() => {
                loading.value = true;
                window.open('/#/scenarios/'+training_session.value.scenario_id+'/share','_blank','width=700,height=800');
                loading.value = false;
            }

            const printFeedback = async() => {
                loading.value = true;
                window.open('/#/scenarios/'+training_session.value.scenario_id+'/print-feedback','_blank','width=1400,height=800');
                loading.value = false;
            }

            const getRecording = async() => {
                loading.value = true;
                var post_data = {
                    training_session_id: training_session.value.id
                };
                await TrainingSession.getRecording(post_data).then((res)=>{
                    loading.value = false;
                    if(res.data.link !== 'undefined'){
                        has_recording.value = true;
                        recording_url.value = res.data.link;
                    }else{
                        has_recording.value = false;
                        recording_error.value = 'Link not generated';
                    }
                },(err)=>{
                    loading.value = false;
                    has_recording.value = false;
                    recording_error.value = err.response.data.message;
                });
            }

            const recordingError = async() => {
                store.commit(MutationTypes.addSystemNotification, {title:'Error retrieving recording.',content:recording_error.value,icon:NotificationIcon.ERROR,color:NotificationColor.RED});
            }

            const updateJobNumber = async() => {
                loading.value = true;
                var post_data = {
                    job_number: job_number_field.value == "" ? null : job_number_field.value.toUpperCase()
                }
                await TrainingSession.updateJobNumber(training_session.value.id, post_data).then(()=>{
                    loading.value = false;
                    training_session.value.job_number = job_number_field.value.toUpperCase();
                    showEditJobNumber();
                },(err)=>{
                    loading.value = false;
                    job_number_error.value = err.response.data.message;
                });
            }

            return {
                addUsers,
                available_users,
                cancel,
                formatted_field_data,
                getRecording,
                getTrainingSession,
                has_user_error,
                has_verbal_feedback,
                has_recording,
                is_admin,
                is_trainer,
                is_participant,
                job_number_error,
                job_number_field,
                loadErrorMessage,
                loading,
                moment,
                printFeedback,
                printScenario,
                removeUser,
                reschedule_error,
                reschedule_modal,
                rescheduleTrainingSession,
                recordingError,
                recording_error,
                recording_url,
                setDate,
                setStartDate,
                shareScenario,
                shareZoomMessage,
                show_cancel,
                showCancelConfirm,
                showEditParticipants,
                show_edit_participants,
                showEditJobNumber,
                show_edit_job_number,
                showFeedback,
                showReschedule,
                showRescheduleModal,
                show_reschedule,
                start_date,
                user: store.state.auth.user,
                training_session,
                TrainingSessionType,
                training_users,
                temp_visit,
                updateJobNumber,
                UserRole,
            };
        },
    });
</script>
<style scoped lang="scss">
    @import '@/assets/_variables.scss';
    .ts-users-select{
        height: 200px;
        border: 1px solid $gray-medium;
        overflow-y: scroll;
        .ts-user-select{
            .ts-user-content{
                margin-left: 3.5rem;
            }
            &:hover{
                background-color: $secondary;
                color: #fff;
                hr {
                    background-color: #fff;
                }
            }
        }
    }

    .checkbox-custom {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 6px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default radio button */
    .checkbox-custom input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 18px;
        left: 10px;
        height: 3rem;
        width: 3rem;
        background-color: $gray-light;
        border-radius: 50%;
        border: 1px solid $gray-medium;
        margin-top: 3px;
    }

    /* On mouse-over, add a grey background color */
    .checkbox-custom:hover input ~ .checkmark {
        background-color: #fff;
    }

    /* When the radio button is checked, add a blue background */
    .checkbox-custom input:checked ~ .checkmark {
        background-color: $gray-light;
        border-color: $primary
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    .check-icon{
        position: absolute;
        display: none;
        color: #fff;
        z-index: 1;
        top: 28px;
        left: 17px;
    }


    /* Show the indicator (dot/circle) when checked */
    .checkbox-custom input:checked ~ .checkmark:after,
    .checkbox-custom input:checked ~ .check-icon {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .checkbox-custom .checkmark:after {
        top: 2px;
        left: 2px;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        background: $primary;
    }

    #job_number_edit {
        text-transform: uppercase;
    }
</style>
